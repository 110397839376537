<template>
    <div class="main-container-wrapper" style="background-color: #101112;
        border-top: 2px solid #2C2E2F;
        height: calc(100vh - 95px);">

        <div class="main-container" :style="{'display': isDesktop ? 'flex' : 'none'}">
            <div class="main-tab" id="mainTab" ref="mainTab">
                <!-- WALLET/PROFILE PREVIEW -->
                <Transition name="modal">
                    <div v-if="UIPreview == 'profile'">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <h3 style="margin-right: auto;padding-top: 0px;padding-bottom: 0px;display: inline;"> Personal Dashboard </h3>
                            <router-link to="/" style="all: unset; margin: 0 10px;">
                                <button class="create-offer tooltip-container" style="margin: 0;">
                                    ⟵ HOME
                                    <span class="tooltip" style="right: -7px;bottom: -55px;font-size: 11px;">
                                        Navigate back to all markets preview.
                                    </span>
                                </button>
                            </router-link>
                        </div>

                        <div class="accordion" style="border: none; background: transparent;">
                            <input type="checkbox" id="assets" checked>
                            <label class="accordion-label" for="assets">
                                My Assets <span class="fa fa-chevron-down" v-if="!loadingUserAsset"></span>
                                <div v-if="loadingUserAsset" class="lumin-loader" style="margin-left: auto;"></div>
                            </label>
                            <div class="accordion-content">
                                <div class="table-wrapper profile-section" style="padding-left: 30px;">
                                    <table class="market-table" cellspacing="0">
                                        <thead>
                                            <tr style="cursor: auto;">
                                                <th></th>
                                                <th> Asset </th>
                                                <!-- <th> Ticker </th> -->
                                                <th> Balance </th>
                                                <th> Deposited </th>
                                                <th> Locked </th>
                                                <th></th>
                                                <th style="text-align: center; width: 70px;"> Deposit </th>
                                                <th style="text-align: center; width: 70px;"> Withdraw </th>
                                                <th style="text-align: center; width: 70px;"> Offer </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data of userWalletData" :key="data.id" style="height: 70px;cursor: auto;">
                                                <td style="border-bottom: 1px solid #353739;">
                                                    <img :src="data.image" alt="" style="width: 32px;height: 32px; border-radius: 25px;"
                                                        :style="{ background: data.ticker == 'MOCK' ? 'transparent' : '#fff' }">
                                                </td>
                                                <td style="text-align: right;border-bottom: 1px solid #353739;"> {{ data.name }} </td>
                                                <!-- <td> {{ data.ticker }} </td> -->
                                                <td style="text-align: right;border-bottom: 1px solid #353739;"> 
                                                    <p style="position: relative; bottom: -3px; margin: 2px;"> {{ formatPrice(data.ticker, data.available) }} </p>
                                                    <p style="position: relative; bottom: 3px; margin: 2px; color: #8f8f91;"> {{ data.available }} </p>
                                                </td>
                                                <td style="text-align: right;border-bottom: 1px solid #353739;"> 
                                                    <p style="position: relative; bottom: -3px; margin: 2px;"> {{ formatPrice(data.ticker, data.deposit) }} </p>
                                                    <p style="position: relative; bottom: 3px; margin: 2px; color: #8f8f91;"> {{ data.deposit }} </p>
                                                </td>
                                                <td style="text-align: right;border-bottom: 1px solid #353739;"> 
                                                    <p style="position: relative; bottom: -3px; margin: 2px;"> {{ formatPrice(data.ticker, data.deposit_lock) }} </p>
                                                    <p style="position: relative; bottom: 3px; margin: 2px; color: #8f8f91;"> {{ data.deposit_lock }} </p>
                                                </td>
                                                <td style="border-bottom: 1px solid #353739;"></td>
                                                <!-- <td style="display: flex; justify-content: center; position: relative; top: 10px; padding-right: 0;border-bottom: 1px solid #353739;height: 59px;"> -->
                                                <td style="text-align: center;border-bottom: 1px solid #353739;padding-right: 0;">
                                                    <button @click="openModal('DEPOSIT', 'Select the amount that you want to deposit', data)" :disabled="unsupportedNetwork"
                                                        class="tooltip-container"
                                                        style="position: relative;width: 50px;"
                                                        :style="{
                                                            opacity: parseInt(data.available) === 0 || unsupportedNetwork ? '0.25' : '1',
                                                            pointerEvents: parseInt(data.available) === 0 || unsupportedNetwork ? 'none' : 'unset'
                                                        }"> ⇓
                                                        <span class="tooltip" style="right: 40px; bottom: 5px; font-size: 11px; width: 90px; top: 5px;">
                                                            Deposit {{ data.name }}.
                                                        </span>
                                                    </button>
                                                </td>
                                                <td style="text-align: center;border-bottom: 1px solid #353739;padding-right: 0;">
                                                    <button @click="openModal('WITHDRAW', 'Select the amount that you want to withdraw', data)" :disabled="unsupportedNetwork"
                                                        class="tooltip-container"
                                                        style="position: relative;width: 50px;"
                                                        :style="{
                                                            opacity: parseInt(data.deposit) === 0 || unsupportedNetwork ? '0.25' : '1',
                                                            pointerEvents: parseInt(data.deposit) === 0 || unsupportedNetwork ? 'none' : 'unset'
                                                        }"> ⇑ 
                                                        <span class="tooltip" style="right: 40px; bottom: 5px; font-size: 11px; width: 100px; top: 5px;">
                                                            Withdraw {{ data.name }}.
                                                        </span>
                                                    </button>
                                                </td>
                                                <td style="text-align: center;border-bottom: 1px solid #353739;padding-right: 0;">
                                                    <button @click="openModal('CREATE OFFER', 'Create loan offer', data)" :disabled="unsupportedNetwork"
                                                        class="tooltip-container"
                                                        style="position: relative;width: 50px;"
                                                        :style="{
                                                            opacity: parseInt(data.deposit) === 0 || unsupportedNetwork ? '0.25' : '1',
                                                            pointerEvents: parseInt(data.deposit) === 0 || unsupportedNetwork ? 'none' : 'unset'
                                                        }"> ⊕  
                                                        <span class="tooltip" style="right: 40px; bottom: 5px; font-size: 11px; width: 90px; top: 5px;">
                                                            Offer {{ data.name }}.
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="userWalletData.length == 0" style="display: flex;
                                        color: #8F8F91;
                                        height: 60px;
                                        align-items: center;
                                        justify-content: center;">
                                            Loading wallet data
                                        <!-- <spinner style="padding-left: 10px;"></spinner> -->
                                    </div>

                                    <div v-if="userWalletData.length == 0" style="display: flex;
                                        color: rgb(143, 143, 145);
                                        height: 0px;
                                        align-items: center;
                                        justify-content: center;">
                                            <p class="dots-loading"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion" style="border: none; background: transparent;">
                            <input type="checkbox" id="offers">
                            <label class="accordion-label" for="offers">
                                My Offers <span class="fa fa-chevron-down" v-if="!loadingUserOffers"></span>
                                <div v-if="loadingUserOffers" class="lumin-loader" style="margin-left: auto;"></div>
                            </label>
                            <div class="accordion-content">
                                <div class="table-wrapper profile-section" style="padding-left: 30px;">
                                    <table v-if="userOffersData.length > 0" class="market-table" cellspacing="0">
                                        <thead>
                                            <tr style="cursor: auto;">
                                                <!-- <th style="line-height: 12px;"> Config ID </th> -->
                                                <th style="line-height: 12px;"> Active <br> Loans </th>
                                                <!-- <th style="line-height: 12px;"> Total / <br> Total Max. </th> -->
                                                <th> Principal Asset </th>
                                                <th> Accepted Collateral </th>
                                                <th> Accepted Interest </th>
                                                <th> Interest </th>
                                                <td style="line-height: 12px;color: #8F8F91;border-bottom: 1px solid #353739;text-align: right;font-size: 14px;"> Repayment <br> Type </td>
                                                <!-- <th style="line-height: 12px;"> Instalments <br> paid </th>
                                                <th> Earnings </th> -->
                                                <th style="text-align: center;"> Actions </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="wallet of userOffersData" :key="wallet.id" style="cursor: auto;">
                                                <!-- <td> {{ wallet.configId }} </td> -->
                                                <td style="border-bottom: 1px solid #353739;"> {{ wallet.active_loans }} </td>
                                                <td style="border-bottom: 1px solid #353739;"> {{ wallet.principal_asset }} </td>
                                                <!-- <td> 
                                                    <p style="position: relative; bottom: -3px; margin: 2px;"> ${{ wallet.total }} </p>
                                                    <p style="position: relative; bottom: 3px; margin: 2px; color: #8f8f91;"> ${{ wallet.total_max }} </p>
                                                </td> -->
                                                <td style="border-bottom: 1px solid #353739;"> {{ wallet.collateral_assets }} </td>
                                                <td style="border-bottom: 1px solid #353739;"> {{ wallet.interest_assets }} </td>
                                                <td style="border-bottom: 1px solid #353739;"> {{ wallet.interest }}% </td>
                                                <td style="border-bottom: 1px solid #353739; color: #8f8f91;"> 
                                                    <div class="tooltip-container" style="display: flex;align-items: center;justify-content: flex-end;font-size: 14px;position: relative;">
                                                        <span style="padding-right: 5px;"> {{ wallet.repayment_type }}  </span>
                                                        <span class="fa fa-question-circle" style="color: #FFF;"></span>

                                                        <span v-if="wallet.repayment_type == 'Type 1'" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -10px; bottom: -10px;left: -120px;">
                                                            Interest will be paid every term and principal will be paid at the end of the loan.
                                                        </span>
                                                        <span v-if="wallet.repayment_type == 'Type 2'" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -10px; bottom: -10px;left: -120px;">
                                                            Interest and principal will be paid every term.
                                                        </span>
                                                        <span v-if="wallet.repayment_type == 'Type 3'" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -10px; bottom: -10px;left: -120px;">
                                                            Interest and principal will be paid at the end of the loan.
                                                        </span>
                                                    </div>
                                                </td>
                                                <!-- <td> {{ wallet.installments_paid }} / {{ wallet.installments_sum }} </td>
                                                <td style="color: #008000;">
                                                    <strong> +${{ wallet.earnings }} </strong>
                                                </td> -->
                                                <td style="display: flex; justify-content: center; position: relative; padding-right: 0;border-bottom: 1px solid #353739;">
                                                    <button @click="openModal('CONFIG STATS', 'Preview of all loans taken under this offer configuration', wallet)" 
                                                        class="personal-offer-stats tooltip-container" :disabled="(wallet.active_loans == 0) || unsupportedNetwork">
                                                        <i class="fa fa-bar-chart"></i>

                                                        <span class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -5px; bottom: -5px; left: -200px;">
                                                            Check statistics of all loans taken under this offer configuration.
                                                        </span>
                                                    </button>

                                                    <button @click="changeOfferAvaliablity(wallet)" class="personal-offer-stats tooltip-container" :disabled="unsupportedNetwork">
                                                        <i :style="{ color: wallet.enabled ? 'green' : 'red' }" class="fa fa-toggle-off"></i>

                                                        <span v-if="wallet.enabled" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -10px; bottom: -10px; left: -190px;">
                                                            Borrowers are able to take loans under this offer configuration. Click to disable.
                                                        </span>

                                                        <span v-if="!wallet.enabled" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: -10px; bottom: -10px; left: -190px;">
                                                            Borrowers are not able to take loans under this offer configuration. Click to enable.
                                                        </span>
                                                    </button>

                                                    <button @click="deleteOffer(wallet)" :disabled="(wallet.active_loans > 0) || unsupportedNetwork" class="personal-offer-stats tooltip-container">
                                                        <i class="fa fa-trash"></i>

                                                        <span class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 90px;top: 0px; bottom: 0px; left: -120px;">
                                                            Delete offer configuration.
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="userOffersData.length == 0" style="display: flex;
                                        color: #8F8F91;
                                        height: 150px;
                                        align-items: center;
                                        justify-content: center;">
                                            Start with creating first offer configuration.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion" style="border: none; background: transparent;">
                            <input type="checkbox" id="borrows">
                            <label class="accordion-label" for="borrows">
                                My Loans <span class="fa fa-chevron-down" v-if="!loadingUserLoans"></span>
                                <div v-if="loadingUserLoans" class="lumin-loader" style="margin-left: auto;"></div>
                            </label>
                            <div class="accordion-content">
                                <div class="table-wrapper profile-section" style="padding-left: 30px;">
                                    <table class="market-table" cellspacing="0">
                                        <thead>
                                            <tr style="cursor: auto;">
                                                <th> Status </th>
                                                <th> Debt Amount </th>
                                                <th> Debt Native </th>
                                                <th style="line-height: 12px;"> Principal <br> to pay </th>
                                                <th style="line-height: 12px;"> Interest <br> to pay </th>
                                                <!-- <th> Asset Type </th> -->
                                                <th> Collateral </th>
                                                <th> Interest </th>
                                                <!-- <th> Terms </th> -->
                                                <!-- <th> Health </th> -->
                                                <th style="text-align: center;"> Actions </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="wallet of userLoansData" :key="wallet.id" style="cursor: auto;" :style="{ display: wallet.visible ? 'table-row' : 'none' }">
                                                <template v-if="wallet.visible">
                                                    <td> 
                                                        {{ wallet.status }}
                                                        <span class="fa fa-done" style=";margin-right: 5px;border: 2px solid;border-radius: 20px;padding: 3px;"
                                                            :style="{ color: wallet.status == 'Open' ? '#008000' : '#808080' }"></span>
                                                    </td>
                                                    <td>
                                                        <p style="line-height: 20px; margin: 0;"> {{ formatPrice(wallet.vanilla_config_data.principalAsset.symbol, wallet.loan_vanilla_data?.principalAmountNative) }} </p> 
                                                    </td>
                                                    <td>
                                                        <p style="line-height: 20px; margin: 0; color: #fff;"> {{ wallet.loan_vanilla_data?.principalAmountNative }} <span style="color: #808080;">{{ wallet.vanilla_config_data.principalAsset.symbol }}</span>  </p> 
                                                    </td>
                                                    <td>
                                                        <div v-if="wallet.loan_vanilla_data?.pendingPrincipalAmountNative > 0">
                                                            <p style="line-height: 20px; margin: 0;color: #fff;"> {{ formatPrice(wallet.vanilla_config_data.principalAsset.symbol, wallet.loan_vanilla_data?.pendingPrincipalAmountNative, 2) }}  </p> 
                                                            <p style="line-height: 20px; margin: 0;color: #808080;"> {{ parseFloat(wallet.loan_vanilla_data?.pendingPrincipalAmountNative).toFixed(6) }} </p> 
                                                        </div>
                                                        <div v-if="wallet.loan_vanilla_data?.pendingPrincipalAmountNative == 0">
                                                            <p> Paid <span class="fa fa-check-circle" style="color: #008000"></span> </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="wallet.loan_vanilla_data?.pendingInterestAmountNative > 0">
                                                            <p style="line-height: 20px; margin: 0;color: #fff;"> {{ formatPrice(wallet.vanilla_config_data.principalAsset.symbol, wallet.loan_vanilla_data?.pendingInterestAmountNative, 2) }}  </p> 
                                                            <p style="line-height: 20px; margin: 0;color: #808080;"> {{ parseFloat(wallet.loan_vanilla_data?.pendingInterestAmountNative).toFixed(6) }} </p> 
                                                        </div>
                                                        <div v-if="wallet.loan_vanilla_data?.pendingInterestAmountNative == 0">
                                                            <p> Paid <span class="fa fa-check-circle" style="color: #008000"></span> </p>
                                                        </div>
                                                    </td>
                                                    <!-- <td> ERC-20 </td> -->
                                                    <td> {{ wallet.collateral }} </td>
                                                    <td> {{ wallet.vanilla_config_data.interestPromille / 10 }}% </td>
                                                    <!-- <td> ... / {{ wallet.vanilla_config_data.terms }} </td> -->
                                                    <td v-if="wallet.pending_changes" style="position: relative; right: 40px;">
                                                        <div class="lumin-loader" style="margin-left: auto;"></div>
                                                    </td>
                                                    <td v-if="!wallet.pending_changes" style="display: flex; justify-content: space-around;">
                                                        <button @click="openModal('LOAN DETAILS', '', wallet)" :disabled="wallet.status == 'Closed' || unsupportedNetwork" class="personal-loans tooltip-container"
                                                            style="position: relative;justify-content: space-evenly;width: 35%;margin: 5px 0;padding: 1px 6px;">
                                                            <i class="fa fa-money"></i>
                                                            <span class="tooltip" style="position: absolute; right: 40px; bottom: 0px; font-size: 11px; width: 100px; top: 0px;background: rgba(51, 51, 51, 0.9);border: 1px solid rgba(34, 34, 34, 0.9);box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);color: #bbb;border-radius: 3px;padding-top: 2px;">
                                                                Manage Interest and Principal payments.
                                                            </span>
                                                        </button>

                                                        <button @click="openModal('ADJUST COLLATERAL', 'Ensure that your loan has a sufficient collateral ratio to avoid the risk of liquidation.', wallet)" class="personal-loans tooltip-container"
                                                            :style="{
                                                                background: wallet.health == 0 ? '#28272A' : (wallet.health < 1.3 ? '#FF0000' : (wallet.health < 1.5 ? '#ffa500' : '#28272A')),
                                                                border: wallet.health == 0 ? 'none' : (wallet.health < 1.3 ? '2px solid rgb(115 28 28)' : (wallet.health < 1.5 ? '2px solid rgb(134 88 4)' : 'none'))
                                                            }" style="position: relative;justify-content: space-evenly; width: 35%; margin: 5px 0px; padding: 1px 6px;"
                                                            >
                                                            <span v-if="wallet.health > 0" style="margin: 0px; border-radius: 5px; background: none; color: #fff; font-size: 14px;"> {{ wallet.health }} </span>
                                                            <span v-if="wallet.health == 0" style="margin: 0px; border-radius: 5px; background: none; font-size: 18px;"> 
                                                                <span class="fa fa-check-circle" style="width: auto;color: rgb(0, 128, 0);margin: 0;background: none;"></span> 
                                                            </span>
                                                            <span class="tooltip" style="position: absolute; right: 40px; bottom: 0px; font-size: 11px; width: 100px; top: 0px;background: rgba(51, 51, 51, 0.9);border: 1px solid rgba(34, 34, 34, 0.9);box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);color: #bbb;border-radius: 3px;padding-top: 2px;">
                                                                Adjust collateral and manage loan health.
                                                            </span>
                                                        </button>
                                                    </td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="userLoansData.length == 0" style="display: flex;
                                        color: #8F8F91;
                                        height: 150px;
                                        align-items: center;
                                        justify-content: center;">
                                            You have no active loans.
                                            <router-link to="/" style="all: unset;">
                                                <span class="underline" style="cursor: pointer; color: #fff">
                                                    &nbsp;Check markets&nbsp;
                                                </span> and pick the asset you want to borrow.
                                            </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                
                <select @change="changePriceSource($event.target.value)" style="display: none;height: max-content; bottom: 20px;left: 20px;position: absolute;background: #101112;border: none;color: #fff;text-align: center;">
                    <option value="coingecko"> CoinGecko API </option>
                    <option value="dia"> Diadata.org </option>
                    <option disabled value="chainlink"> Chainlink </option>
                </select>
            </div>

            <div id="tabsManager" ref="tabsManager" style="display: none;">
                <button @click="manageSidebar()" id="tabsManagerButton">
                    <span v-if="tabsManagerOpen" class="fa fa-angle-left"></span>
                    <span v-if="!tabsManagerOpen" class="fa fa-angle-right"></span>
                </button>
            </div>
                
            <!-- WIDGET WITH LATEST EVENT ON USER LEVEL -->
            <div v-if="UIPreview == 'profile'" class="deposits-tab">
                <h3 style="color: #FDFCFF;"> 
                    Latest Actions 
                    <!-- <spinner style="padding-left: 10px;" v-if="latestActionsProfile.length == 0"></spinner> -->
                </h3>

                <div v-if="latestActionsProfile?.length == 0" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 70px;
                    align-items: center;
                    justify-content: center;">
                        <span> Watching for new personal transactions </span>
                </div>

                <div v-if="latestActionsProfile?.length == 0" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 0px;
                    align-items: center;
                    justify-content: center;">
                        <p :class="{ 'dots-loading': latestActionsProfile.length === 0 }"></p>
                </div>

                <div class="deposits-table-wrapper">
                    <div v-for="action of latestActionsProfile" :key="action.id" class="deposits-table">
                        <span class="deposits-cell" style="text-align: left; padding-left: 5px;"> 
                            <template v-if="action.action == 'Lock'"> <i class="fa fa-lock" style="color: #fff;padding-right: 2px;"></i> Lock </template>
                            <template v-if="action.action == 'Unlock'"> <i class="fa fa-unlock" style="color: #fff;padding-right: 2px;"></i> Unlock </template>
                            <template v-if="action.action == 'PayPrincipal'"> $ Principal </template>
                            <template v-if="action.action == 'PayInterest'"> $ Interest </template>
                            <template v-if="action.action == 'PayFees'"> $ PayFees </template>
                            <template v-if="action.action == 'Lend'"> ⇒ Lend </template>
                            <template v-if="action.action == 'Deposit'"> ⇓ Deposit </template>
                            <template v-if="action.action == 'Withdraw'"> ⇑ Withdraw </template>
                        </span>
                        <img :src="action.asset_img" alt="" style="width: 20px; height: 20px;">
                        <span class="currency-cell" style="display: flex; flex-direction: column; overflow: hidden; text-align: right; line-height: 1;">
                            <span> {{ action.asset }} </span>
                            <span style="color: #808080;"> {{ action.symbol }} </span>
                        </span>
                        <span class="currency-cell" style="display: flex; flex-direction: column; overflow: hidden; text-align: right; line-height: 1; padding-left: 10px;">
                            <span> {{ action.amount }}  </span>
                            <span style="color: #808080;"> {{ formatPrice(action.symbol, action.amount, 2) }} </span>
                        </span>
                        <span class="wallet-cell" style="text-align: right; padding-left: 10px;"> {{ action.userAddressShort }} </span>
                        <span class="ticker-cell" style="display: none">
                            <template> {{ action._timestamp }} </template>
                        </span>
                        <span class="tx-hash tooltip-container">
                            <a :href="'https://testnet.bscscan.com/tx/' + action.transactionHash"
                                target="_blank"
                                class="fa fa-fw fa-external-link"
                                style="cursor: pointer;"
                            ></a>
                            <span class="tooltip"
                                style="right: 0px;
                                bottom: 30px;
                                font-size: 12px;
                                width: 110px;
                                padding: 5px 5px;
                                color: #fff;
                                background: #8080802e;">
                                    Check transaction details
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mobile-stats-view" style="display: flex; justify-content: center; text-align: center; flex-direction: column;">
        <div style="display: flex; justify-content: center; align-items: center;">
            <img :src="logoSrc" alt="" style="width: 40px; height: 40px;">
            <h3 style="color: #fff; padding-left: 10px; font-size: 22px;"> Lumin Finance Stats </h3>
        </div>

        <div style="display: flex; justify-content: flex-start; align-items: center; padding-left: 30px;">
            <p style="color: #fff; margin: 5px 5px 5px; font-size: 18px;"> Arbitrum Markets </p>
            <img :src="arbLogoSrc" alt="" style="width: 20px;">
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="wBTC_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> Bitcoin </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 23.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="wETH_32" alt="" style="width: 20px; width: 20px; background: #fff; border-radius: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> Ethereum </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 13.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="USDC_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> USDC </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 52.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="tether_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> USDT </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 3.23% </span>
            </div>
        </div>
        
    </div>
  
    <!-- MODAL TEMPLATES -->
    <div>
        <Teleport to="#modal">
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'DEPOSIT'">
                    <div class="modal">
                        <h1 class="title" style="margin: 20px 0 0;"> {{ modalTitle }} </h1>
                        <p class="body" style="font-size: 16px; margin-bottom: 5px;"> {{ modalContent }} </p>

                        <div class="deposit-view" style="width: 70%; padding-bottom: 30px;">
                            <div class="asset-amount" style="padding-top: 20px;width: 400px;">
                                <div class="asset-amounts">
                                    <input type="number" step="0.1" min="0" v-model="modalData.deposit_amount" style="font-size: 24px;">
                                    <img :src="modalData.image" alt="" style="position: relative; top: 5px; height: 32px;">
                                </div>

                                <div style="padding-bottom: 15px;">
                                    <input id="rangeSlider" class="range" type="range" name="" min="0" :max="modalData.available" step="0.001"
                                        v-model="modalData.deposit_amount"
                                        @change="rangeSlide(modalData.deposit_amount, modalData.available)" 
                                        @mousemove="rangeSlide(modalData.deposit_amount, modalData.available)"
                                        style="width: 100%;">
                                </div>

                                <div class="details">
                                    <span style="font-size: 14px;"> 
                                        Available: {{ modalData.available }} {{ modalData.ticker }}
                                        <span @click="setMaxAmount()" style="
                                            font-size: 10px;
                                            border: 1px solid grey;
                                            border-radius: 5px;
                                            cursor: pointer;
                                            padding: 0 5px;"> MAX </span>
                                    </span>
                                    <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.deposit_amount) }} </span>
                                </div>

                                <!-- <div class="details">
                                    <span style="font-size: 14px;"> 
                                        Current Deposit: {{ modalData.deposit }} {{ modalData.ticker }}
                                    </span>
                                    <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.deposit) }} </span>
                                </div> -->
                            </div>
                        </div>
  
                        <div class="btn-container" style="padding: 0px 0px 20px;">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>
                            <button @click="isModalOpen = false" class="btn-close"> Close </button>

                            <button v-if="modalData.deposit_amount > modalData.allowed"
                                :disabled="(modalData.deposit_amount <= modalData.allowed) || modalData.processing_tx"
                                @click="setAllowance()"
                                class="btn-submit">
                                    <span v-if="!modalData.processing_tx"> Approve </span> 
                                    <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>

                            <button v-if="modalData.deposit_amount <= modalData.allowed"
                                :disabled="validateDepositInputs() || modalData.processing_tx"
                                :style="{'cursor': validateDepositInputs() ? 'not-allowed' : 'pointer'}"
                                class="btn-submit"
                                @click="assetDeposit()"> 
                                    <span v-if="!modalData.processing_tx"> Deposit </span> 
                                    <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>

                            <!-- <button disabled class="btn-submit"> Allow </button> -->
                            <!-- (click)="setAllowance()" *ngIf="title == 'DEPOSIT' && (workingAsset.deposit > workingAsset.allowed)" -->
                            <!-- <button @click="isModalOpen = false" class="btn-submit"> Deposit </button> -->
                        </div>
                    </div>
                </div>
            </Transition>
  
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'WITHDRAW'">
                    <div class="modal">
                        <h1 class="title" style="margin: 20px 0 0;"> {{ modalTitle }} {{ modalData.asset }} </h1>
                        <p class="body" style="font-size: 16px; margin-bottom: 5px;"> {{ modalContent }} </p>

                        <div class="deposit-view" style="width: 70%; padding-bottom: 10px;">
                            <div class="asset-amount" style="padding-top: 20px;width: 400px;">
                                <div class="asset-amounts">
                                    <input type="number" step="0.1" min="0" v-model="modalData.withdraw_amount" style="font-size: 24px;">
                                    <img :src="modalData.image" alt="" style="position: relative; top: 5px; height: 32px;">
                                </div>

                                <div style="padding-bottom: 15px;">
                                    <input id="rangeSlider" class="range" type="range" name="" min="0" :max="modalData.deposit" step="0.001"
                                        v-model="modalData.withdraw_amount"
                                        @change="rangeSlide(modalData.withdraw_amount, modalData.deposit)" 
                                        @mousemove="rangeSlide(modalData.withdraw_amount, modalData.deposit)"
                                        style="width: 100%;">
                                </div>

                                <div class="details">
                                    <span style="font-size: 14px;"> 
                                        Available: {{ modalData.deposit }} {{ modalData.ticker }}
                                        <span @click="setMaxAmount()" style="
                                            font-size: 10px;
                                            border: 1px solid grey;
                                            border-radius: 5px;
                                            cursor: pointer;
                                            padding: 0 5px;"> MAX </span>
                                    </span>
                                    <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.withdraw_amount) }} </span>
                                </div>
                            </div>
                        </div>
  
                        <div class="btn-container" style="padding: 20px 0px;">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>
                            <button @click="isModalOpen = false" class="btn-close">Close</button>

                            <button :disabled="validateWithdrawInputs() || modalData.processing_tx"
                                class="btn-submit"
                                :style="{'cursor': validateWithdrawInputs() ? 'not-allowed' : 'pointer'}" 
                                @click="assetWithdraw()">
                                    <span v-if="!modalData.processing_tx"> Withdraw </span>
                                    <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
  
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'CREATE OFFER'">
                    <div class="modal" style="top: 50px">
                        <h1 class="title" style="margin: 20px 0 0;"> LOAN CONFIGURATION - {{ modalData.asset }} </h1>
                        <p class="body" style="font-size: 16px; margin-bottom: 15px;"> {{ modalContent }} </p>

                        <div class="deposit-view" style="width: 90%; padding-bottom: 0px;">
                            <div style="display: flex;
                                justify-content: space-evenly;
                                width: 100%;border-top: 2px solid #353739;">
                                <button class="btn-pick" :style="{
                                        background: currentStep == 1 ? '#353739' : '#1c1b1d'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 1"> Principal Asset </button>

                                <button class="btn-pick" :style="{
                                        background: currentStep == 2 ? '#353739' : '#1c1b1d',
                                        opacity: validateFirstStep() ? '1' : '0.25',
                                        'pointer-events': validateFirstStep() ? 'unset' : 'none',
                                        cursor: validateFirstStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 2"> Collateral & <br> Interest Assets </button>

                                <button class="btn-pick" :style="{
                                        background: currentStep == 3 ? '#353739' : '#1c1b1d',
                                        opacity: validateSecondStep() ? '1' : '0.25',
                                        'pointer-events': validateSecondStep() ? 'unset' : 'none',
                                        cursor: validateSecondStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 3"> Interest & Payment </button>

                                <button class="btn-pick" :style="{
                                        background: currentStep == 4 ? '#353739' : '#1c1b1d',
                                        opacity: validateThirdStep() ? '1' : '0.25',
                                        'pointer-events': validateThirdStep() ? 'unset' : 'none',
                                        cursor: validateThirdStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 4"> Loan Summary </button>
                            </div>
                        </div>

                        <div class="deposit-view" style="padding-top: 15px; padding-bottom: 15px;">
                            <div class="stepper-horizontal-interest" ref="stepperInterest" style="margin: 0px auto; width: 90%;">
                                <div class="step" style="padding-bottom: 30px;">
                                    <div class="tooltip-container">
                                        <span class="interest-term-number" style="left: 25%; display: none;"> 1
                                            <span class="tooltip"
                                                style="right: -50px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    First Instalment 10/22/2023 23:52:05
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container">
                                        <span class="interest-term-number" style="left: 50%; display: none;"> 2
                                            <span class="tooltip"
                                                style="right: -50px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    Second Instalment 11/22/2023 23:52:05
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container">
                                        <span class="interest-term-number" style="left: 75%; display: none;"> 3
                                            <span class="tooltip"
                                                style="right: -50px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    Third Instalment 12/22/2023 23:52:05
                                            </span>
                                        </span>
                                    </div>
                                    
                                    <div class="tooltip-container">
                                        <span class="interest-term-number" style="left: 100%; display: none;"> 4
                                            <span class="tooltip"
                                                style="right: -50px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    Fourth Instalment 01/22/2024 23:52:05
                                            </span>
                                        </span>
                                    </div>

                                    <Transition name="modal">
                                        <div style="position: relative; left: -25px; top: -25px;" v-if="minimum_loan_indicator > 0 && minimum_loan_indicator <= 100">
                                            <span class="interest-timeline-indicator" :style="{'left': (minimum_loan_indicator - 2.5) + '%'}" style="padding: 0 10px; line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 0px; font-weight: 100; width: min-content;"> 
                                                MINIMUM LOAN
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Minimum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                            <span class="interest-timeline-indicator" :style="{'left': minimum_loan_indicator + '%'}" style="text-align: center; font-size: 12px; top: 20px; color: #e8e600;"> ▼
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Minimum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                        </div>
                                    </Transition>

                                    <Transition name="modal">
                                        <div style="position: relative; left: -25px; top: -25px;" v-if="maximum_loan_indicator > 0 && maximum_loan_indicator <= 100">
                                            <span class="interest-timeline-indicator" :style="{'left': (maximum_loan_indicator - 2.5) + '%'}" style="padding: 0 10px; background: #101112; line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 0px; font-weight: 100; width: min-content; z-index: 10;"> 
                                                MAXIMUM LOAN
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Maximum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                            <span class="interest-timeline-indicator" :style="{'left': maximum_loan_indicator + '%'}" style="text-align: center; font-size: 12px; top: 20px; color: #e8e600;"> ▼
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Maximum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                        </div>
                                    </Transition>

                                    <Transition name="modal">
                                        <div style="position: relative; left: -25px; top: -25px;" v-if="allocated_deposit_indicator > 0 && allocated_deposit_indicator <= 100">
                                            <span class="interest-timeline-indicator" :style="{'left': (allocated_deposit_indicator - 3.5) + '%'}" style="padding: 0 10px; background: #101112; line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content; z-index: 10;"> 
                                                ALLOCATED DEPOSIT
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Amount of deposit that will be allocated to this offer configuration.
                                                </span>
                                            </span>
                                            <span class="interest-timeline-indicator" :style="{'left': allocated_deposit_indicator + '%'}" style="text-align: center; font-size: 12px; top: 35px; color: #009bda;"> ▲
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Amount of deposit that will be allocated to this offer configuration.
                                                </span>
                                            </span>
                                        </div>
                                    </Transition>

                                    <div style="position: relative; left: -25px; top: -25px;">
                                        <!-- class="tooltip-container" -->
                                        <span class="interest-timeline-indicator" :style="{'left': (available_loan_asset_indicator - 2.5) + '%'}" style="padding: 0 10px; line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content;"> 
                                            AVAILABLE DEPOSIT
                                            <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px; font-weight: 100;">
                                                Available asset balance that can be used for this offer configuration.
                                            </span>
                                        </span>
                                        <span class="interest-timeline-indicator" :style="{'left': available_loan_asset_indicator + '%'}" style="text-align: center; font-size: 12px; top: 35px; color: #009bda;"> ▲
                                            <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px; font-weight: 100;">
                                                Available asset balance that can be used for this offer configuration.
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container">
                                        <span v-if="interestAssetAmount > 0" class="interest-future-payoff-indicator" 
                                            :style="{ left: (15 + interestFuturePayoffBar - 1.5) + '%' }"> ▼ 
                                        </span>
                                        <span class="tooltip"
                                            style="bottom: 55px;
                                            font-size: 12px;
                                            width: 110px;
                                            padding: 5px 5px;
                                            color: #fff;
                                            word-wrap: break-word;
                                            font-weight: 100;
                                            background: transparent;"
                                            :style="{ right: 15 + interestFuturePayoffBar + '%' }">
                                                Status after new payment completed
                                        </span>
                                    </div>

                                    <div class="step-bar-right" style="background: #808080;"></div>
                                </div>
                            </div>
                        </div>
  
                        <div style="display: flex;
                            width: 100%;
                            place-content: center;
                            justify-content: center;
                            flex-direction: column;">
  
                            <div v-if="currentStep == 1" style="height: 100%;">
                                <!-- <div v-for="offer of walletData" :key="offer.id"> -->
                                <div>
                                    <div class="deposit-view" style="padding-bottom: 10px; width: 75%;">
                                        <div class="summary-amount offer-config-params" style="border-top: 2px solid #353739; margin-top: -10px;">
                                            <div class="details">
                                                <span> Principal Asset: </span>
                                                <span class="tooltip-container" style="position: relative; right: 38%;"> 
                                                    <span class="fa fa-question-circle" style="font-size: 14px;"></span> 
                                                    <span class="tooltip" style="top: -100px; bottom: 30px; left: 0px; font-size: 13px; width: 150px;">
                                                        Choose the oracle that will be used to calculate the price of the asset that you want to offer.
                                                    </span>
                                                    Oracle: 
                                                </span>
                                            </div>
                                            <div class="details">
                                                <div class="cell-asset-wrapper">
                                                    <img :src="modalData.image" alt="" :style="{ background: modalData.ticker == 'MOCK' ? 'transparent' : '#fff' }">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ modalData.asset }} </span>
                                                        <span style="text-align: left; color: #808080;"> {{ modalData.ticker }} </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-picker">
                                                    <div style="display: flex;">
                                                        <template v-if="loadingOracleFeeds">
                                                            <div class="lumin-loader"></div>
                                                        </template>

                                                        <Transition name="modal">
                                                            <div v-if="enabledOracleFeeds.length > 0">
                                                                <div v-for="oracle in enabledOracleFeeds" :key="oracle.enabled" class="collateral-asset">
                                                                    {{ oracle.description }}
                                                                    <label class="switch">
                                                                        <input type="checkbox" v-model="oracle.use_oracle" @change="principalAssetOracleChange($event, oracle)">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Transition>

                                                        <div v-if="enabledOracleFeeds.length == 0 && !loadingOracleFeeds" class="collateral-asset" style="font-style: italic;">
                                                            No oracle Price Feeds for asset.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="offer-params-container" style="display: flex; align-items: center;">
                                    <div class="offer-config-params" style="margin: 5px 0px 0px;">
                                        <div class="amounts" style="font-weight: 700;">
                                            <span class="tooltip-container" style="display: inline; width: 120px;position: relative;">
                                                Available deposit <span class="fa fa-question-circle" style="display: inline; font-size: 14px;"></span> 
                                                <span class="tooltip" style="position: absolute;top: -30px; bottom: -20px; left: 130px; font-size: 13px; width: 150px; font-weight: 100;">
                                                    Available asset balance that can be used for this offer configuration.
                                                </span>
                                            </span>
                                            <!-- <input [(ngModel)]="minimum_loan" type="number"> {{ asset_to_offer }} -->
                                            <div style="text-align: right;">
                                                <input disabled v-model="modalData.deposit" type="number" style="font-weight: 700; width: 50%; outline: none; margin-right: 5px;"> {{ modalData.ticker }}
                                            </div>
                                            <span style="text-align: right;"> {{ formatPrice(modalData.ticker, modalData.deposit) }} </span>
                                        </div>

                                        <div class="amounts">
                                            <span class="tooltip-container" style="display: inline; width: 120px;position: relative;">
                                                Minimum loan <span class="fa fa-question-circle" style="display: inline; font-size: 14px;"></span>
                                                <span class="tooltip" style="position: absolute;top: -10px; bottom: -20px; left: 130px; font-size: 13px; width: 150px;">
                                                    Minimum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                            <!-- <input [(ngModel)]="minimum_loan" type="number"> {{ asset_to_offer }} --> 
                                            <div style="text-align: right;">
                                                <input required v-model="loanConfiguration.minPrincipalAmount" @change="loanAmountsChanges('loan_size_min')" type="number" style="width: 50%; outline: none; border-bottom: 1px solid #808080; margin-right: 5px;"> {{ modalData.ticker }}
                                            </div>
                                            <span style="text-align: right;"> {{ formatPrice(modalData.ticker, loanConfiguration.minPrincipalAmount) }} </span>
                                        </div>
                                        <!-- (blur)="transformMinimumAmount($event)" -->
    
                                        <div class="amounts">
                                            <span class="tooltip-container" style="display: inline; width: 120px;position: relative;">
                                                Maximum loan <span class="fa fa-question-circle" style="display: inline; font-size: 14px;"></span>
                                                <span class="tooltip" style="position: absolute;top: -10px; bottom: -20px; left: 130px; font-size: 13px; width: 150px;">
                                                    Maximum amount that can be borrowed per loan.
                                                </span>
                                            </span>
                                            <!-- <input [(ngModel)]="maximum_loan" type="number"> {{ asset_to_offer }} -->
                                            <div style="text-align: right;">
                                                <input required v-model="loanConfiguration.maxPrincipalAmount" @change="loanAmountsChanges('loan_size_max')" type="number" style="width: 50%; outline: none; border-bottom: 1px solid #808080; margin-right: 5px;">  {{ modalData.ticker }}
                                            </div>
                                            <span style="text-align: right;"> {{ formatPrice(modalData.ticker, loanConfiguration.maxPrincipalAmount) }} </span>
                                        </div>
                                        <!-- (blur)="transformMaximumAmount($event)" -->
    
                                        <div class="amounts">
                                            <span class="tooltip-container" style="display: inline; width: 120px;position: relative;">
                                                Allocated deposit <span class="fa fa-question-circle" style="display: inline; font-size: 14px;"></span>
                                                <span class="tooltip" style="position: absolute;top: -30px; bottom: -20px; left: 130px; font-size: 13px; width: 150px;">
                                                    Amount of deposit that will be allocated to this offer configuration.
                                                </span>
                                            </span>
                                            <!-- <input [(ngModel)]="maximum_total" type="number"> {{ asset_to_offer }} -->
                                            <div style="text-align: right;">
                                                <input required v-model="loanConfiguration.maxTotalPendingPrincipalAmount" @change="loanAmountsChanges('loan_size_max_allocated')" type="number" style="width: 50%; outline: none; border-bottom: 1px solid #808080; margin-right: 5px;">  {{ modalData.ticker }}
                                            </div>
                                            <span style="text-align: right;"> {{ formatPrice(modalData.ticker, loanConfiguration.maxTotalPendingPrincipalAmount) }} </span>
                                        </div>
                                        <!-- (blur)="transformMaximumTotalAmount($event)" -->
                                    </div>
                                </div>
                            </div>
  
                            <div v-if="currentStep == 2" style="height: 100%;">
                                <div class="deposit-view" style="flex-direction: column; padding-bottom: 0; max-height: 350px; border: 2px solid #353739; border-radius: 10px;">
                                    <div class="summary-amount" style="border-top: none; padding-top: 0;">
                                        <div class="details" style="height: 30px; border-bottom: 1px solid #353739; margin-bottom: 15px; justify-content: space-evenly;">
                                            <span style="width: 120px;"> Asset </span>
                                            <span style="width: 120px; text-align: center;"> Collateral </span>
                                            <span style="width: 120px; text-align: center;"> Interest </span>
                                        </div>

                                        <div v-for="asset in collateralInterestAssets" :key="asset.ticker" class="details" style="height: 40px; margin: 10px 0;">
                                            <div class="cell-asset-wrapper" style="width: 120px;">
                                                <img :src="asset.asset_img" alt="" style="width: 32px">
                                                <div class="asset">
                                                    <span style="text-align: left;"> {{ asset.name }} </span>
                                                    <span style="text-align: left; color: #808080;"> {{ asset.ticker }} </span>
                                                </div>
                                            </div>
                                            <div class="collateral-asset" style="width: 50px;">
                                                <label class="switch">
                                                    <input type="checkbox" v-model="asset.collateral" @change="changeOfferParam($event, 'collateral', asset)">
                                                    <span class="slider cubic"></span>
                                                </label>
                                            </div>
                                            <div class="collateral-asset" style="width: 80px;">
                                                <label class="switch">
                                                    <input type="checkbox" v-model="asset.interest" @change="changeOfferParam($event, 'interest', asset)">
                                                    <span class="slider cubic"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
  
                            <div v-if="currentStep == 3" style="height: 100%;">
                                <div class="offer-params-container" style="width: 70%; margin: auto; border: 2px solid #353739; padding: 5px 0px 0px; border-radius: 10px;">
                                    <div class="assets-picker-step">
                                        <div class="offer-config-params" style="width: 35%; border: none;">
                                            <label for="acc_collateral"> Interest </label>
                                            <!-- <span class="fa fa-question-circle" style="font-size: 14px; padding-right: 5px;"></span> -->
                                            <select @change="changeOfferParam($event, 'interest_rate')" name="acc_collateral" id="acc_collateral" 
                                                v-model="loanConfiguration.interestPercentage"
                                                style="border: 2px solid #353739; border-radius: 10px; width: 60px;">
                                                <!-- <option v-for="rate of interest_rates" :key="rate.id" [value]="rate"> {{rate}}% </option> -->
                                                <option value="2"> 2% </option>
                                                <option value="3"> 3% </option>
                                                <option value="4"> 4% </option>
                                                <option value="5"> 5% </option>
                                                <option value="6"> 6% </option>
                                                <option value="7"> 7% </option>
                                                <option value="8"> 8% </option>
                                                <option value="9"> 9% </option>
                                                <option value="10"> 10% </option>
                                                <option value="11"> 11% </option>
                                                <option value="12"> 12% </option>
                                            </select>
                                        </div>
    
                                        <div class="offer-config-params" style="width: 45%; border: none;">
                                            <label for="acc_collateral"> Terms  </label>
                                            <!-- <span class="fa fa-question-circle" style="font-size: 14px; padding-right: 5px;"></span> -->
                                            <!-- <select name="acc_collateral" id="acc_collateral" [(ngModel)]="offer_installments"> -->
                                            <select @change="changeOfferParam($event, 'terms')" name="acc_collateral" id="acc_collateral" 
                                                v-model="loanConfiguration.terms"
                                                style="border: 2px solid #353739; border-radius: 10px; width: auto;">
                                                <!-- <option v-for="rate of installements" :key="rate.id" [value]="rate"> {{rate}} </option> -->
                                                <option value="1"> 1 (30 days) </option>
                                                <option value="2"> 2 (60 days) </option>
                                                <option value="3"> 3 (90 days) </option>
                                                <option value="4"> 4 (120 days) </option>
                                                <option value="5"> 5 (150 days) </option>
                                                <option value="6"> 6 (180 days) </option>
                                                <option value="7"> 7 (210 days) </option>
                                                <option value="8"> 8 (240 days) </option>
                                                <option value="9"> 9 (270 days) </option>
                                                <option value="10"> 10 (300 days) </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="deposit-view" style="flex-direction: column; padding-bottom: 0; max-height: 350px; border-radius: 10px; width: auto; margin: 0px 20px 20px;">
                                        <div class="summary-amount" style="border-top: none; padding-top: 0; width: 370px;">
                                            <div class="details" style="height: 30px; border-bottom: 1px solid #353739; margin-bottom: 5px; justify-content: flex-start; padding-left: 20px;">
                                                <span> Loan Repayment Options: </span>
                                            </div>
                                            <div v-for="option in repaymentOptions" :key="option.value" class="details" style="height: 40px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="width: 250px; padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ option.description }} </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset" style="width: 50px;">
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="option.checked" @change="changeOfferParam($event, 'repayment_option', option.value)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
  
                            <div v-if="currentStep == 4" style="height: 100%;">
                                <div class="offer-params-container" style="display: flex; align-items: center;">
                                    <div class="deposit-view" style="flex-direction: column; padding-bottom: 0; max-height: 350px; border: 2px solid #353739; border-radius: 10px; width: 70%; margin: 0px 20px;">
                                        <div class="summary-amount" style="border-top: none; padding-top: 0; width: 370px;">
                                            <div class="details" style="height: 30px; border-bottom: 1px solid #353739; margin-bottom: 5px; justify-content: flex-start; padding-left: 20px;">
                                                <span>  Loan Summary: </span>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Collateral Asset </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div v-for="(asset) in collateralInterestAssets" :key="asset.ticker" class="asset">
                                                        <span v-if="asset.collateral" style="text-align: left;"> {{ asset.name }} </span>
                                                        <span v-if="asset.collateral" style="color: #808080"> ({{ asset.ticker }}) </span>
                                                        <!-- {{ index < collateralInterestAssets.length - 1 ? ',' : '' }} -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Interest Asset </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div v-for="(asset) in collateralInterestAssets" :key="asset.ticker" class="asset">
                                                        <span v-if="asset.interest" style="text-align: left;"> {{ asset.name }} </span>
                                                        <span v-if="asset.interest" style="color: #808080"> ({{ asset.ticker }}) </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Interest </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ loanConfiguration.interestPercentage }}% </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Loan Duration </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ loanConfiguration.terms }} Terms ({{ loanConfiguration.terms_days }} days) </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Loan Repayment Option </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Type {{ loanConfiguration.termPaymentType + 1 }} </span>
                                                        <span class="fa fa-question-circle tooltip-container" style="padding-left: 5px;">
                                                            <span v-if="loanConfiguration.termPaymentType == 0" class="tooltip" style="display: flex; align-items: center; top: 105px; bottom: 125px; left: 220px; font-size: 13px; width: 160px;">
                                                                Interest will be paid every term and principal will be paid at the end of the loan.
                                                            </span>
                                                            <span v-if="loanConfiguration.termPaymentType == 1" class="tooltip" style="display: flex; align-items: center; top: 105px; bottom: 125px; left: 220px; font-size: 13px; width: 160px;">
                                                                Interest and principal will be paid every term.
                                                            </span>
                                                            <span v-if="loanConfiguration.termPaymentType == 2" class="tooltip" style="display: flex; align-items: center; top: 105px; bottom: 125px; left: 220px; font-size: 13px; width: 160px;">
                                                                Interest and principal will be paid at the end of the loan.
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Minimum Loan </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left; padding-right: 5px;"> {{ loanConfiguration.minPrincipalAmount }}</span> 
                                                        <span style="color: #808080">{{ modalData.ticker }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Maximum Loan </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left; padding-right: 5px;"> {{ loanConfiguration.maxPrincipalAmount }}</span> 
                                                        <span style="color: #808080">{{ modalData.ticker }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Allocated deposit </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left; padding-right: 5px;"> {{ loanConfiguration.maxTotalPendingPrincipalAmount }}</span> 
                                                        <span style="color: #808080">{{ modalData.ticker }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
  
                        </div>
  
                        <div class="btn-container" style="width: 70%; padding: 20px 0px 20px;"
                            :style="{ 'justify-content': currentStep == 1 ? 'flex-end' : 'space-between' }">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>

                            <button @click="currentStep--" class="btn-back" v-if="currentStep != 1"> Back </button>

                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 1" :disabled="!validateFirstStep()"> Next </button>
                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 2" :disabled="!validateSecondStep()"> Next </button>
                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 3" :disabled="!validateThirdStep()"> Next </button>

                            <button @click="assetOffer()" class="btn-submit" v-if="currentStep == 4" :disabled="!validateFirstStep() || !validateSecondStep() || !validateThirdStep()" style="line-height: 13px; font-size: 12px;">
                                <span v-if="!modalData.processing_tx"> Create Loan Configuration </span>
                                <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>

            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'CONFIG STATS'">
                    <div class="modal" style="width: 800px">
                        <h1 class="title" style="margin: 20px 0 0;"> {{ modalTitle }} </h1>
                        <p class="body" style="font-size: 16px; margin-bottom: 15px;"> {{ modalContent }} </p>
  
                        <div class="offer-id-view" style="width: 95%; padding-bottom: 0px;justify-content: center;">
                            <table v-if="true" class="market-table" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th> Principal </th>
                                        <th> Principal Paid </th>
                                        <th> Interest </th>
                                        <th> Interest Paid </th>
                                        <th> Borrower </th>
                                        <!-- <th> Actions </th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="loan of modalData?.loans" :key="loan.id">
                                        <td> {{ parseFloat(ethers.formatUnits(loan.principalAmount, loan.config.principalAsset.decimals)) }} <span style="color: #808080">{{ loan.config.principalAsset.symbol }}</span> </td>
                                        <td> {{ parseFloat(loan.principal_paid) }} <span style="color: #808080">{{ loan.config.principalAsset.symbol }}</span> </td>
                                        <td> {{ parseFloat(ethers.formatUnits(loan.principalAmount, loan.config.principalAsset.decimals)) * (loan.config.interestPromille / 1000) }} <span style="color: #808080">{{ loan.config.principalAsset.symbol }}</span> </td>
                                        <td>
                                            <span :style="{'color': parseFloat(loan.interest_paid) > 0 ? '#008000' : '#FFF'}">
                                                <span v-if="parseFloat(loan.interest_paid) > 0"> + </span>
                                                {{ parseFloat(loan.interest_paid) }} 
                                                <span style="color: #808080">{{ loan.config.principalAsset.symbol }}</span>
                                            </span>
                                        </td>
                                        
                                        <td><a :href="'https://testnet.bscscan.com/address/' + loan.borrower" target="_blank" style="all: unset;cursor: pointer;"
                                            > {{ loan.borrower_address_short }} <span class="fa fa-fw fa-external-link" style="font-size: 13px;"></span> </a></td>

                                        <!-- <td style="color: #008000;">
                                            <strong> +${{ wallet.repaid }} </strong>
                                        </td>
                                        <td style="display: flex;padding-top: 10px;">
                                            <button :disabled="wallet.installments_paid == 0" class="liquidate-loan">
                                                LIQUIDATE <i class="fa fa-minus-circle" style="padding-left: 5px;"></i>
                                            </button>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="false" style="display: flex;
                                color: #8F8F91;
                                height: 150px;
                                align-items: center;
                                justify-content: center;">
                                    No active loans.
                            </div>
                        </div>
  
                        <div class="btn-container" style="padding: 20px 0px;">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>
                            <button @click="isModalOpen = false" class="btn-close"> Close </button>

                            <div v-if="modalData.processing_tx" class="lumin-ellipsis"><div></div><div></div><div></div><div></div></div>

                            <button v-if="modalData.deposit_amount > modalData.allowed"
                                :disabled="(modalData.deposit_amount <= modalData.allowed) || modalData.processing_tx"
                                @click="setAllowance()"
                                class="btn-submit"> Approve </button>

                            <button v-if="modalData.deposit_amount <= modalData.allowed" 
                                :disabled="validateDepositInputs() || modalData.processing_tx" 
                                :style="{'cursor': validateDepositInputs() ? 'not-allowed' : 'pointer'}" 
                                class="btn-submit"
                                @click="assetDeposit()"> Deposit </button>

                            <!-- <button disabled class="btn-submit"> Allow </button> -->
                            <!-- (click)="setAllowance()" *ngIf="title == 'DEPOSIT' && (workingAsset.deposit > workingAsset.allowed)" -->
                            <!-- <button @click="isModalOpen = false" class="btn-submit"> Deposit </button> -->
                        </div>
                    </div>
                </div>
            </Transition>
            
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'LOAN DETAILS'">
                    <div class="modal">
                        <h1 class="title"> {{ modalTitle }} </h1>
                        <!-- <p class="body"> {{ modalContent }} </p> -->
  
                        <div class="deposit-view" style="width: 85%; padding-bottom: 0px;flex-direction: column;">

                            <div style="display: flex;
                                justify-content: space-evenly;
                                width: 100%;border-top: 2px solid #353739;">

                                <button class="btn-pick" :style="{
                                    background: currentStep == 1 ? '#353739' : '#1c1b1d'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none;" 
                                    @click="currentStep = 1"> Manage Interest </button>

                                <button class="btn-pick" :style="{
                                    background: currentStep == 2 ? '#353739' : '#1c1b1d'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none;" 
                                    @click="currentStep = 2"> Manage Principal </button>

                                <button class="btn-pick" :style="{
                                    background: currentStep == 3 ? '#353739' : '#1c1b1d'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none;" 
                                    @click="currentStep = 3"> Summary </button>
                            </div>

                            <div v-if="currentStep == 1" class="stepper-horizontal-interest" ref="stepperInterest" style="margin: 15px auto 15px;">
                                <div class="step" style="padding-bottom: 50px;">
                                    <div v-for="term of termsTimeline" :key="term.id" class="tooltip-container">
                                        <span class="interest-term-number" :style="{ left: term.css_left }"> {{ term.id + 1 }} </span>
                                        <!-- <span class="tooltip" :style="{ right: (term - 1) * 25 + '%' }"
                                            style="
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    {{ term.tooltip }} 
                                        </span> -->
                                    </div>

                                    <div class="tooltip-container">
                                        <span class="interest-timeline-indicator" style="left: -2%"> ▼
                                            <span class="tooltip"
                                                style="right: 0px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 70px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;">
                                                    Days in loan 0 out of {{ modalData.vanilla_config_data.terms * 30 }}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container" :style="{ display: (interestPaymentIndicator == 0 || interestPaymentIndicator == 100) ? 'none' : 'unset' }">
                                        <span class="interest-current-payoff-indicator" :style="{ left: (interestPaymentIndicator -0.5) + '%' }"> |
                                            <span class="tooltip"
                                                style="right: -5px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 100px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;">
                                                    Interest payment to date
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container">
                                        <span v-if="interestAssetAmount > 0" class="interest-future-payoff-indicator" 
                                            :style="{ left: (15 + interestFuturePayoffBar - 0.7) + '%' }"
                                            style="top: 23px;"> | 
                                        </span>
                                        <span class="tooltip"
                                            style="bottom: 55px;
                                            font-size: 12px;
                                            width: 110px;
                                            padding: 5px 5px;
                                            color: #fff;
                                            word-wrap: break-word;
                                            font-weight: 100;
                                            background: transparent;"
                                            :style="{ right: 15 + interestFuturePayoffBar + '%' }">
                                                Status after new payment completed
                                        </span>
                                    </div>

                                    <div v-if="interestAssetAmount > 0" class="step-bar-left-future-payoff"
                                        style="background: #808080;"
                                        :style="{ width: 15 + interestFuturePayoffBar + '%' }"></div>
                                        

                                    <div class="step-bar-left" style="background: #808080;" :style="{ width: interestPaymentIndicator + '%' }"></div>
                                    <div class="step-bar-right" style="background: #808080;"></div>
                                </div>
                            </div>

                            <div v-if="currentStep == 1">
                                <div class="manage-interest-control">
                                    <div class="interest-details" v-if="modalData.vanilla_config_data.pendingInterestAmountNative > 0" style="padding: 0 10px;">
                                        <span>
                                            Total to pay: {{ modalData.vanilla_config_data.pendingInterestAmountNative }}
                                            <span style="color: #808080;">{{ modalData.vanilla_config_data.principalAsset.symbol }}</span> 
                                        </span>
                                        <span> {{ formatPrice("", modalData.vanilla_config_data?.principalAsset.max_interest_dollar_amount, 2) }} </span>
                                    </div>
                                    <div class="interest-details" v-if="modalData.vanilla_config_data.pendingInterestAmountNative == 0" style="padding: 0 10px;">
                                        <span>
                                            Interest is paid in full.
                                        </span>
                                    </div>
                                </div>
                                <div class="deposit-view" style="width: 100%; padding: 0 0 20px; flex-direction: column; max-height: 145px;overflow: hidden;"
                                    :style="{ opacity: modalData.vanilla_config_data.pendingInterestAmountNative == 0 ? '0.5' : '1',
                                        cursor: modalData.vanilla_config_data.pendingInterestAmountNative == 0 ? 'not-allowed' : 'pointer' }">

                                    <template v-for="(useAsset, index) of modalData?.vanilla_config_data.acceptedAssets" :key="useAsset.useInterest">
                                        <div v-if="useAsset.useInterest" class="deposit-view" style="width: 100%; padding-bottom: 15px; margin: 0 10px; flex-direction: column;">
                                            <div class="asset-amount" style="padding: 10px 20px; width: 400px; border: 2px solid #353739;">
                                                <div class="asset-amounts">
                                                    <input v-model="useAsset.amount_to_use" 
                                                        :disabled="useAsset.amount_avaliable == 0" 
                                                        :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                        type="number" min="0" 
                                                        :max="useAsset.amount_avaliable" 
                                                        style="font-size: 24px;">
                                                    <img :src="useAsset.asset_img" alt="" style="background: transparent; position: relative; top: 5px; height: 32px;">
                                                </div>

                                                <div style="padding-bottom: 15px;">
                                                    <input :id="'rangeSliderCollateral-' + index" class="range" type="range" name="" min="0"
                                                        :disabled="useAsset.amount_avaliable == 0"
                                                        :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                        :max="useAsset.amount_avaliable" 
                                                        v-model="useAsset.amount_to_use"
                                                        @change="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)" 
                                                        @mousemove="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)"
                                                        style="width: 100%;">
                                                </div>

                                                <div class="details">
                                                    <!-- {{ modalData.available }} {{ modalData.ticker }} -->
                                                    <span style="font-size: 14px;"> 
                                                        Available: {{ useAsset.amount_avaliable }} {{ useAsset.ticker }}
                                                        <span class="tooltip-container" @click="modalData.vanilla_config_data.acceptedAssets[0].amount_to_use = modalData.vanilla_config_data.pendingInterestAmountNative + 1" style="
                                                            position: relative;
                                                            top: -1px;
                                                            font-size: 10px;
                                                            border: 1px solid grey;
                                                            border-radius: 5px;
                                                            cursor: pointer;
                                                            padding: 0 5px;"> 
                                                                MAX
                                                                <span class="tooltip"
                                                                    style="bottom: -10px;
                                                                        left: 40px;
                                                                        top: -15px;
                                                                        font-size: 10px;
                                                                        width: 200px;
                                                                        padding: 5px 5px;
                                                                        color: #fff;
                                                                        word-wrap: break-word;
                                                                        font-weight: 100;">
                                                                            Make sure to have enough deposit to cover interest payments!
                                                                </span>
                                                            </span>
                                                    </span>
                                                    <span style="font-size: 14px;"> {{ formatPrice(useAsset.ticker, useAsset.amount_to_use) }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div v-if="currentStep == 2" class="stepper-horizontal-interest" ref="stepperPrincipal" style="margin: 15px auto 15px;">
                                <div class="step" style="padding-bottom: 50px;">
                                    <div v-for="term of termsTimeline" :key="term.id" class="tooltip-container">
                                        <span class="interest-term-number" :style="{ left: term.css_left }"> {{ term.id + 1 }} </span>
                                        <!-- <span class="tooltip" :style="{ right: (term - 1) * 25 + '%' }"
                                            style="
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    {{ term.tooltip }} 
                                        </span> -->
                                    </div>

                                    <div class="tooltip-container">
                                        <span class="interest-timeline-indicator" style="left: -2%"> ▼
                                            <span class="tooltip"
                                                style="right: 0px;
                                                bottom: 25px;
                                                font-size: 11px;
                                                width: 70px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;">
                                                    Days in loan 0 out of {{ modalData.vanilla_config_data.terms * 30 }}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container" :style="{ display: (principalPaymentIndicator == 0 || principalPaymentIndicator == 100) ? 'none' : 'unset' }">
                                        <span class="interest-current-payoff-indicator" style="z-index: 1000;width: auto;" :style="{ left: (principalPaymentIndicator -0.5) + '%' }"> |
                                            <span class="tooltip"
                                                style="right: -65px;
                                                bottom: 25px;
                                                font-size: 11px;
                                                width: 130px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;">
                                                    Principal payment to date
                                            </span>
                                        </span>
                                    </div>

                                    <div class="tooltip-container" :style="{ display: (interestPaymentIndicator == 0 || interestPaymentIndicator == 100) ? 'none' : 'unset' }">
                                        <span class="interest-current-payoff-indicator" style="color: #e8e600;width: auto;" :style="{ left: (interestPaymentIndicator -0.5) + '%' }"> |
                                            <span class="tooltip"
                                                style="top: -25px;
                                                right: -65px;
                                                bottom: 25px;
                                                font-size: 11px;
                                                width: 120px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;
                                                border: 1px solid #e8e60075;">
                                                    Max. principal payment
                                            </span>
                                        </span>
                                    </div>

                                    <!-- <Transition name="modal">
                                        <span v-if="principalPaymentIndicator == 100" style="position: absolute;right: -30px;top: 15px;color: green;font-size: 14px;width: auto;"> Full Principal Payment </span>
                                    </Transition> -->

                                    <div class="tooltip-container">
                                        <span v-if="principalAssetAmount > 0" class="interest-future-payoff-indicator" 
                                            :style="{ left: (15 + principalFuturePayoffBar - 0.7) + '%' }"
                                            style="top: 23px;"> | 
                                        </span>
                                        <span class="tooltip"
                                            style="bottom: 55px;
                                            font-size: 12px;
                                            width: 110px;
                                            padding: 5px 5px;
                                            color: #fff;
                                            word-wrap: break-word;
                                            font-weight: 100;
                                            background: transparent;"
                                            :style="{ right: 15 + principalFuturePayoffBar + '%' }">
                                                Status after new payment completed
                                        </span>
                                    </div>

                                    <div v-if="principalAssetAmount > 0" class="step-bar-left-future-payoff"
                                        style="background: #808080;"
                                        :style="{ width: 15 + principalFuturePayoffBar + '%' }"></div>

                                    <div class="step-bar-left" style="background: #e8e600; border-style: dashed;" :style="{ width: interestPaymentIndicator + '%' }"></div>
                                    <div class="step-bar-left" style="background: #808080;" :style="{ width: principalPaymentIndicator + '%' }"></div>
                                    <div class="step-bar-right" style="background: #808080;"></div>
                                </div>
                            </div>

                            <div v-if="currentStep == 2">
                                <div class="manage-interest-control">
                                    <div class="interest-details" v-if="modalData.vanilla_config_data.pendingPrincipalAmountNative > 0">
                                        <span> 
                                            Total to pay: {{ modalData.vanilla_config_data.pendingPrincipalAmountNative }}
                                            <span style="color: #808080;">{{ modalData.vanilla_config_data.principalAsset.symbol }}</span> 
                                        </span>
                                        <span> {{ formatPrice(modalData.vanilla_config_data.principalAsset.symbol, modalData.loan_vanilla_data?.pendingPrincipalAmountNative) }} </span>
                                    </div>
                                    <div class="interest-details" v-if="modalData.vanilla_config_data.pendingPrincipalAmountNative == 0" style="padding: 0 10px;">
                                        <span> 
                                            Principal is paid in full.
                                        </span>
                                    </div>
                                </div>
                                <div class="deposit-view" style="width: 100%; padding: 0 0 20px; flex-direction: column;">
                                    <div class="asset-amount" style="padding: 10px 20px; width: 400px; border: 2px solid #353739;">
                                        <div class="asset-amounts">
                                            <input v-model="modalData.vanilla_config_data.principalAsset.amount_to_use" 
                                                :disabled="modalData.vanilla_config_data.principalAsset.amount_avaliable == 0" 
                                                :style="{ opacity: modalData.vanilla_config_data.principalAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                type="number" step="0.1" min="0" 
                                                :max="modalData.vanilla_config_data.principalAsset.amount_avaliable"
                                                style="font-size: 24px;">
                                            <img :src="modalData.vanilla_config_data.principalAsset.asset_img" alt="" style="background: transparent; position: relative; top: 5px; height: 32px;">
                                        </div>

                                        <div style="padding-bottom: 15px;">
                                            <input :id="'rangeSliderCollateral-' + index" class="range" type="range" name="" min="0" step="0.001"
                                                :disabled="modalData.vanilla_config_data.principalAsset.amount_avaliable == 0"
                                                :style="{ opacity: modalData.vanilla_config_data.principalAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                :max="modalData.vanilla_config_data.principalAsset.amount_avaliable" 
                                                v-model="modalData.vanilla_config_data.principalAsset.amount_to_use"
                                                @change="rangeSliderCollateral(modalData.vanilla_config_data.principalAsset.amount_to_use, modalData.vanilla_config_data.principalAsset.amount_avaliable, index)" 
                                                @mousemove="rangeSliderCollateral(modalData.vanilla_config_data.principalAsset.amount_to_use, modalData.vanilla_config_data.principalAsset.amount_avaliable, index)"
                                                style="width: 100%;">
                                        </div>

                                        <div class="details">
                                            <!-- {{ modalData.available }} {{ modalData.ticker }} -->
                                            <span style="font-size: 14px;"> 
                                                Available: {{ modalData.vanilla_config_data.principalAsset.amount_avaliable }} {{ modalData.vanilla_config_data.principalAsset.symbol }}
                                                <span class="tooltip-container" @click="modalData.vanilla_config_data.principalAsset.amount_to_use = (modalData.vanilla_config_data.principalAsset.amount_avaliable + 1)" style="
                                                    position: relative;
                                                    top: -1px;
                                                    font-size: 10px;
                                                    border: 1px solid grey;
                                                    border-radius: 5px;
                                                    cursor: pointer;
                                                    padding: 0 5px;"> 
                                                        MAX
                                                        <span class="tooltip"
                                                            style="bottom: -10px;
                                                                left: 40px;
                                                                top: -15px;
                                                                font-size: 10px;
                                                                width: 200px;
                                                                padding: 5px 5px;
                                                                color: #fff;
                                                                word-wrap: break-word;
                                                                font-weight: 100;">
                                                                    Make sure to have enough deposit to cover principal payments!
                                                        </span>
                                                    </span>
                                            </span>
                                            <span style="font-size: 14px;"> {{ formatPrice(modalData.vanilla_config_data.principalAsset.symbol, modalData.vanilla_config_data.principalAsset.amount_to_use) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="currentStep == 3" class="deposit-view" style="flex-direction: column; padding-bottom: 0; max-height: 400px; overflow-y: scroll; overflow-x: hidden;width: 95%;">
                                <template v-if="modalData.vanilla_config_data.principalAsset.amount_to_use > 0">
                                    <h2 class="summary-title"> Principal: </h2>
                                    <div class="summary-amount">
                                        <div class="details">
                                            <span style="min-width: 100px;"> Asset Used </span>
                                            <span> Current Balance </span>
                                            <span> Amount Paid </span>
                                        </div>
                                        <div class="details">
                                            <div class="cell-asset-wrapper" style="min-width: 120px;">
                                                <img :src="modalData.vanilla_config_data.principalAsset.asset_img" alt="" style="background: transparent">
                                                <div class="asset">
                                                    <span style="text-align: left;"> {{ modalData.vanilla_config_data.principalAsset.name }} </span>
                                                    <span style="text-align: left; color: #808080;"> ({{ modalData.vanilla_config_data.principalAsset.ticker }}) </span>
                                                </div>
                                            </div>
                                            <p>
                                                <span> {{ formatPrice(modalData.vanilla_config_data.principalAsset.ticker, modalData.vanilla_config_data.principalAsset.amount_avaliable) }} </span>
                                                <span style="color: #808080;"> {{ modalData.vanilla_config_data.principalAsset.amount_avaliable }} </span>
                                            </p>
                                            <p>
                                                <span style="color: #E8E600;"> {{ formatPrice(modalData.vanilla_config_data.principalAsset.ticker, modalData.vanilla_config_data.principalAsset.amount_to_use) }} </span>
                                                <span style="color: #808080;"> {{ parseFloat(modalData.vanilla_config_data.principalAsset.amount_to_use).toFixed(6) }} </span>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                
                                <template v-if="modalData.vanilla_config_data.acceptedAssets[0].amount_to_use > 0">
                                    <h2 class="summary-title"> Interest: </h2>
                                    <div class="summary-amount">
                                        <div class="details">
                                            <span style="min-width: 100px;"> Asset Used </span>
                                            <span> Current Balance </span>
                                            <span> Amount Paid </span>
                                        </div>
                                        <div v-for="interestAsset of modalData?.vanilla_config_data.acceptedAssets" :key="interestAsset.ticker" class="details">
                                            <template v-if="interestAsset.useInterest">
                                                <div class="cell-asset-wrapper" style="min-width: 120px;">
                                                    <img :src="interestAsset.asset_img" alt="" style="background: transparent">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ interestAsset.name }} </span>
                                                        <span style="text-align: left; color: #808080;"> ({{ interestAsset.ticker }}) </span>
                                                    </div>
                                                </div>
                                                <p>
                                                    <span> {{ formatPrice(interestAsset.ticker, interestAsset.amount_avaliable) }} </span>
                                                    <span style="color: #808080;"> {{ interestAsset.amount_avaliable }} </span>
                                                </p>
                                                <p>
                                                    <span style="color: #E8E600;"> {{ formatPrice(interestAsset.ticker, interestAsset.amount_to_use) }} </span>
                                                    <span style="color: #808080;"> {{ parseFloat(interestAsset.amount_to_use).toFixed(6) }} </span>
                                                </p>
                                            </template>
                                        </div>
                                    </div>
                                </template>

                                <h2 class="summary-title"> Principal & Interest: </h2>
                                <div class="summary-amount">
                                    <div class="details">
                                        <span> Principal </span>
                                        <span> {{ formatPrice(modalData.vanilla_config_data.principalAsset.ticker, modalData.vanilla_config_data.principalAsset.amount_to_use) }} </span>
                                    </div>
                                    <div class="details">
                                        <span> Interest </span>
                                        <span> {{ cumulativeInterestPayment() }} </span>
                                    </div>
                                    <div class="details" style="font-weight: 800;padding-top: 5px;">
                                        <span> Total Payment </span>
                                        <span> ${{ parseInt(formatPrice(modalData.vanilla_config_data.principalAsset.ticker, modalData.vanilla_config_data.principalAsset.amount_to_use).substring(2)) + parseInt(cumulativeInterestPayment().substring(2)) }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
  
                        <div class="btn-container" style="width: 73%; padding: 0px 0px 20px;"
                            :style="{ 'justify-content': currentStep == 1 ? 'flex-end' : 'space-between' }">
                            
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>

                            <button v-if="currentStep == 2 || currentStep == 3"
                                :disabled="currentStep == 1" 
                                @click="currentStep--"
                                class="btn-back" style="font-size: 12px;"> Back </button>

                            <button v-if="currentStep == 1 || currentStep == 2" 
                                @click="currentStep++" 
                                class="btn-next" style="font-size: 12px;"> Next </button>

                            <button @click="payInterestPrincipal()" v-if="currentStep == 3" class="btn-submit" style="font-size: 12px;"
                                :disabled="
                                    (modalData.vanilla_config_data.principalAsset.amount_to_use == 0 && modalData.vanilla_config_data.acceptedAssets[0].amount_to_use == 0)
                                    ||
                                    (modalData.vanilla_config_data.principalAsset.amount_to_use > modalData.vanilla_config_data.principalAsset.amount_avaliable)
                                    ||
                                    (modalData.vanilla_config_data.acceptedAssets[0].amount_to_use > modalData.vanilla_config_data.acceptedAssets[0].amount_avaliable)
                                "> 
                                <span v-if="!modalData.processing_tx"> Pay </span>
                                <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
            
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'ADJUST COLLATERAL'">
                    <div class="modal" style="top: 80px;">
                        <h1 class="title"> {{ modalTitle }} </h1>
                        <p class="body" style="color: #FFF; font-size: 14px; text-align: center; margin: 0 0 15px;"> {{ modalContent }} </p>
  
                        <div class="deposit-view" style="display: none;width: 90%; padding-bottom: 0px;justify-content: center;">
                            <div style="display: flex;
                                justify-content: space-evenly;
                                width: 100%;border-top: 2px solid #353739;">
                                <button @click="collateral_adjustment_option = 'supply'" 
                                    class="btn-pick" 
                                    style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none;"
                                    :style="{'background': collateral_adjustment_option == 'supply' ? '#353739' : '#1c1b1d'}"> Supply </button>

                                <button @click="collateral_adjustment_option = 'withdraw'" 
                                    class="btn-pick" 
                                    style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none;"
                                    :style="{'background': collateral_adjustment_option == 'withdraw' ? '#353739' : '#1c1b1d'}"
                                    > Withdraw </button>
                            </div>
                        </div>
  
                        <div class="deposit-view" style="width: 85%; padding-bottom: unset;flex-direction: column;">
                            <div class="loan-progress" style="display: none;">
                                <span style="display: block; font-size: 14px; margin: auto;width: auto;"> Loan Health </span>
                                <span class="loan-bar-text" id="loan-bar-text">
                                    <strong> {{ modalData.health }} </strong>
                                </span>
                            </div>

                            <div class="deposit-view" style="padding-top: 15px; padding-bottom: 15px;">
                                <div class="stepper-horizontal-interest" ref="stepperInterest" style="margin: 0px auto; width: 100%;">
                                    <div class="step" style="padding-bottom: 30px;">
                                        <div class="tooltip-container">
                                            <span class="interest-term-number" style="left: 25%; display: none;"> 1
                                                <span class="tooltip"
                                                    style="right: -50px;
                                                    bottom: 25px;
                                                    font-size: 12px;
                                                    width: 110px;
                                                    padding: 5px 5px;
                                                    color: #fff;
                                                    background: transparent;">
                                                        First Instalment 10/22/2023 23:52:05
                                                </span>
                                            </span>
                                        </div>

                                        <!-- HEALTH FACTOR INDICATOR -->
                                        <Transition name="modal">
                                            <div v-if="healthFactorCalc < 160" style="position: relative; top: -25px;">
                                                <div v-if="healthFactorCalc > 100" :style="{'left': (healthFactorCalc - 100) * 1.66666667 + '%'}" class="indicator-wrapper">
                                                    <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 10px; font-weight: 100; width: min-content;left: -10px;">
                                                        {{ (healthFactorCalc).toFixed(2) }}%
                                                    </span>
                                                    <span class="interest-timeline-indicator" style="text-align: left; font-size: 14px; top: 20px; left: 2px; color: #e8e600;"> ▼ </span>
                                                </div>
                                            </div>
                                        </Transition>

                                        <Transition name="modal">
                                            <span v-if="healthFactorCalc >= 160" style="position: absolute;right: -30px;top: 15px;color: green;font-size: 14px;width: auto;"> {{ (healthFactorCalc).toFixed(2) }}% </span>
                                        </Transition>

                                        <!-- LIQUIDATION VALUE -->
                                        <!-- LIQUIDATION VALUE INDICATOR - ABOVE LINE -->
                                        <div style="position: relative; top: -25px;">
                                            <div :style="{'left': 33 + '%'}" class="indicator-wrapper">
                                                <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 20px; font-weight: 100; width: min-content;"> 
                                                    120%
                                                    <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                        Liquidation value of the loan with current loan parameters.
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <!-- LIQUIDATION VALUE INDICATOR - UNDER LINE -->
                                        <div style="position: relative; top: -25px;">
                                            <div :style="{'left': (34-5) + '%'}" class="indicator-wrapper">
                                                <span class="interest-timeline-indicator" style="position: absolute;left: -5px;line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content;"> 
                                                    LIQUIDATION VALUE
                                                </span>
                                                <span class="interest-timeline-indicator" style="text-align: center; font-size: 14px; top: 35px; color: #ff0000;"> ▲ </span>
                                            </div>
                                        </div>

                                        <!-- COLLATERAL BUFFER -->
                                        <!-- COLLATERAL BUFFER INDICATOR - ABOVE LINE -->
                                        <div style="position: relative; top: -25px;">
                                            <div :style="{'left': 50 + '%'}" class="indicator-wrapper">
                                                <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 20px; font-weight: 100; width: min-content;"> 
                                                    130%
                                                    <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                        Liquidation value of the loan with current loan parameters.
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div style="position: relative; top: -25px;">
                                            <div :style="{'left': 46 + '%'}" class="indicator-wrapper">
                                                <span class="interest-timeline-indicator" style="position: absolute;left: 5px;line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content;"> 
                                                    BUFFER
                                                </span>
                                                <span class="interest-timeline-indicator" style="text-align: center; font-size: 14px; top: 35px; color: #ff7f00;"> ▲ </span>
                                            </div>
                                        </div>

                                        <div class="tooltip-container">
                                            <span v-if="interestAssetAmount > 0" class="interest-future-payoff-indicator"
                                                :style="{ left: (15 + interestFuturePayoffBar - 1.5) + '%' }"> ▼ 
                                            </span>
                                            <span class="tooltip"
                                                style="bottom: 55px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                word-wrap: break-word;
                                                font-weight: 100;
                                                background: transparent;"
                                                :style="{ right: 15 + interestFuturePayoffBar + '%' }">
                                                    Status after new payment completed
                                            </span>
                                        </div>

                                        <div class="step-bar-right" style="height: 2px; border: none; background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 0, 0) 30%, rgb(255, 127, 0) 36%, rgb(0, 255, 0) 100%);"></div>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; width: 100%; justify-content: space-around;">
                                <p style="color: #8f8f91;font-size: 14px;"> 
                                    Loan amount 
                                    <span style="font-size: 18px;color: #fff;width: auto;display: block;"> {{ formatPrice(modalData.vanilla_config_data.principalAsset.symbol, modalData.loan_vanilla_data.pendingPrincipalAmountNative, 2) }} </span>
                                </p>
                                <p style="color: #8f8f91;font-size: 14px;"> 
                                    Collateral
                                    <span style="font-size: 18px;color: #fff;width: auto;display: block;"> {{ modalData.collateral }} </span> 
                                </p>
                                <p style="color: #8f8f91;font-size: 14px;"> 
                                    Debt asset
                                    <span style="display: flex;align-items: center;font-size: 18px;color: #fff;width: auto;"> 
                                        {{ modalData.vanilla_config_data.principalAsset.symbol }} 
                                        <img :src="modalData.vanilla_config_data.principalAsset.asset_img"
                                        :style="{ background: modalData.vanilla_config_data.principalAsset.ticker == 'MOCK' ? 'transparent;' : '#fff;' }"
                                        style="height: 20px; margin-left: 5px; border-radius: 20px;">
                                    </span>  
                                </p>
                                <!-- <p style="color: #8f8f91;font-size: 14px;"> 
                                    Health
                                    <span style="font-size: 18px; color: #fff; width: auto; display: block;"
                                    :style="{
                                        color: modalData.health < 1.3 ? '#FF0000' : (modalData.health < 1.5 ? '#ffa500' : '#0c830c')
                                    }"> {{ modalData.health }} </span>
                                </p> -->
                            </div>
                        </div>
                        
                        <div class="offer-params-container" style="display: flex; align-items: center;">
                            <div style="position: relative; left: 2px; max-height: 160px; margin: 0 5px;">
                                <div v-for="(useAsset, index) of modalData?.vanilla_config_data.acceptedAssets" :key="useAsset.useCollateral" class="deposit-view" style="width: 90%; padding-bottom: 15px;">
                                    <template v-if="useAsset.useCollateral">
                                        <div class="asset-amount" style="padding: 10px 20px; width: 400px; border: 2px solid #353739;" :style="{ opacity: modalData.status == 'Closed' ? '0.5' : '1' }">
                                            <div class="asset-amounts">
                                                <input v-model="useAsset.amount_to_use" 
                                                    :disabled="useAsset.amount_avaliable == 0 || modalData.status == 'Closed'" 
                                                    :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                    type="number" min="0" 
                                                    :max="useAsset.amount_avaliable" 
                                                    style="font-size: 24px;">
                                                <img :src="useAsset.asset_img" alt="" style="position: relative; top: 5px; height: 32px;">
                                            </div>

                                            <div style="padding-bottom: 15px;">
                                                <input :id="'rangeSliderCollateral-' + index" class="range" type="range" name="" min="0"
                                                    :disabled="useAsset.amount_avaliable == 0 || modalData.status == 'Closed'"
                                                    :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                    :max="useAsset.amount_avaliable" 
                                                    v-model="useAsset.amount_to_use"
                                                    @change="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)" 
                                                    @mousemove="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)"
                                                    style="width: 100%;">
                                            </div>

                                            <div class="details">
                                                <!-- {{ modalData.available }} {{ modalData.ticker }} -->
                                                <span style="font-size: 14px;"> 
                                                    Available: {{ useAsset.amount_avaliable }} {{ useAsset.ticker }}
                                                    <span @click="useAsset.amount_to_use = useAsset.amount_avaliable" style="
                                                        position: relative;
                                                        top: -1px;
                                                        font-size: 10px;
                                                        border: 1px solid grey;
                                                        border-radius: 5px;
                                                        cursor: pointer;
                                                        padding: 0 5px;"
                                                        :style="{ cursor: modalData.status == 'Closed' ? 'not-allowed' : 'pointer' }"> MAX </span>
                                                </span>
                                                <span style="font-size: 14px;"> {{ formatPrice(useAsset.ticker, useAsset.amount_to_use) }} </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
  
                        <div class="btn-container" style="padding: 20px 0px; justify-content: space-between;width: 67%;">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>
                            <button @click="isModalOpen = false" class="btn-close"> Close </button>

                            <button @click="adjustCollateral()" :disabled="modalData.health < 130 || modalData.status == 'Closed'" class="btn-submit" style="font-size: 12px;"> 
                                <span v-if="!modalData.processing_tx"> Adjust </span>
                                <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>

    <Teleport to="#toast">
        <div v-for="(toast, index) in toasts" :key="toast.icon">
            <Transition name="toast">
                <div class="toast" v-if="toast.isToastOpen" style="display: flex;flex-direction: row;align-items: center;padding: 5px 20px;background: rgb(40 39 42 / 95%);"
                    @click="toast.isToastOpen = false"
                    :style="{'top': 60 * index + 'px'}">
                    <i :style="{'color': toast.color}" :class="toast.icon" style="margin: 0 10px 0 0;padding: 5px;border-radius: 25px;width: 16px; height: 16px;"></i>
                    <div style="line-height: 15px;">
                        <p style="margin: 5px;"> {{ toast.toastTitle }} </p>
                        <span style="margin-left: 5px; font-size: 12px; color: #808080;"> {{ toast.toastContent }} </span>
                    </div>
                </div>
            </Transition>
        </div>
        <!-- <Transition name="toast">
            <div class="toast" v-if="isToastOpen" style=""
                @click="isToastOpen = false">
                <p> {{ toastTitle }} </p>
            </div>
        </Transition> -->
    </Teleport>
</template>
    
<script>
// @ts-check
// @ts-ignore
// @ts-nocheck
import { ref, onMounted, watch, computed } from 'vue';
import { ethers, Contract, formatUnits, parseUnits, FixedNumber } from 'ethers';

import { LuminClient } from 'lumin-lib/client/LuminClient';
import { LoanConfigAcceptedAsset, LoanConfigBuilder, LoanConfigAsset } from 'lumin-lib/types/LoanManagerDelegator.js';

import { createConfig, configureChains, waitForTransaction } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { bscTestnet } from '@wagmi/core/chains'

import ERC20 from '@/components/ERC20.json';

import { ROARR, Roarr as log } from 'roarr';
import { createLogWriter } from '@roarr/browser-log-writer';

ROARR.write = (message) => {
    // console.log(JSON.parse(message));
};

log.write = createLogWriter();

import logoSrc from '@/assets/lumin_icon.svg';
import luminIconPng from '@/assets/Lumin_Logo2.png';

import walletConnectionIcon from '@/assets/wallet-connection-icon.png';
import bscLogo from '@/assets/bsc-logo.svg';
import wBTC_32 from '@/assets/wBTC_32.png';
import wETH_32 from '@/assets/wETH_32.png';
import USDC_32 from '@/assets/USDC_32.png';
import tether_32 from '@/assets/tether_32.png';
import coins_illustration from '@/assets/coins.png';

import LUMIN_grey from '@/assets/LUMIN_grey.png';
import wBTC_grey from '@/assets/wBTC_grey.png';
import wETH_grey from '@/assets/wETH_grey.png';
import USDC_grey from '@/assets/USDC_grey.png';
import USDT_grey from '@/assets/USDT_grey.png';

import twitter_white from '@/assets/twitter-white.png';
import telegram_white from '@/assets/telegram-white.png';
import medium_white from '@/assets/medium-white.png';
import web_white from '@/assets/web-white.png';
import arbLogoSrc from '@/assets/arbitrum-logo.svg';

import gql from "graphql-tag";
import { useQuery, useApolloClient } from "@vue/apollo-composable";

export default {
    name: 'LuminProfile',
    setup() {
        // LUMIN VARs
        let luminInstance;
        let chainAssets;
        let assetManager;
        const { resolveClient } = useApolloClient();

        // WALLET VARs
        let walletConnected = ref(false);
        let walletString = ref("0x");
        let walletStringShort = ref("");

        // WAGMI SETUP
        const { chains, publicClient, webSocketPublicClient } = configureChains(
            [bscTestnet],
            [publicProvider()],
        );
 
        const config = createConfig({
            bscTestnet,
            publicClient,
            webSocketPublicClient,
        });

        // APOLLO GRAPHQL SETUP
        // WALLET ACTIONS
        // let totalDeposits = ref(0);
        let latestActionsProfile = ref([]);
        let userOffersData = ref([]);
        let userLoansData = ref([]);
        let allLoansData = ref([]);
        let assetsActiveOracles = ref([]);

        const latestActionsProfileQuery = useQuery(gql`
            query assetActions ($userAddress: Bytes!) {
                assetActions(
                    where: { userAddress: $userAddress }
                    orderBy: blockNumber, orderDirection:desc
                ) {
                    id
                    asset {
                        symbol
                        decimals
                    }
                    amount
                    userAddress
                    action
                    transactionHash
                    blockNumber
                },
                loanConfigs (
                    where: { lender: $userAddress }
                    orderBy: id, orderDirection: desc
                ) {
                    id
                    enabled
                    minPrincipalAmount
                    maxPrincipalAmount
                    maxTotalPendingPrincipalAmount
                    termPaymentType
                    interestPromille
                    principalAsset {
                        id
                        symbol
                        decimals
                    }
                    acceptedPriceFeeds {
                        id
                        description
                    }
                    acceptedAssets {
                        asset {
                            id
                            symbol
                        }
                        useInterest
                        useCollateral
                        acceptedPriceFeeds {
                            id
                            description
                        }
                    }
                    lender
                    lenderDeposit {
                        total
                        locked
                    }
                },
                loans (
                    where: { config_: { lender: $userAddress }}
                ) {
                    id
                    principalAmount
                    principalPriceFeedProxy {
                        id
                        description    
                    }
                    acceptedAssetsAmounts
                    acceptedAssetsPriceFeedProxies
                    status
                    borrower
                    principalPayments {
                        amountPaid
                        amountPending
                        payer
                    }
                    interestPayments {
                        amountPaid
                        amountPending
                        payer
                    }
                    created {
                        id
                        blockTimestamp
                    }
                    events {
                        transactionHash
                    }
                    config {
                        id
                        enabled
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        totalPendingPrincipalAmount
                        termPaymentType
                        interestPromille
                        terms
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        lender
                        lenderDeposit {
                            total
                            locked
                        }
                    }
                },
                loanCreateds {
                    id
                    loanId
                    config {
                        id
                        enabled
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        totalPendingPrincipalAmount
                        termPaymentType
                        interestPromille
                        terms
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        lender
                        lenderDeposit {
                            total
                            locked
                        }
                    }
                    transactionHash
                },
                all_user_loans: loans (
                    where: { borrower: $userAddress }
                ) {
                    id
                    principalAmount
                    principalPriceFeedProxy {
                        id
                        description    
                    }
                    acceptedAssetsAmounts
                    acceptedAssetsPriceFeedProxies
                    status
                    borrower
                    created {
                        id
                        blockTimestamp
                    }
                    events {
                        transactionHash
                    }
                    principalPayments {
                        amountPaid
                        blockTimestamp
                    }
                    interestPayments {
                        amountPaid
                        blockTimestamp
                    }
                    pendingInterestAmount
                    pendingPrincipalAmount
                    config {
                        id
                        enabled
                        termPaymentType
                        interestPromille
                        terms
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                                decimals
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        totalPendingPrincipalAmount
                    }
                },
                assets {
                    symbol
                    priceFeeds {
                        enabled
                        priceFeedIndex
                        proxy {
                            description
                        }
                    }
                }
            }
            `, {
                userAddress: walletString
            }, { fetchPolicy: 'no-cache' }
        );
        latestActionsProfileQuery.onResult( data => {
            latestActionsProfile.value.length = 0;
            if (data?.data?.assetActions) {
                // console.log(data?.data)
                for (let action of data?.data?.assetActions) {
                    const actionData = {
                        id: action.id,
                        asset: action.asset.symbol,
                        userAddress: action.userAddress,
                        action: action.action,
                        transactionHash: action.transactionHash,
                        symbol: action.asset.symbol,
                        decimals: action.asset.decimals
                    }

                    actionData['amount'] = parseFloat(ethers.formatUnits(action.amount.toString(), action.asset.decimals)),
                    actionData['userAddressShort'] = action.userAddress.slice(0, 4) + "..." + action.userAddress.slice(-4);

                    if (actionData.symbol == "BTC") {
                        actionData.asset_img = wBTC_grey;
                        actionData.asset = "Bitcoin";
                        actionData['amount'] = actionData['amount'].toFixed(7);
                    } else if (actionData.symbol == "ETH") {
                        actionData.asset_img = wETH_grey;
                        actionData.asset = "Ethereum";
                        actionData['amount'] = actionData['amount'].toFixed(7);
                    } else if (actionData.symbol == "USDC") {
                        actionData.asset_img = USDC_grey;
                        actionData.asset = "USD Coin";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "USDT") {
                        actionData.asset_img = USDT_grey;
                        actionData.asset = "Tether";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "MOCK") {
                        actionData.asset_img = LUMIN_grey;
                        actionData.asset = "Mock";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "SMOCK") {
                        actionData.asset_img = LUMIN_grey;
                        actionData.asset = "SMock";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    }

                    latestActionsProfile.value.push(actionData);
                }
                userOffersData.value.length = 0;
                for (let offer of data?.data?.loanConfigs) {
                    const offerData = {
                        configId: offer.id,
                        enabled: offer.enabled,
                        collateral_assets: [],
                        interest_assets: [],
                        principal_asset_decimals: offer.principalAsset.decimals,
                        loans: []
                    }

                    if (offer.termPaymentType == "None") offerData['repayment_type'] = "Type 1";
                    if (offer.termPaymentType == "Interest") offerData['repayment_type'] = "Type 2";
                    if (offer.termPaymentType == "InterestAndPrincipal") offerData['repayment_type'] = "Type 3";

                    for (let acceptedAsset of offer.acceptedAssets) {
                        if (acceptedAsset.useCollateral) {
                            offerData['collateral_assets'].push(acceptedAsset.asset.symbol);
                        }
                        if (acceptedAsset.useInterest) {
                            offerData['interest_assets'].push(acceptedAsset.asset.symbol);
                        }
                    }

                    // Make string from offerData['collateral_assets'] and offerData['interest_assets'] array
                    offerData['collateral_assets'] = offerData['collateral_assets'].join(', ');
                    offerData['interest_assets'] = offerData['interest_assets'].join(', ');
                    // If array is empty set horizontal HTML symbol
                    if (offerData['collateral_assets'] == "") {
                        offerData['collateral_assets'] = "—";
                    }
                    if (offerData['interest_assets'] == "") {
                        offerData['interest_assets'] = "—";
                    }

                    offerData['active_loans'] = 0;
                    offerData['principal_asset'] = offer.principalAsset.symbol;

                    userOffersData.value.push(offerData);
                }
                // GO THROUGHT data?.data?.loans AND INCREMENT active_loans FOR EACH OFFER MATHCING configId
                for (let loan of data?.data?.loans) {
                    for (let userOffer of userOffersData.value) {
                        if (userOffer.configId == loan.config.id) {
                            let loanPrincipalPaid = 0;
                            let loanInterestPaid = 0;

                            userOffer.active_loans += 1;
                            for (let allPaidPrincipal of loan.principalPayments) {
                                loanPrincipalPaid += parseFloat(ethers.formatUnits(allPaidPrincipal.amountPaid, loan.config.principalAsset.decimals));
                            }
                            loan['principal_paid'] = loanPrincipalPaid.toFixed(3);
                            for (let allInterestPaid of loan.interestPayments) {
                                loanInterestPaid += parseFloat(ethers.formatUnits(allInterestPaid.amountPaid, loan.config.principalAsset.decimals));
                            }
                            loan['interest_paid'] = loanInterestPaid.toFixed(3);
                            loan['borrower_address_short'] = loan.borrower.slice(0, 4) + "..." + loan.borrower.slice(-4);

                            userOffer.loans.push(loan);
                        }
                    }
                }
                // GO THROUGHT ALL LOANS AND ADD IT TO userLoansData
                userLoansData.value.length = 0;
                for (let loan of data?.data?.all_user_loans) {
                    const loanData = {
                        loanId: loan.id,
                        events: loan.events,
                        principalAmount: loan.principalAmount,
                        principalPriceFeedProxy: loan.principalPriceFeedProxy,
                        collateralAssetAmounts: loan.acceptedAssetsAmounts,
                        status: loan.status,
                        principalPayments: loan.principalPayments,
                        interestPayments: loan.interestPayments,
                        pendingInterestAmount: loan.pendingInterestAmount,
                        pendingPrincipalAmount: loan.pendingPrincipalAmount,
                        vanilla_config_data: loan.config,
                        created_blocktimestamp: loan.created.blockTimestamp,
                        visible: true,
                        pending_changes: false
                    }
                    userLoansData.value.push(loanData);
                }
                // MAKE A LIST OF ALL ASSETS AVALIABLE ORACLES
                assetsActiveOracles.value.length = 0;
                for (let asset of data?.data?.assets) {
                    assetsActiveOracles.value.push(asset);
                }
                findWalletLoansData();
            }
            loadingUserAsset.value = false;
            loadingUserOffers.value = false;
            loadingUserLoans.value = false;
            /* console.log("userOffersData -----------> ", userOffersData) */
        });

        // APOLLO GRAPHQL SETUP
        // WALLET ACTIONS
        // let totalDeposits = ref(0);
        let userActiveLoansQuery = useQuery(gql`
            query userLoans ($userAddress: Bytes!) {
                all_user_loans: loans (
                    where: { borrower: $userAddress }
                ) {
                    id
                    principalAmount
                    acceptedAssetsAmounts
                    status
                    borrower
                    created {
                        id
                        blockTimestamp
                    }
                    events {
                        transactionHash
                    }
                    principalPayments {
                        amountPaid
                        blockTimestamp
                    }
                    interestPayments {
                        amountPaid
                        blockTimestamp
                    }
                    pendingInterestAmount
                    pendingPrincipalAmount
                    config {
                        id
                        enabled
                        termPaymentType
                        interestPromille
                        terms
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                                decimals
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        totalPendingPrincipalAmount
                    }
                }
            }
            `, {
                userAddress: walletString
            }, { fetchPolicy: 'no-cache' }
        );
        userActiveLoansQuery.onResult( data => {
            if (data?.data?.userLoans) {
                /* console.log("all_user_loans ------------>", data?.data) */
                userLoansData.value.length = 0;
                // GO THROUGHT ALL LOANS AND ADD IT TO userLoansData
                for (let loan of data?.data?.all_user_loans) {
                    const loanData = {
                        loanId: loan.id,
                        events: loan.events,
                        principalAmount: loan.principalAmount,
                        principalPriceFeedProxy: loan.principalPriceFeedProxy,
                        collateralAssetAmounts: loan.acceptedAssetsAmounts,
                        status: loan.status,
                        principalPayments: loan.principalPayments,
                        interestPayments: loan.interestPayments,
                        pendingInterestAmount: loan.pendingInterestAmount,
                        pendingPrincipalAmount: loan.pendingPrincipalAmount,
                        vanilla_config_data: loan.config,
                        visible: true,
                        pending_changes: false
                    }
                    userLoansData.value.push(loanData);
                }
                findWalletLoansData();
            }
            /* console.log("userOffersData -----------> ", userOffersData) */
        });

        // Health Factor Indicators
        let healthFactorCalc = ref(0);
        let healthFactorIndicator = ref(0);

        // Principal and Interest Payment Indicators
        let principalPaymentIndicator = ref(0);
        let interestPaymentIndicator = ref(0);

        const screenWidth = ref(window.screen.width);
        let isMobileView = ref(screenWidth.value < 1024);
        let isDesktop = ref(screenWidth.value >= 1024);
        let unsupportedNetwork = ref(false);
        let ethereum = ref(window['ethereum']);
        let web3Provider = ref(null);

        let loaderWorking = ref(true);
        let libIsInstantiated = ref(true);


        let loadingUserAsset = ref(false);
        let loadingUserOffers = ref(false);
        let loadingUserLoans = ref(false);
        let loanDetailDialog = ref("manage-interest");
        let payingInterestTerm = ref(false);
        let showInterestOptions = ref(false);
        // manage-principal
        const UIPreview = ref("profile");
        const mainUIheader = ref("");
        
        const userWalletData = ref([]);
        const userBorrowsData = ref([]);
        const tabsManager = ref(null);
        const mainTab = ref(null);

        // Offer Dialog Variables
        let loadingAssetDollarValues = ref(true);
        let bitcoinPrice = ref(0);
        let ethereumPrice = ref(0);
        let usdcPrice = ref(0);
        let usdtPrice = ref(0);
        let mockPrice = ref(0);
        let smockPrice = ref(0);

        let tabsManagerOpen = ref(false);

        let isModalOpen = ref(false);
        let modalTitle = ref('');
        let modalContent = ref('');
        let modalData = ref(null);

        let isToastOpen = ref(false);
        let toastTitle = ref('');
        let toastContent = ref('');
        let toastData = ref(null);
        let notifications = ref([]);

        // TOAST NOTIFICATIONS REWORK
        let toasts = ref([]);

        let currentStep = ref(1);
        let numSteps = ref(4);

        // Animations
        let isWalletOffersExpanded = ref(true);
        let isWalletBorrowsExpanded = ref(true);

        // Filter Management
        let allMarketFilters = ref(
            {
                marketFilterLoanSize: {
                    in_use: false,
                    selected_min: 100,
                    selected_max: 100000
                },
                marketFilterCollateral: {
                    in_use: false,
                    selected: []
                },
                marketFilterTerms: {
                    in_use: false,
                    selected_min: 1,
                    selected_max: 10
                },
                marketFilterInterest: {
                    in_use: false,
                    selected_min: 2,
                    selected_max: 12
                },
                marketFilterInterestPerTerm: {
                    in_use: false,
                    selected_min: 2,
                    selected_max: 12
                },
                marketFilterRepaymentType: {
                    in_use: false,
                    selected: []
                },
            }
        );
        let loadingOffers = ref(false);
        let filterActive = ref(false);
        let interestAssetAmount = ref(0);
        let principalAssetAmount = ref(0);
        let interestFuturePayoffBar = ref(0);
        let principalFuturePayoffBar = ref(0);

        let showMarketFilter = ref(false);
        let marketFilterLoanSize = ref(false);
        let marketFilterCollateral = ref(false);
        let marketFilterTerms = ref(false);
        let marketFilterInterest = ref(false);
        let marketFilterInterestPerTerm = ref(false);
        let marketFilterRepaymentType = ref(false);

        let loanSizeUnit = ref("USD");

        let available_loan_asset_indicator = ref(100);
        let minimum_loan_indicator = ref(0);
        let maximum_loan_indicator = ref(0);
        let allocated_deposit_indicator = ref(0);

        let collateral_adjustment_option = ref('supply');

        let loanConfiguration = ref({
            collateralPercentage: 130,
            interestPercentage: null,
            minPrincipalAmount: null,
            maxPrincipalAmount: null,
            maxTotalPendingPrincipalAmount: null,
            safetyBufferPercentage: 10,
            termPaymentType: null,
            terms: null,
            terms_days: null,
        });

        let offerObject = ref({
            loan_size_min: 0,
            loan_size_max: 0,
            loan_size_min_int: 0,
            loan_size_max_int: 0,
            loan_size_max_total: 0,
            allowed_collateral: "",
            interest: 0,
            interest_int: 0,
            installments: 0
        });

        let principalAssetFeeds = ref([]);
        let collateralInterestAssets = ref([]);
        let repaymentOptions = ref([
            {
                value: 0,
                checked: false,
                description: "Interest every term"
            },
            {
                value: 1,
                checked: false,
                description: "Interest & Principal every term"
            },
            {
                value: 2,
                checked: false,
                description: "Interest & Principal on last day of loan"
            }
        ]);
        let enabledOracleFeeds = ref([]);
        let loadingOracleFeeds = ref(false);

        let loanConfigurationValidation = ref({
            principal: false,
            collateral: false,
            interest: false,
            loan_summary: false
        });

        // PAYING OFF LOANS
        let termsTimeline = ref([]);
  
        // methods
        const startLumin = async () => {
            let provider = new ethers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545');

            // NEW LIB LOGIC
            luminInstance = new LuminClient();
            await luminInstance.init();
            luminInstance.provider = provider;
        };

        const switchNetwork = async () => {
            console.log(unsupportedNetwork.value)
            const provider = window['ethereum'];
            if(!provider) {
                console.log("Metamask is not installed, please install!");
            } else {
                const chainId = await provider.request({ method: 'eth_chainId' });
                const binanceTestChainId = '0x61'
                unsupportedNetwork.value = true;

                console.log(unsupportedNetwork.value)

                if (chainId === binanceTestChainId) {

                console.log("Bravo!, you are on the correct network");

                } else {
                    console.log("oulalal, switch to the correct network")
                    try {

                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: '0x61'}],
                        });
                        console.log("You have succefully switched to Binance Test network")
                        unsupportedNetwork.value = false;

                    } catch (switchError) {

                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === binanceTestChainId) {
                        console.log("This network is not available in your metamask, please add it")
                        }
                        console.log("Failed to switch to the network")
                    }
                }
            }
        };

        const connectWallet = async () => {
            const provider = window['ethereum'];

            if (typeof ethereum.value !== 'undefined') {
                console.log('MetaMask is installed!');
            } else {
                console.log('MetaMask is not installed!');
            }
            
            if (ethereum.value) {
                web3Provider.value = ethereum.value;

                try {
                    // Request account access
                    loaderWorking.value = true;
                    ethereum.value.request({ method: 'eth_requestAccounts' })
                    .then(async (address) => {
                        console.log("Account connected: ", address[0]); // Account address that you had imported
                        console.log("Account addresses: ", address); // Account address that you had

                        walletConnected.value = true;
                        walletString.value = address[0];
                        walletStringShort.value = address[0].substring(0,4) + '...' + address[0].substr(address[0].length - 4);
                        loaderWorking.value = false;

                        // Check if the key exists in local storage
                        if (localStorage.getItem('connected_address')) {
                        // Key exists, update the value
                            localStorage.setItem('connected_address', walletString.value);
                        } else {
                        // Key doesn't exist, set the value
                            localStorage.setItem('connected_address', walletString.value);
                        }

                        // Detect Network on Connect Even
                        const chainId = await provider.request({ method: 'eth_chainId' });
                        if (chainId != '0x61') {
                            unsupportedNetwork.value = true;
                        } else {
                            unsupportedNetwork.value = false;
                        }

                        // Fetch wallet holdings
                        refreshUserHoldings(address[0]);
                        fetchUserActions(address[0]);

                        // Watch Network Changes
                        startChainDetection();
                    })
                    .catch(() => {
                        loaderWorking.value = false;
                    })
                } catch (error) {
                    // User denied account access...
                    loaderWorking.value = false;
                }
            }
        };

        const checkWalletConnection = async () => {
            // Check if the key exists in local storage
            // Key exists, retrieve the value
            let walletValue = localStorage.getItem('connected_address');
            libIsInstantiated = true;

            // FETCH USER DATA ON PROTOCOL LEVEL
            if (walletValue) {
                // Watch Network Changes
                startChainDetection();

                walletConnected.value = true;
                walletString.value = walletValue;
                walletStringShort.value = walletValue.substring(0,4) + '...' + walletValue.substr(walletValue.length - 4);

                refreshUserHoldings(walletValue);
                fetchUserActions(walletValue);
                fetchUserLoans(walletValue);
            }
        };

        const refreshUserHoldings = async (wallet) => {
            // FETCH USER DATA ON PROTOCOL LEVEL
            console.log(wallet)
            console.log(luminInstance)
            try {
                /* console.log("USER WALLET", wallet); */
                const walletStats = await luminInstance.assetManager.balancesOf(wallet);
                const assets = await luminInstance.backend.assets();
                console.log("luminInstance ------------------------->", assets);

                for (let libAsset of assets) {
                    for (let usrAsset of walletStats) {
                        if (libAsset.id == usrAsset.assetId) {

                            usrAsset['allowed'] = null;
                            usrAsset['id'] = libAsset.symbol;
                            usrAsset['ticker'] = libAsset.symbol;
                            usrAsset['decimals'] = libAsset.decimals;
                            usrAsset['contract'] = libAsset.address;
                            usrAsset['processing_tx'] = false;
                            usrAsset['available'] = parseFloat(ethers.formatUnits(usrAsset.balance, libAsset.decimals));
                            usrAsset['deposit'] = parseFloat(ethers.formatUnits(usrAsset.user.total, libAsset.decimals));
                            usrAsset['deposit_lock'] = parseFloat(ethers.formatUnits(usrAsset.user.locked, libAsset.decimals));
                            usrAsset['lib_asset'] = libAsset;

                            if (libAsset.symbol == 'ETH') {
                                usrAsset['name'] = 'Ethereum';
                                usrAsset['asset'] = 'Ethereum';
                                usrAsset['image'] = wETH_32;
                                usrAsset['available'] = usrAsset.available.toFixed(7);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }
                            if (libAsset.symbol == 'BTC') {
                                usrAsset['name'] = 'Bitcoin';
                                usrAsset['asset'] = 'Bitcoin';
                                usrAsset['image'] = wBTC_32;
                                usrAsset['available'] = usrAsset.available.toFixed(7);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }
                            if (libAsset.symbol == 'USDC') {
                                usrAsset['name'] = 'USD Coin';
                                usrAsset['asset'] = 'USD Coin';
                                usrAsset['image'] = USDC_32;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }
                            if (libAsset.symbol == 'USDT') {
                                usrAsset['name'] = 'Tether';
                                usrAsset['asset'] = 'Tether';
                                usrAsset['image'] = tether_32;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }

                            if (libAsset.symbol == 'MOCK') {
                                usrAsset['name'] = 'Mock';
                                usrAsset['asset'] = 'Mock';
                                usrAsset['image'] = LUMIN_grey;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }

                            if (libAsset.symbol == 'SMOCK') {
                                usrAsset['name'] = 'SMock';
                                usrAsset['asset'] = 'SMock';
                                usrAsset['image'] = LUMIN_grey;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                    usrAsset['price'] = parseFloat(formatUnits(price[0].value, price[0].decimals));
                                });
                            }
                        }
                    }
                }

                // SORT walletStats BY AVAILABLE BALANCE
                walletStats.sort((a, b) => (a.available < b.available) ? 1 : -1);
                userWalletData.value = walletStats;
            } catch (error) {
                console.log(error);
            }
        };

        const findWalletLoansData = async () => {
            // Check if the key exists in local storage
            // Key exists, retrieve the value
            let walletValue = localStorage.getItem('connected_address');
            // console.log("findWalletLoansData ------------------------->", userLoansData.value)

            if (walletValue) {
                for (let loan of userLoansData.value) {
                    let collateralList = "";
                    let cumulativeDebt = 0;

                    for (let collateral of loan.vanilla_config_data.acceptedAssets) {
                        collateralList += collateral.asset.symbol + ", ";
                        const indexOfAcceptedAsset = loan.vanilla_config_data.acceptedAssets.indexOf(collateral);
                        cumulativeDebt += parseFloat(ethers.formatUnits(loan.collateralAssetAmounts[indexOfAcceptedAsset], loan.vanilla_config_data.acceptedAssets[indexOfAcceptedAsset].asset.decimals));
                    }

                    loan['collateral'] = collateralList.slice(0, -2);
                    loan['loan_vanilla_data'] = loan.vanilla_config_data;
                    loan['loan_vanilla_data']['principalAmountNative'] = parseFloat(ethers.formatUnits(loan.principalAmount, loan.vanilla_config_data.principalAsset.decimals)).toFixed(3);
                    loan['loan_vanilla_data']['pendingPrincipalAmountNative'] = ethers.formatUnits(loan.pendingPrincipalAmount, loan.vanilla_config_data.principalAsset.decimals);
                    loan['loan_vanilla_data']['pendingInterestAmountNative'] = ethers.formatUnits(loan.pendingInterestAmount, loan.vanilla_config_data.principalAsset.decimals);
                    loan['cumulativeDebt'] = cumulativeDebt;
                    // CALCULATE HEALTH RATIO AND TAKE INTO ACCOUNT ALL COLLATERAL ASSETS + PRINCIPAL ASSET PRICE
                    if (loan['loan_vanilla_data']['pendingPrincipalAmountNative'] > 0) loan['health'] = (loan['cumulativeDebt'] / (loan['loan_vanilla_data']['pendingPrincipalAmountNative']) * 1).toFixed(2)
                    else loan['health'] = 0;
                }
            }

            // console.log("ALL USER LOANS AFTER ------------------------->", userLoansData.value)
        };

        const fetchUserActions = async (wallet) => {
            /* console.log("USER WALLET", wallet); */
            setTimeout(() => {
                latestActionsProfileQuery.refetch();
            }, 5000);
        };

        const fetchUserLoans = async (wallet) => {
            /* console.log("USER WALLET", wallet); */
            setTimeout(() => {
                userActiveLoansQuery.refetch();
            }, 5000);
        };

        const startChainDetection = () => {
            window.ethereum
                .on("chainChanged", (chainId) => {
                    // console.log("Chain Detection Trigger ---------> ", chainId)

                    if (chainId != '0x61') {
                        unsupportedNetwork.value = true;
                    } else {
                        unsupportedNetwork.value = false;
                    }

                })
                .on("accountsChanged", () => {
                    // console.log("User Wallet Changed!")
                    disconnectAddress();
                });

            window.ethereum.request({ method: 'eth_chainId' })
                .then((chainId) => {
                    // console.log("Chain Detection Trigger ---------> ", chainId)

                    if (chainId != '0x61') {
                        unsupportedNetwork.value = true;
                    } else {
                        unsupportedNetwork.value = false;
                    }

                })
                .catch((err) => {
                    console.error(err);
                });
        };

        const disconnectAddress = () => {
            // Remove the key 'connected_address' from local storage
            localStorage.removeItem('connected_address');
            walletConnected.value = false;
            walletString.value = "";
            walletStringShort.value = "";
            userWalletData.value = [];
            latestActionsProfile.value = [];
            UIPreview.value = "markets";
        };
  
        const manageSidebar = () => {
            console.log(mainTab)
            console.log(tabsManagerOpen)

            /* if (mainTab.nativeElement.style.width == '100%')
                mainTab.nativeElement.style.width = '66%'

            else mainTab.nativeElement.style.width = '100%'; */

            tabsManagerOpen.value = !tabsManagerOpen.value;
        };
  
        const showMarket = (market) => {
            console.log(market)
            mainUIheader.value = market;
            UIPreview.value = "market";
            loadingOffers.value = true;

            setTimeout(() => {
                loadingOffers.value = false;
            },  1000);
        };

        const showProfile = (wallet) => {
            console.log(wallet)
            UIPreview.value = "profile";
            mainUIheader.value = wallet;
        };

        // MODAL LOGIC
        const openModal = async (title, paragraph, data) => {
            isModalOpen.value = true;

            if (title == 'DEPOSIT' || title == 'WITHDRAW') {

                modalTitle.value = title;
                modalContent.value = paragraph;
                modalData.value = JSON.parse(JSON.stringify(data));

                console.log(data)

                modalData.value['withdraw_amount'] = 0;
                modalData.value['deposit_amount'] = 0;

                try {
                    let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                    const signer = await temp_provider.getSigner();

                    let temp_erc20Contract = new Contract(
                        modalData.value.contract,
                        ERC20,
                        signer
                    );

                    const luminContracts = await luminInstance.backend.contracts();
                    // AssetManger contract is the second contract in the array 
                    // const luminManagerAddress = luminContracts[1].address;
                    // Change logic that is one line above, find inside array the contract with the name AssetManager name: "AssetManager"
                    const luminManagerAddress = luminContracts.find((contract) => contract.name == "AssetManager").address;
                    console.log(luminManagerAddress);

                    let allowanceCheck = await temp_erc20Contract['allowance'](walletString.value, luminManagerAddress);
                    console.log(typeof allowanceCheck)
                    console.log(Number(allowanceCheck))

                    let allowanceAmount = allowanceCheck.toString();
                    let assetAllowed = parseFloat(formatUnits(allowanceAmount, modalData.value.decimals));

                    console.log(assetAllowed); // Output: 123456789
                    modalData.value.allowed = assetAllowed;

                } catch (error) {
                    console.log(error);
                    // OPEN TOAST WITH ERROR
                    newToastMessage({
                        isToastOpen: true,
                        title: "RPC Error - Change RPC URL",
                        content: (error.toString().substring(0,75)) + '.....',
                        icon: "fa fa-close", 
                        color: "red"
                    });
                    // CLOSE DIALOG
                    isModalOpen.value = false;
                }

            } else if (title == 'LOAN DETAILS') {
                
                isModalOpen.value = true;

                modalTitle.value = title;
                modalContent.value = paragraph;
                modalData.value = JSON.parse(JSON.stringify(data));

                console.log(userWalletData.value)

                for (let collAsset of modalData.value?.vanilla_config_data.acceptedAssets) {
                    for (let userAsset of userWalletData.value) {
                        if (collAsset.asset.symbol == userAsset.id) {
                            collAsset['amount_avaliable'] = parseFloat(userAsset.deposit);
                            collAsset['amount_to_use'] = 0;
                            collAsset['deposit'] = userAsset.deposit;
                            collAsset['deposit_lock'] = userAsset.deposit_lock;
                            collAsset['allowed'] = userAsset.allowed;
                            collAsset['ticker'] = userAsset.ticker;
                            collAsset['decimals'] = userAsset.decimals;
                            collAsset['contract'] = userAsset.contract;
                            collAsset['processing_tx'] = false;
                            collAsset['asset_img'] = userAsset.image;
                            collAsset['name'] = userAsset.name;
                            collAsset['asset'] = userAsset.asset;
                            collAsset['price'] = userAsset.price;
                            collAsset['lib_asset'] = userAsset.lib_asset;

                            // FETCH INTEREST ASSET PRICE DATA FROM SELECTED ORACLE
                            luminInstance.assetManager.getPrice(collAsset.lib_asset).then((price) => {
                                // console.log("ORACLES PRICE DATA FOR INTEREST ASSETS ------------------------->", price);
                                if (price.length == 1) {
                                    collAsset['price'] = parseFloat((FixedNumber.fromValue(price[0].value, price[0].decimals))['_value']);
                                } else {
                                    if (collAsset.acceptedPriceFeeds[0].id == "0") collAsset['price'] = parseFloat((FixedNumber.fromValue(price[0].value, price[0].decimals))['_value']);
                                    if (collAsset.acceptedPriceFeeds[0].id == "5") collAsset['price'] = parseFloat((FixedNumber.fromValue(price[1].value, price[1].decimals))['_value']);
                                }
                            });
                        }

                        // PUT IMAGE INSIDE modalData.value.vanilla_config_data.principalAsset
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'ETH') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = wETH_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Ethereum';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'BTC') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = wBTC_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Bitcoin';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'USDC') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = USDC_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'USD Coin';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'USDT') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = tether_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Tether';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'MOCK') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = LUMIN_grey;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Mock';
                        }
                    }
                }

                // PUT SAME DATA AS ABOVE FOR PRINCIPAL ASSET INSIDE modalData.value.vanilla_config_data.principalAsset
                for (let userAsset of userWalletData.value) {
                    if (modalData.value.vanilla_config_data.principalAsset.symbol == userAsset.id) {
                        modalData.value.vanilla_config_data.principalAsset['amount_avaliable'] = parseFloat(userAsset.deposit);
                        modalData.value.vanilla_config_data.principalAsset['amount_to_use'] = 0;
                        modalData.value.vanilla_config_data.principalAsset['deposit'] = userAsset.deposit;
                        modalData.value.vanilla_config_data.principalAsset['deposit_lock'] = userAsset.deposit_lock;
                        modalData.value.vanilla_config_data.principalAsset['allowed'] = userAsset.allowed;
                        modalData.value.vanilla_config_data.principalAsset['ticker'] = userAsset.ticker;
                        modalData.value.vanilla_config_data.principalAsset['decimals'] = userAsset.decimals;
                        modalData.value.vanilla_config_data.principalAsset['contract'] = userAsset.contract;
                        modalData.value.vanilla_config_data.principalAsset['processing_tx'] = false;
                        modalData.value.vanilla_config_data.principalAsset['asset_img'] = userAsset.image;
                        modalData.value.vanilla_config_data.principalAsset['name'] = userAsset.name;
                        modalData.value.vanilla_config_data.principalAsset['asset'] = userAsset.asset;
                        modalData.value.vanilla_config_data.principalAsset['price'] = userAsset.price;
                        modalData.value.vanilla_config_data.principalAsset['lib_asset'] = userAsset.lib_asset;

                        // FETCH PRINCIPAL ASSET PRICE DATA FROM SELECTED ORACLE
                        luminInstance.assetManager.getPrice(modalData.value.vanilla_config_data.principalAsset.lib_asset).then((price) => {
                            // console.log("ORACLES PRICE DATA FOR PRINCIPAL ASSET ------------------------->", price);
                            if (price.length == 1) {
                                modalData.value.vanilla_config_data.principalAsset['price'] = parseFloat((FixedNumber.fromValue(price[0]?.value, price[0]?.decimals))['_value']);
                            } else {
                                if (modalData.value.principalPriceFeedProxy.id == "0") modalData.value.vanilla_config_data.principalAsset['price'] = parseFloat((FixedNumber.fromValue(price[0]?.value, price[0]?.decimals))['_value']);
                                if (modalData.value.principalPriceFeedProxy.id == "5") modalData.value.vanilla_config_data.principalAsset['price'] = parseFloat((FixedNumber.fromValue(price[1]?.value, price[1]?.decimals))['_value']);
                            }
                        });
                    }
                }

                termsTimeline.value.length = 0;
                // CHECK INSIDE modalData.value.vanilla_config_data.terms TO SEE HOW MANY TERMS ARE THERE
                // AND PUSH THEM TO termsTImeline AS AN STRING LIKE 'First/Second/Third Instalment' UP TO 10 INSTALMENTS
                // DEPENDING ON NUMBER OF TERMS PUT css_left WHERE THE END IS 100% AND START IS 0% - SO IF THERE ARE 5 TERMS PUT 20% FOR EACH OR IF THERE ARE 10 TERMS PUT 10% FOR EACH etc.
                for (let i = 0; i < modalData.value.vanilla_config_data.terms; i++) {
                    termsTimeline.value.push({id: i, tooltip: "Instalment " + (i + 1), css_left: (100 / modalData.value.vanilla_config_data.terms) * (i + 1) + "%"});
                }

                modalData.value.vanilla_config_data.principalAsset['max_interest_dollar_amount'] = modalData.value.vanilla_config_data.pendingInterestAmountNative * modalData.value.vanilla_config_data.principalAsset.price;

                console.log(modalTitle.value)
                console.log(modalData.value)

                animateProgress();
            } else if (title == 'ADJUST COLLATERAL') {
                isModalOpen.value = true;

                modalTitle.value = title;
                modalContent.value = paragraph;
                modalData.value = JSON.parse(JSON.stringify(data));

                for (let collAsset of modalData.value.vanilla_config_data.acceptedAssets) {
                    for (let userAsset of userWalletData.value) {
                        if (collAsset.asset.symbol == userAsset.id) {
                            collAsset['amount_avaliable'] = parseInt(userAsset.available);
                            collAsset['deposit'] = userAsset.deposit;
                            collAsset['deposit_lock'] = userAsset.deposit_lock;
                            collAsset['allowed'] = userAsset.allowed;
                            collAsset['ticker'] = userAsset.ticker;
                            collAsset['decimals'] = userAsset.decimals;
                            collAsset['contract'] = userAsset.contract;
                            collAsset['processing_tx'] = false;
                            collAsset['asset_img'] = userAsset.image;
                            collAsset['name'] = userAsset.name;
                            collAsset['asset'] = userAsset.asset;
                            collAsset['price'] = userAsset.price;
                            collAsset['lib_asset'] = userAsset.lib_asset;
                        }

                        // TAKE INDEX OF collAsset AND USE IT TO FIND THE SAME DATA INSIDE modalData.value.loan_vanilla_data.collateralAssetAmounts
                        const indexOfAcceptedAsset = modalData.value.vanilla_config_data.acceptedAssets.indexOf(collAsset);
                        collAsset['amount_to_use'] = parseInt(ethers.formatUnits(modalData.value.collateralAssetAmounts[indexOfAcceptedAsset], modalData.value.vanilla_config_data.acceptedAssets[indexOfAcceptedAsset].decimals));
                        // PUT IMAGE INSIDE modalData.value.vanilla_config_data.principalAsset
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'ETH') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = wETH_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Ethereum';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'BTC') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = wBTC_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Bitcoin';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'USDC') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = USDC_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'USD Coin';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'USDT') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = tether_32;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Tether';
                        }
                        if (modalData.value.vanilla_config_data.principalAsset.symbol == 'MOCK') {
                            modalData.value.vanilla_config_data.principalAsset['asset_img'] = LUMIN_grey;
                            modalData.value.vanilla_config_data.principalAsset['name'] = 'Mock';
                        }
                    }
                }

                console.log(modalTitle.value)
                console.log(modalData.value)
                console.log(userWalletData.value)
            } else if (title == 'CREATE OFFER') {
                isModalOpen.value = true;
                loadingOracleFeeds.value = true;

                modalTitle.value = title;
                modalContent.value = paragraph;
                modalData.value = JSON.parse(JSON.stringify(data));
                console.log(data)
                console.log("modalTitle.value", modalTitle.value)

                // FIND THE PRINCIPAL ASSET
                const assets = await luminInstance.backend.assets();
                const offerAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
                const principalAssetOracles = new LoanConfigAsset(offerAsset);
                const principalAsset = new LoanConfigAcceptedAsset(offerAsset);

                console.log(assets)
                console.log("principalAssetOracles ------------>", principalAssetOracles)
                console.log("principalAssetOracles ------------>", principalAssetOracles.acceptedPriceFeedProxies)

                // Add all assets as accepted collateral assets - exclude offerAsset
                for (const asset of assets) {
                    /* if (asset.symbol != modalData.value.ticker) { */
                    let assetBlueprint = {
                        ticker: asset.symbol,
                        collateral: false,
                        interest: false,
                        asset_img: null,
                        name: null
                    };

                    if (asset.symbol == 'ETH') {
                        assetBlueprint['asset_img'] = wETH_32;
                        assetBlueprint['name'] = 'Ethereum';
                    }
                    if (asset.symbol == 'BTC') {
                        assetBlueprint['asset_img'] = wBTC_32;
                        assetBlueprint['name'] = 'Bitcoin';
                    }
                    if (asset.symbol == 'USDC') {
                        assetBlueprint['asset_img'] = USDC_32;
                        assetBlueprint['name'] = 'USD Coin';
                    }
                    if (asset.symbol == 'USDT') {
                        assetBlueprint['asset_img'] = tether_32;
                        assetBlueprint['name'] = 'Tether';
                    }
                    if (asset.symbol == 'MOCK') {
                        assetBlueprint['asset_img'] = LUMIN_grey;
                        assetBlueprint['name'] = 'Mock';
                    }

                    collateralInterestAssets.value.push(assetBlueprint);
                    /* } */
                    console.log(collateralInterestAssets);
                }

                // Add all price enabled price feed proxies as accepted price feed proxies
                luminInstance.assetManager?.ensurePriceFeedProxies().then((res) => {
                    console.log(res);
                    console.log("All Enabled ---> Price Feed Proxy", luminInstance.assetManager?.priceFeedProxies.filter((p) => p.enabled));
                    for (const priceFeedProxy of luminInstance.assetManager?.priceFeedProxies.filter((p) => p.enabled) ?? []) {
                        for (let oracle of assetsActiveOracles.value) {
                            if (oracle.symbol == modalData.value.ticker) {
                                for (let singleOracle of oracle.priceFeeds) {
                                    if (singleOracle.enabled && singleOracle.priceFeedIndex == priceFeedProxy.index) {
                                        priceFeedProxy['use_oracle'] = false;
                                        principalAssetOracles.addAcceptedPriceFeedProxy(priceFeedProxy);
                                        enabledOracleFeeds.value.push(priceFeedProxy);
                                    }
                                }
                            }
                        }


                        console.log(priceFeedProxy);
                        console.log(assetsActiveOracles.value);
                        // CHECK WHAT PRICE ORACLE IS AVAILABLE FOR PRINCIPAL ASSET
                        // LOOK INTO assetsActiveOracles.value
                        /* for (let oracle of assetsActiveOracles.value) {
                            if (oracle.symbol == modalData.value.ticker) {
                                console.log(oracle)
                                console.log(priceFeedProxy)

                                if (priceFeedProxy.index == oracle.priceFeedIndex) {
                                    for (let oracleFeed of oracle.feeds) {
                                        if (oracleFeed.enabled == true && oracleFeed.priceFeedIndex == priceFeedProxy.index) {
                                            console.log(oracleFeed)
                                            priceFeedProxy['use_oracle'] = true;
                                            principalAsset.addAcceptedPriceFeedProxy(priceFeedProxy);
                                            enabledOracleFeeds.value.push(priceFeedProxy);
                                        }
                                    }
                                }
                            }
                        } */
                        /* priceFeedProxy['use_oracle'] = false;
                        principalAsset.addAcceptedPriceFeedProxy(priceFeedProxy);
                        enabledOracleFeeds.value.push(priceFeedProxy); */
                    }

                    // If one oracle is available, set it as default used oracle
                    if (enabledOracleFeeds.value.length == 1) {
                        enabledOracleFeeds.value[0].use_oracle = true;
                    }
                    loadingOracleFeeds.value = false;
                })
            } else {
                console.log("modalTitle: ", modalTitle.value)
                isModalOpen.value = true;
                loadingOracleFeeds.value = true;

                modalTitle.value = title;
                modalContent.value = paragraph;
                modalData.value = JSON.parse(JSON.stringify(data));

                console.log(data)
            }
        };

        // MODAL ALLOWANCE LOGIC
        const setAllowance = async () => {
            modalData.value.processing_tx = true;

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                let erc20Contract = new ethers.Contract(
                    modalData.value.contract,
                    ERC20,
                    signer
                );

                console.log("ERC20 Contract", erc20Contract)

                const luminContracts = await luminInstance.backend.contracts();
                console.log(luminContracts);
                // AssetManger contract is the second contract in the array 
                // Change logic that is one line above, find inside array the contract with the name AssetManager name: "AssetManager"
                const luminManagerAddress = luminContracts.find((contract) => contract.name == "AssetManager").address;

                // ESTIMATE GAS TX COST
                const gasEstimate = await temp_provider.getFeeData();
                console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                let options = { gasPrice: gasEstimate.gasPrice };

                try {
                    const tx = await erc20Contract.approve(
                        luminManagerAddress,
                        ethers.parseUnits(modalData.value.deposit_amount.toString(), modalData.value.decimals),
                        options
                    );

                    // waitForTransaction() FROM wagmi library
                    const waitTransactionEnd = waitForTransaction({
                        chainId: 97,
                        hash: tx.hash
                    });

                    // IF WE GET HASH SHOW MESSAGE THAT APPROVAL IS PROCESSING
                    if (tx.hash) {
                        newToastMessage({
                            isToastOpen: true,
                            title: "Transaction Pending", 
                            content: "Approval is being processed",
                            icon: "fa fa-info", 
                            color: "grey"
                        });
                    }

                    // AFTER TRANSACTION IS MINED SHOW MESSAGE THAT APPROVAL IS FINISHED
                    waitTransactionEnd.then(res => {
                        newToastMessage({
                            isToastOpen: true,
                            title: "Approval", 
                            content: "Approval is finished", 
                            icon: "fa fa-check", 
                            color: "green"
                        });
                        modalData.value.processing_tx = false;
                        modalData.value.allowed = modalData.value.deposit_amount;
                    }).catch(err => {
                        console.log(err);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Tracking Transaction Lost",
                            content: "To see if allowance is approved, please open modal again!",
                            icon: "fa fa-close", 
                            color: "red"
                        });
                        modalData.value.processing_tx = false;
                    })
                } catch (error) {
                    console.log(error);
                    newToastMessage({
                        isToastOpen: true,
                        title: "Transaction Reverted", 
                        content: (error.toString().substring(0,75)) + '.....',
                        icon: "fa fa-close", 
                        color: "red"
                    });
                    modalData.value.processing_tx = false;
                }
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        // MODAL DEPOSIT LOGIC
        const assetDeposit = async () => {
            console.log("Deposit Asset")
            console.log(modalData.value.deposit)
            console.log(modalData)
            modalData.value.processing_tx = true;

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const assets = await luminInstance.backend.assets();
                const depositAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
                const depositAmount = ethers.parseUnits(modalData.value.deposit_amount.toString(), modalData.value.decimals);

                const depositAsset0Transaction = await luminInstance.assetManager?.deposit(depositAsset, depositAmount);
                console.log(depositAsset0Transaction);
                depositAsset0Transaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction is valid");
                        try {
                            depositAsset0Transaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    tx.gasPrice = gasEstimate.gasPrice;

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);
                                        
                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: 97,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Deposit is being processed",
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                            isModalOpen.value = false;
                                            modalData.value.processing_tx = false;
                                        }

                                        let walletValue = localStorage.getItem('connected_address');
                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "New Deposit", 
                                                content: "Deposit arrived on the protocol", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });
                                            loadingUserAsset.value = true;
                                            setTimeout(() => {
                                                refreshUserHoldings(walletValue);
                                                fetchUserActions(walletValue);
                                            }, 3000);
                                        }).catch(err => {
                                            console.log(err);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Tracking Transaction Lost",
                                                content: "Transaction tracking is lost - trying to update data now...",
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                            refreshUserHoldings(walletValue);
                                            fetchUserActions(walletValue);
                                            modalData.value.processing_tx = false;
                                        })
                                    } catch (error) {
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Transaction Error", 
                                            content: (error.toString().substring(0,75)) + '.....',
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                        console.log(error);
                                        modalData.value.processing_tx = false;
                                    }
                                })
                        } catch (error) {
                            newToastMessage({
                                isToastOpen: true,
                                title: "Deposit Error", 
                                content: (error.toString().substring(0,75)) + '.....',
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            console.log(error);
                            modalData.value.processing_tx = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Validation Error", 
                            content: error?.message,
                            icon: "fa fa-close", 
                            color: "red"
                        });
                        modalData.value.processing_tx = false;
                    })
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        const validateDepositInputs = () => {
            if (
                modalData.value.deposit_amount > 0.001 ) {
                return false;
            } else {
                return true;
            }
        };

        // MODAL WITHDRAW LOGIC
        const assetWithdraw = async () => {
            console.log("Withdraw Asset")
            console.log(modalData.value.deposit)
            console.log(modalData)
            modalData.value.processing_tx = true;

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const assets = await luminInstance.backend.assets();
                const withdrawAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
                const withdrawAmount = ethers.parseUnits(modalData.value.withdraw_amount.toString(), modalData.value.decimals);

                const withdrawAsset0Transaction = await luminInstance.assetManager?.withdraw(withdrawAsset, withdrawAmount);
                console.log(withdrawAsset0Transaction);
                withdrawAsset0Transaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction is valid");
                        try {
                            withdrawAsset0Transaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    tx.gasPrice = gasEstimate.gasPrice;

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);

                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: 97,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Withdrawal is being processed",
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                            isModalOpen.value = false;
                                            modalData.value.processing_tx = false;
                                        }

                                        let walletValue = localStorage.getItem('connected_address');
                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "New Withdrawal", 
                                                content: "Withdraw is finished", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });
                                            loadingUserAsset.value = true;
                                            setTimeout(() => {
                                                refreshUserHoldings(walletValue);
                                                fetchUserActions(walletValue);
                                            }, 3000);
                                        }).catch(err => {
                                            console.log(err);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Tracking Transaction Lost",
                                                content: "Transaction tracking is lost - trying to update data now...",
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                            refreshUserHoldings(walletValue);
                                            fetchUserActions(walletValue);
                                            modalData.value.processing_tx = false;
                                        })
                                    } catch (error) {
                                        console.log(error);
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Transaction Error", 
                                            content: (error.toString().substring(0,75)) + '.....',
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                        modalData.value.processing_tx = false;
                                    }
                                })
                        } catch (error) {
                            newToastMessage({
                                isToastOpen: true,
                                title: "Transaction Error", 
                                content: (error.toString().substring(0,75)) + '.....',
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            console.log(error);
                            modalData.value.processing_tx = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Validation Error", 
                            content: error?.message,
                            icon: "fa fa-close", 
                            color: "red"
                        });
                        modalData.value.processing_tx = false;
                    })
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        const validateWithdrawInputs = () => {
            if (
                modalData.value.withdraw_amount > 0 ) {
                return false;
            } else {
                return true;
            }
        };

        // MODAL LOAN DETAILS LOGIC (CREATING AND TAKING LOANS)
        const nextStep = () => {
            currentStep.value++;

            if (currentStep.value > numSteps.value) {
                currentStep.value = 1;
            }
        };

        const stepClasses = (index) => {
            const stepNum = index + 1;

            return {
                editing: stepNum === currentStep.value,
                done: stepNum < currentStep.value,
            };
        };

        const navigateLoanDetailTabs = (direction) => {
            if (direction == 'next') {
                if (loanDetailDialog.value == 'summary') loanDetailDialog.value = 'manage-principal';
                if (loanDetailDialog.value == 'manage-principal') loanDetailDialog.value = 'manage-interest';
                if (loanDetailDialog.value == 'manage-interest') loanDetailDialog.value = 'summary';
            }
            if (direction == 'prev') {
                if (loanDetailDialog.value == 'summary') loanDetailDialog.value = 'manage-interest';
                if (loanDetailDialog.value == 'manage-principal') loanDetailDialog.value = 'summary';
                if (loanDetailDialog.value == 'manage-interest') loanDetailDialog.value = 'manage-principal';
            }
        };

        const changeOfferParam = (value, param1, param2 = null) => {
            if (param1 == 'interest_rate') {
                loanConfiguration.value.interestPercentage = parseInt(value.target.value);
            }
            if (param1 == 'terms') {
                loanConfiguration.value.terms = parseInt(value.target.value);
                loanConfiguration.value.terms_days = parseInt(value.target.value) * 30;
            }
            if (param1 == 'repayment_option') {
                for (let option of repaymentOptions.value) {
                    option.checked = false;
                    if (value.target.checked && option.value == param2) {
                        option.checked = value.target.checked;
                        loanConfiguration.value.termPaymentType = param2;
                    }
                }
            }
            if (param1 == 'collateral') {
                for (let asset of collateralInterestAssets.value) asset.collateral = false;
                param2.collateral = value.target.checked;
                console.log(value)
                console.log(param1)
                console.log(param2)
            }
            if (param1 == 'interest') {
                for (let asset of collateralInterestAssets.value) asset.interest = false;
                param2.interest = value.target.checked;
                console.log(value)
                console.log(param1)
                console.log(param2)
            }
        };

        const principalAssetOracleChange = (event, oracle) => {
            console.log(event)
            console.log(oracle)

            for (let feed of enabledOracleFeeds.value) {
                feed.use_oracle = false;
            }
            oracle.use_oracle = event.target.checked;
        };

        const validateFirstStep = () => {
            if (loanConfiguration.value.minPrincipalAmount > 0 
                && loanConfiguration.value.minPrincipalAmount <= loanConfiguration.value.maxPrincipalAmount
                && loanConfiguration.value.maxPrincipalAmount <= loanConfiguration.value.maxTotalPendingPrincipalAmount
                && enabledOracleFeeds.value.some((feed) => feed.use_oracle == true)
                && loanConfiguration.value.maxTotalPendingPrincipalAmount <= modalData.value.deposit) {
                return true;
            } else {
                return false;
            }
        };

        const validateSecondStep = () => {
            if (collateralInterestAssets.value.some((asset) => asset.collateral == true)
                && collateralInterestAssets.value.some((asset) => asset.interest == true)) {
                return true;
            } else {
                return false;
            }
        };

        const validateThirdStep = () => {
            if (loanConfiguration.value.interestPercentage > 0 
                && loanConfiguration.value.terms > 0
                && repaymentOptions.value.some((option) => option.checked == true)) {
                return true;
            } else {
                return false;
            }
        };

        const assetOffer = async () => {
            console.log(loanConfiguration.value);

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();
                modalData.value.processing_tx = true;

                // FIND THE PRINCIPAL ASSET
                const assets = await luminInstance.backend.assets();
                console.log(assets);
                const offerAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
                console.log(offerAsset);

                const principalAsset = new LoanConfigAcceptedAsset(offerAsset);
                console.log(principalAsset);

                if (principalAsset) {
                    const loanConfig = new LoanConfigBuilder(principalAsset);

                    console.log(loanConfig);

                    loanConfig['collateralPercentage'] = 130;
                    loanConfig['interestPercentage'] = loanConfiguration.value.interestPercentage;
                    loanConfig['minPrincipalAmount'] = parseUnits(loanConfiguration.value.minPrincipalAmount.toString(), modalData.value.decimals);
                    loanConfig['maxPrincipalAmount'] = parseUnits(loanConfiguration.value.maxPrincipalAmount.toString(), modalData.value.decimals);
                    loanConfig['maxTotalPendingPrincipalAmount'] = parseUnits(loanConfiguration.value.maxTotalPendingPrincipalAmount.toString(), modalData.value.decimals);
                    loanConfig['safetyBufferPercentage'] = 10;
                    loanConfig['termPaymentType'] = loanConfiguration.value.termPaymentType;
                    loanConfig['terms'] = loanConfiguration.value.terms;

                    // Adding checked Price Feed on Principal Asset
                    for (const priceFeedProxy of luminInstance.assetManager?.priceFeedProxies.filter((p) => p.enabled) ?? []) {
                        for (let principalFeed of enabledOracleFeeds.value) {
                            if ((principalFeed.index == priceFeedProxy.index) && principalFeed.use_oracle == true) {
                                principalAsset.addAcceptedPriceFeedProxy(priceFeedProxy);
                            }
                        }
                    }

                    console.log(loanConfig);
                    console.log(collateralInterestAssets.value);

                    for (let collIntAsset of assets) {
                        for (let collIntAssetLocal of collateralInterestAssets.value) {
                            if ((collIntAsset.symbol == collIntAssetLocal.ticker) && (collIntAssetLocal.collateral == true || collIntAssetLocal.interest == true)) {
                                const configCollateralInterestAsset = new LoanConfigAcceptedAsset(collIntAsset);

                                if (collIntAssetLocal.collateral == true) {
                                    configCollateralInterestAsset.acceptAsCollateral = true;

                                    for (const priceFeedProxy of luminInstance.assetManager?.priceFeedProxies.filter((p) => p.enabled) ?? []) {
                                        console.log(priceFeedProxy);
                                        configCollateralInterestAsset.addAcceptedPriceFeedProxy(priceFeedProxy);
                                    }
                                }
                                if (collIntAssetLocal.interest == true) {
                                    configCollateralInterestAsset.acceptAsInterestPayment = true;

                                    for (const priceFeedProxy of luminInstance.assetManager?.priceFeedProxies.filter((p) => p.enabled) ?? []) {
                                        console.log(priceFeedProxy);
                                        configCollateralInterestAsset.addAcceptedPriceFeedProxy(priceFeedProxy);
                                    }
                                }

                                loanConfig.addCollateralAsset(configCollateralInterestAsset);
                            }
                        }
                    }
                    console.log(loanConfig);
                    const createLoanConfigTransaction = luminInstance.loanManagerDelegator?.createLoanConfig(loanConfig);
                    console.log(createLoanConfigTransaction);
                    if (createLoanConfigTransaction) {
                        console.log('About to validate');
                        createLoanConfigTransaction.validate(walletString.value)
                            .then(() => {
                                console.log("Transaction is valid");

                                try {
                                    createLoanConfigTransaction.transaction
                                        .then(async tx => {
                                            console.log(tx);

                                            // ESTIMATE GAS TX COST
                                            const gasEstimate = await temp_provider.getFeeData();
                                            console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                            tx.gasPrice = gasEstimate.gasPrice;

                                            try {
                                                const ongoingTx = await signer.sendTransaction(tx);
                                                console.log(ongoingTx);
                                                
                                                const waitTransactionEnd = waitForTransaction({
                                                    chainId: 97,
                                                    hash: ongoingTx?.hash
                                                });

                                                if (ongoingTx?.hash) {
                                                    newToastMessage({
                                                        isToastOpen: true,
                                                        title: "Transaction Pending", 
                                                        content: "Offer is being created",
                                                        icon: "fa fa-info", 
                                                        color: "grey"
                                                    });
                                                    isModalOpen.value = false;
                                                    modalData.value.processing_tx = false;
                                                }

                                                let walletValue = localStorage.getItem('connected_address');
                                                waitTransactionEnd.then(res => {
                                                    newToastMessage({
                                                        isToastOpen: true,
                                                        title: "New Offer", 
                                                        content: "Offer created successfully", 
                                                        icon: "fa fa-check", 
                                                        color: "green"
                                                    });
                                                    loadingUserOffers.value = true;
                                                    setTimeout(() => {
                                                        refreshUserHoldings(walletValue);
                                                        fetchUserActions(walletValue);
                                                    }, 5000);
                                                }).catch(err => {
                                                    console.log(err);
                                                    newToastMessage({
                                                        isToastOpen: true,
                                                        title: "Tracking Transaction Lost",
                                                        content: "Transaction tracking is lost - trying to update Offers now...",
                                                        icon: "fa fa-close", 
                                                        color: "red"
                                                    });
                                                    refreshUserHoldings(walletValue);
                                                    fetchUserActions(walletValue);
                                                    modalData.value.processing_tx = false;
                                                })
                                            } catch (error) {
                                                console.log(error.toString());
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Transaction Error", 
                                                    content: (error.toString().substring(0,75)) + '.....',
                                                    icon: "fa fa-close", 
                                                    color: "red"
                                                });
                                                console.log(error);
                                                modalData.value.processing_tx = false;
                                            }
                                        })
                                } catch (error) {
                                    console.log(error.toString());
                                    newToastMessage({
                                        isToastOpen: true,
                                        title: "Transaction Error",
                                        content: (error.toString().substring(0,75)) + '.....',
                                        icon: "fa fa-close", 
                                        color: "red"
                                    });
                                    console.log(error);
                                    modalData.value.processing_tx = false;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                console.log(error.toString());
                                newToastMessage({
                                    isToastOpen: true,
                                    title: "Validation Error",
                                    content: (error.toString().substring(0,75)) + '.....',
                                    icon: "fa fa-close", 
                                    color: "red"
                                });
                                console.log(error?.message);
                                modalData.value.processing_tx = false;
                            })
                    }
                }
            } catch (error) {
                console.log(error);
                console.log(error.toString());
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        // ENABLE / DISABLE OFFER CONFIGURATION
        const changeOfferAvaliablity = async (offer) => {
            console.log(offer)
            const pickedOffer = JSON.parse(JSON.stringify(offer));
            console.log(pickedOffer)

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const offerAvaliability0Transaction = luminInstance.loanManagerDelegator?.updateLoanConfigEnabledStatus(pickedOffer.configId, !pickedOffer.enabled);
                console.log(offerAvaliability0Transaction);
                offerAvaliability0Transaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction is valid");
                        if (!pickedOffer.enabled) {
                            newToastMessage({
                                isToastOpen: true,
                                title: "Offer Status", 
                                content: "Sign transaction to enable your offer", 
                                icon: "fa fa-info", 
                                color: "grey"
                            });
                        } else {
                            newToastMessage({
                                isToastOpen: true,
                                title: "Offer Status", 
                                content: "Sign transaction to disable your offer", 
                                icon: "fa fa-info", 
                                color: "grey"
                            });
                        }
                        try {
                            offerAvaliability0Transaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    tx.gasPrice = gasEstimate.gasPrice;

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);

                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: 97,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Waiting for transaction to be mined", 
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                            isModalOpen.value = false;
                                        }

                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Offer Status", 
                                                content: "Status is updated successfully", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });
                                            for (let offer of userOffersData.value) {
                                                if (offer.configId == pickedOffer.configId) {
                                                    offer.enabled = !pickedOffer.enabled;
                                                }
                                            }
                                        }).catch(err => {
                                            console.log(err);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Tracking Transaction Lost",
                                                content: "Transaction tracking is lost - please try to refresh page to see most recent data!",
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                        })
                                    } catch (error) {
                                        console.log(error);
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Transaction Error", 
                                            content: (error.toString().substring(0,75)) + '.....',
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                        console.log(error?.message);
                                    }
                                })
                        } catch (error) {
                            console.log(error);
                            newToastMessage({
                                isToastOpen: true,
                                title: "Transaction Error", 
                                content: (error.toString().substring(0,75)) + '.....',
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            console.log(error?.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error?.message);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Validation Error", 
                            content: error?.message, 
                            icon: "fa fa-close", 
                            color: "red"
                        });
                    })
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        // DELETE OFFER 
        const deleteOffer = async (offer) => {
            newToastMessage({
                isToastOpen: true,
                title: "Delete Offer", 
                content: "Sign transaction to delete your offer", 
                icon: "fa fa-info", 
                color: "grey"
            });

            console.log(offer)
            const pickedOffer = JSON.parse(JSON.stringify(offer));
            console.log(pickedOffer)

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const offerAvaliability0Transaction = luminInstance.loanManagerDelegator?.deleteLoanConfig(pickedOffer.configId);
                console.log(offerAvaliability0Transaction);
                offerAvaliability0Transaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction is valid");
                        try {
                            offerAvaliability0Transaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    tx.gasPrice = gasEstimate.gasPrice;

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);

                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: 97,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Waiting for transaction to be mined", 
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                            isModalOpen.value = false;
                                        }

                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Offer Deleted", 
                                                content: "Offer is deleted successfully", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });

                                            for (let offer of userOffersData.value) {
                                                if (offer.configId == pickedOffer.configId) {
                                                    // TAKE INDEX AND SPLICE IT FROM ARRAY
                                                    let index = userOffersData.value.indexOf(offer);
                                                    userOffersData.value.splice(index, 1);
                                                }
                                            }
                                        }).catch(err => {
                                            console.log(err);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Tracking Transaction Lost",
                                                content: "Transaction tracking is lost - please try to refresh page to see most recent data!",
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                        })
                                    } catch (error) {
                                        console.log(error);
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Transaction Error", 
                                            content: (error.toString().substring(0,75)) + '.....',
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    console.log(error?.message);
                                    newToastMessage({
                                        isToastOpen: true,
                                        title: "Transaction Error", 
                                        content: (error.toString().substring(0,75)) + '.....',
                                        icon: "fa fa-close", 
                                        color: "red"
                                    });
                                })
                        } catch (error) {
                            console.log(error);
                            console.log(error?.message);
                            newToastMessage({
                                isToastOpen: true,
                                title: "Transaction Error", 
                                content: (error.toString().substring(0,75)) + '.....',
                                icon: "fa fa-close", 
                                color: "red"
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error?.message);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Validation Error", 
                            content: error?.message, 
                            icon: "fa fa-close", 
                            color: "red"
                        });
                    })
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        };

        // TOAST LOGIC
        const newToastMessage = ({title: title, content: content, icon: icon, color: color}) => {
            // NEW TOAST ARRAY LOGIC
            const newToast = {
                isToastOpen: true,
                toastTitle: title,
                toastContent: content,
                icon: icon,
                color: color
                /* icon: "fa fa-check",
                color: "green" */
            };

            toasts.value.push(newToast);
            
            setTimeout((toastIndex) => {
                fifoToastList();
            }, 5000);
        };

        const fifoToastList = () => {
            if (toasts.value.length > 0) {
                toasts.value[0].isToastOpen = false;
                setTimeout(() => {
                    toasts.value.shift();
                    if (toasts.value.length > 0) {
                        // If there are more items, set another timeout for the next removal
                        setTimeout(() => {
                            fifoToastList();
                        }, 5000);
                    }
                }, 1000);
            }
        };

        const rangeSlide = (value, max) => {
            const start = 0;
            const end = max;
            const percentage = (value - start) / (end - start) * 100;
            // console.log(percentage)

            const slider = document.getElementById('rangeSlider');
            if (slider) {
                // console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 ${percentage}%, #E8E600 ${percentage}%, #808080 0%, #808080 100%)`;
            }
        }

        const setMaxAmount = () => {
            if (modalTitle.value == 'DEPOSIT') modalData.value.deposit_amount = modalData.value.available;
            if (modalTitle.value == 'WITHDRAW') modalData.value.withdraw_amount = modalData.value.deposit;
            
            const slider = document.getElementById('rangeSlider');
            if (slider) {
                console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 100%, #E8E600 100%, #808080 0%, #808080 100%)`;
            }
        }

        const rangeSlideInterest = (value) => {
            console.log(value)
            // Calculate future payoff percentage
            const start = 0;
            const end = 122.52;
            const percentage = (value - start) / (end - start) * 100;
            interestFuturePayoffBar.value = percentage;
        }

        const rangeSlidePrincipal = (value) => {
            console.log(value)
            // Calculate future payoff percentage
            const start = 0;
            const end = 6756.77;
            const percentage = (value - start) / (end - start) * 100;
            principalFuturePayoffBar.value = percentage;
        }

        const rangeOfferAmounts = () => {
            const lowerSlider = document.getElementById('lower');
            const upperSlider = document.getElementById('upper');

            if (lowerSlider.value > upperSlider.value) {
                upperSlider.value += 500;
            }

            if (upperSlider.value < lowerSlider.value) {
                lowerSlider.value -= 500;
            }

            /* console.log(lowerSlider.value)
            console.log(upperSlider.value) */

            /* const start = 0;
            const end = max;

            const percentage = (value - start) / (end - start) * 100;
            console.log(percentage) */
        }

        const rangeSliderCollateral = (value, max, index) => {
            const start = 0;
            const end = max;
            const percentage = (value - start) / (end - start) * 100;
            // console.log(percentage)

            const slider = document.getElementById('rangeSliderCollateral-' + index);
            if (slider) {
                // console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 ${percentage}%, #E8E600 ${percentage}%, #808080 0%, #808080 100%)`;
            }
            // SETTING COLLATERAL AMOUNT AT RIGHT INDEX INSIDE loanAcceptObject.value.collateralAmount
            // const valueString = value.toString();
            // loanAcceptObject.value.collateralAmount[index] = ethers.parseUnits(valueString, modalData.value.loan_vanilla_data.acceptedAssets[index].asset.decimals);
        }

        const switchLoanSizeUnit = () => {
            const switchIcon = document.getElementsByClassName('native-currency-switch');
            console.log(switchIcon[0])
            switchIcon[0].style.transform = `rotate(180deg)`;

            if (loanSizeUnit.value != 'USD') {
                loanSizeUnit.value = 'USD';
            } else {
                loanSizeUnit.value = 'NATIVE';
            }
        }

        // ADJUST COLLATERAL
        const adjustCollateral = async () => {
            console.log("Adjust Collateral");
            console.log(modalData.value);
            console.log("MODAL OFFER CONFIG ID", ethers.parseUnits((modalData.value.loanId).toString(), 'wei'));
            modalData.value.processing_tx = true;

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const loanID = ethers.parseUnits((modalData.value.loanId).toString(), 'wei');
                let collateralAssetAmountsList = [];

                for (let asset of modalData.value?.vanilla_config_data.acceptedAssets) {
                    collateralAssetAmountsList.push(ethers.parseUnits(asset.amount_to_use.toString(), asset.decimals));
                }

                console.log(loanID);
                console.log(collateralAssetAmountsList);

                const adjustCollateralTransaction = luminInstance.loanManagerDelegator?.setCollateral(loanID, collateralAssetAmountsList);
                console.log(adjustCollateralTransaction);

                if (adjustCollateralTransaction) {
                    console.log('About to validate');
                    adjustCollateralTransaction.validate(walletString.value)
                        .then(() => {
                            console.log("Transaction is valid");

                            try {
                                adjustCollateralTransaction.transaction
                                    .then(async tx => {

                                        // ESTIMATE GAS TX COST
                                        const gasEstimate = await temp_provider.getFeeData();
                                        console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                        tx.gasPrice = gasEstimate.gasPrice;

                                        try {
                                            const ongoingTx = await signer.sendTransaction(tx);
                                            const waitTransactionEnd = waitForTransaction({
                                                chainId: 97,
                                                hash: ongoingTx?.hash
                                            });
                                            // FIND LOAN ID FROM userLoansData AND UPDATE pending_changes VALUE
                                            for (let loan of userLoansData.value) {
                                                if (loan.loanId == modalData.value.loanId) {
                                                    loan.pending_changes = true;
                                                }
                                            }

                                            if (ongoingTx?.hash) {
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Transaction Pending", 
                                                    content: "Collateral is being adjusted",
                                                    icon: "fa fa-info", 
                                                    color: "grey"
                                                });
                                                isModalOpen.value = false;
                                                modalData.value.processing_tx = false;
                                            }

                                            waitTransactionEnd.then(res => {
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Success", 
                                                    content: "Collateral is adjusted successfully",
                                                    icon: "fa fa-check", 
                                                    color: "green"
                                                });
                                                loadingUserAsset.value = true;
                                                setTimeout(() => {
                                                    let walletValue = localStorage.getItem('connected_address');
                                                    refreshUserHoldings(walletValue);
                                                    fetchUserActions(walletValue);
                                                    fetchUserLoans(walletValue);
                                                }, 3000);
                                            }).catch(err => {
                                                console.log(err);
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Tracking Transaction Lost",
                                                    content: "Transaction tracking is lost - please try to refresh page to see most recent data!",
                                                    icon: "fa fa-close", 
                                                    color: "red"
                                                });
                                            })
                                        } catch (error) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Error", 
                                                content: (error.toString().substring(0,75)) + '.....',
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                            modalData.value.processing_tx = false;
                                            modalData.value.pending_changes = false;
                                        }
                                    })
                            } catch (error) {
                                newToastMessage({
                                    isToastOpen: true,
                                    title: "Transaction Error", 
                                    content: (error.toString().substring(0,75)) + '.....',
                                    icon: "fa fa-close", 
                                    color: "red"
                                });
                                modalData.value.processing_tx = false;
                            }
                        })
                        .catch((error) => {
                            newToastMessage({
                                isToastOpen: true,
                                title: "Validation Error", 
                                content: error?.message, 
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            modalData.value.processing_tx = false;
                        })
                }
            } catch (error) {
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        }

        const loanAmountsChanges = (param) => {
            /* console.log("Loan Amounts Changes")
            console.log(param) */

            if (param == 'loan_size_min') {
                minimum_loan_indicator.value = ((loanConfiguration.value.minPrincipalAmount / modalData.value.deposit) * 100).toFixed(2);
                /* console.log(minimum_loan_indicator.value) */
            }

            if (param == 'loan_size_max') {
                maximum_loan_indicator.value = ((loanConfiguration.value.maxPrincipalAmount / modalData.value.deposit) * 100).toFixed(2);
                /* console.log(maximum_loan_indicator.value) */
            }

            if (param == 'loan_size_max_allocated') {
                allocated_deposit_indicator.value = ((loanConfiguration.value.maxTotalPendingPrincipalAmount / modalData.value.deposit) * 100).toFixed(2);
                /* console.log(allocated_deposit_indicator.value) */
            }
            /* console.log(loanConfigurationValidation.value) */
        }

        // PAY INTEREST OR/AND PRINCIPAL
        const payInterestPrincipal = async () => {
            console.log("Pay Interest or Principal");
            console.log(modalData.value);
            console.log("MODAL OFFER CONFIG ID", ethers.parseUnits((modalData.value.loanId).toString(), 'wei'));
            modalData.value.processing_tx = true;

            console.log("PRINCIPAL AMOUNT TO USE --->", typeof modalData.value?.vanilla_config_data.principalAsset.amount_to_use);
            console.log("INTEREST AMOUNTS TO USE --->", modalData.value?.vanilla_config_data.acceptedAssets);

            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const loanID = ethers.parseUnits((modalData.value.loanId).toString(), 'wei');
                let interestAssetAmountsList = [];

                // INSTANTIATE NEW PRINCIPAL AMOUNT VARIABLE
                let principalTweakedAmount;

                if (Number(modalData.value?.vanilla_config_data.principalAsset.amount_to_use) > 0) principalTweakedAmount = (Number(modalData.value?.vanilla_config_data.principalAsset.amount_to_use) + 0.000001).toFixed(6)
                else principalTweakedAmount = 0;

                let principalAssetAmount = ethers.parseUnits(principalTweakedAmount.toString(), modalData.value?.vanilla_config_data.principalAsset.decimals);
                
                for (let asset of modalData.value?.vanilla_config_data.acceptedAssets) {
                    if (asset.useInterest == true) {
                        // INSTANTIATE NEW INTEREST AMOUNT VARIABLE
                        if (asset.amount_to_use > 0) {
                            let interestTweakedAmount;

                            if (asset.amount_to_use < 1) interestTweakedAmount = Number(asset.amount_to_use + 0.000001).toFixed(6)
                            else interestTweakedAmount = Number(asset.amount_to_use);

                            interestAssetAmountsList.push(ethers.parseUnits(interestTweakedAmount.toString(), asset.decimals));
                        } else {
                            const interestTweakedAmount = 0;
                            interestAssetAmountsList.push(ethers.parseUnits(interestTweakedAmount.toString(), asset.decimals));
                        }
                    } else {
                        interestAssetAmountsList.push(0);
                    }
                }

                console.log("loanID ----->", loanID);
                console.log("interestAssetAmountsList ----->", interestAssetAmountsList);
                console.log("principalAssetAmount ----->", principalAssetAmount);

                const payInterestPrincip = luminInstance.loanManagerDelegator?.pay(loanID, interestAssetAmountsList, principalAssetAmount);
                console.log(payInterestPrincip);

                if (payInterestPrincip) {
                    console.log('About to validate');
                    payInterestPrincip.validate(walletString.value)
                        .then(() => {
                            console.log("Transaction is valid");

                            try {
                                payInterestPrincip.transaction
                                    .then(async tx => {
                                        console.log(tx);

                                        // ESTIMATE GAS TX COST
                                        const gasEstimate = await temp_provider.getFeeData();
                                        console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                        tx.gasPrice = gasEstimate.gasPrice;

                                        try {
                                            const ongoingTx = await signer.sendTransaction(tx);
                                            console.log(ongoingTx);
                                            
                                            const waitTransactionEnd = waitForTransaction({
                                                chainId: 97,
                                                hash: ongoingTx?.hash
                                            });

                                            if (ongoingTx?.hash) {
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Transaction Pending", 
                                                    content: "Payment is being processed",
                                                    icon: "fa fa-info", 
                                                    color: "grey"
                                                });
                                                isModalOpen.value = false;
                                                modalData.value.processing_tx = false;
                                            }

                                            waitTransactionEnd.then(res => {
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Success", 
                                                    content: "Payment is processed successfully",
                                                    icon: "fa fa-check", 
                                                    color: "green"
                                                });
                                                loadingUserLoans.value = true;
                                                loadingUserAsset.value = true;
                                                setTimeout(() => {
                                                    let walletValue = localStorage.getItem('connected_address');
                                                    refreshUserHoldings(walletValue);
                                                    fetchUserActions(walletValue);
                                                    fetchUserLoans(walletValue);
                                                }, 3000);
                                            }).catch(err => {
                                                console.log(err);
                                                newToastMessage({
                                                    isToastOpen: true,
                                                    title: "Tracking Transaction Lost",
                                                    content: "Transaction tracking is lost - please try to refresh page to see most recent data!",
                                                    icon: "fa fa-close", 
                                                    color: "red"
                                                });
                                            })
                                        } catch (error) {
                                            console.log(error);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Error", 
                                                content: (error.toString().substring(0,75)) + '.....',
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                            modalData.value.processing_tx = false;
                                        }
                                    })
                            } catch (error) {
                                console.log(error);
                                newToastMessage({
                                    isToastOpen: true,
                                    title: "Transaction Error", 
                                    content: (error.toString().substring(0,75)) + '.....',
                                    icon: "fa fa-close", 
                                    color: "red"
                                });
                                modalData.value.processing_tx = false;
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            newToastMessage({
                                isToastOpen: true,
                                title: "Validation Error", 
                                content: error?.message, 
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            console.log(error?.message);
                            modalData.value.processing_tx = false;
                        })
                }
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
                // CLOSE DIALOG
                isModalOpen.value = false;
            }
        }

        // PRICES LOGIC
        const fetchCoinGeckoData = async () => {
            loadingAssetDollarValues.value = true;
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd');
                const data = await response.json();

                bitcoinPrice.value = data.bitcoin.usd;
                ethereumPrice.value = data.ethereum.usd;
                usdcPrice.value = data['usd-coin'].usd;
                usdtPrice.value = data.tether.usd;

                /* console.log('Bitcoin Price:', bitcoinPrice);
                console.log('Ethereum Price:', ethereumPrice);
                console.log('USDC Price:', usdcPrice);
                console.log('USDT Price:', usdtPrice); */

                loadingAssetDollarValues.value = false;
            } catch (error) {
                console.log(error);
            }
        };

        const fetchDiaData = async () => {
            loadingAssetDollarValues.value = true;
            try {
                const responseEth = await fetch('https://api.diadata.org/v1/quotation/ETH');
                const responseBtc = await fetch('https://api.diadata.org/v1/quotation/BTC');
                const responseUSDC = await fetch('https://api.diadata.org/v1/quotation/USDC');
                const responseUSDT = await fetch('https://api.diadata.org/v1/quotation/USDT');
                
                Promise.all([responseEth, responseBtc, responseUSDC, responseUSDT])
                .then(async (responses) => {
                    const dataEth = await responses[0].json();
                    const dataBtc = await responses[1].json();
                    const dataUSDC = await responses[2].json();
                    const dataUSDT = await responses[3].json();

                    ethereumPrice.value = dataEth.Price;
                    bitcoinPrice.value = dataBtc.Price;
                    usdcPrice.value = dataUSDC.Price;
                    usdtPrice.value = dataUSDT.Price;

                    /* console.log('Bitcoin Price:', bitcoinPrice.value);
                    console.log('Ethereum Price:', ethereumPrice.value);
                    console.log('USDC Price:', usdcPrice.value);
                    console.log('USDT Price:', usdtPrice.value); */

                    loadingAssetDollarValues.value = false;
                })
            } catch (error) {
                console.log(error);
            }
        };

        const fetchLuminPriceData = async () => {
            const assets = await luminInstance?.backend.assets();
            console.log(assets);

            for (let libAsset of assets) {
                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                    /* console.log(price); */

                    /* ethereumPrice.value = dataEth.Price;
                    bitcoinPrice.value = dataBtc.Price;
                    usdcPrice.value = dataUSDC.Price;
                    usdtPrice.value = dataUSDT.Price; */

                    if (libAsset.symbol == 'ETH') {
                        ethereumPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'BTC') {
                        bitcoinPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'USDC') {
                        usdcPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'USDT') {
                        usdtPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'MOCK') {
                        mockPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'SMOCK') {
                        smockPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }

                    /* console.log('Bitcoin Price:', bitcoinPrice.value);
                    console.log('Ethereum Price:', ethereumPrice.value);
                    console.log('USDC Price:', usdcPrice.value);
                    console.log('USDT Price:', usdtPrice.value);
                    console.log('MOCK Price:', mockPrice.value); */
                });
            }
        };

        const formatPrice = (asset, value, decimals = 0) => {
            if (asset == 'ETH') {
                let val = (value * ethereumPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                return val
            }
            if (asset == 'BTC') {
                let val = (value * bitcoinPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDC') {
                let val = (value * usdcPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDT') {
                let val = (value * usdtPrice.value).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'MOCK') {
                let val = (value * mockPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'SMOCK') {
                let val = (value * smockPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == '') {
                let val = value.toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
        };

        const cumulativeInterestPayment = () => {
            if (modalData.value.loan_vanilla_data) {
                const interestAmount = parseInt(modalData.value.loan_vanilla_data.principalAmountNative * (modalData.value.loan_vanilla_data.interestPromille / 1000));
                let interestAmountToPay = 0;
                let cumulativeDollarValueInterestAssets = 0;

                // INTEREST AMOUNT TO PLAY SHOULD BE IN NATIVE ASSET
                // FIRST DISCOVER DOLLAR AMOUNTS OF modalData.value.vanilla_config_data.acceptedAssets THAT ARE USED FOR INTEREST PAYMENT
                // AND THEN RECALCULATE THAT DOLLAR AMOUNT TO NATIVE ASSET
                for (let asset of modalData.value?.vanilla_config_data.acceptedAssets) {
                    if (asset.useInterest == true) {
                        if (asset.ticker == 'USDC') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'USDT') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'BTC') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'ETH') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'MOCK') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'SMOCK') {
                            cumulativeDollarValueInterestAssets += parseInt(asset.amount_to_use) * asset.price;
                        }
                    }
                }
                // RECALCULATION INTO NATIVE ASSET AMOUNT
                /* console.log("Cumulative Dollar Value Interest Assets:", cumulativeDollarValueInterestAssets); */
                interestAmountToPay = cumulativeDollarValueInterestAssets / modalData.value?.vanilla_config_data.principalAsset.price;

                /* console.log("Interest Amount:", interestAmount);
                console.log("Amount To Use:", interestAmountToPay);

                console.log(typeof formatPrice(modalData.value.vanilla_config_data.principalAsset.ticker, interestAmountToPay));
                console.log(parseInt(formatPrice(modalData.value.vanilla_config_data.principalAsset.ticker, interestAmountToPay).substring(2)));
                console.log(formatPrice(modalData.value.vanilla_config_data.principalAsset.ticker, interestAmountToPay)); */
                return formatPrice(modalData.value?.vanilla_config_data.principalAsset.ticker, interestAmountToPay);
            }
        };

        const changePriceSource = (source) => {
            if (source == 'dia') fetchDiaData();
            if (source == 'coingecko') fetchCoinGeckoData();
        };

        // ANIMATION LOGIC
        const animateProgress = () => {
            setTimeout(() => {
                const bar = document.getElementById('loanBar');
                const perc = parseInt(modalData.value.progress, 10);

                let p = 0;
                const animationId = setInterval(() => {
                    p++;
                    if (p > perc) {
                        clearInterval(animationId);
                    }
                    /* bar.style.transform = `rotate(${45 + p * 1.8}deg)`; */
                }, 10);

                // Principal Bar Animation
                const barPrincipal = document.getElementById('loanBarPrincipal');
                const percPrincipal = parseInt(15, 10);

                let pPrincipal = 0;
                const animationIdPrincipal = setInterval(() => {
                    pPrincipal++;
                    if (pPrincipal > percPrincipal) {
                        clearInterval(animationIdPrincipal);
                    }
                    /* barPrincipal.style.transform = `rotate(${45 + pPrincipal * 1.8}deg)`; */
                }, 10);
            }, 500);
        };

        // ROUNDING DOWN TO xxx DECIMAL
        const roundDown = (num, precision) => {
            precision = Math.pow(10, precision)
            return Math.floor(num * precision) / precision
        };

        onMounted(() => {
            if (isDesktop.value) {
                // console.log("Desktop preview");
                // your code
            }

            if (isMobileView.value) {
                isMobileView.value = true;
            }

            console.log(isMobileView)
            console.log(isDesktop)

            startLumin();
            setTimeout(() => {
                checkWalletConnection();
                fetchLuminPriceData();
            }, 2000);
        });
    
        watch(
            [isModalOpen, loanConfiguration, modalData], 
            ([newIsModalOpen, newMinPrincipalAmount, newModalDataChange], 
            [oldIsModalOpen, prevMinPrincipalAmount, prevModalDataChange]) => {
                // Reacting to minPrincipalAmount changes
                /* console.log("minPrincipalAmount changes");
                console.log("prevMinPrincipalAmount:", prevMinPrincipalAmount);
                console.log("newMinPrincipalAmount:", newMinPrincipalAmount); */

                minimum_loan_indicator.value = ((loanConfiguration.value.minPrincipalAmount / modalData.value?.deposit) * 100).toFixed(2);
                maximum_loan_indicator.value = ((loanConfiguration.value.maxPrincipalAmount / modalData.value?.deposit) * 100).toFixed(2);
                allocated_deposit_indicator.value = ((loanConfiguration.value.maxTotalPendingPrincipalAmount / modalData.value?.deposit) * 100).toFixed(2);
                
                if (loanConfiguration.value.minPrincipalAmount > 0 && loanConfiguration.value.minPrincipalAmount <= loanConfiguration.value.maxPrincipalAmount) {
                    if (loanConfiguration.value.maxPrincipalAmount <= modalData.value.deposit) {
                        loanConfigurationValidation.value.principal = true;
                    } else {
                        loanConfigurationValidation.value.principal = false;
                    }
                } else {
                    loanConfigurationValidation.value.principal = false;
                }
                if (loanConfiguration.value.maxTotalPendingPrincipalAmount >= loanConfiguration.value.maxPrincipalAmount) {
                    loanConfigurationValidation.value.principal = true;
                } else {
                    loanConfigurationValidation.value.principal = false;
                }
                
                // Reacting to Collateral changes
                let cumulativeCollateralAmount = 0;
                let principalAmountUsd = 0;
                if (modalData.value?.vanilla_config_data && modalData.value?.vanilla_config_data.acceptedAssets.length > 0) {
                    for (let collAmount of modalData?.value.vanilla_config_data.acceptedAssets) {
                        /* console.log(collAmount.ticker + " Collateral Amount Change:", collAmount.amount_to_use); */
                        // CHECK ASSET VALUES AND ADD THEM TO CUMULATIVE COLLATERAL AMOUNT
                        if (collAmount.ticker == 'USDC') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * usdcPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * usdcPrice.value;
                        }
                        if (collAmount.ticker == 'USDT') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * usdtPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * usdtPrice.value;
                        }
                        if (collAmount.ticker == 'BTC') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * bitcoinPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * bitcoinPrice.value;
                        }
                        if (collAmount.ticker == 'ETH') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * ethereumPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * ethereumPrice.value;
                        }
                        if (collAmount.ticker == 'MOCK') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * mockPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * mockPrice.value;
                        }
                        if (collAmount.ticker == 'SMOCK') {
                            cumulativeCollateralAmount += parseFloat(collAmount.amount_to_use) * smockPrice.value;
                            principalAmountUsd = parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative) * smockPrice.value;
                        }
                    }
                }
                /* console.log("Cumulative Collateral Amount:", cumulativeCollateralAmount); */
                // CALCULATE HEALTH FACTOR WITH BORROWING AMOUNT AND CUMULATIVE COLLATERAL AMOUNT AND MAKE IT IN PERCENTAGES
                healthFactorCalc.value = (cumulativeCollateralAmount / principalAmountUsd) * 100;
                healthFactorIndicator.value = ((cumulativeCollateralAmount / principalAmountUsd) * 100);
                if (modalData.value) modalData.value.health = healthFactorCalc.value.toFixed(2);
                /* console.log("Borrowing Paylod Object: ", loanAcceptObject.value); */

                // CALCULATION OF PERCENTAGE OF PRINCIPAL PAYMENT AND USE THAT PERCENTAGE TO MOVE INDICATOR
                // IF AMOUNT TO USE IS MORE THAN 100% THAN SET IT TO 100%
                if (modalData.value?.loan_vanilla_data) {
                    if (modalData.value.vanilla_config_data.principalAsset.amount_to_use == '') modalData.value.vanilla_config_data.principalAsset.amount_to_use = 0;

                    const principalAmount = parseFloat(modalData.value.loan_vanilla_data.principalAmountNative);
                    const principalAmountToPay = parseFloat(modalData.value.vanilla_config_data.principalAsset.amount_to_use) + (parseFloat(modalData.value.loan_vanilla_data.principalAmountNative) - parseFloat(modalData.value.loan_vanilla_data.pendingPrincipalAmountNative));
                    principalPaymentIndicator.value = (principalAmountToPay / principalAmount) * 100;

                    if (principalPaymentIndicator.value > 100) {
                        principalPaymentIndicator.value = 100;
                        if (modalData.value.vanilla_config_data.principalAsset.amount_avaliable < modalData.value.loan_vanilla_data.pendingPrincipalAmountNative)
                            modalData.value.vanilla_config_data.principalAsset.amount_to_use = modalData.value.vanilla_config_data.principalAsset.amount_avaliable;
                        else 
                            modalData.value.vanilla_config_data.principalAsset.amount_to_use = modalData.value.vanilla_config_data.pendingPrincipalAmountNative;
                    }

                    // PERCENTAGE OF PRINCIPAL PAYMENT CANNOT BE BIGGER THAN PERCENTAGE OF ALL INTEREST PAYMENTS
                    if (principalPaymentIndicator.value > interestPaymentIndicator.value) {
                        principalPaymentIndicator.value = interestPaymentIndicator.value;
                        console.log(roundDown((modalData.value.vanilla_config_data.pendingPrincipalAmountNative * (principalPaymentIndicator.value / 100)), 3))
                        modalData.value.vanilla_config_data.principalAsset.amount_to_use = modalData.value.vanilla_config_data.pendingPrincipalAmountNative * (principalPaymentIndicator.value / 100);
                    }
                    
                    /* if (principalPaymentIndicator.value <= interestPaymentIndicator.value) {
                        principalPaymentIndicator.value = 100;
                        if (modalData.value.vanilla_config_data.principalAsset.amount_avaliable < modalData.value.loan_vanilla_data.pendingPrincipalAmountNative)
                            modalData.value.vanilla_config_data.principalAsset.amount_to_use = modalData.value.vanilla_config_data.principalAsset.amount_avaliable;
                        else 
                            modalData.value.vanilla_config_data.principalAsset.amount_to_use = modalData.value.vanilla_config_data.pendingPrincipalAmountNative;
                    } */

                    /* console.log("Principal Amount:", principalAmount);
                    console.log("Amount To Use:", principalAmountToPay);
                    console.log("Principal Payoff Percentage:", parseFloat(principalPaymentIndicator.value).toFixed(2)); */
                }

                // CALCULATE INTEREST PERCENTAGE AND USE THAT PERCENTAGE TO MOVE INDICATOR
                // IF AMOUNT TO USE IS MORE THAN 100% THAN SET IT TO 100%
                if (modalData.value?.loan_vanilla_data) {
                    const interestAmount = parseFloat(modalData.value.loan_vanilla_data.principalAmountNative * (modalData.value.loan_vanilla_data.interestPromille / 1000));
                    const interestAmountLeftToPayFixed = parseFloat(modalData.value.loan_vanilla_data.pendingInterestAmountNative);
                    const interestAmountLeftToPay = parseFloat(modalData.value.loan_vanilla_data.pendingInterestAmountNative);
                    
                    let interestAmountAlreadyPaid = 0;
                    let cumulativeDollarValueInterestAssets = 0;
                    let interestAmountToPay = 0;

                    // LOGIC TO CHECK ALREADY PAID INTEREST IN modalData.value.interestPayments LIST AND ADD TO interestAmountAlreadyPaid
                    // AND PARSE FROM ETHERUM TO NATIVE ASSET
                    for (let intPaid of modalData.value.interestPayments) {
                        interestAmountAlreadyPaid += parseFloat(formatUnits(intPaid.amountPaid, modalData.value.vanilla_config_data.principalAsset.decimals));
                    }

                    // INTEREST AMOUNT TO PAY SHOULD BE IN NATIVE ASSET
                    // FIRST DISCOVER DOLLAR AMOUNTS OF modalData.value.vanilla_config_data.acceptedAssets THAT ARE USED FOR INTEREST PAYMENT
                    // AND THEN RECALCULATE THAT DOLLAR AMOUNT TO NATIVE ASSET
                    for (let asset of modalData.value.vanilla_config_data.acceptedAssets) {
                        if (asset.useInterest == true) {
                            if (asset.amount_to_use == '') asset.amount_to_use = 0;
                            if (asset.ticker == 'USDC') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                            if (asset.ticker == 'USDT') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                            if (asset.ticker == 'BTC') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                            if (asset.ticker == 'ETH') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                            if (asset.ticker == 'MOCK') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                            if (asset.ticker == 'SMOCK') {
                                cumulativeDollarValueInterestAssets += parseFloat(asset.amount_to_use) * asset.price;
                            }
                        }
                    }
                    // RECALCULATION INTO NATIVE ASSET AMOUNT
                    interestAmountAlreadyPaid += cumulativeDollarValueInterestAssets / modalData.value.vanilla_config_data.principalAsset.price;
                    interestAmountToPay = cumulativeDollarValueInterestAssets / modalData.value.vanilla_config_data.principalAsset.price;
                    interestPaymentIndicator.value = (interestAmountAlreadyPaid / interestAmount) * 100;

                    if ((interestAmountLeftToPay - interestAmountToPay) <= 0) {
                        interestPaymentIndicator.value = 100;
                        // RECALCULATE interestAmount BACK TO DOLLARS AND THAN TO PRINCIPAL ASSET AMOUNT
                        let dollarValuePrincipalAssetAmount = interestAmountLeftToPayFixed * modalData.value.vanilla_config_data.principalAsset.price;

                        let amountToUseInterestAssetNative = dollarValuePrincipalAssetAmount / modalData.value.vanilla_config_data.acceptedAssets[0].price;
                        modalData.value.vanilla_config_data.acceptedAssets[0].amount_to_use = amountToUseInterestAssetNative;
                    }
                }

                // Reacting to isModalOpen changes
                /* console.log("newIsModalOpen:", newIsModalOpen);
                console.log("oldIsModalOpen:", oldIsModalOpen); */

                if (!newIsModalOpen) {
                    console.log("Modal is closed");
                    enabledOracleFeeds.value = [];
                    collateralInterestAssets.value = [];
                    loanConfiguration.value.minPrincipalAmount = null;
                    loanConfiguration.value.maxPrincipalAmount = null;
                    loanConfiguration.value.maxTotalPendingPrincipalAmount = null;
                    loanConfiguration.value.interestPercentage = null;
                    loanConfiguration.value.terms = null;
                    loanConfiguration.value.termPaymentType = null;
                    currentStep.value = 1;
                    modalData.value = null;
                }
            },
            { deep: true }
        );
  
        return {
            luminInstance,
            chainAssets,
            assetManager,
            ethers,
            ERC20,
            formatUnits,
            createConfig,
            waitForTransaction,
            // data and methods
            screenWidth,
            isMobileView,
            isDesktop,
            unsupportedNetwork,
            ethereum,
            libIsInstantiated,
            walletConnected,
            walletStringShort,
            UIPreview,
            latestActionsProfile,
            mainUIheader,
            loadingUserAsset,
            loadingUserOffers,
            loadingUserLoans,
            userWalletData,
            userOffersData,
            userBorrowsData,
            tabsManager,
            switchNetwork,
            connectWallet,
            checkWalletConnection,
            startChainDetection,
            showProfile,
            disconnectAddress,
            showMarket,
            startLumin,
            manageSidebar,
            tabsManagerOpen,
            validateDepositInputs,
            validateWithdrawInputs,
            userLoansData,
            findWalletLoansData,
            refreshUserHoldings,
            fetchUserActions,
            fetchUserLoans,
            navigateLoanDetailTabs,
            // images
            logoSrc,
            walletConnectionIcon,
            bscLogo,
            wBTC_32,
            wETH_32,
            USDC_32,
            tether_32,
            // Grey Asset Icons
            LUMIN_grey,
            wBTC_grey,
            wETH_grey,
            USDC_grey,
            USDT_grey,
            coins_illustration,
            twitter_white,
            telegram_white,
            medium_white,
            web_white,
            arbLogoSrc,
            // modal
            isModalOpen,
            modalTitle,
            modalContent,
            modalData,
            loanDetailDialog,
            payingInterestTerm,
            openModal,
            showInterestOptions,
            rangeSlide,
            rangeSlideInterest,
            rangeSlidePrincipal,
            rangeOfferAmounts,
            rangeSliderCollateral,
            switchLoanSizeUnit,
            loanSizeUnit,
            loanAmountsChanges,
            available_loan_asset_indicator,
            minimum_loan_indicator,
            maximum_loan_indicator,
            allocated_deposit_indicator,
            collateral_adjustment_option,
            interestAssetAmount,
            principalAssetAmount,
            interestFuturePayoffBar,
            principalFuturePayoffBar,
            setMaxAmount,
            // toast,
            isToastOpen,
            toastTitle,
            toastContent,
            toastData,
            notifications,
            toasts,
            newToastMessage,
            fifoToastList,
            // Offer creation
            currentStep,
            numSteps,
            nextStep,
            stepClasses,
            setAllowance,
            assetDeposit,
            assetWithdraw,
            changeOfferParam,
            principalAssetOracleChange,
            assetOffer,
            // paying off principal and interest
            termsTimeline,
            // animation variable
            isWalletOffersExpanded,
            isWalletBorrowsExpanded,
            animateProgress,
            formatPrice,
            cumulativeInterestPayment,
            // Unnecessary logic
            changePriceSource,
            fetchCoinGeckoData,
            loadingAssetDollarValues,
            bitcoinPrice,
            ethereumPrice,
            usdcPrice,
            usdtPrice,
            mockPrice,
            smockPrice,
            // Offer Dialog Variables
            offerObject,
            loadingOffers,
            // FILTERS
            allMarketFilters,
            showMarketFilter,
            marketFilterLoanSize,
            marketFilterCollateral,
            marketFilterTerms,
            marketFilterInterest,
            marketFilterInterestPerTerm,
            marketFilterRepaymentType,
            filterActive,
            // Dialogs Validations
            loanConfiguration,
            principalAssetFeeds,
            collateralInterestAssets,
            repaymentOptions,
            enabledOracleFeeds,
            loadingOracleFeeds,
            loanConfigurationValidation,
            validateFirstStep,
            validateSecondStep,
            validateThirdStep,
            changeOfferAvaliablity,
            deleteOffer,
            adjustCollateral,
            healthFactorCalc,
            healthFactorIndicator,
            principalPaymentIndicator,
            interestPaymentIndicator,
            payInterestPrincipal,
            roundDown,
            // APOLLO DATA
            latestActionsProfileQuery,
            userActiveLoansQuery
        };
      },
      /* components: {
          Modal
      } */
};
</script>
    
<style lang="scss">
    @import "../styles/luminApp.scss";
</style>