<template>
  <div class="main-container" style="background-color: transparent;border: none;height: unset;">
    <div class="lumin-info" style="text-align: center;">
      <h1>Lumin Finance
        <img :src="logoSrc" alt="" style="height: 50px;">
      </h1>
      <p style="margin-left: 35px;opacity: 1;"> Lumin Finance faucet created to mint funds needed for protocol testing </p>
      <div class="socials" style="opacity: 1;">
        <a href="https://twitter.com/luminprotocol" target="_blank">
          <img :src="twitterWhite" alt="">
        </a>
        <a href="https://t.me/LuminFinance" target="_blank">
          <img :src="telegramWhite" alt="">
        </a>
        <a href="https://lumin.finance/" target="_blank">
          <img :src="webWhite" alt="">
        </a>
        <a href="https://docs.lumin.finance/start/" target="_blank" style="width: 110px;">
          <img :src="gitbookLogo" alt="" style="width: 35px; vertical-align: sub; filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(299deg) brightness(102%) contrast(122%);;">
        </a>
      </div>
    </div>
    <div class="faucet-header">
      <div class="header-wrapper">

        <h1 style="font-size: 46px;margin: 30px 30px 0;"> Lumin Faucet </h1>
        
        <div class="unsupported-network tooltip-container" v-if="unsupportedNetwork"
          style="cursor: pointer; position: relative;"
          @click="switchNetwork()">
              <span> Unsupported Network </span>
              <span class="fa fa-exclamation-triangle"></span>
              <span class="tooltip" style="right: 15px;bottom: -35px;font-size: 12px;padding: 5px 15px;background: #ff0000;color: #fff;">
                  Click to change Network
              </span>
        </div>

        <!-- <button @click="addNetwork()" style="cursor: pointer;">
          <span> Add Test Network </span>
        </button> -->

        <button v-if="!walletConnected" @click="connectWallet()" style="cursor: pointer;">
          <img :src="walletConnectionIcon" alt="">
          <span> Connect Wallet </span>
        </button>

        <button v-if="walletConnected" disabled>
          <img :src="walletConnectionIn" alt="">
          <span> {{ walletStringShort }} </span>
        </button>

      </div>

      <p style="margin: 30px 30px 10px;opacity: 1;"> Mint funds needed for protocol testing </p>
      
      <div class="sequencer-wrapper">
        <div class="asset-price">
          <p style="text-align: left; display: inline; padding-right: 20px;"> Manual Price Feed Sequencer Status: </p>
            
          <span class="label-active" v-if="manual_sequencer_status == 0"> Active </span>
          <button class="active" v-if="manual_sequencer_status == 0" @click="toggleSequencer(false)" :disabled="!walletConnected">
            Turn off
          </button>
            
          <span class="label-unavailable" v-if="manual_sequencer_status == 1"> Unavailable </span>
          <button class="unavailable" v-if="manual_sequencer_status == 1" @click="toggleSequencer(true)" :disabled="!walletConnected">
            Turn on
          </button>
        </div>
      </div>

      <div style="display: grid;grid-template-columns: repeat(2,1fr);">
        <div class="test-assets" v-for="asset in testnetAssets" :key="asset.ticker">
          <p style="margin-top: 0px;font-size: 22px;"> ERC20 {{ asset.ticker }}: </p> 

          <div style="display: flex; justify-content: space-evenly;">
            <button @click="mintERC20(asset)" :disabled="!walletConnected || !asset.avaliable || unsupportedNetwork" style="width: 40%;">Mint {{ asset.name }}</button>
            <button @click="addToMetamask(asset)" :disabled="!walletConnected || !asset.avaliable" style="width: 40%;"> Add to Metamask </button>
          </div>
          
          <!-- <div class="asset-price">
            <span style="color: #fff; font-size: 12px; margin-top: 15px; display: inline-block; width: 100%;"> Chainlink Price Feed </span>
            <input type="number" v-model.number="asset.price" 
              :min="asset.price * 0.8" 
              :max="asset.price * 1.2" 
              :step="asset.ticker === 'BTC' ? 500 : asset.ticker === 'ETH' ? 100 : 0.01" 
              @input="validatePrice(asset)" disabled />
          </div> -->

          <div class="asset-price">
            <span style="color: #fff; font-size: 18px; margin-top: 15px; display: inline-block; width: 100%;"> Manual Price Feed </span>
            <input type="number" v-model.number="asset.price"  style="font-size: 18px;"
              :min="asset.price * 0.8" 
              :max="asset.price * 1.2" 
              :step="asset.ticker === 'BTC' ? 500 : asset.ticker === 'ETH' ? 100 : 0.01" 
              @input="validatePrice(asset)" />
            <button @click="setContractPrice(asset)" :disabled="!walletConnected || !asset.avaliable" style="width: 40%; background: transparent;"> Set Price → </button>
          </div>
          
          <div class="asset-price" style="margin-top: 15px;">
            <span style="color: #fff; font-size: 12px; display: inline; width: 100%;"> Chainlink Price Feed </span>
            <span style="color: #fff; font-size: 12px; display: inline; width: 100%;"><strong> ${{ formatPrice(asset.price) }} </strong></span>
            <!-- <span style="color: #fff; font-size: 10px; display: inline-block; width: 100%;"> (for reference only) </span> -->
          </div>
          
          <div class="asset-price" style="margin-top: 15px;">
            <span style="color: #fff; font-size: 12px; display: inline; width: 100%;"> CoinGecko Price </span>
            <span style="color: #fff; font-size: 12px; display: inline; width: 100%;"><strong> ${{ formatPrice(asset.price) }} </strong></span>
            <!-- <span style="color: #fff; font-size: 10px; display: inline-block; width: 100%;"> (for reference only) </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { ethers } from 'ethers';
/* const ERC20 = require('../dashboard/modals/ERC20.json'); */

import logoSrc from '@/assets/Lumin_Logo_Keylines.png';
import twitterWhite from '@/assets/twitter-white.png';
import telegramWhite from '@/assets/telegram-white.png';
import webWhite from '@/assets/web-white.png';
import gitbookLogo from '@/assets/gitbook.svg';
import walletConnectionIcon from '@/assets/wallet-connection-icon.png';
import walletConnectionIn from '@/assets/wallet-connection-in.png';


export default {
  name: 'LuminFaucet',
  setup() {
    const mainImageAnimation = ref('in');
    const notifications = ref([]);
    const ethereum = ref(window['ethereum']);
    const walletString = ref("");
    const walletStringShort = ref("");
    const walletConnected = ref(false);
    const unsupportedNetwork = ref(false);
    let manual_sequencer_status = ref(0);

    const testnetAssets = ref([
        {
            name: " ₿ BITCOIN ",      
            ticker: "BTC",
            contract: "0x8D88e5E43b396F4bf84E1AEB80A9567dB2785Ea9",  
            shorthand_contract: "0x8D....5Ea9",
            avaliable: false,
            processing_tx: false,
            decimals: 18,
            price: 30839.92,            
            previous_price: null,
            min: null,
            max: null
        },
        {
            name: " Ξ ETHEREUM ",      
            ticker: "ETH",
            contract: "0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378",     
            shorthand_contract: "0x6E....323E",
            avaliable: true,
            processing_tx: false,
            decimals: 18,
            price: 1835.91,            
            previous_price: null,
            min: null,
            max: null
        },
        {
            name: " $ USDC ",      
            ticker: "USDC",
            contract: "0xd3177739a516cd3a5fc0c50f9bbecea3e5e5ced1",      
            shorthand_contract: "0x22....B428",
            avaliable: true,
            processing_tx: false,
            decimals: 6,
            price: 1,            
            previous_price: null,
            min: null,
            max: null
        },
        {
            name: " ₮ USDT ",      
            ticker: "USDT",
            contract: "0x221c5b1a293aac1187ed3a7d7d2d9ad7fe1f3fb0",
            shorthand_contract: "0x....USDT",
            avaliable: true,
            processing_tx: false,
            decimals: 18,
            price: 1,            
            previous_price: null,
            min: null,
            max: null
        },
        {
            name: " SMOCK ",      
            ticker: "SMOCK",
            contract: "0xbecdf1dd79b0eb54b608bbe5cf6b6d38d0f39276",
            shorthand_contract: "0x....SMOCK",
            avaliable: true,
            processing_tx: false,
            decimals: 18,
            price: 1,            
            previous_price: null,
            min: null,
            max: null
        },
        {
            name: " MOCK ",      
            ticker: "MOCK",
            contract: "0xe0e0a2a3212a4f787ed89053adc8ffba96f9b764",
            shorthand_contract: "0x....MOCK",
            avaliable: true,
            processing_tx: false,
            decimals: 18,
            price: 1,            
            previous_price: null,
            min: null,
            max: null
        }
    ]);

    const connectWallet = async () => {
      if (ethereum.value) {
        try {
          const accounts = await ethereum.value.request({ method: 'eth_requestAccounts' });
          walletString.value = accounts[0];
          walletStringShort.value = `${accounts[0].substring(0, 6)}...${accounts[0].substring(38)}`;
          walletConnected.value = true;
          startChainDetection();
        } catch (err) {
          console.error(err);
        }
      } else {
        console.log('Please install MetaMask!');
      }
    };

    const startChainDetection = async () => {
      window.ethereum
          .on("chainChanged", (chainId) => {
              // console.log("Chain Detection Trigger ---------> ", chainId)

              if (chainId != '0x61') {
                  unsupportedNetwork.value = true;
              } else {
                  unsupportedNetwork.value = false;
              }

          })
          .on("accountsChanged", () => {
              // console.log("User Wallet Changed!")
              disconnectAddress();
          });

      window.ethereum.request({ method: 'eth_chainId' })
          .then((chainId) => {
              // console.log("Chain Detection Trigger ---------> ", chainId)

              if (chainId != '0x61') {
                  unsupportedNetwork.value = true;
              } else {
                  unsupportedNetwork.value = false;
              }

          })
          .catch((err) => {
              console.error(err);
          });
    };

    const disconnectAddress = () => {
        // Remove the key 'connected_address' from local storage
        localStorage.removeItem('connected_address');
        walletConnected.value = false;
        walletString.value = "";
        walletStringShort.value = "";
    };

    const addNetwork = async () => {
      const testnetNetwork = {
        chainId: "0x61",
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: {
          name: "tBNB",
          symbol: "tBNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-testnet.publicnode.com"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
      }

      try {
        const wasAdded = await ethereum.value.request({
          method: 'wallet_addEthereumChain',
          params: [testnetNetwork],
        });

        if (wasAdded) {
          console.log('Network Added to MM!');
        } else {
          console.log('User Denied Network Addition!');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const switchNetwork = async () => {
      const provider = window['ethereum'];

      if (!provider) {
        console.log("Metamask is not installed, please install!");
      } else {
        
        const chainId = await provider.request({ method: 'eth_chainId' });
        const goerliTestChainId = '0x61'

        if (chainId === goerliTestChainId) {

          console.log("Bravo!, you are on the correct network");

        } else {
          console.log("oulalal, switch to the correct network")
          try {

            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x61'}],
            });
            /* console.log("You have succefully switched to Arbitrum Test network") */
            this.unsupportedNetwork = false;

          } catch (switchError) {

            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === goerliTestChainId) {
              console.log("This network is not available in your metamask, please add it")
            }
            console.log("Failed to switch to the network")

          }
        }
      }
    };

    const mintERC20 = async (asset) => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        let contractAddress;

        if (asset.ticker == 'BTC') contractAddress = "0x8D88e5E43b396F4bf84E1AEB80A9567dB2785Ea9";
        if (asset.ticker == 'ETH') contractAddress = "0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378";
        if (asset.ticker == 'USDC') contractAddress = "0xd3177739a516cd3a5fc0c50f9bbecea3e5e5ced1";
        if (asset.ticker == 'USDT') contractAddress = "0x221c5b1a293aac1187ed3a7d7d2d9ad7fe1f3fb0";
        if (asset.ticker == 'SMOCK') contractAddress = "0xbecdf1dd79b0eb54b608bbe5cf6b6d38d0f39276";
        if (asset.ticker == 'MOCK') contractAddress = "0xe0e0a2a3212a4f787ed89053adc8ffba96f9b764";

        const abi = [
          'function mint(uint256 amount)'
        ];

        const contract = new ethers.Contract(contractAddress, abi, signer);
        const tx = await contract['mint'](ethers.parseUnits('100000000000000', 'gwei'));

        try {
          await tx.wait()
              .then((res) => {
                  console.log(res)

                  if (res['hash']) {
                      console.log("Mint is successful");
                  } else {
                      console.log("Mint failed");
                  }
              })
              .catch((err) => {
                  console.error(err);
              });

          console.log("Minting transaction:", tx);
          console.log(asset);
        } catch (err) {
          if (err.code === 4001) {
            // User rejected transaction
            console.log('Transaction was rejected by the user');
            // Here you can dispatch a notification or an alert to inform the user
          } else {
            // Other error
            console.error(err);
          }
        }
    };

    const addToMetamask = async (asset) => {
        console.log(asset);
        // Add to Metamask logic here
        const tokenAddress = asset.contract;
        const tokenSymbol = asset.ticker;
        const tokenDecimals = asset.decimals;
        
        try {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          const wasAdded = await ethereum.value.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
              },
            },
          });
        
          if (wasAdded) {
            console.log('Token Added to MM!');
          } else {
            console.log('User Denied Token!');
          }
        } catch (error) {
          console.log(error);
        }
    };

    const validatePrice = (asset) => {
      console.log(asset.value)
      if (asset.price < asset.min || asset.price > asset.max) {
        asset.price = asset.previous_price;
        console.log('Invalid price!');
      } else {
        console.log('Valid price!');
      }
    };

    const setContractPrice = async (asset) => {
      console.log(asset.price)
      console.log(asset.ticker)

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        let contractAddress;
        let newPrice;

        if (asset.ticker == 'BTC') {
          contractAddress = "0xc929699A9c1e487D04CA5bAd3F926FA24d738764";
          newPrice = ethers.parseUnits(asset.price.toString(), 18);
        }

        if (asset.ticker == 'ETH') {
          contractAddress = "0x94250BD291315CFc8F6aFC4D46F1Bf35dA9D841d";
          newPrice = ethers.parseUnits(asset.price.toString(), 18);
        }

        if (asset.ticker == 'USDC') {
          contractAddress = "0x06cbb8c212496232b231556A4725cddEe5d728aD";
          newPrice = ethers.parseUnits(asset.price.toString(), 6);
        }


        console.log(contractAddress)
        console.log(newPrice)

        const abi = [
          'function setPrice(int256)'
        ];

        const contract = new ethers.Contract(contractAddress, abi, signer);
        const tx = await contract.setPrice(newPrice);
        console.log(tx);
    };

    const toggleSequencer = async (boolean) => {
      // SEQUENCER TOGGLE WORKS ONLY ON CHAINLINKFEED RIGHT NOW
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const abi = [
        'function sequencerUp(bool)',
        'function latestAnswer() external view returns (uint256)'
      ];


      const chainlinkOracle = new ethers.Contract("0x599d9faA527E7575c3C7837E8DEf55a55Cd0b3ff", abi, signer);

      let assetPrice = await chainlinkOracle.latestAnswer();
      console.log(typeof assetPrice);
      console.log(Number(assetPrice));

      const tx = await chainlinkOracle.sequencerUp(boolean);
      console.log(tx);
    }

    const fetchCoinGeckoData = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd');
        const data = await response.json();

        const bitcoinPrice = data.bitcoin.usd;
        const ethereumPrice = data.ethereum.usd;
        const usdcPrice = data['usd-coin'].usd;
        const usdtPrice = data.tether.usd;

        testnetAssets.value.forEach((asset) => {
          if (asset.ticker === 'BTC') {
            asset.price = bitcoinPrice;            
            asset.previous_price = bitcoinPrice;
          } else if (asset.ticker === 'ETH') {
            asset.price = ethereumPrice;            
            asset.previous_price = ethereumPrice;
          } else if (asset.ticker === 'USDC') {
            asset.price = usdcPrice;            
            asset.previous_price = usdcPrice;
          } else if (asset.ticker === 'USDT') {
            asset.price = usdtPrice;            
            asset.previous_price = usdtPrice;
          }
          asset.min = asset.price * 0.8;
          asset.max = asset.price * 1.2;
        });

        console.log('Bitcoin Price:', bitcoinPrice);
        console.log('Ethereum Price:', ethereumPrice);
        console.log('USDC Price:', usdcPrice);
        console.log('USDT Price:', usdtPrice);

        console.log(testnetAssets.value)
      } catch (error) {
        console.log(error);
      }
    };

    const formatPrice = (value) => {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    onMounted(async () => {
      // On mounted logic here
      fetchCoinGeckoData()
    });

    return {
      mainImageAnimation,
      notifications,
      ethereum,
      walletString,
      walletStringShort,
      walletConnected,
      unsupportedNetwork,
      testnetAssets,
      validatePrice,
      setContractPrice,
      toggleSequencer,
      manual_sequencer_status,
      // network logic
      ethers,
      connectWallet,
      startChainDetection,
      switchNetwork,
      addNetwork,
      mintERC20,
      addToMetamask,
      fetchCoinGeckoData,
      formatPrice,
      // images
      logoSrc,
      twitterWhite,
      telegramWhite,
      webWhite,
      gitbookLogo,
      walletConnectionIcon,
      walletConnectionIn
    };
    }
}
</script>

<style scoped lang="scss">
  .main-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-around;
      position: relative;
      padding-top: 50px;

      div {
          h1 {
              font-family: 'Baloo Thambi 2';
              font-style: normal;
              font-weight: 400;
              font-size: 86px;
              line-height: 100%;
              /* identical to box height, or 86px */

              letter-spacing: -0.05em;
              color: #FDFCFF;
              margin: 30px;

              img {
                  top: -40px;
                  position: relative;
              }
          }

          p {
              font-family: 'Baloo Thambi 2';
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 100%;
              /* identical to box height, or 28px */

              letter-spacing: -0.05em;
              color: #8F8F91;
              opacity: 0;
          }

          .socials {
              opacity: 0;
              z-index: 1;
              padding: 10px 35px;

              img {
                  color: #FDFCFF;
                  width: 30px;
                  padding: 0px 30px 0px 0px;
                  transition: all 0.5s ease-in-out;
      
                  &:hover {
                      transition: all 0.5s ease-in-out;
                      transform: translateY(-5px);
                      cursor: pointer;
                  }
              }
          }
      }
      .faucet-header {
          background: #3537391a;
          margin: 20px;
          border-radius: 20px;
          border: 2px solid #353739;
          padding: 0 40px;

          .header-wrapper {
              display: flex;
              justify-content: space-between;

              button {
                  display: inline;
                  background: #28272A;
                  border-radius: 25px;
                  color: #FFF;
                  cursor: pointer;
                  border: 2px solid #353739;
                  height: 50px;
                  width: 200px;
                  font-size: 15px;
                  border-radius: 10px;
                  margin-top: 25px;
                  transition: all 200ms;

                  &:hover {
                      // transform: translateY(-3px);
                      background: #353739;
                      cursor: pointer;
                  }

                  img {
                      vertical-align: middle;
                  }

                  span {
                      padding: 0 5px;
                  }
              }
          }

          .test-assets {
              padding: 20px 30px;
              border: 2px solid #353739;
              border-radius: 5px;
              margin: 15px 0;
              width: 85%;
              text-align: center;

              p:first-child {
                  font-size: 18px;
                  margin-bottom: 5px;

                  opacity: 1;
                  display: inline-block;
                  width: 100%;
              }

              i:hover {
                  cursor: pointer;
              }

              div {

                  p {
                      font-size: 20px;
                      text-align: center;
                      color: #FFF;
                      display: inline;
                  }

                  i {
                      padding-left: 10px;
                      color: white;
                      display: inline;
                  }
              }

              button {
                  display: inline;
                  background: #28272A;
                  border-radius: 25px;
                  color: #FFF;
                  cursor: pointer;
                  border: 2px solid #353739;
                  height: 40px;
                  width: 100%;
                  font-size: 15px;
                  border-radius: 10px;
                  margin-top: 15px;
                  transition: all 200ms;

                  &:hover {
                      // transform: translateY(-3px);
                      background: #353739;
                      cursor: pointer;
                  }
              }

              button:disabled {
                  display: inline;
                  border-radius: 25px;
                  color: #FFF;
                  cursor: not-allowed;
                  border: 2px solid #353739;
                  height: 40px;
                  width: 70%;
                  font-size: 15px;
                  border-radius: 10px;
                  opacity: 0.35;

                  &:hover {
                      transform: none;
                      cursor: not-allowed;
                  }
              }

              .asset-price input {
                  height: 30px;
                  background: transparent;
                  border: none;
                  color: #FDFCFF;
                  border-bottom: 2px solid #353739;
                  text-align: center;

                  input {
                      background: transparent;
                      border: none;
                      color: #FDFCFF;
                      width: 100px;
                      font-size: 16px;
                      border-bottom: 2px solid #353739;
                      margin-left: auto;
                  }
              }
          }

          .sequencer-wrapper {
              padding: 20px 30px;
              border: 2px solid #353739;
              border-radius: 5px;
              margin: 15px 0;
              width: 85%;
              text-align: center;
              border: none; 
              text-align: left; 
              padding: 0 30px;

              p:first-child {
                  font-size: 18px;
                  margin-bottom: 5px;

                  opacity: 1;
                  display: inline-block;
                  width: 100%;
              }

              i:hover {
                  cursor: pointer;
              }

              div {
                  p {
                      font-size: 20px;
                      text-align: center;
                      color: #FFF;
                      display: inline;
                  }
              }

              .asset-price {
                .label-active {
                  display: inline-block;
                  width: 100px;
                  background: rgba(0, 128, 0, 0.5019607843);
                  border: 1px solid #008000;
                  height: 25px;
                  font-size: 14px;
                  color: #fff;
                  vertical-align: middle;
                  border-radius: 5px;
                  text-align: center;
                  padding-top: 5px;
                }
                .active {
                  background: transparent;
                  color: #ff0000;
                  margin-left: 20px;
                  border-radius: 5px;
                  padding: 3px 15px;
                  border: 1px solid #ff0000;

                  &:hover {
                    cursor: pointer;
                  }

                  i {
                    color: #ff0000;
                  }

                  .fa-power-off::before {
                    font-family: unset;
                  }
                }
                .label-unavailable {
                  display: inline-block;
                  width: 100px;
                  background: rgba(255, 0, 0, 0.5019607843);
                  border: 1px solid #ff0000;
                  height: 25px;
                  font-size: 14px;
                  color: #fff;
                  vertical-align: middle;
                  border-radius: 5px;
                  text-align: center;
                  padding-top: 5px;
                }
                .unavailable {
                  background: transparent;
                  color: #008000;
                  margin-left: 20px;
                  border-radius: 5px;
                  padding: 3px 15px;
                  border: 1px solid #008000;

                  &:hover {
                    cursor: pointer;
                  }

                  i {
                    color: #008000;
                  }
                  .fa-power-off::before {
                    font-family: unset;
                  }
                }
              }

          }
      }
  }
  .unsupported-network {
      display: flex; 
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
      width: auto;
      height: 45px;
      font-size: 12px;
      border: 2px solid #731c1c;
      border-radius: 10px;
      margin-top: 25px;
      color: #fff;

      span {
          padding: 15px 10px;
          width: auto;
          font-size: 14px;
      }
  }

  .tooltip-container {
    cursor: pointer;
    display: inline-block;
  }

  .tooltip {
    opacity: 0;
    z-index: 99;
    color: #bbb;
    width: 140px;
    display: block;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    text-shadow: 1px 1px 2px #111;
    background: rgba(51,51,51,0.9);
    border: 1px solid rgba(34,34,34,0.9);
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  .tooltip-container:hover .tooltip,a:hover .tooltip {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  @media only screen and (max-width: 900px) {
      .main-container {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-around;
          position: relative;
          padding-top: 50px;

          div {
              h1 {
                  font-family: 'Baloo Thambi 2';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 60px;
                  line-height: 100%;
                  /* identical to box height, or 86px */
      
                  letter-spacing: -0.05em;
                  color: #FDFCFF;
                  margin: 30px;
      
                  img {
                      top: unset;
                      position: unset;
                  }
              }
      
              p {
                  font-family: 'Baloo Thambi 2';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 28px;
                  line-height: 100%;
                  /* identical to box height, or 28px */
      
                  letter-spacing: -0.05em;
                  color: #8F8F91;
                  opacity: 0;
                  margin: 30px;
              }

              .socials {
                  opacity: 0;

                  img {
                      color: #FDFCFF;
                      width: 30px;
                      padding: 30px 30px 0px 0px;
                      transition: all 0.5s ease-in-out;
          
                      &:hover {
                          transition: all 0.5s ease-in-out;
                          transform: translateY(-5px);
                          cursor: pointer;
                      }
                  }
              }
          }

      .faucet-header {
          background: #3537391a;
          margin: 20px;
          border-radius: 20px;
          border: 2px solid #353739;
          padding: 0 40px;

          .header-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              button {
                  display: inline;
                  background: #28272A;
                  border-radius: 25px;
                  color: #FFF;
                  cursor: pointer;
                  border: 2px solid #353739;
                  height: 50px;
                  width: 200px;
                  font-size: 15px;
                  border-radius: 10px;
                  margin-top: 25px;
                  transition: all 200ms;

                  &:hover {
                      // transform: translateY(-3px);
                      background: #353739;
                      cursor: pointer;
                  }

                  img {
                      vertical-align: middle;
                  }

                  span {
                      padding: 0 5px;
                  }
              }
          }

          div {
            flex-direction: column;
            .test-assets {
                padding: 20px 30px;
                border: 1px solid #353739;
                border-radius: 5px;
                margin: 15px 0;
                width: 85%;
                text-align: center;

                p:first-child {
                    font-size: 18px;
                    margin-bottom: 5px;

                    opacity: 1;
                    display: inline-block;
                    width: 80%;
                }

                i:hover {
                    cursor: pointer;
                }

                div {

                    p {
                        font-size: 20px;
                        text-align: center;
                        color: #FFF;
                        display: inline;
                    }

                    i {
                        padding-left: 10px;
                        color: white;
                        display: inline;
                    }
                }

                button {
                    display: inline;
                    background: #28272A;
                    border-radius: 25px;
                    color: #FFF;
                    cursor: pointer;
                    border: 2px solid #353739;
                    height: 40px;
                    width: 100%;
                    font-size: 15px;
                    border-radius: 10px;
                    margin-top: 15px;
                    transition: all 200ms;

                    &:hover {
                        // transform: translateY(-3px);
                        background: #353739;
                        cursor: pointer;
                    }
                }

                button:disabled {
                    display: inline;
                    border-radius: 25px;
                    color: #FFF;
                    cursor: not-allowed;
                    border: 2px solid #353739;
                    height: 40px;
                    width: 70%;
                    font-size: 15px;
                    border-radius: 10px;
                    opacity: 0.35;

                    &:hover {
                        transform: none;
                        cursor: not-allowed;
                    }
                }

                .asset-price input {
                    margin-top: 15px;
                    height: 30px;
                    background: transparent;
                    border: none;
                    color: #FDFCFF;
                    border-bottom: 2px solid #353739;
                    text-align: center;

                    input {
                        background: transparent;
                        border: none;
                        color: #FDFCFF;
                        width: 100px;
                        font-size: 16px;
                        border-bottom: 2px solid #353739;
                        margin-left: auto;
                    }
                }
            }
          }
      }
    }
  }
</style>