// router.js
import { createRouter, createWebHistory } from 'vue-router'
import LuminApp from './components/LuminApp.vue'
import LuminMarket from './components/LuminMarket.vue'
import LuminProfile from './components/LuminProfile.vue'
import LuminFaucet from './components/LuminFaucet.vue'
import LuminOnePageDeposit from './components/LuminOnePageDeposit.vue'
import LuminOnePageWithdraw from './components/LuminOnePageWithdraw.vue'
/* import {defineAsyncComponent, h} from 'vue'

function lazyLoad(component_name, params = {}) {
  return {
    render() {
      const async_component = defineAsyncComponent({
        loader: () => import(`pages/${component_name}`),
        loadingComponent: Loading,
        ...params
      })
      return h(async_component)
    }
  }
} */

const routes = [
  { path: '/', component: LuminApp },
  { path: '/market', component: LuminMarket },
  { path: '/profile', component: LuminProfile },
  { path: '/faucet', component: LuminFaucet },
  { path: '/deposit', component: LuminOnePageDeposit },
  { path: '/withdraw', component: LuminOnePageWithdraw }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router