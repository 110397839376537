<template>
    <div>
        <div class="nav-identity">
            <div class="logo-title-nav" style="font-size: 30px;" @click="UIPreview = 'markets'"> 
                <img :src="logoSrc" alt="">
                <span> Lumin Finance Liquidations </span>
            </div>
    
            <Transition name="modal">
                <div class="network-info" v-if="!unsupportedNetwork" style="width: 160px;">
                    <span> Arbitrum One </span>
                    <img v-bind:src="arbLogoSrc" alt="">
                    <!-- ▼ -->
                </div>
            </Transition>
    
            <Transition name="modal">
                <div class="unsupported-network tooltip-container" v-if="unsupportedNetwork"
                    style="cursor: pointer; position: relative;"
                    @click="switchNetwork">
                        <span> Unsupported Network </span>
                        <span class="fa fa-exclamation-triangle"></span>
                        <span class="tooltip" style="right: 15px;bottom: -35px;font-size: 12px;padding: 5px 15px;background: #ff0000;color: #fff;">
                            Click to change Network
                        </span>
                </div>
            </Transition>
    
            <!-- ALLOW CONNECTION LOGIC ONLY AFTER LIB INSTANTIATION -->
            <!-- <spinner style="padding-left: 10px;" v-if="!libIsInstantiated"></spinner> -->
    
            <div class="connect-button" v-if="!walletConnected && libIsInstantiated && !isMobileView" @click="connectWallet">
                <img v-bind:src="walletConnectionIcon">
                <span> Connect </span>
            </div>
    
            <!-- <Transition name="modal">
                <div class="profile-button" v-if="walletConnected && libIsInstantiated" @click="showProfile(walletStringShort)">
                    <span> Visit Profile </span>
                    <span class="fa fa-user"></span>
                </div>
            </Transition> -->
    
            <Transition name="modal">
                <div class="tooltip-container" v-if="walletConnected && libIsInstantiated" @click="showProfile(walletStringShort)" style="cursor: pointer;position: relative;">
                    {{ walletStringShort }}
                    <span class="pulse"> &#x2022; </span>
                    <span class="tooltip" style="right: -20px;bottom: -40px;font-size: 12px;">
                        Visit profile Dashboard
                    </span>
                </div>
            </Transition>
    
            <Transition name="modal">
                <div v-if="walletConnected && libIsInstantiated" @click="disconnectAddress()" style="display: flex; align-items: center; cursor: pointer;margin-left: 20px;margin-right: 20px;width: auto;height: 45px;font-size: 12px;border-radius: 10px;">
                    <span style="padding: 0 10px;width: 20px; font-size: 20px;text-align: center;" class="fa fa-sign-out"></span>
                </div>
            </Transition>
        </div>
    </div>

    <div class="main-container-wrapper" style="background-color: #101112;
        border-top: 2px solid #2C2E2F;
        height: calc(100vh - 95px);">

        <div class="main-container" style="display: flex;">
                
            <!-- WIDGET WITH LATEST EVENT ON PROTOCOL LEVEL -->
            <div v-if="UIPreview != 'profile'" class="deposits-tab">
                <div id="market_deposits" [ngStyle]="{'width': width, 'display': displayChart}" v-if="false"></div>
                
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <h3 style="color: #FDFCFF;"> Overview of all Active loans </h3>
                    <!-- <span style="color: #FDFCFF; font-size: 14px; padding-right: 20px;"> Next price check in {{ timeToNextPriceCheck }}s </span> -->
                </div>

                <div style="color: #fff; padding: 0px 40px 10px;">
                    Liquidations can happen on:
                    <br>
                    1. All loans <br>
                    <span style="color: #808080;"> - Collateral is too low </span> <br>
                    <span style="color: #808080;"> - Interest or principal has not been paid back by the end of the loan. End of loan = Start timestamp + (30 days * terms fully elapsed). </span>
                    <br>
                    2. Payment type is <strong>"Interest and principal"</strong> <br>
                    <span style="color: #808080;"> - Not enough principal has been paid for the amount of terms passed (only for loans that want Interest and principal each term). </span>
                    <br>
                    3. Payment type is <strong>"Interest"</strong> or <strong>"Interest and principal"</strong> <br>
                    <span style="color: #808080;"> - Not enough interest has been paid for the amount of terms passed. </span>
                </div>

                <div class="table-wrapper profile-section" style="padding-left: 30px; overflow-y: scroll;" v-if="!loadingAndCalculatingAllLoans">
                    <table class="market-table" cellspacing="0">
                        <thead>
                            <tr style="cursor: auto;">
                                <th style="background: #1c1c1d;"> Loan ID </th>
                                <th style="background: #1c1c1d;"> Status </th>
                                <th style="background: #1c1c1d;"> Loan Amount </th>
                                <th style="background: #1c1c1d;"> Collateral Value </th>
                                <th style="background: #1c1c1d;"> Collateral Coverage </th>
                                <th style="background: #1c1c1d;"> Borrower </th>
                                <th style="background: #1c1c1d;"> Payments </th>
                                <th style="text-align: center;background: #1c1c1d;"> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="loan of allProtocolLoans" :key="loan.id" style="height: 50px;cursor: auto;">
                                <td style="text-align: right; padding-left: 5px;border-bottom: 1px solid #353739;">
                                    {{ loan.loanId }}
                                </td>
                                <td style="text-align: right; padding-left: 5px;border-bottom: 1px solid #353739;">
                                    {{ loan.status }} <span class="fa fa-done" style=";margin-right: 5px;border: 2px solid;border-radius: 20px;padding: 3px;" :style="{ color: loan.status == 'Open' ? '#008000' : '#808080' }"></span>
                                </td>
                                <td style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739; line-height: 15px;"> 
                                    {{ formatPrice("", loan?.pendingPrincipalAmount, loan?.principalAssetPrice) }} <br>
                                    <span style="font-size: 12px; color: #808080;"> {{ loan.principalAsset.symbol }} </span>
                                </td>
                                <td style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739; line-height: 15px;"> 
                                    {{ formatPrice("", loan?.collateralAssetAmountsNew, loan?.collateralAssetPrice) }} <br>
                                    <span style="font-size: 12px; color: #808080;"> {{ loan.collateralAssetSymbol }} </span>
                                 </td>
                                <td style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739;"> 
                                    <span class="tooltip-container" style="position: relative;padding: 2px;border-radius: 5px;" :style="{
                                        background: loan.health == 0 ? 'none' : (loan.health < 130 ? '#ff0000' : (loan.health < 150 ? '#ffa500' : 'none')),
                                        border: loan.health == 0 ? 'none' : (loan.health < 130 ? '1px solid #ff0000' : (loan.health < 150 ? '1px solid #ffa500' : 'none'))
                                    }"> {{ loan.health }}% 
                                        <span class="tooltip" style="position: absolute; right: 70px; font-size: 11px; width: 100px; top: -7px; bottom: -25px;background: rgba(51, 51, 51, 0.9);border: 1px solid rgba(34, 34, 34, 0.9);box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);color: #bbb;border-radius: 3px;padding-top: 2px;">
                                            Health calculation: Principal To Pay / Collateral Value
                                        </span>
                                    </span>
                                </td>
                                <td style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739;"> {{ loan.borrower_short }} </td>
                                <td v-if="validateTermPaymentsOnly(loan)" style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739;"> 
                                    On Time <span class="fa fa-check" style="margin-right: 5px;border-radius: 20px;padding: 3px;color: #008000;font-size: 12px;"></span>
                                </td>
                                <td v-if="!validateTermPaymentsOnly(loan)" style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739;"> 
                                    Missed <span class="fa fa-remove" style="margin-right: 5px;border-radius: 20px;padding: 3px;color: #ff0000;font-size: 12px;"></span>
                                </td>
                                <td v-if="walletConnected" style="text-align: right; padding-left: 10px;border-bottom: 1px solid #353739;"> 
                                    <button class="liquidate-loan" 
                                        :disabled="validateTermPayments(loan)" 
                                        @click="liquidateLoan(loan.loanId)" 
                                        style="background: transparent;color: #fff;border: 1px solid grey;border-radius: 5px;padding: 3px 10px;">
                                        <!-- :style="{ 
                                            opacity: loan.health > 130 ? 0.35 : 1,
                                            cursor: loan.health > 130 ? 'not-allowed' : 'pointer'
                                        }" -->
                                        Liquidate
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="allProtocolLoans?.length == 0 || loadingAndCalculatingAllLoans" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 70px;
                    align-items: center;
                    justify-content: center;">
                        <span> Watching for new loans </span>
                </div>

                <div v-if="allProtocolLoans?.length == 0 || loadingAndCalculatingAllLoans" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 0px;
                    align-items: center;
                    justify-content: center;">
                        <p :class="{'dots-loading': allProtocolLoans.length === 0 || loadingAndCalculatingAllLoans}"></p>
                </div>
            </div>
        </div>
    </div>
  
    <!-- MODAL TEMPLATES -->
    <div>
        <Teleport to="#modal">
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'DEPOSIT'">
                    <div class="modal">
                        <h1 class="title" style="margin: 20px 0 0;"> {{ modalTitle }} </h1>
                        <p class="body" style="font-size: 16px; margin-bottom: 5px;"> {{ modalContent }} </p>

                        <div class="deposit-view" style="width: 70%; padding-bottom: 30px;">
                            <div class="asset-amount" style="padding-top: 20px;width: 400px;">
                                <div class="asset-amounts">
                                    <input type="number" step="0.1" min="0" v-model="modalData.deposit_amount" style="font-size: 24px;">
                                    <img :src="modalData.image" alt="" style="position: relative; top: 5px; height: 32px;">
                                </div>

                                <div style="padding-bottom: 15px;">
                                    <input id="rangeSlider" class="range" type="range" name="" min="0" :max="modalData.available" step="0.001"
                                        v-model="modalData.deposit_amount"
                                        @change="rangeSlide(modalData.deposit_amount, modalData.available)" 
                                        @mousemove="rangeSlide(modalData.deposit_amount, modalData.available)"
                                        style="width: 100%;">
                                </div>

                                <div class="details">
                                    <span style="font-size: 14px;"> 
                                        Available: {{ modalData.available }} {{ modalData.ticker }}
                                        <span @click="setMaxAmount()" style="
                                            font-size: 10px;
                                            border: 1px solid grey;
                                            border-radius: 5px;
                                            cursor: pointer;
                                            padding: 0 5px;"> MAX </span>
                                    </span>
                                    <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.deposit_amount) }} </span>
                                </div>

                                <div class="details">
                                    <span style="font-size: 14px;"> 
                                        Current Deposit: {{ modalData.deposit }} {{ modalData.ticker }}
                                    </span>
                                    <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.deposit) }} </span>
                                </div>
                            </div>
                        </div>
  
                        <div class="btn-container" style="padding: 0px 0px 20px;">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>
                            <button @click="isModalOpen = false" class="btn-close"> Close </button>

                            <button v-if="modalData.deposit_amount > modalData.allowed"
                                :disabled="(modalData.deposit_amount <= modalData.allowed) || modalData.processing_tx"
                                @click="setAllowance()"
                                class="btn-submit">
                                    <span v-if="!modalData.processing_tx"> Approve </span> 
                                    <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>

                            <button v-if="modalData.deposit_amount <= modalData.allowed"
                                :disabled="validateDepositInputs() || modalData.processing_tx"
                                :style="{'cursor': validateDepositInputs() ? 'not-allowed' : 'pointer'}"
                                class="btn-submit"
                                @click="assetDeposit()"> 
                                    <span v-if="!modalData.processing_tx"> Deposit </span> 
                                    <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>

                            <!-- <button disabled class="btn-submit"> Allow </button> -->
                            <!-- (click)="setAllowance()" *ngIf="title == 'DEPOSIT' && (workingAsset.deposit > workingAsset.allowed)" -->
                            <!-- <button @click="isModalOpen = false" class="btn-submit"> Deposit </button> -->
                        </div>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>

    <Teleport to="#toast">
        <div v-for="(toast, index) in toasts" :key="toast.icon">
            <Transition name="toast">
                <div class="toast" v-if="toast.isToastOpen" style="display: flex;flex-direction: row;align-items: center;padding: 5px 20px;"
                    @click="toast.isToastOpen = false"
                    :style="{'top': 60 * index + 'px'}">
                    <i :style="{'color': toast.color}" :class="toast.icon" style="margin: 0 10px 0 0;padding: 5px;border-radius: 25px;width: 16px; height: 16px;"></i>
                    <div style="line-height: 15px;">
                        <p style="margin: 5px;"> {{ toast.toastTitle }} </p>
                        <span style="margin-left: 5px; font-size: 12px; color: #808080;"> {{ toast.toastContent }} </span>
                    </div>
                </div>
            </Transition>
        </div>
        <Transition name="toast">
            <div class="toast" v-if="isToastOpen" style=""
                @click="isToastOpen = false">
                <!-- :style="{'top': 60 * (index) + 'px'}"  -->
                <p> {{ toastTitle }} </p>
            </div>
        </Transition>
    </Teleport>
</template>
    
<script>
// @ts-check
// @ts-ignore
// @ts-nocheck
import { ref, onMounted, watch, computed } from 'vue';
import { ethers, Contract, formatUnits } from 'ethers';

import { LuminClient } from 'lumin-lib/client/LuminClient';

import { createConfig, configureChains, waitForTransaction } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { arbitrum, bscTestnet, arbitrumSepolia } from '@wagmi/core/chains'

import ERC20 from '@/components/ERC20.json';

import { ROARR, Roarr as log } from 'roarr';
import { createLogWriter } from '@roarr/browser-log-writer';

import logoSrc from '@/assets/lumin_icon.svg';
import luminIconPng from '@/assets/Lumin_Logo2.png';

import walletConnectionIcon from '@/assets/wallet-connection-icon.png';
import bscLogo from '@/assets/bsc-logo.svg';
import wBTC_32 from '@/assets/wBTC_32.png';
import wETH_32 from '@/assets/wETH_32.png';
import USDC_32 from '@/assets/USDC_32.png';
import tether_32 from '@/assets/tether_32.png';
import coins_illustration from '@/assets/coins.png';

import LUMIN_grey from '@/assets/LUMIN_grey.png';
import wBTC_grey from '@/assets/wBTC_grey.png';
import wETH_grey from '@/assets/wETH_grey.png';
import USDC_grey from '@/assets/USDC_grey.png';
import USDT_grey from '@/assets/USDT_grey.png';

import twitter_white from '@/assets/twitter-white.png';
import telegram_white from '@/assets/telegram-white.png';
import medium_white from '@/assets/medium-white.png';
import web_white from '@/assets/web-white.png';
import arbLogoSrc from '@/assets/arbitrum-logo.svg';

import gql from "graphql-tag";
import { useQuery, useApolloClient } from "@vue/apollo-composable";

import PriceFeedContractABI from './AssetManager.json';

const QUERY_PROTOCOL_LOANS = gql`
  query Loans {
    loans {
        id
        principalAmount
        pendingPrincipalAmount
        interestAmount
        pendingInterestAmount
        loanAssets {
            id
            asset {
                symbol
                isCollateral
                decimals
            }
            priceFeedProxy {
                id
                description
            }
            collateralAmount
        }
        status
        borrower
        created {
            id
            blockTimestamp
        }
        events {
            transactionHash
        }
        principalPayments {
            amountPaid
            blockTimestamp
        }
        interestPayments {
            amountPaid
            blockTimestamp
        }
        config {
            id
            enabled
            termPaymentType
            interestPromille
            terms
            collateralPercentage
            safetyBufferPercentage
            principalAsset {
                id
                symbol
                decimals
            }
            acceptedAssets {
                asset {
                    id
                    symbol
                    decimals
                }
                useInterest
                useCollateral
                acceptedPriceFeeds {
                    id
                    description
                }
            }
            minPrincipalAmount
            maxPrincipalAmount
            maxTotalPendingPrincipalAmount
            totalPendingPrincipalAmount
        }
    }
}
`;

export default {
    name: 'LuminApp',
    setup() {
        // LOGGER
        ROARR.write = (message) => {
            console.log(JSON.parse(message));
        };
        log.write = createLogWriter();

        // LUMIN VARs
        let luminInstance;
        let chainAssets;
        let assetManager;
        const { resolveClient } = useApolloClient();

        // WALLET VARs
        let walletConnected = ref(false);
        let walletString = ref("0x");
        let walletStringShort = ref("");

        // WAGMI SETUP
        const { chains, publicClient, webSocketPublicClient } = configureChains(
            [arbitrum, bscTestnet, arbitrumSepolia],
            [publicProvider()],
        )
 
        const config = createConfig({
            bscTestnet,
            publicClient,
            webSocketPublicClient,
        })

        // APOLLO GRAPHQL SETUP
        // ALL MARKETS DATA ACTIONS
        let allProtocolLoans = ref([]);
        const latestActionsQuery = useQuery(QUERY_PROTOCOL_LOANS, null, { fetchPolicy: 'no-cache' });

        // Handle if result is to be fetched from server
        latestActionsQuery.onResult( data => {
            allProtocolLoans.value.length = 0;
            if (data?.data?.loans) {
                for (let loan of data?.data?.loans) {
                    let collateralAssetAmountNew;
                    let collateralPriceFeedProxyNew;
                    let principalPriceFeedProxyNew;

                    for (let coll of loan.loanAssets) {
                        if (coll.asset.isCollateral) {
                            collateralAssetAmountNew = coll.collateralAmount;
                            collateralPriceFeedProxyNew = coll.priceFeedProxy;
                        }
                        if (coll.asset.symbol == loan.config.principalAsset.symbol) {
                            principalPriceFeedProxyNew = coll.priceFeedProxy;
                        }
                    }

                    if (loan.status == "Open") {
                        const loanData = {
                            loanId: loan.id,
                            events: loan.events,
                            principalAmount: loan.principalAmount,
                            interestAmount: loan.interestAmount,
                            collateralPriceFeedProxy: null,
                            collateralAssetAmounts: [],
                            status: loan.status,
                            principalAsset: loan.config.principalAsset,
                            principalAmountNative: ethers.formatUnits(loan.principalAmount, loan.config.principalAsset.decimals),
                            interestAmountNative: ethers.formatUnits(loan.interestAmount, loan.config.principalAsset.decimals),
                            principalPayments: loan.principalPayments,
                            interestPayments: loan.interestPayments,
                            pendingInterestAmount: loan.pendingInterestAmount > 0 ? ethers.formatUnits(loan.pendingInterestAmount, loan.config.principalAsset.decimals) : loan.pendingInterestAmount,
                            pendingPrincipalAmount: loan.pendingPrincipalAmount > 0 ? ethers.formatUnits(loan.pendingPrincipalAmount, loan.config.principalAsset.decimals) : loan.pendingPrincipalAmount,
                            vanilla_config_data: loan.config,
                            created_blocktimestamp: loan.created.blockTimestamp,
                            visible: true,
                            pending_changes: false,
                            borrower: loan.borrower,
                            borrower_short: loan.borrower.substring(0,4) + '...' + loan.borrower.substr(loan.borrower.length - 4),
                            health: 0,
                            terms: loan.config.terms,
                            termPaymentType: loan.config.termPaymentType,
                            loanAssets: loan.loanAssets,
                        }

                        loanData['collateralAssetAmountsNew'] = collateralAssetAmountNew;
                        loanData['collateralPriceFeedProxyNew'] = collateralPriceFeedProxyNew;
                        loanData['principalPriceFeedProxy'] = principalPriceFeedProxyNew;

                        // CALCULATE collateralNativeValue FROM acceptedAssetsAmounts WHERE useCollateral == true
                        for (let asset of loan.loanAssets) {
                            if (asset.collateralAmount != null) {
                                loanData['collateralNativeValue'] = ethers.formatUnits(asset.collateralAmount, asset.asset.decimals);
                            }
                        }

                        for (let asset of loanData.vanilla_config_data.acceptedAssets) {
                            if (asset.useCollateral) {
                                loanData['collateralAssetSymbol'] = asset.asset.symbol;
                            }
                        }

                        allProtocolLoans.value.push(loanData);
                    }
                }
                console.log("ALL PROTOCOL LOANS --->", allProtocolLoans.value)
            }
            /* console.log(allProtocolLoans); */
        });

        const screenWidth = ref(window.screen.width);
        let isMobileView = ref(screenWidth.value < 1024);
        let isDesktop = ref(screenWidth.value >= 1024);
        let unsupportedNetwork = ref(false);
        let ethereum = ref(window['ethereum']);
        let web3Provider = ref(null);

        let loaderWorking = ref(true);
        let libIsInstantiated = ref(true);


        let loadingUserAsset = ref(false);
        let loanDetailDialog = ref("manage-interest");
        let payingInterestTerm = ref(false);
        let showInterestOptions = ref(false);
        // manage-principal
        const UIPreview = ref("markets");
        const mainUIheader = ref("");
        const all_assets_deposits_usd = ref(0);
        const all_assets_borrows_usd = ref(0);
        const all_assets_paid_fees = ref(0);
        const offersList = ref([]);
        const userWalletData = ref([]);
        const userOffersData = ref([]);
        const userBorrowsData = ref([]);
        const tabsManager = ref(null);
        const mainTab = ref(null);

        // Offer Dialog Variables
        let loadingAssetDollarValues = ref(true);
        let bitcoinPrice = ref(0);
        let ethereumPrice = ref(0);
        let usdcPrice = ref(0);
        let usdtPrice = ref(0);
        let mockPrice = ref(0);
        let smockPrice = ref(0);

        let isModalOpen = ref(false);
        let modalTitle = ref('');
        let modalContent = ref('');
        let modalData = ref(null);

        let isToastOpen = ref(false);
        let toastTitle = ref('');
        let toastContent = ref('');
        let toastData = ref(null);
        let notifications = ref([]);

        // TOAST NOTIFICATIONS REWORK
        let toasts = ref([]);

        // CONTRACT FEED PRICES
        let contractFeedPrices = ref({});
        let timeToNextPriceCheck = ref(10);
        let loadingAndCalculatingAllLoans = ref(true);

        let luminPriceFeedContractInit;
        let luminManagerAddress = '0x7f9a5443c1938fCc7b5A0FffA94385CC867A9495';

        // SUPPORTED CHAINS
        // BSC Testnet 0x61
        // Arbitrum Sepolia 0x66eee
        // Arbitrum Sepolia ID 421614;
        // const jsonRpcProvider = ref('https://sepolia-rollup.arbitrum.io/rpc');
        // Arbitrum One 0xa4b1
        // Arbitrum One ID 42161
        const dAppNetwork = ref('0xa4b1');
        const dAppNetworkChainId = 42161;
        const jsonRpcProvider = ref('https://arb1.arbitrum.io/rpc');
  
        // methods
        const startLumin = async () => {
            let provider = new ethers.JsonRpcProvider(jsonRpcProvider.value);

            // NEW LIB LOGIC
            luminInstance = new LuminClient();
            await luminInstance.init();
            luminInstance.provider = provider;

            loadingAssetDollarValues.value = true;
            const assets = await luminInstance?.backend.assets();
            console.log(assets);

            // INITI FEED CONTRACT
            luminPriceFeedContractInit = new Contract(
                luminManagerAddress,
                PriceFeedContractABI,
                provider
            );

            for (let libAsset of assets) {
                contractFeedPrices.value[libAsset.symbol] = {};
                
                const priceOfAsset = await luminPriceFeedContractInit['assetValueInUSD'](libAsset.id, 0, ethers.parseUnits("1", libAsset.decimals));

                // console.log("PRICE OF ASSET", priceOfAsset);
                // console.log("ASSET VALUE IN USD", libAsset.symbol, priceOfAsset);

                contractFeedPrices.value[libAsset.symbol][0] = parseFloat(formatUnits(priceOfAsset[1], 18));

                /* luminInstance.assetManager.getPrice(libAsset).then((price) => {
                    contractFeedPrices.value[libAsset.symbol] = {};

                    for (let feedPrice of price) {
                        const takeIndexOf = price.indexOf(feedPrice);
                        contractFeedPrices.value[libAsset.symbol][takeIndexOf] = parseFloat(formatUnits(feedPrice.value, feedPrice.decimals));
                    }

                }); */

            }

            loadingAssetDollarValues.value = false;

            checkWalletConnection();
            retreiveContractLoanPrices();

            // SORT EVERYTHING FROM LOWEST HEALTH TO HIGHEST AND FILTER THOSE LOANS WITH MORE THAN 1000% HEALTH
            allProtocolLoans.value.sort((a, b) => (parseFloat(b.loanId) > parseFloat(a.loanId)) ? 1 : -1);
            allProtocolLoans.value = allProtocolLoans.value.filter(loan => parseFloat(loan.health) < 1000);

            loaderWorking.value = false;
            loadingAndCalculatingAllLoans.value = false;

            setTimeout(() => {
                loadingAssetDollarValues.value = false;
                console.log("FEED PRICES ----------->", contractFeedPrices.value)
            }, 3000);
        };

        const switchNetwork = async () => {
            console.log(unsupportedNetwork.value)
            const provider = window['ethereum'];
            if(!provider) {
                console.log("Metamask is not installed, please install!");
            } else {
                const chainId = await provider.request({ method: 'eth_chainId' });
                const binanceTestChainId = dAppNetwork.value;
                unsupportedNetwork.value = true;

                console.log(unsupportedNetwork.value)

                if (chainId === binanceTestChainId) {

                console.log("Bravo!, you are on the correct network");

                } else {
                    console.log("oulalal, switch to the correct network")
                    try {

                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: dAppNetwork.value }],
                        });
                        console.log("You have succefully switched to Binance Test network")
                        unsupportedNetwork.value = false;

                    } catch (switchError) {

                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === binanceTestChainId) {
                        console.log("This network is not available in your metamask, please add it")
                        }
                        console.log("Failed to switch to the network")

                    }
                }
            }
        };

        const connectWallet = async () => {
            const provider = window['ethereum'];

            if (typeof ethereum.value !== 'undefined') {
                console.log('MetaMask is installed!');
            } else {
                console.log('MetaMask is not installed!');
            }
            
            if (ethereum.value) {
                web3Provider.value = ethereum.value;

                try {
                    // Request account access
                    loaderWorking.value = true;
                    ethereum.value.request({ method: 'eth_requestAccounts' })
                    .then(async (address) => {
                        console.log("Account connected: ", address[0]); // Account address that you had imported
                        console.log("Account addresses: ", address); // Account address that you had

                        walletConnected.value = true;
                        walletString.value = address[0];
                        walletStringShort.value = address[0].substring(0,4) + '...' + address[0].substr(address[0].length - 4);
                        loaderWorking.value = false;

                        // Check if the key exists in local storage
                        if (localStorage.getItem('connected_address')) {
                        // Key exists, update the value
                            localStorage.setItem('connected_address', walletString.value);
                        } else {
                        // Key doesn't exist, set the value
                            localStorage.setItem('connected_address', walletString.value);
                        }

                        // Detect Network on Connect Even
                        const chainId = await provider.request({ method: 'eth_chainId' });
                        if (chainId != dAppNetwork.value) {
                            unsupportedNetwork.value = true;
                        } else {
                            unsupportedNetwork.value = false;
                        }

                        // Fetch wallet holdings
                        refreshUserHoldings(address[0]);

                        // Watch Network Changes
                        startChainDetection();
                    })
                    .catch(() => {
                        loaderWorking.value = false;
                    })
                } catch (error) {
                    // User denied account access...
                    loaderWorking.value = false;
                }
            }
        };

        const checkWalletConnection = async () => {
            // Check if the key exists in local storage
            // Key exists, retrieve the value
            let walletValue = localStorage.getItem('connected_address');
            libIsInstantiated = true;

            // console.log(walletValue)

            // FETCH USER DATA ON PROTOCOL LEVEL
            if (walletValue) {
                // Watch Network Changes
                startChainDetection();

                walletConnected.value = true;
                walletString.value = walletValue;
                walletStringShort.value = walletValue.substring(0,4) + '...' + walletValue.substr(walletValue.length - 4);

                refreshUserHoldings(walletValue);
            }
        };

        const refreshUserHoldings = async (wallet) => {
            // FETCH USER DATA ON PROTOCOL LEVEL
            /* console.log(wallet)
            console.log(luminInstance) */
            try {
                /* console.log("USER WALLET", wallet); */
                const walletStats = await luminInstance.assetManager.balancesOf(wallet);
                const assets = await luminInstance.backend.assets();
                /* console.log(assets); */

                for (let libAsset of assets) {
                    for (let usrAsset of walletStats) {
                        if (libAsset.id == usrAsset.assetId) {

                            usrAsset['allowed'] = null;
                            usrAsset['id'] = libAsset.symbol;
                            usrAsset['ticker'] = libAsset.symbol;
                            usrAsset['decimals'] = libAsset.decimals;
                            usrAsset['contract'] = libAsset.address;
                            usrAsset['processing_tx'] = false;
                            usrAsset['available'] = parseInt(ethers.formatUnits(usrAsset.balance, libAsset.decimals));
                            usrAsset['deposit'] = parseInt(ethers.formatUnits(usrAsset.user.total, libAsset.decimals));
                            usrAsset['deposit_lock'] = parseInt(ethers.formatUnits(usrAsset.user.locked, libAsset.decimals));
                            usrAsset['lib_asset'] = libAsset;

                            if (libAsset.symbol == 'ETH') {
                                usrAsset['name'] = 'Ethereum';
                                usrAsset['asset'] = 'Ethereum';
                                usrAsset['image'] = wETH_32;
                                usrAsset['price'] = ethereumPrice.value;
                                usrAsset['available'] = usrAsset.available.toFixed(7);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);
                            }
                            if (libAsset.symbol == 'BTC') {
                                usrAsset['name'] = 'Bitcoin';
                                usrAsset['asset'] = 'Bitcoin';
                                usrAsset['image'] = wBTC_32;
                                usrAsset['price'] = bitcoinPrice.value;
                                usrAsset['available'] = usrAsset.available.toFixed(7);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);
                            }
                            if (libAsset.symbol == 'USDC') {
                                usrAsset['name'] = 'USD Coin';
                                usrAsset['asset'] = 'USD Coin';
                                usrAsset['image'] = USDC_32;
                                usrAsset['price'] = usdcPrice.value;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);
                            }
                            if (libAsset.symbol == 'USDT') {
                                usrAsset['name'] = 'Tether';
                                usrAsset['asset'] = 'Tether';
                                usrAsset['image'] = tether_32;
                                usrAsset['price'] = usdtPrice.value;
                                usrAsset['available'] = usrAsset.available.toFixed(2);
                                usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                                usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);
                            }
                        }
                    }
                }

                userWalletData.value = walletStats;
            } catch (error) {
                console.log(error);
            }
        };

        const startChainDetection = () => {
            if (window.ethereum) {
                window.ethereum
                    .on("chainChanged", (chainId) => {
                        // console.log("Chain Detection Trigger ---------> ", chainId)

                        if (chainId != dAppNetwork.value) {
                            unsupportedNetwork.value = true;
                        } else {
                            unsupportedNetwork.value = false;
                        }

                    })
                    .on("accountsChanged", () => {
                        // console.log("User Wallet Changed!")
                        disconnectAddress();
                    });

                window.ethereum?.request({ method: 'eth_chainId' })
                    .then((chainId) => {
                        // console.log("Chain Detection Trigger ---------> ", chainId)

                        if (chainId != dAppNetwork.value) {
                            unsupportedNetwork.value = true;
                        } else {
                            unsupportedNetwork.value = false;
                        }

                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        };

        const disconnectAddress = () => {
            // Remove the key 'connected_address' from local storage
            localStorage.removeItem('connected_address');
            walletConnected.value = false;
            walletString.value = "";
            walletStringShort.value = "";
            userWalletData.value = [];
            UIPreview.value = "markets";
        };

        // MODAL LOGIC
        const openModal = async (title, paragraph, data) => {
            isModalOpen.value = true;

            console.log(modalTitle.value);
            console.log(modalContent.value);
            console.log(modalData.value);
        };

        const newToastMessage = ({title: title, content: content, icon: icon, color: color}) => {
            // NEW TOAST ARRAY LOGIC
            const newToast = {
                isToastOpen: true,
                toastTitle: title,
                toastContent: content,
                icon: icon,
                color: color
                /* icon: "fa fa-check",
                color: "green" */
            };

            toasts.value.push(newToast);

            setTimeout(() => {
                fifoToastList();
            }, 5000);
        };

        const fifoToastList = () => {
            if (toasts.value.length > 0) {
                toasts.value[0].isToastOpen = false;
                setTimeout(() => {
                    toasts.value.shift();
                    if (toasts.value.length > 0) {
                        // If there are more items, set another timeout for the next removal
                        setTimeout(() => {
                            fifoToastList();
                        }, 5000);
                    }
                }, 1000);
            }
        };

        const validateTermPayments = (loan) => {
            /* console.log("LOAN TERM PAYMENTS VALIDATION LOAN ID ------>", loan);
            console.log(loan.termPaymentType); */

            // THREE termPaymentType AND WHEN LOAN CAN BE LIQUIDATED
            // None - Interest or principal has not been paid back by the end of the loan. The end of the loan is loan start timestamp + (30 days * terms fully elapsed).
            // Interest - Not enough interest has been paid for the amount of terms passed.
            // InterestAndPrincipal - Not enough principal has been paid for the amount of terms passed (only for loans that want Interest and principal each term).

            // CALCULATE HOW MANY TERMS HAVE PASSED FROM CURRENT TIMESTAMP - 30 DAYS TERM FRAME
            const currentTimestamp = Math.floor(Date.now() / 1000);
            // const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / (60 * 60 * 24 * 30));
            // 30 MINUTES TERM FRAME FOR TESTING
            // const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / 1800);
            // 1 HOUR TERM FRAME FOR TESTING
            const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds
            const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / thirtyDaysInSeconds);

            if (loan.termPaymentType == 'None') {
                // CHECK IF PRINCIPAL AND INTEREST IS FULLY PAID BACK BY THE END OF LOAN
                const termsPassedPercentage = (termsPassed / loan.terms) * 100;

                /* console.log("loan.terms ------->", loan.terms);
                console.log("termsPassed ------->", termsPassed);
                console.log("termsPassedPercentage ------->", termsPassedPercentage + "%");
                console.log("pendingInterestAmount ------->", parseFloat(loan.pendingInterestAmount));
                console.log("pendingPrincipalAmount ------->", parseFloat(loan.pendingPrincipalAmount)); */
                
                if (loan.health > 130) {
                    if (termsPassed >= loan.terms) {
                        if ((parseFloat(loan.pendingInterestAmount) == 0 && parseFloat(loan.pendingPrincipalAmount) == 0)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            }
            if (loan.termPaymentType == 'Interest') {
                // CHECK IF PART OF INTEREST IS PAID BY THE END OF EACH TERM (FOR EXAMPLE IF THERE IS 3 TERMS, AT THE END OF EVERY TERM 33% OF INTEREST SHOULD BE PAID)
                const termsPassedPercentage = (termsPassed / loan.terms) * 100;
                const interestForPay = parseFloat(loan.principalAmountNative) * (loan.vanilla_config_data.interestPromille / 1000);
                const interestAlreadyPaid = parseFloat(loan.principalAmountNative * (loan.vanilla_config_data.interestPromille / 1000)) - parseFloat(loan.pendingInterestAmount);

                // PERCETAGE OF PAID INTEREST
                const paidInterestPercentage = (interestAlreadyPaid / interestForPay) * 100;

                console.log("termsPassedPercentage ------->", termsPassedPercentage + "%");
                console.log("paidInterestPercentage ------->", paidInterestPercentage + "%");

                if (loan.health > 130) {
                    if (paidInterestPercentage >= termsPassedPercentage) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            if (loan.termPaymentType == 'InterestAndPrincipal') {
                // CHECK IF PART OF PRINCIPAL AND INTEREST IS PAID BY THE END OF EACH TERM 
                // FOR EXAMPLE IF THERE IS 3 TERMS, AT THE END OF EVERY TERM 33% OF PRINCIPAL AND INTEREST SHOULD BE PAID
                const termsPassedPercentage = (termsPassed / loan.terms) * 100;
                // FIRST CALCULATION ON INTEREST
                const interestForPay = parseFloat(loan.principalAmountNative) * (loan.vanilla_config_data.interestPromille / 1000);
                const interestAlreadyPaid = parseFloat(loan.interestAmountNative) - parseFloat(loan.pendingInterestAmount);

                /* for (let intPaid of loan.interestPayments) {
                    interestAlreadyPaid += parseFloat(formatUnits(intPaid.amountPaid, loan.principalAsset.decimals));
                } */

                // PERCENTAGE OF PAID INTEREST
                const paidInterestPercentage = (interestAlreadyPaid / interestForPay) * 100;

                // SECOND CALCULATION ON PRINCIPAL
                const principalForPay = parseFloat(loan.principalAmountNative);
                const principalAlreadyPaid = parseFloat(loan.principalAmountNative) - parseFloat(loan.pendingPrincipalAmount);

                /* for (let prPaid of loan.principalPayments) {
                    principalAlreadyPaid += parseFloat(formatUnits(prPaid.amountPaid, loan.principalAsset.decimals));
                } */

                // PERCETAGE OF PAID PRINCIPAL
                const paidPrincipalPercentage = (principalAlreadyPaid / principalForPay) * 100;

                /* console.log("termsPassedPercentage ------->", termsPassedPercentage + "%");
                console.log("paidInterestPercentage ------->", paidInterestPercentage + "%");
                console.log("paidPrincipalPercentage ------->", paidPrincipalPercentage + "%"); */

                if (loan.health > 130) {
                    if (paidInterestPercentage >= termsPassedPercentage && paidPrincipalPercentage >= termsPassedPercentage) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        };

        const validateTermPaymentsOnly = (loan) => {
            // THREE termPaymentType AND WHEN LOAN CAN BE LIQUIDATED
            // None - Interest or principal has not been paid back by the end of the loan. The end of the loan is loan start timestamp + (30 days * terms fully elapsed).
            // Interest - Not enough interest has been paid for the amount of terms passed.
            // InterestAndPrincipal - Not enough principal has been paid for the amount of terms passed (only for loans that want Interest and principal each term).

            // CALCULATE HOW MANY TERMS HAVE PASSED FROM CURRENT TIMESTAMP - 30 DAYS TERM FRAME
            const currentTimestamp = Math.floor(Date.now() / 1000);
            // const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / (60 * 60 * 24 * 30));
            // 30 MINUTES TERM FRAME FOR TESTING
            // const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / 1800);
            // 1 HOUR TERM FRAME FOR TESTING
            const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds
            const termsPassed = Math.floor((currentTimestamp - loan.created_blocktimestamp) / thirtyDaysInSeconds);

            if (loan.termPaymentType == 'None') {
                // CHECK IF PRINCIPAL AND INTEREST IS FULLY PAID BACK BY THE END OF LOAN
                if (termsPassed >= loan.terms) {
                    if (parseFloat(loan.pendingInterestAmount) == 0 && parseFloat(loan.pendingPrincipalAmount) == 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
            if (loan.termPaymentType == 'Interest') {
                // CHECK IF PART OF INTEREST IS PAID BY THE END OF EACH TERM (FOR EXAMPLE IF THERE IS 3 TERMS, AT THE END OF EVERY TERM 33% OF INTEREST SHOULD BE PAID)
                const termsPassedPercentage = (termsPassed / loan.terms) * 100;
                const interestForPay = parseFloat(loan.principalAmountNative) * (loan.vanilla_config_data.interestPromille / 1000);
                const interestAlreadyPaid = parseFloat(loan.principalAmountNative * (loan.vanilla_config_data.interestPromille / 1000)) - parseFloat(loan.pendingInterestAmount);

                // PERCETAGE OF PAID INTEREST
                const paidInterestPercentage = (interestAlreadyPaid / interestForPay) * 100;

                // console.log("termsPassedPercentage ------->", termsPassedPercentage);
                // console.log("paidInterestPercentage ------->", paidInterestPercentage);

                if (paidInterestPercentage >= termsPassedPercentage) {
                    return true;
                } else {
                    return false;
                }
            }
            if (loan.termPaymentType == 'InterestAndPrincipal') {
                // CHECK IF PART OF PRINCIPAL AND INTEREST IS PAID BY THE END OF EACH TERM 
                // FOR EXAMPLE IF THERE IS 3 TERMS, AT THE END OF EVERY TERM 33% OF PRINCIPAL AND INTEREST SHOULD BE PAID
                const termsPassedPercentage = (termsPassed / loan.terms) * 100;
                // FIRST CALCULATION ON INTEREST
                const interestForPay = parseFloat(loan.principalAmountNative) * (loan.vanilla_config_data.interestPromille / 1000);
                const interestAlreadyPaid = parseFloat(loan.interestAmountNative) - parseFloat(loan.pendingInterestAmount);

                /* for (let intPaid of loan.interestPayments) {
                    interestAlreadyPaid += parseFloat(formatUnits(intPaid.amountPaid, loan.principalAsset.decimals));
                } */

                // PERCENTAGE OF PAID INTEREST
                const paidInterestPercentage = (interestAlreadyPaid / interestForPay) * 100;

                // SECOND CALCULATION ON PRINCIPAL
                const principalForPay = parseFloat(loan.principalAmountNative);
                const principalAlreadyPaid = parseFloat(loan.principalAmountNative) - parseFloat(loan.pendingPrincipalAmount);

                /* for (let prPaid of loan.principalPayments) {
                    principalAlreadyPaid += parseFloat(formatUnits(prPaid.amountPaid, loan.principalAsset.decimals));
                } */

                // PERCETAGE OF PAID PRINCIPAL
                const paidPrincipalPercentage = (principalAlreadyPaid / principalForPay) * 100;

                /* if (loan.loanId == 2) {
                    console.log("Loan ID ------->", loan.loanId);
                    console.log("termsPassedPercentage ------->", termsPassedPercentage + "%");
                    console.log("paidInterestPercentage ------->", paidInterestPercentage + "%");
                    console.log("paidPrincipalPercentage ------->", paidPrincipalPercentage + "%");
                } */

                if (paidInterestPercentage >= termsPassedPercentage && paidPrincipalPercentage >= termsPassedPercentage) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        const formatPrice = (asset, value, priceInput = null, decimals = 2) => {
            if (asset == 'ETH') {
                let val;
                if (priceInput == null) val = (value * ethereumPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                return val
            }
            if (asset == 'BTC') {
                let val;
                if (priceInput == null) val = (value * bitcoinPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDC') {
                let val;
                if (priceInput == null) val = (value * usdcPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDT') {
                let val;
                if (priceInput == null) val = (value * usdtPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'MOCK') {
                let val;
                if (priceInput == null) val = (value * mockPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'SMOCK') {
                let val;
                if (priceInput == null) val = (value * smockPrice.value).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == '') {
                let val;
                if (priceInput == null) val = (value * 1).toFixed()
                else val = (value * priceInput).toFixed();
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                return val
            }
        };

        const retreiveContractLoanPrices = async () => {
            for (let loan of allProtocolLoans.value) {
                // CALCULATE PRICE OF PRINCIPAL ASSET AND COLLATERAL ASSET IN USD WITH PRICES FROM CONTRACT FEEDS
                // console.log("USER ACTIVE LOAN -------->", loan);
                loan['principalAssetPrice'] = contractFeedPrices.value[loan.principalAsset.symbol][0];

                for (let collAsset of loan.loanAssets) {
                    if (collAsset.collateralAmount != null) {
                        loan['collateralAssetPrice'] = contractFeedPrices.value[collAsset.asset.symbol][0];
                        loan['collateralAssetAmountsNew'] = ethers.formatUnits(collAsset.collateralAmount, collAsset.asset.decimals);
                    }
                }

                /* console.log("PRINCIPAL ASSET DOLLAR AMOUNT ----------->", parseFloat(loan.pendingPrincipalAmount) * loan.principalAssetPrice);
                console.log("COLLATERAL ASSET DOLLAR AMOUNT ----------->", loan.collateralAssetAmountsNew * loan.collateralAssetPrice); */

                loan['health'] = parseFloat(((loan.collateralAssetAmountsNew * loan.collateralAssetPrice) / (loan.pendingPrincipalAmount * loan.principalAssetPrice)) * 100).toFixed(2);
                // console.log("HEALTH ----------->", loan.health);
            }
        };

        const poolOraclePrices = async () => {
            /* let interval = setInterval(() => {
                if (timeToNextPriceCheck.value == 0) {
                    timeToNextPriceCheck.value = 10;
                    clearInterval(interval);
                }
                timeToNextPriceCheck.value--
            }, 1000); */

            setInterval(() => {
                retreiveContractLoanPrices();
            }, 10000)
        };

        // LIQUIDATION LOGIC
        const liquidateLoan = async (loan) => {
            console.log(loan);
            const configId = ethers.parseUnits((loan).toString(), 'wei');
        
            try {
                let temp_provider = new ethers.BrowserProvider(window['ethereum']);
                const signer = await temp_provider.getSigner();

                const liquidationTransaction = luminInstance.loanManagerDelegator?.liquidate(configId);
                console.log(liquidationTransaction);
                liquidationTransaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction Validated");
                        try {
                            liquidationTransaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    tx.gasPrice = gasEstimate.gasPrice;

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);

                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: dAppNetworkChainId,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Waiting for transaction to be mined", 
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                        }

                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Loan Status", 
                                                content: "Loan is liquidated successfully", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });
                                        }).catch(err => {
                                            console.log(err);
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Tracking Transaction Lost",
                                                content: "Transaction tracking is lost - please try to refresh page to see most recent data!",
                                                icon: "fa fa-close", 
                                                color: "red"
                                            });
                                        })

                                    } catch (error) {
                                        console.log(error);
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Transaction Error", 
                                            content: (error.toString().substring(0,75)) + '.....',
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                        console.log(error?.message);
                                    }
                                })
                        } catch (error) {
                            console.log(error);
                            newToastMessage({
                                isToastOpen: true,
                                title: "Transaction Error", 
                                content: (error.toString().substring(0,75)) + '.....',
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            console.log(error?.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error?.message);
                        newToastMessage({
                            isToastOpen: true,
                            title: "Validation Error", 
                            content: error?.message, 
                            icon: "fa fa-close", 
                            color: "red"
                        });
                    });
            } catch (error) {
                console.log(error);
                // OPEN TOAST WITH ERROR
                newToastMessage({
                    isToastOpen: true,
                    title: "RPC Error - Change RPC URL",
                    content: (error.toString().substring(0,75)) + '.....',
                    icon: "fa fa-close", 
                    color: "red"
                });
            }
        };

        onMounted(() => {
            if (isDesktop.value) {
                // console.log("Desktop preview");
                // your code
            }

            if (isMobileView.value) {
                isMobileView.value = true;
            }

            /* console.log(isMobileView)
            console.log(isDesktop) */

            startLumin();

            /* setTimeout(() => {
                checkWalletConnection();
            }, 2000); */

            // CALCULATE HEALTS OF ALL LOANS
            /* setTimeout(() => {
                retreiveContractLoanPrices();

                // SORT EVERYTHING FROM LOWEST HEALTH TO HIGHEST AND FILTER THOSE LOANS WITH MORE THAN 1000% HEALTH
                allProtocolLoans.value.sort((a, b) => (parseFloat(b.loanId) > parseFloat(a.loanId)) ? 1 : -1);
                allProtocolLoans.value = allProtocolLoans.value.filter(loan => parseFloat(loan.health) < 1000);

                loaderWorking.value = false;
                loadingAndCalculatingAllLoans.value = false;

                // poolOraclePrices();
            }, 5000); */
        });
    
        watch(
            [isModalOpen], 
            ([newIsModalOpen], [oldIsModalOpen]) => {
                // Reacting to isModalOpen changes
                console.log("isModalOpen:", newIsModalOpen);
                if (!newIsModalOpen) {
                    console.log("Modal is closed");
                    /* refreshUserHoldings(); */
                }
            },
            { deep: true }
        );
  
        return {
            luminInstance,
            chainAssets,
            assetManager,
            ethers,
            ERC20,
            formatUnits,
            createConfig,
            waitForTransaction,
            dAppNetwork,
            dAppNetworkChainId,
            jsonRpcProvider,
            // data and methods
            screenWidth,
            isMobileView,
            isDesktop,
            unsupportedNetwork,
            ethereum,
            libIsInstantiated,
            walletConnected,
            walletStringShort,
            UIPreview,
            allProtocolLoans,
            mainUIheader,
            all_assets_deposits_usd,
            all_assets_borrows_usd,
            all_assets_paid_fees,
            loadingUserAsset,
            offersList,
            userWalletData,
            userOffersData,
            userBorrowsData,
            tabsManager,
            switchNetwork,
            connectWallet,
            checkWalletConnection,
            startChainDetection,
            disconnectAddress,
            startLumin,
            refreshUserHoldings,
            liquidateLoan,
            validateTermPayments,
            validateTermPaymentsOnly,
            contractFeedPrices,
            retreiveContractLoanPrices,
            poolOraclePrices,
            timeToNextPriceCheck,
            loadingAndCalculatingAllLoans,
            // images
            logoSrc,
            walletConnectionIcon,
            bscLogo,
            wBTC_32,
            wETH_32,
            USDC_32,
            tether_32,
            // Grey Asset Icons
            LUMIN_grey,
            wBTC_grey,
            wETH_grey,
            USDC_grey,
            USDT_grey,
            coins_illustration,
            twitter_white,
            telegram_white,
            medium_white,
            web_white,
            arbLogoSrc,
            // modal
            isModalOpen,
            modalTitle,
            modalContent,
            modalData,
            loanDetailDialog,
            payingInterestTerm,
            openModal,
            showInterestOptions,
            // toast,
            isToastOpen,
            toastTitle,
            toastContent,
            toastData,
            newToastMessage,
            notifications,
            toasts,
            fifoToastList,
            // Offer creation
            // animation variable
            // Unnecessary logic
            formatPrice,
            loadingAssetDollarValues,
            bitcoinPrice,
            ethereumPrice,
            usdcPrice,
            usdtPrice,
            mockPrice,
            smockPrice,
            // APOLLO DATA
            latestActionsQuery,
            luminPriceFeedContractInit,
            luminManagerAddress,
            PriceFeedContractABI
        };
      },
      /* components: {
          Modal
      } */
};
</script>
    
<style lang="scss">
    @import "../styles/luminApp.scss";
</style>