<template>
    <div class="main-container-wrapper" style="background-color: #101112;
        border-top: 2px solid #2C2E2F;
        height: calc(100vh - 95px);">

        <div class="main-container" :style="{'display': isDesktop ? 'flex' : 'none'}">
            <div class="main-tab" id="mainTab" ref="mainTab">
                <!-- SINGLE MARKET PREVIEW -->
                <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom: 0px;">
                    <img v-if="mainUIheader == 'Bitcoin'" v-bind:src="wBTC_32" alt="" style="margin-left: 40px;">
                    <img v-if="mainUIheader == 'Ethereum'" v-bind:src="wETH_32" alt="" style="background: white; border-radius: 20px;margin-left: 40px;">
                    <img v-if="mainUIheader == 'USD Coin'" v-bind:src="USDC_32" alt="" style="margin-left: 40px;">
                    <img v-if="mainUIheader == 'USDT'" v-bind:src="tether_32" alt="" style="margin-left: 40px;">

                    <h3 style="margin-right: auto;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;display: inline;"> {{ mainUIheader }} Market </h3>
                    <router-link to="/" style="all: unset; margin: 0 10px;">
                        <button class="create-offer tooltip-container" style="margin: 0;">
                            ⟵ HOME
                            <span class="tooltip" style="right: -7px;bottom: -55px;font-size: 11px;">
                                Navigate back to all markets preview.
                            </span>
                        </button>
                    </router-link>
                </div>

                <div class="protocol-data" style="justify-content: space-between; margin: 0 35px;">
                    <div>
                        <p class="data-title"> Market Size <span class="fa fa-question-circle"></span> </p>
                        <p class="data-amount"> {{ marketSize }} </p>
                    </div>
                    <div>
                        <p class="data-title"> Borrowed <span class="fa fa-question-circle"></span> </p>
                        <p class="data-amount"> {{ formatPrice(marketTicker, marketPendingPrincipals) }} </p>
                    </div>
                    <div>
                        <p class="data-title"> Pending Interests <span class="fa fa-question-circle"></span> </p>
                        <p class="data-amount"> {{ formatPrice(marketTicker, marketPendingInterests) }} </p>
                    </div>
                    <div>
                        <p class="data-title"> Utilisation Rate <span class="fa fa-question-circle"></span> </p>
                        <p class="data-amount"> {{ marketUtilisationRate }}% </p>
                    </div>
                    <div v-if="false">
                        <p class="data-title"> Oracle Price <span class="fa fa-question-circle"></span> </p>
                        <p class="data-amount" v-if="mainUIheader == 'Bitcoin'"> ${{ bitcoinPrice }} </p>
                        <p class="data-amount" v-if="mainUIheader == 'Ethereum'"> ${{ ethereumPrice }} </p>
                        <p class="data-amount" v-if="mainUIheader == 'USD Coin Market'"> ${{ usdcPrice }} </p>
                    </div>
                </div>

                <h3 style="padding: 0px 40px 0px;"> Offers </h3>
                <!-- FILTERS -->
                <Transition name="modal">
                    <div v-if="UIPreview == 'market' && showMarketFilter" style="display: flex;justify-content: flex-start;align-content: space-between;flex-wrap: wrap;margin: 0px 35px 0px;">
                        <div v-if="allMarketFilters.marketFilterCollateral.selected.length > 0" style="color: #fff; border: 1px solid #353739; border-radius: 5px; padding: 5px; margin: 0 10px;">
                            <span style="padding: 0 10px;"> {{ allMarketFilters.marketFilterCollateral.selected[0] }} </span>
                            <button @click="resetMarketOffersFilterBy('marketFilterCollateral')" style="all: unset; margin: 0 10px; font-size: 12px;">
                                <i class="fa fa-close" style="font-size: 10px;padding-left: 5px;"></i>
                            </button>
                        </div>

                        
                        <div v-if="allMarketFilters.marketFilterRepaymentType.selected.length > 0" style="color: #fff; border: 1px solid #353739; border-radius: 5px; padding: 5px; margin: 0 10px;">
                            <span style="padding: 0 10px;"> Type {{ allMarketFilters.marketFilterRepaymentType.selected[0] }} </span>
                            <button @click="resetMarketOffersFilterBy('marketFilterRepaymentType')" style="all: unset; margin: 0 10px; font-size: 12px;">
                                <i class="fa fa-close" style="font-size: 10px;padding-left: 5px;"></i>
                            </button>
                        </div>
                        
                        <Transition name="modal">
                            <div v-if="marketFilterLoanSize" style="display: none;margin: 0 5px;width: 40%;">
                                <p style="color: #fff; font-size: 12px; margin: 0;"> Available: </p>
                                <div style="display: flex; border: 2px solid #353739; width: 100%; color: #FDFCFF; padding: 5px; border-radius: 10px; opacity: 1; z-index: unset;">
                                    <div class="tooltip-container" style="position: relative;height: 20px;">
                                        <span class="native-currency-switch"
                                            :style="{'color': loanSizeUnit == 'USD' ? '#fff' : '#8f8f91'}"
                                            @click="switchLoanSizeUnit()"> ⇄ </span>
                                        <span id="currency-switch" class="tooltip" style="right: -70px;bottom: 35px;font-size: 12px;padding: 5px 15px;background: #333333e6;color: #fff;">
                                            Switch slider to search by native asset and vice versa.
                                        </span>
                                    </div>

                                    <template v-if="loanSizeUnit == 'USD'">
                                        <span style="position: relative; font-size: 14px; padding-left: 5px; top: 1px;"> $ </span>
                                        <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="offerRangeMin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0px; width: 45px; -moz-appearance: textfield">

                                        <span class="multi-range" style="width: 50%;">
                                            <input type="range" min="0" max="100000" id="lowerAmount" step="100"
                                                v-model="offerRangeMin"
                                                @change="rangeOfferAmountFinal('amount')"
                                                @mousemove="rangeOfferAmounts()">

                                            <input type="range" min="0" max="100000" id="upperAmount" step="100"
                                                v-model="offerRangeMax"
                                                @change="rangeOfferAmountFinal('amount')"
                                                @mousemove="rangeOfferAmounts()">
                                        </span>

                                        <span style="position: relative; font-size: 14px; top: 1px; padding-left: 5px;"> $ </span>
                                        <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="offerRangeMax" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 5px 0 0; width: 45px; -moz-appearance: textfield">
                                    </template>

                                    <template v-if="loanSizeUnit == 'NATIVE'">
                                        <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="offerRangeNativeMin" 
                                            style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0px; width: 45px; -moz-appearance: textfield">

                                        <span class="multi-range" style="width: 50%;">
                                            <input type="range" min="0" max="100" id="lowerNativeAmount" step="0.001" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                                v-model="offerRangeNativeMin"
                                                @change="rangeOfferAmountFinal('nativeAmount')"
                                                @mousemove="rangeOfferAmounts()">

                                            <input type="range" min="0" max="100" id="upperNativeAmount" step="0.001" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                                v-model="offerRangeNativeMax"
                                                @change="rangeOfferAmountFinal('nativeAmount')"
                                                @mousemove="rangeOfferAmounts()">
                                        </span>

                                        <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="offerRangeNativeMax" 
                                            style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 5px 0 0; width: 45px; -moz-appearance: textfield">
                                    </template>
                                </div>
                            </div>
                        </Transition>

                        <Transition name="modal">
                            <div v-if="marketFilterCollateral" style="display: none;margin: 0 5px;width: 20%;">
                                <p style="color: #fff; font-size: 12px; margin: 0;"> Allowed collateral: </p>
                                <select @change="filterMarketOffers($event, 'collateral')" name="acc_collateral" id="acc_collateral" style="
                                        position: relative;
                                        padding-left: 5px;
                                        top: 1px;
                                        border: 2px solid rgb(53, 55, 57);
                                        border-radius: 10px;
                                        background: rgb(16, 17, 18);
                                        color: rgb(253, 252, 255);
                                        font-size: 14px;
                                        width: 100%;
                                        height: 33px;
                                        font-size: 14px;">
                                    <option value=""> Select... </option>
                                    <option value="ETH"> Ξ ETH</option>
                                    <option value="USDC"> $ USDC</option>
                                    <option value="BTC"> ₿ BTC</option>
                                    <option value="USDT"> ₮ USDT</option>
                                </select>
                            </div>
                        </Transition>

                        <Transition name="modal">
                            <div v-if="marketFilterTerms" style="display: none;margin: 0 5px;width: 30%;">
                                <p style="color: #fff; font-size: 12px; margin: 0;"> Terms: </p>
                                <div style="display: flex; border: 2px solid #353739; width: 100%; color: #FDFCFF; padding: 5px; border-radius: 10px; opacity: 1; z-index: unset;">
                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="termsRangeMin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0px; padding-left: 5px; width: 20px; -moz-appearance: textfield">

                                    <span class="multi-range" style="width: 65%;">
                                        <input type="range" min="1" max="10" id="lower" step="1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="termsRangeMin"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">

                                        <input type="range" min="1" max="10" id="upper" step="1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="termsRangeMax"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">
                                    </span>

                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="termsRangeMax" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 5px 0 0; width: 20px; -moz-appearance: textfield">
                                </div>
                            </div>
                        </Transition>

                        <Transition name="modal">
                            <div style="display: none;margin: 0 5px;" v-if="marketFilterInterest">
                                <p style="color: #fff; font-size: 12px; margin: 0;"> Interest: </p>
                                <div style="display: flex; border: 2px solid #353739; width: 180px; color: #FDFCFF; padding: 5px; border-radius: 10px; opacity: 1; z-index: unset;">
                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="interestRangeMin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0px; padding-left: 5px; width: 20px; -moz-appearance: textfield">
                                    <span style="position: relative; font-size: 12px; top: 1px;"> % </span>

                                    <span class="multi-range" style="width: 55%;">
                                        <input type="range" min="2" max="12" id="lower" step="1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="interestRangeMin"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">

                                        <input type="range" min="2" max="12" id="upper" step="1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="interestRangeMax"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">
                                    </span>

                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="interestRangeMax" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0; width: 20px; -moz-appearance: textfield">
                                    <span style="position: relative; font-size: 12px; top: 1px; padding-right: 5px;"> % </span>
                                </div>
                            </div>
                        </Transition>

                        <Transition name="modal">
                            <div style="display: none;margin: 0 5px;" v-if="marketFilterInterestPerTerm">
                                <p style="color: #fff; font-size: 12px; margin: 0;"> Interest per term: </p>
                                <div style="display: flex; border: 2px solid #353739; width: 180px; color: #FDFCFF; padding: 5px; border-radius: 10px; opacity: 1; z-index: unset;">
                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="interestRangeMin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0px; padding-left: 5px; width: 20px; -moz-appearance: textfield">
                                    <span style="position: relative; font-size: 12px; top: 1px;"> % </span>

                                    <span class="multi-range" style="width: 55%;">
                                        <input type="range" min="0" max="12" id="lower" step="0.1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="interestRangeMin"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">

                                        <input type="range" min="0" max="12" id="upper" step="0.1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                            v-model="interestRangeMax"
                                            @change="rangeOfferAmountFinal()"
                                            @mousemove="rangeOfferAmounts()">
                                    </span>

                                    <input @change="rangeOfferAmountFinal()" type="number" min="0" max="100000" class="range-figures" v-model="interestRangeMax" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                        style="opacity: 1; background: transparent; color: #fff; border: none; z-index: unset;padding: 0 0; width: 20px; -moz-appearance: textfield">
                                    <span style="position: relative; font-size: 12px; top: 1px; padding-right: 5px;"> % </span>
                                </div>
                            </div>
                        </Transition>

                        <div style="display: none;margin: 0 5px;">
                            <p style="color: #fff; font-size: 12px; margin: 0;"> Action: </p>
                            <div style="display: flex;">
                                <div style="border: 1px solid #e8e600;width: max-content;color: #FDFCFF;padding: 5px;border-radius: 10px;opacity: 1;z-index: unset;height: 22px;position: relative;margin-right: 5px;">
                                    <button @click="resetMarketOffersFilter()" style="all: unset; position: relative; top: -2px; margin: 0 10px; font-size: 12px;">
                                        APPLY <i class="fa fa-filter" style="font-size: 10px;padding-left: 5px;"></i>
                                    </button>
                                </div>

                                <div style="border: 1px solid #e8e600;width: max-content;color: #FDFCFF;padding: 5px;border-radius: 10px;opacity: 1;z-index: unset;height: 22px;position: relative;"
                                    v-if="filterActive">
                                    <button @click="resetMarketOffersFilter()" style="all: unset; margin: 0 10px; font-size: 12px;">
                                        RESET <i class="fa fa-close" style="font-size: 10px;padding-left: 5px;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <input type="number" name="" id="">
                        <input type="number" name="" id=""> -->
                    </div>
                </Transition>

                <!-- DATA TABLE -->
                <div class="table-wrapper" style="overflow-y: scroll; max-height: 50%; margin: 0px 35px;">
                    <Transition name="modal">
                        <table v-if="!loadingOffers" class="market-table" cellspacing="0">
                            <template v-if="marketOffers?.length > 0">
                                <thead>
                                    <tr>
                                        <th> Min. / Max. Loan Size
                                            <!-- <i @click="startMarketOffersFilter('marketFilterLoanSize')" class="fa fa-filter" :style="{ color: marketFilterLoanSize ? 'green' : '' }" style="font-size: 14px;"></i> -->

                                            <Transition name="modal">
                                                <div v-if="allMarketFilters.marketFilterLoanSize.in_use" style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-around;
                                                    flex-wrap: wrap;
                                                    position: absolute;
                                                    top: 50px;
                                                    border: 2px solid rgb(53, 55, 57);
                                                    border-radius: 10px;
                                                    background: rgb(16, 17, 18);
                                                    color: rgb(253, 252, 255);
                                                    font-size: 14px;
                                                    width: 180px;
                                                    height: 100px;
                                                    font-size: 14px;">
                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Min: <input type="number" min="100" max="100000" v-model="allMarketFilters.marketFilterLoanSize.selected_min"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterLoanSize.selected_min == allMarketFilters.marketFilterLoanSize.selected_max" @click.stop="setFilterLoanSize('min', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterLoanSize.selected_min == 100" @click.stop="setFilterLoanSize('min', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Max: <input type="number" min="100" max="100000" v-model="allMarketFilters.marketFilterLoanSize.selected_max"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterLoanSize.selected_max == 100000" @click.stop="setFilterLoanSize('max', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterLoanSize.selected_max == allMarketFilters.marketFilterLoanSize.selected_min" @click.stop="setFilterLoanSize('max', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <button @click.stop="resetMarketOffersFilterBy('marketFilterLoanSize')" style="all: unset; margin: 0 10px; font-size: 10px;">
                                                                APPLY
                                                            </button>
                                                        </div>

                                                        
                                                    <!-- <input type="number" min="100" max="100000" v-model="allMarketFilters.selected_max"
                                                        style="position: relative;"> -->
                                                </div>
                                            </Transition>
                                        </th>
                                        <th style="height: 45px; line-height: 12px;"> 
                                            <div style="display: flex; align-items: center; justify-content: flex-end;">
                                                <span> Allowed <br> Collateral </span>
                                                <!-- <span @click="startMarketOffersFilter('marketFilterCollateral')"  style="padding-left: 5px;"> 
                                                    <i class="fa fa-filter" :style="{ color: marketFilterCollateral ? 'green' : '' }" style="font-size: 14px;"></i> 
                                                </span> -->
                                            </div>

                                            <!-- <Transition name="modal"> -->
                                                <div v-if="allMarketFilters.marketFilterCollateral.in_use">
                                                    <select @click.stop @change.stop="filterMarketOffers($event, 'collateral')" name="acc_collateral" id="acc_collateral" style="
                                                            position: absolute;
                                                            padding-left: 5px;
                                                            top: 50px;
                                                            left: 0px;
                                                            border: 2px solid rgb(53, 55, 57);
                                                            border-radius: 10px;
                                                            background: rgb(16, 17, 18);
                                                            color: rgb(253, 252, 255);
                                                            font-size: 14px;
                                                            width: 120px;
                                                            height: 33px;
                                                            font-size: 14px;">
                                                        <option value=""> Select... </option>
                                                        <option value="ETH"> Ξ ETH</option>
                                                        <option value="USDC"> $ USDC</option>
                                                        <option value="BTC"> ₿ BTC</option>
                                                        <option value="USDT"> ₮ USDT</option>
                                                    </select>
                                                </div>
                                            <!-- </Transition> -->
                                        </th>
                                        <th> Terms 
                                            <!-- <i @click="startMarketOffersFilter('marketFilterTerms')" class="fa fa-filter" :style="{ color: marketFilterTerms ? 'green' : '' }" style="font-size: 14px;"></i> -->

                                            <Transition name="modal">
                                                <div v-if="allMarketFilters.marketFilterTerms.in_use" style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-around;
                                                    flex-wrap: wrap;
                                                    position: absolute;
                                                    top: 50px;
                                                    border: 2px solid rgb(53, 55, 57);
                                                    border-radius: 10px;
                                                    background: rgb(16, 17, 18);
                                                    color: rgb(253, 252, 255);
                                                    font-size: 14px;
                                                    width: 180px;
                                                    height: 100px;
                                                    font-size: 14px;">
                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Min: <input type="number" min="1" max="10" v-model="allMarketFilters.marketFilterTerms.selected_min"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterTerms.selected_min == allMarketFilters.marketFilterTerms.selected_max" @click.stop="setFilterTerms('min', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterTerms.selected_min == 1" @click.stop="setFilterTerms('min', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Max: <input type="number" min="1" max="10" v-model="allMarketFilters.marketFilterTerms.selected_max"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterTerms.selected_max == 10" @click.stop="setFilterTerms('max', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterTerms.selected_max == allMarketFilters.marketFilterTerms.selected_min" @click.stop="setFilterTerms('max', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <button @click.stop="resetMarketOffersFilterBy('marketFilterTerms')" style="all: unset; margin: 0 10px; font-size: 10px;">
                                                                APPLY
                                                            </button>
                                                        </div>

                                                        
                                                    <!-- <input type="number" min="100" max="100000" v-model="allMarketFilters.selected_max"
                                                        style="position: relative;"> -->
                                                </div>
                                            </Transition>
                                        </th>
                                        <th> Interest 
                                            <!-- <i @click="startMarketOffersFilter('marketFilterInterest')" class="fa fa-filter" :style="{ color: marketFilterInterest ? 'green' : '' }" style="font-size: 14px;"></i> -->

                                            <Transition name="modal">
                                                <div v-if="allMarketFilters.marketFilterInterest.in_use" style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-around;
                                                    flex-wrap: wrap;
                                                    position: absolute;
                                                    top: 50px;
                                                    border: 2px solid rgb(53, 55, 57);
                                                    border-radius: 10px;
                                                    background: rgb(16, 17, 18);
                                                    color: rgb(253, 252, 255);
                                                    font-size: 14px;
                                                    width: 180px;
                                                    height: 100px;
                                                    font-size: 14px;">
                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Min: <input type="number" min="2" max="12" v-model="allMarketFilters.marketFilterInterest.selected_min"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterInterest.selected_min == allMarketFilters.marketFilterInterest.selected_max" @click.stop="setFilterInterest('min', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterInterest.selected_min == 2" @click.stop="setFilterInterest('min', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Max: <input type="number" min="2" max="12" v-model="allMarketFilters.marketFilterInterest.selected_max"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterInterest.selected_max == 12" @click.stop="setFilterInterest('max', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterInterest.selected_max == allMarketFilters.marketFilterInterest.selected_min" @click.stop="setFilterInterest('max', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <button @click.stop="resetMarketOffersFilterBy('marketFilterInterest')" style="all: unset; margin: 0 10px; font-size: 10px;">
                                                                APPLY
                                                            </button>
                                                        </div>

                                                        
                                                    <!-- <input type="number" min="100" max="100000" v-model="allMarketFilters.selected_max"
                                                        style="position: relative;"> -->
                                                </div>
                                            </Transition>
                                        </th>
                                        <th style="height: 45px; line-height: 12px;"> 
                                            <div style="display: flex; align-items: center; justify-content: flex-end;">
                                                <span> Interest <br> per term </span> 
                                                <!-- <span @click="startMarketOffersFilter('marketFilterInterestPerTerm')" style="padding-left: 5px;"> 
                                                    <i class="fa fa-filter" :style="{ color: marketFilterInterestPerTerm ? 'green' : '' }" style="font-size: 14px;"></i> 
                                                </span> -->
                                            </div>

                                            <Transition name="modal">
                                                <div v-if="allMarketFilters.marketFilterInterestPerTerm.in_use" style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-around;
                                                    flex-wrap: wrap;
                                                    position: absolute;
                                                    top: 50px;
                                                    border: 2px solid rgb(53, 55, 57);
                                                    border-radius: 10px;
                                                    background: rgb(16, 17, 18);
                                                    color: rgb(253, 252, 255);
                                                    font-size: 14px;
                                                    width: 180px;
                                                    height: 100px;
                                                    font-size: 14px;">
                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Min: <input type="number" min="2" max="12" v-model="allMarketFilters.marketFilterInterestPerTerm.selected_min"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterInterestPerTerm.selected_min == allMarketFilters.marketFilterInterestPerTerm.selected_max" @click.stop="setFilterInterestPerTerm('min', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterInterestPerTerm.selected_min == 2" @click.stop="setFilterInterestPerTerm('min', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-around; align-items: flex-end; width: 80%;">
                                                            Max: <input type="number" min="2" max="12" v-model="allMarketFilters.marketFilterInterestPerTerm.selected_max"
                                                            style="position: relative; opacity: 1; z-index: 0; background: transparent; border: none; border-bottom: 1px solid #80808080; width: 50%; color: #fff; font-size: 16px; text-align: right;">
                                                            <div style="display: flex; flex-direction: column;">
                                                                <button :disabled="allMarketFilters.marketFilterInterestPerTerm.selected_max == 12" @click.stop="setFilterInterestPerTerm('max', 'up')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-up" style="font-size: 14px;"></i>
                                                                </button>
                                                                <button :disabled="allMarketFilters.marketFilterInterestPerTerm.selected_max == allMarketFilters.marketFilterInterestPerTerm.selected_min" @click.stop="setFilterInterestPerTerm('max', 'down')" style="all: unset;height: 15px;">
                                                                    <i class="fa fa-angle-down" style="font-size: 14px;"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <button @click.stop="resetMarketOffersFilterBy('marketFilterInterestPerTerm')" style="all: unset; margin: 0 10px; font-size: 10px;">
                                                                APPLY
                                                            </button>
                                                        </div>

                                                        
                                                    <!-- <input type="number" min="100" max="100000" v-model="allMarketFilters.selected_max"
                                                        style="position: relative;"> -->
                                                </div>
                                            </Transition>
                                        </th>
                                        <th style="height: 45px; line-height: 12px;">
                                            <div style="display: flex; align-items: center; justify-content: flex-end;">
                                                <span> Repayment <br> Type </span>
                                                <!-- <span @click="startMarketOffersFilter('marketFilterRepaymentType')" style="padding-left: 5px;"> 
                                                    <i class="fa fa-filter" :style="{ color: marketFilterRepaymentType ? 'green' : '' }" style="font-size: 14px;"></i> 
                                                </span> -->
                                            </div>

                                            <!-- <Transition name="modal"> -->
                                                <div v-if="allMarketFilters.marketFilterRepaymentType.in_use">
                                                    <select @click.stop @change.stop="filterMarketOffers($event, 'repayment_type')" name="acc_repayment_type" id="acc_repayment_type" style="
                                                            position: absolute;
                                                            padding-left: 5px;
                                                            top: 50px;
                                                            left: 0px;
                                                            border: 2px solid rgb(53, 55, 57);
                                                            border-radius: 10px;
                                                            background: rgb(16, 17, 18);
                                                            color: rgb(253, 252, 255);
                                                            font-size: 14px;
                                                            width: 120px;
                                                            height: 33px;
                                                            font-size: 14px;">
                                                        <option value=""> Select... </option>
                                                        <option value="1"> Type 1 </option>
                                                        <option value="2"> Type 2 </option>
                                                        <option value="3"> Type 3 </option>
                                                    </select>
                                                </div>
                                            <!-- </Transition> -->
                                        </th>
                                        <th style="text-align: center;"> Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="offer of marketOffers" :key="offer.id">
                                        <tr v-if="offer.display">
                                            <td style="padding-top: 12px; padding-right: 10px; text-align: right;">
                                                <p style="line-height: 10px; margin: 0;color: #FDFCFF;"> {{ formatPrice(offer.ticker, offer.loan_size_native_min) }} / {{ formatPrice(offer.ticker, offer.loan_size_native_max) }} </p> 
                                                <p style="margin: 0;color: #8F8F91;font-size: 14px;"> {{ offer.loan_size_native_min }} / {{ offer.loan_size_native_max }} </p>
                                            </td>
                                            <td> 
                                                <p style="margin: 0;color: #8F8F91;"> {{ offer.allowed_collateral }} </p> 
                                            </td>
                                            <td style="font-weight: 600;"> {{ offer.installments }} </td>
                                            <td style="font-weight: 600;"> {{ offer.interest }}% </td>
                                            <td style="font-weight: 600; width: 100px;"> {{ (offer.interest / offer.installments).toFixed(2) }}% </td>
                                            <td style="padding-right: 0px; padding-left: 10px;">
                                                <button disabled class="repayment-type-box tooltip-container" style="position: relative;">
                                                    TYPE {{ offer.repayment_type }} <span class="fa fa-question-circle"></span>

                                                    <span v-if="offer.repayment_type == 1" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: 0px; bottom: 0px;left: -190px;">
                                                            Interest will be paid every term and principal will be paid at the end of the loan.
                                                    </span>
                                                    <span v-if="offer.repayment_type == 2" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: 0px; bottom: 0px;left: -190px;">
                                                        Interest and principal will be paid every term.
                                                    </span>
                                                    <span v-if="offer.repayment_type == 3" class="tooltip" style="display: flex;position: absolute;align-items: center;font-size: 11px;width: 160px;top: 0px; bottom: 0px;left: -190px;">
                                                        Interest and principal will be paid at the end of the loan.
                                                    </span>
                                                </button>
                                            </td>
                                            <td>
                                                <button :disabled="unsupportedNetwork || !walletConnected" @click="openModal('OFFER PREVIEW', 'Offer details', offer)" class="take-offer">
                                                    TAKE <img v-bind:src="coins_illustration" alt="" style="margin-left: 5px;">
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </table>
                    </Transition>

                    <div v-if="!loadingOffers" style="display: flex;
                        color: rgb(143, 143, 145);
                        height: 150px;
                        align-items: center;
                        justify-content: center;">
                            <span v-if="marketOffers?.length == 0"> There are no active offers in {{ mainUIheader }} market. </span>
                    </div>

                    <div v-if="loadingOffers" style="display: flex;
                        color: rgb(143, 143, 145);
                        height: 150px;
                        align-items: center;
                        justify-content: center;">
                            <div class="lumin-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
                <p v-if="!loadingOffers" style="color: #fff; font-size: 12px; margin: 0; text-align: right; padding-top: 25px; margin: 0px 35px;"> Total: {{ marketOffers?.length }} </p>

                <select @change="changePriceSource($event.target.value)" style="display: none;height: max-content; bottom: 20px;left: 20px;position: absolute;background: #101112;border: none;color: #fff;text-align: center;">
                    <option value="dia"> Diadata.org </option>
                    <option value="coingecko"> CoinGecko API </option>
                    <option disabled value="chainlink"> Chainlink </option>
                </select>
            </div>

            <div id="tabsManager" ref="tabsManager" style="display: none;">
                <button @click="manageSidebar()" id="tabsManagerButton">
                    <span v-if="tabsManagerOpen" class="fa fa-angle-left"></span>
                    <span v-if="!tabsManagerOpen" class="fa fa-angle-right"></span>
                </button>
            </div>
                
            <!-- WIDGET WITH LATEST EVENT ON PROTOCOL LEVEL -->
            <div v-if="UIPreview != 'profile'" class="deposits-tab">
                <div id="market_deposits" [ngStyle]="{'width': width, 'display': displayChart}" v-if="false"></div>
                
                <h3 style="color: #FDFCFF;"> Latest Market Actions </h3>

                <div class="deposits-table-wrapper" v-if="!loadingActionsProtocol">
                    <div v-for="action of latestActionsProtocol" :key="action.id" class="deposits-table">
                        <span class="deposits-cell" style="text-align: left; padding-left: 5px;">
                            <template v-if="action.action == 'Lock'"> <i class="fa fa-lock" style="color: #fff;padding-right: 2px;"></i> Lock </template>
                            <template v-if="action.action == 'Unlock'"> <i class="fa fa-unlock" style="color: #fff;padding-right: 2px;"></i> Unlock </template>
                            <template v-if="action.action == 'PayPrincipal'"> $ Principal </template>
                            <template v-if="action.action == 'PayInterest'"> $ Interest </template>
                            <template v-if="action.action == 'PayFees'"> $ PayFees </template>
                            <template v-if="action.action == 'Lend'"> ⇒ Lend </template>
                            <template v-if="action.action == 'Deposit'"> ⇓ Deposit </template>
                            <template v-if="action.action == 'Withdraw'"> ⇑ Withdraw </template>
                        </span>
                        <img :src="action.asset_img" alt="" style="width: 20px; height: 20px;">
                        <span class="currency-cell" style="display: flex; flex-direction: column; overflow: hidden; text-align: right; line-height: 1;">
                            <span> {{ action.asset }} </span>
                            <span style="color: #808080;"> {{ action.symbol }} </span>
                        </span>
                        <span class="currency-cell" style="display: flex; flex-direction: column; overflow: hidden; text-align: right; line-height: 1; padding-left: 10px;">
                            <span> {{ action.amount }}  </span>
                            <span style="color: #808080;"> {{ formatPrice(action.symbol, action.amount) }} </span>
                        </span>
                        <span class="wallet-cell" style="text-align: right; padding-left: 10px;"> {{ action.userAddressShort }} </span>
                        <span class="ticker-cell" style="display: none">
                            <template> {{ action._timestamp }} </template>
                        </span>
                        <span class="tx-hash tooltip-container">
                            <a :href="'https://testnet.bscscan.com/tx/' + action.transactionHash"
                                target="_blank"
                                class="fa fa-fw fa-external-link"
                                style="cursor: pointer;"
                            ></a>
                            <span class="tooltip"
                                style="right: 0px;
                                bottom: 30px;
                                font-size: 12px;
                                width: 110px;
                                padding: 5px 5px;
                                color: #fff;
                                background: #8080802e;">
                                    Check transaction details
                            </span>
                        </span>
                    </div>
                </div>

                <div v-if="loadingActionsProtocol || latestActionsProtocol.length == 0" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 70px;
                    align-items: center;
                    justify-content: center;">
                        <span> Watching for new transactions </span>
                </div>

                <div v-if="loadingActionsProtocol || latestActionsProtocol.length == 0" style="display: flex;
                    color: rgb(143, 143, 145);
                    height: 0px;
                    align-items: center;
                    justify-content: center;">
                        <p :class="{'dots-loading': loadingActionsProtocol || latestActionsProtocol.length == 0}"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="mobile-stats-view" style="display: flex; justify-content: center; text-align: center; flex-direction: column;">
        <div style="display: flex; justify-content: center; align-items: center;">
            <img :src="logoSrc" alt="" style="width: 40px; height: 40px;">
            <h3 style="color: #fff; padding-left: 10px; font-size: 22px;"> Lumin Finance Stats </h3>
        </div>

        <div style="display: flex; justify-content: flex-start; align-items: center; padding-left: 30px;">
            <p style="color: #fff; margin: 5px 5px 5px; font-size: 18px;"> Arbitrum Markets </p>
            <img :src="arbLogoSrc" alt="" style="width: 20px;">
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="wBTC_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> Bitcoin </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 23.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="wETH_32" alt="" style="width: 20px; width: 20px; background: #fff; border-radius: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> Ethereum </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 13.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="USDC_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> USDC </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 52.23% </span>
            </div>
        </div>

        <div style="display: flex; justify-content: flex-start; flex-direction: column; align-items: center; margin: 5px 30px;background: linear-gradient(180deg, #1C1E1F 0%, #1C1E1F 100%); border: 1px solid #353739; border-radius: 10px;">
            <div style="display: flex; align-items: center; color: #fff; width: 95%; padding-right: 10px; font-size: 18px; margin-bottom: 5px; border-bottom: 1px solid rgb(53, 55, 57);">
                <img :src="tether_32" alt="" style="width: 20px; width: 20px;">
                <p style="color: #fff; padding: 10px; font-size: 16px; margin: 0 0 0;"> USDT </p>
            </div>

            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Price </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351.94 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total deposits </span>
                <span style="text-align: right;font-size: 14px;"> $ 126,097 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Total borrowed </span>
                <span style="text-align: right;font-size: 14px;"> $ 0 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Available </span>
                <span style="text-align: right;font-size: 14px;"> $ 37,351 </span>
            </div>
            <div style="display: flex; justify-content: space-between; color: #fff; padding-right: 10px; padding-bottom: 5px; font-size: 18px; width: 90%;">
                <span style="text-align: left;font-size: 14px;"> Utilization </span>
                <span style="text-align: right;font-size: 14px;"> 3.23% </span>
            </div>
        </div>
        
    </div>
  
    <!-- MODAL TEMPLATES -->
    <div>
        <Teleport to="#modal">
            <Transition name="modal">
                <div class="modal-bg" v-if="isModalOpen && modalTitle == 'OFFER PREVIEW'">
                    <div class="modal" style="top: 80px;">
                        <h1 class="title" style="margin: 20px 0 15px;"> Accept offer </h1>

                        <div class="deposit-view" style="width: 90%; padding-bottom: 0px;">
                            <div style="display: flex;
                                justify-content: space-evenly;
                                width: 100%;border-top: 2px solid #353739;">
                                <button class="btn-pick" :style="{
                                    background: currentStep == 1 ? '#353739' : '#1c1b1d'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 1"> Principal Asset & Loan Amounts </button>

                                <button class="btn-pick" :style="{
                                    background: currentStep == 2 ? '#353739' : '#1c1b1d',
                                    opacity: validateFirstStep() ? '1' : '0.25',
                                    'pointer-events': validateFirstStep() ? 'unset' : 'none',
                                    cursor: validateFirstStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 2"> Accepted Collateral & Collateral Amount </button>

                                <button class="btn-pick" :style="{
                                    background: currentStep == 3 ? '#353739' : '#1c1b1d',
                                    opacity: validateSecondStep() ? '1' : '0.25',
                                    'pointer-events': validateSecondStep() ? 'unset' : 'none',
                                    cursor: validateSecondStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 3"> Accepted Interest & Oracle Interest </button>

                                <button class="btn-pick" :style="{
                                    background: currentStep == 4 ? '#353739' : '#1c1b1d',
                                    opacity: validateSecondStep() ? '1' : '0.25',
                                    'pointer-events': validateSecondStep() ? 'unset' : 'none',
                                    cursor: validateSecondStep() ? 'pointer' : 'not-allowed'
                                    }" style="border-radius: unset; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: none; line-height: 13px;" 
                                    @click="currentStep = 4"> Loan Details </button>
                            </div>
                        </div>

                        <div class="deposit-view" style="padding-top: 15px; padding-bottom: 15px;">
                            <div class="stepper-horizontal-interest" ref="stepperInterest" style="margin: 0px auto; width: 90%;">
                                <div class="step" style="padding-bottom: 30px;">
                                    <div class="tooltip-container">
                                        <span class="interest-term-number" style="left: 25%; display: none;"> 1
                                            <span class="tooltip"
                                                style="right: -50px;
                                                bottom: 25px;
                                                font-size: 12px;
                                                width: 110px;
                                                padding: 5px 5px;
                                                color: #fff;
                                                background: transparent;">
                                                    First Instalment 10/22/2023 23:52:05
                                            </span>
                                        </span>
                                    </div>

                                    <!-- HEALTH FACTOR INDICATOR -->
                                    <Transition name="modal">
                                        <div v-if="healthFactorCalc < 160" style="position: relative; top: -25px;">
                                            <div v-if="healthFactorCalc > 100" :style="{'left': (healthFactorCalc - 100) * 1.66666667 + '%'}" class="indicator-wrapper">
                                                <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 10px; font-weight: 100; width: min-content;left: -10px;">
                                                    {{ (healthFactorCalc).toFixed(2) }}%
                                                </span>
                                                <span class="interest-timeline-indicator" style="text-align: left; font-size: 14px; top: 20px; left: 2px; color: #e8e600;"> ▼ </span>
                                            </div>
                                        </div>
                                    </Transition>

                                    <Transition name="modal">
                                        <span v-if="healthFactorCalc >= 160" style="position: absolute;right: -30px;top: 15px;color: green;font-size: 14px;width: auto;"> {{ (healthFactorCalc).toFixed(2) }}% </span>
                                    </Transition>

                                    <!-- LIQUIDATION VALUE -->
                                    <!-- LIQUIDATION VALUE INDICATOR - ABOVE LINE -->
                                    <div style="position: relative; top: -25px;">
                                        <div :style="{'left': 33 + '%'}" class="indicator-wrapper">
                                            <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 20px; font-weight: 100; width: min-content;"> 
                                                120%
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Liquidation value of the loan with current loan parameters.
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <!-- LIQUIDATION VALUE INDICATOR - UNDER LINE -->
                                    <div style="position: relative; top: -25px;">
                                        <div :style="{'left': (34-5) + '%'}" class="indicator-wrapper">
                                            <span class="interest-timeline-indicator" style="position: absolute;left: -5px;line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content;"> 
                                                LIQUIDATION VALUE
                                            </span>
                                            <span class="interest-timeline-indicator" style="text-align: center; font-size: 14px; top: 35px; color: #ff0000;"> ▲ </span>
                                        </div>
                                    </div>

                                    <!-- COLLATERAL BUFFER -->
                                    <!-- COLLATERAL BUFFER INDICATOR - ABOVE LINE -->
                                    <div style="position: relative; top: -25px;">
                                        <div :style="{'left': 50 + '%'}" class="indicator-wrapper">
                                            <span class="interest-timeline-indicator" style="line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 20px; font-weight: 100; width: min-content;"> 
                                                130%
                                                <span class="tooltip" style="top: 270px; bottom: 230px; left: 220px; font-size: 13px; width: 150px;">
                                                    Liquidation value of the loan with current loan parameters.
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div style="position: relative; top: -25px;">
                                        <div :style="{'left': 46 + '%'}" class="indicator-wrapper">
                                            <span class="interest-timeline-indicator" style="position: absolute;left: 5px;line-height: 11px; text-align: center; font-size: 11px; color: #fff; top: 55px; font-weight: 100; width: min-content;"> 
                                                BUFFER
                                            </span>
                                            <span class="interest-timeline-indicator" style="text-align: center; font-size: 14px; top: 35px; color: #ff7f00;"> ▲ </span>
                                        </div>
                                    </div>

                                    <div class="tooltip-container">
                                        <span v-if="interestAssetAmount > 0" class="interest-future-payoff-indicator"
                                            :style="{ left: (15 + interestFuturePayoffBar - 1.5) + '%' }"> ▼ 
                                        </span>
                                        <span class="tooltip"
                                            style="bottom: 55px;
                                            font-size: 12px;
                                            width: 110px;
                                            padding: 5px 5px;
                                            color: #fff;
                                            word-wrap: break-word;
                                            font-weight: 100;
                                            background: transparent;"
                                            :style="{ right: 15 + interestFuturePayoffBar + '%' }">
                                                Status after new payment completed
                                        </span>
                                    </div>

                                    <div class="step-bar-right" style="height: 2px; border: none; background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 0, 0) 30%, rgb(255, 127, 0) 36%, rgb(0, 255, 0) 100%);"></div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;
                            width: 100%;
                            place-content: center;
                            justify-content: center;
                            flex-direction: column;">

                            <div v-if="currentStep == 1">
                                <div>
                                    <div class="deposit-view" style="padding-bottom: 10px; width: 74.5%;">
                                        <div class="summary-amount offer-config-params" style="border-top: 2px solid #353739; margin-top: -10px;">
                                            <div class="details">
                                                <span> Principal Asset: </span>
                                                <span class="tooltip-container" style="position: relative; right: 38%;"> 
                                                    <span class="fa fa-question-circle" style="font-size: 14px;"></span> 
                                                    <span class="tooltip" style="top: -100px; bottom: 30px; left: 0px; font-size: 13px; width: 150px;">
                                                        Choose the oracle that will be used to calculate the price of the asset that you want to offer.
                                                    </span>
                                                    Oracle: 
                                                </span>
                                            </div>
                                            <div class="details">
                                                <div class="cell-asset-wrapper">
                                                    <img :src="modalData.asset_img" alt="">
                                                    <div class="asset" style="line-height: 1.2;">
                                                        <span style="text-align: left;"> {{ modalData.asset }} </span>
                                                        <span style="font-size: 12px;text-align: left; color: #808080;"> {{ modalData.ticker }} </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-picker">
                                                    <div style="display: flex;">
                                                        <template v-if="loadingOracleFeeds">
                                                            <div class="lumin-loader"></div>
                                                        </template>

                                                        <Transition name="modal">
                                                            <div v-if="principalAssetFeeds.length > 0">
                                                                <div v-for="oracle in principalAssetFeeds" :key="oracle.id" class="collateral-asset">
                                                                    {{ oracle.description }}
                                                                    <label class="switch">
                                                                        <input type="checkbox" v-model="oracle.use_oracle" @change="principalAssetOracleChange($event, oracle)">
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Transition>

                                                        <div v-if="principalAssetFeeds.length == 0 && !loadingOracleFeeds" class="collateral-asset" style="font-style: italic;">
                                                            No oracle Price Feeds for asset.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="deposit-view" style="width: 73%; padding-bottom: 15px;">
                                    <div class="asset-amount" style="padding: 10px 20px; width: 400px; border: 2px solid #353739;">
                                        <div class="asset-amounts">
                                            <input v-model="modalData.borrowing_amount" type="number" step="0.1" min="0" value="0" style="font-size: 24px;">
                                            <img :src="modalData.asset_img" alt="" style="position: relative; top: 5px; height: 32px;">
                                        </div>

                                        <div style="padding-bottom: 15px;">
                                            <input id="rangeSliderPrincipal" class="range" type="range" name="" min="0" step="0.001"
                                                :max="modalData.available_for_borrow_native"
                                                v-model="modalData.borrowing_amount"
                                                @change="rangeSliderPrincipal(modalData.borrowing_amount, modalData.available_for_borrow_native)" 
                                                @mousemove="rangeSliderPrincipal(modalData.borrowing_amount, modalData.available_for_borrow_native)"
                                                style="width: 100%;">
                                        </div>

                                        <div class="details">
                                            <span style="font-size: 14px;"> 
                                                Available: {{ modalData.available_for_borrow_native }} {{ modalData.ticker }}
                                                <span @click="modalData.borrowing_amount = modalData.available_for_borrow_native" style="
                                                    position: relative;
                                                    top: -1px;
                                                    font-size: 10px;
                                                    border: 1px solid grey;
                                                    border-radius: 5px;
                                                    cursor: pointer;
                                                    padding: 0 5px;"> MAX </span>
                                            </span>
                                            <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.borrowing_amount) }} </span>
                                            
                                        </div>

                                        <!-- <div class="details">
                                            <span style="font-size: 14px;"> 
                                                Current Deposit: {{ modalData.deposit }} {{ modalData.ticker }}
                                            </span>
                                            <span style="font-size: 14px;"> {{ formatPrice(modalData.ticker, modalData.deposit) }} </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div v-if="currentStep == 2">
                                <div>
                                    <div class="deposit-view" style="padding-bottom: 10px; width: 75%;">
                                        <div class="summary-amount offer-config-params" style="border-top: 2px solid #353739; margin-top: -10px;max-height: 115px;overflow: scroll;overflow-x: hidden;">
                                            <div class="details">
                                                <span> Collateral Asset: </span>
                                                <span class="tooltip-container" style="position: relative; right: 38%;"> 
                                                    <span class="fa fa-question-circle" style="font-size: 14px;"></span> 
                                                    <span class="tooltip" style="top: -100px; bottom: 30px; left: 0px; font-size: 13px; width: 150px;">
                                                        Choose the oracle that will be used to calculate the price of the asset that you want to use as collateral.
                                                    </span>
                                                    Oracle: 
                                                </span>
                                            </div>
                                            <div class="details" v-for="(collOracle, parent_index) of modalData.loan_vanilla_data.acceptedAssets" :key="collOracle.useCollateral">
                                                <template v-if="collOracle.useCollateral">
                                                    <div class="cell-asset-wrapper" :style="{ opacity: collOracle.amount_avaliable == 0 ? '0.5' : '1' }">
                                                        <img :src="collOracle.asset_img" alt="">
                                                        <div class="asset" style="line-height: 1.2;">
                                                            <span style="text-align: left;"> {{ collOracle.name }} </span>
                                                            <span style="font-size: 12px;text-align: left; color: #808080;"> {{ collOracle.asset.symbol }} </span>
                                                        </div>
                                                    </div>

                                                    <div class="collateral-picker">
                                                        <div style="display: flex;" v-for="(oracle, index) of collOracle.acceptedPriceFeeds" :key="oracle.id">
                                                            <div class="collateral-asset">
                                                                <div style="display: flex;flex-direction: column;padding-right: 10px;line-height: 1.2;">
                                                                    <span> {{ oracle.description }} </span>
                                                                    <!-- <span style="font-size: 12px;color: #808080;"> {{ collOracle.price }} </span> -->
                                                                </div>
                                                                <label class="switch">
                                                                    <input type="checkbox" v-model="oracle.use_oracle" 
                                                                        @change="collateralAssetOracleChange($event, collOracle, index, parent_index)">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="position: relative; left: 2px; max-height: 165px; overflow: scroll; overflow-x: hidden; margin: 0 5px;">
                                    <div v-for="(useAsset, index) of modalData.loan_vanilla_data.acceptedAssets" :key="useAsset.useCollateral" class="deposit-view" style="width: 75%; padding-bottom: 15px;">
                                        <template v-if="useAsset.useCollateral">
                                            <div class="asset-amount" style="padding: 10px 20px; width: 400px; border: 2px solid #353739;">
                                                <div class="asset-amounts">
                                                    <input v-model="useAsset.amount_to_use" 
                                                        :disabled="useAsset.amount_avaliable == 0" 
                                                        :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                        type="number" step="0.1" min="0" 
                                                        :max="useAsset.amount_avaliable" 
                                                        style="font-size: 24px;">
                                                    <img :src="useAsset.asset_img" alt="" style="position: relative; top: 5px; height: 32px;">
                                                </div>

                                                <div style="padding-bottom: 15px;">
                                                    <input :id="'rangeSliderCollateral-' + index" class="range" type="range" name="" min="0" step="0.001"
                                                        :disabled="useAsset.amount_avaliable == 0"
                                                        :style="{ opacity: useAsset.amount_avaliable == 0 ? '0.5' : '1' }"
                                                        :max="useAsset.amount_avaliable" 
                                                        v-model="useAsset.amount_to_use"
                                                        @change="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)" 
                                                        @mousemove="rangeSliderCollateral(useAsset.amount_to_use, useAsset.amount_avaliable, index)"
                                                        style="width: 100%;">
                                                </div>

                                                <div class="details">
                                                    <!-- {{ modalData.available }} {{ modalData.ticker }} -->
                                                    <span style="font-size: 14px;"> 
                                                        Available: {{ useAsset.amount_avaliable }} {{ useAsset.ticker }}
                                                        <span @click="setMaxAmount()" style="
                                                            position: relative;
                                                            top: -1px;
                                                            font-size: 10px;
                                                            border: 1px solid grey;
                                                            border-radius: 5px;
                                                            cursor: pointer;
                                                            padding: 0 5px;"> MAX </span>
                                                    </span>
                                                    <span style="font-size: 14px;"> {{ formatPrice(useAsset.ticker, useAsset.amount_to_use) }} </span>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div v-if="currentStep == 3">
                                <div>
                                    <div class="deposit-view" style="padding-bottom: 5px; width: 74.5%;">
                                        <div class="summary-amount offer-config-params" style="border-top: 2px solid #353739; margin-top: -10px;">
                                            <div class="details">
                                                <span> Interest Asset: </span>
                                                <span class="tooltip-container" style="position: relative; right: 38%;"> 
                                                    <span class="fa fa-question-circle" style="font-size: 14px;"></span> 
                                                    <span class="tooltip" style="top: -100px; bottom: 30px; left: 0px; font-size: 13px; width: 150px;">
                                                        Choose the oracle that will be used to calculate the price of the asset that you want to use as collateral.
                                                    </span>
                                                    Oracle: 
                                                </span>
                                            </div>
                                            <div class="details" v-for="interestOracle of modalData.loan_vanilla_data.acceptedAssets" :key="interestOracle.useCollateral">
                                                <template v-if="interestOracle.useInterest">
                                                    <div class="cell-asset-wrapper">
                                                        <img :src="interestOracle.asset_img" alt="">
                                                        <div class="asset" style="line-height: 1.2;">
                                                            <span style="text-align: left;"> {{ interestOracle.name }} </span>
                                                            <span style="font-size: 12px;text-align: left; color: #808080;"> {{ interestOracle.asset.symbol }} </span>
                                                        </div>
                                                    </div>

                                                    <div class="collateral-picker">
                                                        <div style="display: flex;" v-for="oracle of interestOracle.acceptedPriceFeeds" :key="oracle.id">
                                                            <div class="collateral-asset">
                                                                <div style="display: flex;flex-direction: column;padding-right: 10px;line-height: 1.2;">
                                                                    <span> {{ oracle.description }} </span>
                                                                    <!-- <span style="font-size: 12px;color: #808080;"> {{ interestOracle.price }} </span> -->
                                                                </div>
                                                                <label class="switch" :style="{ opacity: interestOracle.useCollateral ? '0.5' : '1' }">
                                                                    <input :disabled="interestOracle.useCollateral" type="checkbox" 
                                                                        v-model="oracle.use_oracle" 
                                                                        @change="collateralAssetOracleChange($event, collOracle.acceptedPriceFeeds, oracle)">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="currentStep == 4">
                                <div class="offer-params-container" style="display: flex; align-items: center;">
                                    <div class="deposit-view" style="flex-direction: column; padding-bottom: 0; max-height: 350px; border: 2px solid #353739; border-radius: 10px; width: 73%;">
                                        <div class="summary-amount" style="border-top: none; padding-top: 0; width: 370px;">
                                            <div class="details" style="height: 30px; border-bottom: 1px solid #353739; margin-bottom: 5px; justify-content: flex-start; padding-left: 20px;">
                                                <span>  Loan Summary: </span>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Principal Asset </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ modalData.asset }} <!-- <span style="color: grey;"> {{ modalData.ticker }} </span> --> </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Loan Amount </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ formatPrice(modalData.ticker, modalData.borrowing_amount) }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Collateral Asset </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ modalData.allowed_collateral }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Collateral Amount </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ cumulativeCollateralAssetsAmount() }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Loan Health </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ (healthFactorCalc).toFixed(2) }}% </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details" style="height: 30px; margin: 0 0; align-items: center; padding-bottom: 5px;">
                                                <div class="cell-asset-wrapper" style="padding-left: 20px;">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> Loan Duration </span>
                                                    </div>
                                                </div>
                                                <div class="collateral-asset">
                                                    <div class="asset">
                                                        <span style="text-align: left;"> {{ modalData.installments }} Terms ({{ modalData.installments * 30 }} days) </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="btn-container" style="padding: 20px 0px; justify-content: space-between; width: 73%;"
                            :style="{ 'justify-content': currentStep == 1 ? 'flex-end' : 'space-between' }">
                            <button @click="isModalOpen = false" class="btn-close-top-right"> 
                                <i class="fa fa-close"></i> 
                            </button>

                            <button @click="currentStep--" class="btn-back" v-if="currentStep != 1"> Back </button>

                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 1" :disabled="!validateFirstStep()"> Next </button>
                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 2" :disabled="!validateSecondStep()"> Next </button>
                            <button @click="nextStep()" class="btn-next" v-if="currentStep == 3" :disabled="!validateThirdStep()"> Next </button>

                            <button @click="loanTaking()" class="btn-submit" v-if="currentStep == 4" style="line-height: 13px; font-size: 12px;">
                                <span v-if="!modalData.processing_tx"> Accept Offer </span>
                                <div v-if="modalData.processing_tx" class="lumin-ellipsis" style="left: -35px; top: 5px;"><div></div><div></div><div></div><div></div></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>

    <Teleport to="#toast">
        <div v-for="(toast, index) in toasts" :key="toast.icon">
            <Transition name="toast">
                <div class="toast" v-if="toast.isToastOpen" style="display: flex;flex-direction: row;align-items: center;padding: 5px 20px;"
                    @click="toast.isToastOpen = false"
                    :style="{'top': 60 * index + 'px'}">
                    <i :style="{'color': toast.color}" :class="toast.icon" style="margin: 0 10px 0 0;padding: 5px;border-radius: 25px;width: 16px; height: 16px;"></i>
                    <div style="line-height: 15px;">
                        <p style="margin: 5px;"> {{ toast.toastTitle }} </p>
                        <span style="margin-left: 5px; font-size: 12px; color: #808080;"> {{ toast.toastContent }} </span>
                    </div>
                </div>
            </Transition>
        </div>
        <Transition name="toast">
            <div class="toast" v-if="isToastOpen" style=""
                @click="isToastOpen = false">
                <!-- :style="{'top': 60 * (index) + 'px'}"  -->
                <p> {{ toastTitle }} </p>
            </div>
        </Transition>
    </Teleport>
</template>
    
<script>
// @ts-check
// @ts-ignore
// @ts-nocheck
import { ref, onMounted, watch, computed } from 'vue';
import { ethers, Contract, formatUnits, BigNumber } from 'ethers';

import { LuminClient } from 'lumin-lib/client/LuminClient';
import LuminApp from './LuminApp.vue'

import { createConfig, configureChains, waitForTransaction } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { bscTestnet } from '@wagmi/core/chains'

import ERC20 from '@/components/ERC20.json';

import { ROARR, Roarr as log } from 'roarr';
import { createLogWriter } from '@roarr/browser-log-writer';

ROARR.write = (message) => {
    // console.log(JSON.parse(message));
};

log.write = createLogWriter();

import logoSrc from '@/assets/lumin_icon.svg';
import luminIconPng from '@/assets/Lumin_Logo2.png';

import walletConnectionIcon from '@/assets/wallet-connection-icon.png';
import bscLogo from '@/assets/bsc-logo.svg';
import wBTC_32 from '@/assets/wBTC_32.png';
import wETH_32 from '@/assets/wETH_32.png';
import USDC_32 from '@/assets/USDC_32.png';
import tether_32 from '@/assets/tether_32.png';
import coins_illustration from '@/assets/coins.png';

import LUMIN_grey from '@/assets/LUMIN_grey.png';
import wBTC_grey from '@/assets/wBTC_grey.png';
import wETH_grey from '@/assets/wETH_grey.png';
import USDC_grey from '@/assets/USDC_grey.png';
import USDT_grey from '@/assets/USDT_grey.png';

import twitter_white from '@/assets/twitter-white.png';
import telegram_white from '@/assets/telegram-white.png';
import medium_white from '@/assets/medium-white.png';
import web_white from '@/assets/web-white.png';
import arbLogoSrc from '@/assets/arbitrum-logo.svg';

import gql from "graphql-tag";
import { useQuery, useApolloClient } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import router from '../router.js'

const QUERY_PROTOCOL_ACTIONS = gql`
  query AssetAction {
        assetActions(orderBy: blockNumber, orderDirection:desc) {
            id
            asset {
                symbol
                decimals
            }
            amount
            userAddress
            action
            transactionHash
            blockNumber
        }
    }
`;

export default {
    name: 'LuminMarket',
    setup() {
        // LUMIN VARs
        let luminInstance;
        let chainAssets;
        let assetManager;
        const { resolveClient } = useApolloClient();

        // WALLET VARs
        let walletConnected = ref(false);
        let walletString = ref("0x");
        let walletStringShort = ref("");

        // WAGMI SETUP
        const { chains, publicClient, webSocketPublicClient } = configureChains(
            [bscTestnet],
            [publicProvider()],
        )
 
        const config = createConfig({
            bscTestnet,
            publicClient,
            webSocketPublicClient,
        })

        const availableMarkets = ref(["Bitcoin", "Ethereum", "USD Coin", "Tether", "Mock", "SMock"]);
        let marketTicker = ref(null);

        // APOLLO GRAPHQL SETUP
        // ALL MARKETS DATA
        let assetsActiveOracles = ref([]);
        const marketOffers = ref([]);
        const marketOffersData = useQuery(gql`
            query Loans {
                loans {
                    id
                    config {
                        id
                        enabled
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        totalPendingPrincipalAmount
                        termPaymentType
                        interestPromille
      		            terms
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                                decimals
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        lender
                        lenderDeposit {
                            total
                            locked
                        }
                    }
                }
                loanConfigs {
                    id
                    enabled
                    minPrincipalAmount
                    maxPrincipalAmount
                    maxTotalPendingPrincipalAmount
                    totalPendingPrincipalAmount
                    termPaymentType
                    interestPromille
                    terms
                    principalAsset {
                        id
                        symbol
                        decimals
                    }
                    acceptedPriceFeeds {
                        id
                        description
                    }
                    acceptedAssets {
                        asset {
                            id
                            symbol
                            decimals
                        }
                        useInterest
                        useCollateral
                        acceptedPriceFeeds {
                            id
                            description
                        }
                    }
                    lender
                    lenderDeposit {
                        total
                        locked
                    }
                }
                assets {
                    symbol
                    priceFeeds {
                        enabled
                        priceFeedIndex
                        proxy {
                            description
                        }
                    }
                }
            }
            `, null, { fetchPolicy: 'no-cache' });
        // Handle if result is to be fetched from server
        marketOffersData.onResult( data => {
            marketOffers.value.length = 0;
            for (let loan of data?.data?.loanConfigs) {
                const loanData = {
                    ticker: loan.principalAsset.symbol,
                    maxTotalPendingPrincipalAmount: parseInt(formatUnits(loan.maxTotalPendingPrincipalAmount.toString(), loan.principalAsset.decimals)),
                    totalPendingPrincipalAmount: parseInt(formatUnits(loan.totalPendingPrincipalAmount.toString(), loan.principalAsset.decimals)),
                    available_for_borrow: null,
                    available_for_borrow_native: null,
                    loan_size_min: null,
                    loan_size_max: null,
                    loan_size_native_min: parseFloat(formatUnits(loan.minPrincipalAmount.toString(), loan.principalAsset.decimals)),
                    loan_size_native_max: parseFloat(formatUnits(loan.maxPrincipalAmount.toString(), loan.principalAsset.decimals)),
                    loan_size_min_int: loan.minPrincipalAmount,
                    loan_size_max_int: loan.maxPrincipalAmount,
                    interest: (loan.interestPromille / 10),
                    interest_int: (loan.interestPromille / 10),
                    installments: loan.terms,
                    display: true,
                    decimals: loan.principalAsset.decimals,
                    loan_vanilla_data: loan
                }

                // CALCULATE HOW MUCH IS LEFT TO BE BORROWED
                loanData['available_for_borrow_native'] = loanData.maxTotalPendingPrincipalAmount - loanData.totalPendingPrincipalAmount;
                loanData['available_for_borrow'] = loanData.maxTotalPendingPrincipalAmount - loanData.totalPendingPrincipalAmount;

                if (loanData['available_for_borrow'] > parseInt(loanData.loan_size_native_max)) {
                    loanData['available_for_borrow_native'] = parseInt(loanData.loan_size_native_max);
                    loanData['available_for_borrow'] = parseInt(loanData.loan_size_native_max);
                } else {
                    loanData['loan_size_native_max'] = loanData['available_for_borrow_native'];
                }

                // CHECK FOR LOAN TYPE
                console.log("loan.termPaymentType ------>", loan.termPaymentType)
                if (loan.termPaymentType == "None") {
                    loanData['repayment_type'] = "1";
                } else if (loan.termPaymentType == "Interest") {
                    loanData['repayment_type'] = "2";
                } else if (loan.termPaymentType == "InterestAndPrincipal") {
                    loanData['repayment_type'] = "3";
                }

                // CHECK ALL loan.acceptedAssets and add to allowed_collateral
                if (loan.acceptedAssets.length > 0) {
                    let allowedCollateral = "";
                    for (let asset of loan.acceptedAssets) {
                        allowedCollateral += asset.asset.symbol + ", ";
                    }
                    loanData['allowed_collateral'] = allowedCollateral.slice(0, -2);
                } else {
                    loanData['allowed_collateral'] = " - ";
                }

                if (loan.principalAsset.symbol == 'BTC') {
                    loanData.asset_img = wBTC_32;
                    loanData.asset = "Bitcoin";
                } else if (loan.principalAsset.symbol == 'ETH') {
                    loanData.asset_img = wETH_32;
                    loanData.asset = "Ethereum";
                } else if (loan.principalAsset.symbol == 'USDC') {
                    loanData.asset_img = USDC_32;
                    loanData.asset = "USD Coin";
                } else if (loan.principalAsset.symbol == 'USDT') {
                    loanData.asset_img = tether_32;
                    loanData.asset = "Tether";
                } else if (loan.principalAsset.symbol == 'MOCK') {
                    loanData.asset_img = luminIconPng;
                    loanData.asset = "Mock";
                } else if (loan.principalAsset.symbol == 'SMOCK') {
                    loanData.asset_img = LUMIN_grey;
                    loanData.asset = "SMock";
                }
                /* repayment_type: 1, */
                // IF AVAILABLE FOR BORROW IS LESS THAN MINUMUM LOAN SIZE, THEN DON'T ADD TO LIST
                if (loanData.available_for_borrow_native >= loanData.loan_size_native_min) {
                    marketOffers.value.push(loanData);
                }

                // CALCULATE FOR OVERALL DATA ONLY FOR THAT DATA WHERE available_for_borrow_native > loan_size_native_min
                /* if (loanData.available_for_borrow_native > loanData.loan_size_native_min) {
                    marketAvailable.value += loanData.available_for_borrow_native;
                } */
            }
            // FILTER OUT OFFERS THAT ARE NOT ENABLED AND THOSE WHERE loan_vanilla_data.lender IS THE USER THAT IS CONNECTED walletString
            marketOffers.value = marketOffers.value.filter(
                offer => offer.loan_vanilla_data.enabled == true
            );
            if (marketTicker.value != null) {
                marketOffers.value = marketOffers.value.filter(
                    offer => offer.ticker == marketTicker.value
                );
            }
            // MAKE A LIST OF ALL ASSETS AVALIABLE ORACLES
            assetsActiveOracles.value.length = 0;
            for (let asset of data?.data?.assets) {
                assetsActiveOracles.value.push(asset);
            }

            /* console.log("marketOffers onResult GraphQL -------------->", marketOffers.value);
            console.log("assetsActiveOracles -------------->", assetsActiveOracles); */
        });

        // APOLLO GRAPHQL SETUP
        // ALL USERS OFFERS DATA
        const marketPendingPrincipals = ref(0);
        const marketPendingInterests = ref(0);

        const allProtocolOffers = ref([]);
        const latestActionsProfileQuery = useQuery(gql`
            query UsersOffers {
                loans {
                    id
                    status
                    created {
                        id
                        blockTimestamp
                    }
                    pendingInterestAmount
                    pendingPrincipalAmount
                    config {
                        id
                        enabled
                        minPrincipalAmount
                        maxPrincipalAmount
                        maxTotalPendingPrincipalAmount
                        termPaymentType
                        interestPromille
                        principalAsset {
                            id
                            symbol
                            decimals
                        }
                        acceptedPriceFeeds {
                            id
                            description
                        }
                        acceptedAssets {
                            asset {
                                id
                                symbol
                            }
                            useInterest
                            useCollateral
                            acceptedPriceFeeds {
                                id
                                description
                            }
                        }
                        lender
                        lenderDeposit {
                            total
                            locked
                        }
                    }
                }
            }
            `, null, { fetchPolicy: 'no-cache' });

        latestActionsProfileQuery.onResult( data => {
            allProtocolOffers.value.length = 0;
            /* console.log("allProtocolOffers", data); */

            for (let loan of data?.data?.loans) {
                allProtocolOffers.value.push(loan);
            }
        });

        // APOLLO GRAPHQL SETUP
        // ALL MARKETS DATA ACTIONS
        let loadingActionsProtocol = ref(true);
        let latestActionsProtocol = ref([]);
        const latestActionsQuery = useQuery(QUERY_PROTOCOL_ACTIONS, null, { fetchPolicy: 'no-cache' });

        // Handle if result is to be fetched from server
        latestActionsQuery.onResult( data => {
            latestActionsProtocol.value.length = 0;
            if (data?.data?.assetActions) {
                for (let action of data?.data?.assetActions) {
                    const actionData = {
                        id: action.id,
                        asset: action.asset.symbol,
                        userAddress: action.userAddress,
                        action: action.action,
                        transactionHash: action.transactionHash,
                        symbol: action.asset.symbol,
                        decimals: action.asset.decimals
                    }

                    actionData['amount'] = parseFloat(ethers.formatUnits(action.amount.toString(), action.asset.decimals)),
                    actionData['userAddressShort'] = action.userAddress.slice(0, 4) + "..." + action.userAddress.slice(-4);
                    

                    if (actionData.symbol == "BTC") {
                        actionData.asset_img = wBTC_grey;
                        actionData.asset = "Bitcoin";
                        actionData['amount'] = actionData['amount'].toFixed(7);
                    } else if (actionData.symbol == "ETH") {
                        actionData.asset_img = wETH_grey;
                        actionData.asset = "Ethereum";
                        actionData['amount'] = actionData['amount'].toFixed(7);
                    } else if (actionData.symbol == "USDC") {
                        actionData.asset_img = USDC_grey;
                        actionData.asset = "USD Coin";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "USDT") {
                        actionData.asset_img = USDT_grey;
                        actionData.asset = "Tether";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "MOCK") {
                        actionData.asset_img = LUMIN_grey;
                        actionData.asset = "Mock";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    } else if (actionData.symbol == "SMOCK") {
                        actionData.asset_img = LUMIN_grey;
                        actionData.asset = "SMock";
                        actionData['amount'] = actionData['amount'].toFixed(2);
                    }

                    latestActionsProtocol.value.push(actionData);
                }
            }
            console.log("latestActionsProtocol ----> ", latestActionsProtocol.value);
        });

        // MARKET OVERALL DATA
        /* Market Size
        Available
        Borrows
        Utilisation Rate */
        let marketSize = ref(0);
        let marketAvailable = ref(0);
        let marketBorrows = ref(0);
        let marketUtilisationRate = ref(0);

        // BLUEPRINT DATA FOR TAKING LOANS
        let principalAssetFeeds = ref([]);
        let loadingOracleFeeds = ref(true);

        const screenWidth = ref(window.screen.width);
        let isMobileView = ref(screenWidth.value < 1024);
        let isDesktop = ref(screenWidth.value >= 1024);
        let unsupportedNetwork = ref(false);
        let ethereum = ref(window['ethereum']);
        let web3Provider = ref(null);

        let loaderWorking = ref(true);
        let libIsInstantiated = ref(true);


        let loadingUserAsset = ref(false);
        let loanDetailDialog = ref("manage-interest");
        let payingInterestTerm = ref(false);
        let showInterestOptions = ref(false);
        // manage-principal
        const UIPreview = ref("market");
        const mainUIheader = ref("");
        const all_assets_deposits_usd = ref(0);
        const all_assets_borrows_usd = ref(0);
        const all_assets_paid_fees = ref(0);
        const offersList = ref([]);
        const userWalletData = ref([]);
        const userOffersData = ref([]);
        const userBorrowsData = ref([]);
        const tabsManager = ref(null);
        const mainTab = ref(null);

        // Offer Dialog Variables
        let loadingAssetDollarValues = ref(true);
        let bitcoinPrice = ref(0);
        let ethereumPrice = ref(0);
        let usdcPrice = ref(0);
        let usdtPrice = ref(0);
        let mockPrice = ref(0);
        let smockPrice = ref(0);

        // ALL DUMMY DATA FOR UI MOCK
        const offersListDummy = ref([
            {
                asset: "USD Coin",
                ticker: "USDC",
                deposits: "138.75",
                asset_img: USDC_32,
                available_for_borrow: 1500,
                loan_size_min: "$1,000",
                loan_size_max: "$5,000",
                loan_size_native_min: "0.0235521",
                loan_size_native_max: "3.0232521",
                loan_size_min_int: 1000,
                loan_size_max_int: 5000,
                allowed_collateral: "USDC, ETH",
                interest: "3",
                interest_int: 3,
                installments: 6,
                repayment_type: 1,
                display: true
            },
            {
                asset: "Ethereum",
                ticker: "ETH",
                deposits: "135.1603463",
                asset_img: wETH_32,
                available_for_borrow: 2,
                loan_size_min: "$500",
                loan_size_max: "$15,000",
                loan_size_native_min: "1.3212352",
                loan_size_native_max: "4.3214643",
                loan_size_min_int: 500,
                loan_size_max_int: 15000,
                allowed_collateral: "ETH",
                interest: "5",
                interest_int: 5,
                installments: 10,
                repayment_type: 3,
                display: true
            },
            {
                asset: "Ethereum",
                ticker: "ETH",
                deposits: "135.2386325",
                asset_img: wETH_32,
                available_for_borrow: 1,
                loan_size_min: "$600",
                loan_size_max: "$25,000",
                loan_size_native_min: "52.6543453",
                loan_size_native_max: "75.4663121",
                loan_size_min_int: 600,
                loan_size_max_int: 25000,
                allowed_collateral: "USDC",
                interest: "7",
                interest_int: 7,
                installments: 4,
                repayment_type: 3,
                display: true
            },
            {
                asset: "Ethereum",
                ticker: "ETH",
                deposits: "135.2386325",
                asset_img: wETH_32,
                available_for_borrow: 2,
                loan_size_min: "$1,000",
                loan_size_max: "$6,000",
                loan_size_native_min: "1.5322321",
                loan_size_native_max: "5.3246231",
                loan_size_min_int: 1000,
                loan_size_max_int: 6000,
                allowed_collateral: "USDC",
                interest: "5.5",
                interest_int: 5.5,
                installments: 10,
                repayment_type: 2,
                display: true
            },
            {
                asset: "Ethereum",
                ticker: "ETH",
                deposits: "135.3586325",
                asset_img: wETH_32,
                available_for_borrow: 3,
                loan_size_min: "$5,000",
                loan_size_max: "$15,000",
                loan_size_native_min: "3.2352621",
                loan_size_native_max: "14.0223231",
                loan_size_min_int: 5000,
                loan_size_max_int: 15000,
                allowed_collateral: "BTC",
                interest: "6",
                interest_int: 6,
                installments: 5,
                repayment_type: 2,
                display: true
            },
            {
                asset: "Bitcoin",
                ticker: "BTC",
                deposits: "3.3752346",
                asset_img: wBTC_32,
                available_for_borrow: 4,
                loan_size_min: "$900",
                loan_size_max: "$5,000",
                loan_size_native_min: "12.0232521",
                loan_size_native_max: "24.0236521",
                loan_size_min_int: 900,
                loan_size_max_int: 5000,
                allowed_collateral: "USDC",
                interest: "2",
                interest_int: 2,
                installments: 6,
                repayment_type: 2,
                display: true
            },
            {
                asset: "Bitcoin",
                ticker: "BTC",
                deposits: "3.3752362",
                asset_img: wBTC_32,
                available_for_borrow: 2,
                loan_size_min: "$10,000",
                loan_size_max: "$60,000",
                loan_size_native_min: "1.0235221",
                loan_size_native_max: "23.0223521",
                loan_size_min_int: 10000,
                loan_size_max_int: 60000,
                allowed_collateral: "ETH, BTC",
                interest: "5.5",
                interest_int: 5.5,
                installments: 1,
                repayment_type: 2,
                display: true
            }
        ]);
        const interestTokensList = ref([
            {
                name: 'Bitcoin',
                asset_img: wBTC_32,
                available: 1.3252642,
                display_token: true
            },
            {
                name: 'Ethereum',
                asset_img: wETH_32,
                available: 0,
                display_token: false
            },
            {
                name: 'USDC',
                asset_img: USDC_32,
                available: 0,
                display_token: true
            },
            {
                name: 'Tether',
                asset_img: tether_32,
                available: 0,
                display_token: true
            },

        ]);

        let tabsManagerOpen = ref(false);

        let isModalOpen = ref(false);
        let modalTitle = ref('');
        let modalContent = ref('');
        let modalData = ref(null);

        let isToastOpen = ref(false);
        let toastTitle = ref('');
        let toastContent = ref('');
        let toastData = ref(null);
        let notifications = ref([]);

        // TOAST NOTIFICATIONS REWORK
        let toasts = ref([]);

        let currentStep = ref(1);
        let numSteps = ref(4);

        // Animations
        let isWalletOffersExpanded = ref(true);
        let isWalletBorrowsExpanded = ref(true);

        // Filter Management
        let allMarketFilters = ref(
            {
                marketFilterLoanSize: {
                    in_use: false,
                    selected_min: 100,
                    selected_max: 100000
                },
                marketFilterCollateral: {
                    in_use: false,
                    selected: []
                },
                marketFilterTerms: {
                    in_use: false,
                    selected_min: 1,
                    selected_max: 10
                },
                marketFilterInterest: {
                    in_use: false,
                    selected_min: 2,
                    selected_max: 12
                },
                marketFilterInterestPerTerm: {
                    in_use: false,
                    selected_min: 2,
                    selected_max: 12
                },
                marketFilterRepaymentType: {
                    in_use: false,
                    selected: []
                },
            }
        );
        let loadingOffers = ref(true);
        let filterActive = ref(false);
        let interestAssetAmount = ref(0);
        let principalAssetAmount = ref(0);
        let interestFuturePayoffBar = ref(0);
        let principalFuturePayoffBar = ref(0);
        let healthFactorCalc = ref(0);
        let healthFactorIndicator = ref(0);

        let showMarketFilter = ref(false);
        let marketFilterLoanSize = ref(false);
        let marketFilterCollateral = ref(false);
        let marketFilterTerms = ref(false);
        let marketFilterInterest = ref(false);
        let marketFilterInterestPerTerm = ref(false);
        let marketFilterRepaymentType = ref(false);

        let offerRangeMin = ref(5000);
        let offerRangeMax = ref(45000);
        
        let offerRangeNativeMin = ref(1);
        let offerRangeNativeMax = ref(25);
        
        let termsRangeMin = ref(1);
        let termsRangeMax = ref(10);
        
        let interestRangeMin = ref(1);
        let interestRangeMax = ref(12);

        let loanSizeUnit = ref("USD");

        let available_loan_asset_indicator = ref(100);
        let minimum_loan_indicator = ref(0);
        let maximum_loan_indicator = ref(0);
        let allocated_deposit_indicator = ref(0);

        let collateral_adjustment_option = ref('supply');

        let loanConfigurationValidation = ref({
            principal: false,
            collateral: false,
            interest: false,
            loan_summary: false
        });

        let loanAcceptValidation = ref({
            principal: false,
            collateral: false,
            interest: false,
            loan_summary: false
        });

        let loanAcceptObject = ref({
            configId: null,
            principalAmount: null,
            takingAssetPriceFeedIndex: null,
            collateralAmount: [],
            collateralAssetPriceFeedIndex: [],
        });
  
        // methods
        const startLumin = async () => {
            let provider = new ethers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545');

            // NEW LIB LOGIC
            luminInstance = new LuminClient();
            await luminInstance.init();
            luminInstance.provider = provider;
        };

        const switchNetwork = async () => {
            console.log(unsupportedNetwork.value)
            const provider = window['ethereum'];
            if(!provider) {
                console.log("Metamask is not installed, please install!");
            } else {
                const chainId = await provider.request({ method: 'eth_chainId' });
                const binanceTestChainId = '0x61'
                unsupportedNetwork.value = true;

                console.log(unsupportedNetwork.value)

                if (chainId === binanceTestChainId) {

                console.log("Bravo!, you are on the correct network");

                } else {
                    console.log("oulalal, switch to the correct network")
                    try {

                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: '0x61'}],
                        });
                        console.log("You have succefully switched to Binance Test network")
                        unsupportedNetwork.value = false;

                    } catch (switchError) {

                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === binanceTestChainId) {
                        console.log("This network is not available in your metamask, please add it")
                        }
                        console.log("Failed to switch to the network")

                    }
                }
            }
        };

        const connectWallet = async () => {
            const provider = window['ethereum'];

            if (typeof ethereum.value !== 'undefined') {
                console.log('MetaMask is installed!');
            } else {
                console.log('MetaMask is not installed!');
            }
            
            if (ethereum.value) {
                web3Provider.value = ethereum.value;

                try {
                    // Request account access
                    loaderWorking.value = true;
                    ethereum.value.request({ method: 'eth_requestAccounts' })
                    .then(async (address) => {
                        console.log("Account connected: ", address[0]); // Account address that you had imported
                        console.log("Account addresses: ", address); // Account address that you had

                        walletConnected.value = true;
                        walletString.value = address[0];
                        walletStringShort.value = address[0].substring(0,4) + '...' + address[0].substr(address[0].length - 4);
                        loaderWorking.value = false;

                        // Check if the key exists in local storage
                        if (localStorage.getItem('connected_address')) {
                        // Key exists, update the value
                            localStorage.setItem('connected_address', walletString.value);
                        } else {
                        // Key doesn't exist, set the value
                            localStorage.setItem('connected_address', walletString.value);
                        }

                        // Detect Network on Connect Even
                        const chainId = await provider.request({ method: 'eth_chainId' });
                        if (chainId != '0x61') {
                            unsupportedNetwork.value = true;
                        } else {
                            unsupportedNetwork.value = false;
                        }

                        // Watch Network Changes
                        startChainDetection();
                    })
                    .catch(() => {
                        loaderWorking.value = false;
                    })
                } catch (error) {
                    // User denied account access...
                    loaderWorking.value = false;
                }
            }
        };

        const checkWalletConnection = async () => {
            // Check if the key exists in local storage
            // Key exists, retrieve the value
            let walletValue = localStorage.getItem('connected_address');
            libIsInstantiated = true;

            // console.log(walletValue)

            // FETCH USER DATA ON PROTOCOL LEVEL
            if (walletValue) {
                // Watch Network Changes
                startChainDetection();

                walletConnected.value = true;
                walletString.value = walletValue;
                walletStringShort.value = walletValue.substring(0,4) + '...' + walletValue.substr(walletValue.length - 4);
            }
        };

        const startChainDetection = () => {
            window.ethereum
                .on("chainChanged", (chainId) => {
                    // console.log("Chain Detection Trigger ---------> ", chainId)

                    if (chainId != '0x61') {
                        unsupportedNetwork.value = true;
                    } else {
                        unsupportedNetwork.value = false;
                    }

                })
                .on("accountsChanged", () => {
                    // console.log("User Wallet Changed!")
                    disconnectAddress();
                });

            window.ethereum.request({ method: 'eth_chainId' })
                .then((chainId) => {
                    // console.log("Chain Detection Trigger ---------> ", chainId)

                    if (chainId != '0x61') {
                        unsupportedNetwork.value = true;
                    } else {
                        unsupportedNetwork.value = false;
                    }

                })
                .catch((err) => {
                    console.error(err);
                });
        };

        const disconnectAddress = () => {
            // Remove the key 'connected_address' from local storage
            localStorage.removeItem('connected_address');
            walletConnected.value = false;
            walletString.value = "";
            walletStringShort.value = "";
            userWalletData.value = [];
            UIPreview.value = "markets";
        };
  
        const manageSidebar = () => {
            console.log(mainTab)
            console.log(tabsManagerOpen)

            /* if (mainTab.nativeElement.style.width == '100%')
                mainTab.nativeElement.style.width = '66%'

            else mainTab.nativeElement.style.width = '100%'; */

            tabsManagerOpen.value = !tabsManagerOpen.value;
        };
  
        const showMarket = (market) => {
            console.log(market)
            mainUIheader.value = market;
        };

        const filterMarketOffers = (value, select) => {
            filterActive.value = true;

            for (let offer of offersListDummy.value) {
                offer.display = true;
            }

            if (select == 'collateral') {
                console.log("Picked Collateral", value.target.value)

                loadingOffers.value = true;
                allMarketFilters.value.marketFilterCollateral.selected.length = 0;
                allMarketFilters.value.marketFilterCollateral.selected.push(value.target.value);

                for (let offer of offersListDummy.value) {
                    if (offer.allowed_collateral.includes(value.target.value)) {
                        offer.display = true;
                    } else {
                        offer.display = false;
                    }
                }

                allMarketFilters.value.marketFilterCollateral.in_use = false;
                console.log(allMarketFilters.value.marketFilterCollateral);

                setTimeout(() => {
                    loadingOffers.value = false;
                    marketFilterCollateral.value = false;
                    showMarketFilter.value = true;
                }, 2000);
            }
            if (select == 'repayment_type') {
                console.log("Repayment Type", value.target.value)

                loadingOffers.value = true;
                allMarketFilters.value.marketFilterRepaymentType.selected.length = 0;
                allMarketFilters.value.marketFilterRepaymentType.selected.push(value.target.value);

                for (let offer of offersListDummy.value) {
                    if (offer.repayment_type == value.target.value) {
                        offer.display = true;
                    } else {
                        offer.display = false;
                    }
                }

                allMarketFilters.value.marketFilterRepaymentType.in_use = false;
                console.log(allMarketFilters.value.marketFilterRepaymentType);

                setTimeout(() => {
                    loadingOffers.value = false;
                    marketFilterRepaymentType.value = false;
                    showMarketFilter.value = true;
                }, 2000);

                console.log(showMarketFilter)
            }
            if (select == 'interest') {
                console.log("Picked interest", value.target.value)
                loadingOffers.value = true;

                for (let offer of offersListDummy.value) {
                    if (offer.interest == value.target.value) {
                        offer.display = true;
                    } else {
                        offer.display = false;
                    }
                }

                setTimeout(() => {
                    loadingOffers.value = false;
                }, 2000);
            }
            if (select == 'terms') {
                console.log("Picked instalments", value.target.value)
                loadingOffers.value = true;

                for (let offer of offersListDummy.value) {
                    if (offer.installments == value.target.value) {
                        offer.display = true;
                    } else {
                        offer.display = false;
                    }
                }

                setTimeout(() => {
                    loadingOffers.value = false;
                }, 2000);
            }
        };

        const startMarketOffersFilter = (attr) => {
            console.log(attr)
            console.log(offersListDummy.value)

            if (attr == 'marketFilterLoanSize') {
                if (!marketFilterLoanSize.value) {
                    marketFilterLoanSize.value = true;
                    allMarketFilters.value.marketFilterLoanSize.in_use = true;
                } else {
                    marketFilterLoanSize.value = false;
                    allMarketFilters.value.marketFilterLoanSize.in_use = false;
                }
            }

            if (attr == 'marketFilterCollateral') {
                if (!marketFilterCollateral.value) {
                    marketFilterCollateral.value = true;
                    allMarketFilters.value.marketFilterCollateral.in_use = true;
                } else {
                    marketFilterCollateral.value = false;
                    allMarketFilters.value.marketFilterCollateral.in_use = false;
                }
            }

            if (attr == 'marketFilterTerms') {
                if (!marketFilterTerms.value) {
                    marketFilterTerms.value = true;
                    allMarketFilters.value.marketFilterTerms.in_use = true;
                } else {
                    marketFilterTerms.value = false;
                    allMarketFilters.value.marketFilterTerms.in_use = false;
                }
            }

            if (attr == 'marketFilterInterest') {
                if (!marketFilterInterest.value) {
                    marketFilterInterest.value = true;
                    allMarketFilters.value.marketFilterInterest.in_use = true;
                } else {
                    marketFilterInterest.value = false;
                    allMarketFilters.value.marketFilterInterest.in_use = false;
                }
            }

            if (attr == 'marketFilterInterestPerTerm') {
                if (!marketFilterInterestPerTerm.value) {
                    marketFilterInterestPerTerm.value = true;
                    allMarketFilters.value.marketFilterInterestPerTerm.in_use = true;
                } else {
                    marketFilterInterestPerTerm.value = false;
                    allMarketFilters.value.marketFilterInterestPerTerm.in_use = false;
                }
            }

            if (attr == 'marketFilterRepaymentType') {
                if (!marketFilterRepaymentType.value) {
                    marketFilterRepaymentType.value = true;
                    allMarketFilters.value.marketFilterRepaymentType.in_use = true;
                } else {
                    marketFilterRepaymentType.value = false;
                    allMarketFilters.value.marketFilterRepaymentType.in_use = false;
                }
            }

            // OLD LOGIC
            /* if (attr == 'marketFilterCollateral') {
                if (!marketFilterCollateral.value) {
                    marketFilterCollateral.value = true;
                    allMarketFilters.value.marketFilterCollateral.in_use = true;
                } else {
                    marketFilterCollateral.value = false;
                    allMarketFilters.value.marketFilterCollateral.in_use = false;
                }
            } */

            if (marketFilterLoanSize.value == false && marketFilterCollateral.value == false && marketFilterTerms.value == false && marketFilterInterest.value == false && marketFilterInterestPerTerm.value == false) {
                showMarketFilter.value = false;
            }

            /* 
            marketFilterLoanSize
            marketFilterCollateral
            marketFilterTerms
            marketFilterInterest
            marketFilterInterestPerTerm
            marketFilterRepaymentType
            */
        };

        const resetMarketOffersFilter = () => {
            filterActive.value = false;
            loadingOffers.value = true;
            console.log(offersListDummy.value)

            for (let offer of offersListDummy.value) {
                console.log(offer)
                offer.display = true;
            }

            setTimeout(() => {
                loadingOffers.value = false;
            }, 2000);
        };

        const resetMarketOffersFilterBy = (param) => {
            loadingOffers.value = true;

            if (param == 'marketFilterCollateral') {
                allMarketFilters.value.marketFilterCollateral.selected.length = 0;
                allMarketFilters.value.marketFilterCollateral.in_use = false;
                marketFilterCollateral.value = false;

                // CROSS CHECK IF OTHER FILTERS ARE ACTIVE !!!
                for (let offer of offersListDummy.value) {
                    console.log(offer)
                    offer.display = true;
                }
            }

            if (param == 'marketFilterRepaymentType') {
                allMarketFilters.value.marketFilterRepaymentType.selected.length = 0;
                allMarketFilters.value.marketFilterRepaymentType.in_use = false;
                marketFilterRepaymentType.value = false;

                // CROSS CHECK IF OTHER FILTERS ARE ACTIVE !!!
                for (let offer of offersListDummy.value) {
                    console.log(offer)
                    offer.display = true;
                }
            }

            // Set filterActive.value = false if every object in allMarketFilters.value.marketFilterCollateral.in_use is false
            const areAllInactive = Object.values(allMarketFilters.value).every(filter => !filter.active);
            if (areAllInactive) {
                filterActive.value = false;
            }

            setTimeout(() => {
                loadingOffers.value = false;
            }, 2000);
        };

        const setFilterLoanSize = (input, direction) => {
            console.log("Set Filter Loan Size")
            
            if (input == 'max') {
                if (direction == 'up') allMarketFilters.value.marketFilterLoanSize.selected_max += 100;
                if (direction == 'down') allMarketFilters.value.marketFilterLoanSize.selected_max -= 100;
            }

            if (input == 'min') {
                if (direction == 'up') allMarketFilters.value.marketFilterLoanSize.selected_min += 100;
                if (direction == 'down') allMarketFilters.value.marketFilterLoanSize.selected_min -= 100;
            }
        };

        const setFilterTerms = (input, direction) => {
            console.log("Set Filter Terms")
            
            if (input == 'max') {
                if (direction == 'up') allMarketFilters.value.marketFilterTerms.selected_max += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterTerms.selected_max -= 1;
            }

            if (input == 'min') {
                if (direction == 'up') allMarketFilters.value.marketFilterTerms.selected_min += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterTerms.selected_min -= 1;
            }
        };

        const setFilterInterest = (input, direction) => {
            console.log("Set Filter Interest")
            
            if (input == 'max') {
                if (direction == 'up') allMarketFilters.value.marketFilterInterest.selected_max += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterInterest.selected_max -= 1;
            }

            if (input == 'min') {
                if (direction == 'up') allMarketFilters.value.marketFilterInterest.selected_min += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterInterest.selected_min -= 1;
            }
        };

        const setFilterInterestPerTerm = (input, direction) => {
            console.log("Set Filter Interest Per Term")
            
            if (input == 'max') {
                if (direction == 'up') allMarketFilters.value.marketFilterInterestPerTerm.selected_max += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterInterestPerTerm.selected_max -= 1;
            }

            if (input == 'min') {
                if (direction == 'up') allMarketFilters.value.marketFilterInterestPerTerm.selected_min += 1;
                if (direction == 'down') allMarketFilters.value.marketFilterInterestPerTerm.selected_min -= 1;
            }
        };

        const showProfile = (wallet) => {
            console.log(wallet)
            UIPreview.value = "profile";
            mainUIheader.value = wallet;
        };
  
        const backToAllMarkets = () => {
            mainUIheader.value = "";
            UIPreview.value = "markets";
        };

        // MODAL LOGIC
        const openModal = async (title, paragraph, data) => {
            isModalOpen.value = true;

            modalTitle.value = title;
            modalContent.value = paragraph;
            modalData.value = JSON.parse(JSON.stringify(data));
            modalData.value['borrowing_amount'] = 0;

            console.log("MODAL DATA", modalData.value);
            console.log("MODAL OFFER CONFIG ID", ethers.parseUnits((modalData.value.loan_vanilla_data.id).toString(), 'wei'));

            let temp_provider = new ethers.BrowserProvider(window['ethereum']);
            const signer = await temp_provider.getSigner();
            const walletStats = await luminInstance.assetManager.balancesOf(signer.address);
            const assets = await luminInstance.backend.assets();

            console.log("USER WALLET DATA ------------->", walletStats);

            // PREPARING USER DATA FOR MODAL
            for (let libAsset of assets) {
                for (let usrAsset of walletStats) {
                    if (libAsset.id == usrAsset.assetId) {

                        usrAsset['allowed'] = null;
                        usrAsset['id'] = libAsset.symbol;
                        usrAsset['ticker'] = libAsset.symbol;
                        usrAsset['decimals'] = libAsset.decimals;
                        usrAsset['contract'] = libAsset.address;
                        usrAsset['processing_tx'] = false;
                        usrAsset['available'] = parseInt(ethers.formatUnits(usrAsset.balance, libAsset.decimals));
                        usrAsset['deposit'] = parseInt(ethers.formatUnits(usrAsset.user.total, libAsset.decimals));
                        usrAsset['deposit_lock'] = parseInt(ethers.formatUnits(usrAsset.user.locked, libAsset.decimals));
                        usrAsset['lib_asset'] = libAsset;

                        if (libAsset.symbol == 'ETH') {
                            usrAsset['name'] = 'Ethereum';
                            usrAsset['asset'] = 'Ethereum';
                            usrAsset['image'] = wETH_32;
                            usrAsset['available'] = usrAsset.available.toFixed(7);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                        if (libAsset.symbol == 'BTC') {
                            usrAsset['name'] = 'Bitcoin';
                            usrAsset['asset'] = 'Bitcoin';
                            usrAsset['image'] = wBTC_32;
                            usrAsset['available'] = usrAsset.available.toFixed(7);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(7);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(7);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                        if (libAsset.symbol == 'USDC') {
                            usrAsset['name'] = 'USD Coin';
                            usrAsset['asset'] = 'USD Coin';
                            usrAsset['image'] = USDC_32;
                            usrAsset['available'] = usrAsset.available.toFixed(2);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                        if (libAsset.symbol == 'USDT') {
                            usrAsset['name'] = 'Tether';
                            usrAsset['asset'] = 'Tether';
                            usrAsset['image'] = tether_32;
                            usrAsset['available'] = usrAsset.available.toFixed(2);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                        if (libAsset.symbol == 'MOCK') {
                            usrAsset['name'] = 'Mock';
                            usrAsset['asset'] = 'Mock';
                            usrAsset['image'] = LUMIN_grey;
                            usrAsset['available'] = usrAsset.available.toFixed(2);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                        if (libAsset.symbol == 'SMOCK') {
                            usrAsset['name'] = 'SMock';
                            usrAsset['asset'] = 'SMock';
                            usrAsset['image'] = LUMIN_grey;
                            usrAsset['available'] = usrAsset.available.toFixed(2);
                            usrAsset['deposit'] = usrAsset.deposit.toFixed(2);
                            usrAsset['deposit_lock'] = usrAsset.deposit_lock.toFixed(2);

                            luminInstance.assetManager.getPrice(libAsset).then((price) => {
                                usrAsset['price'] = parseInt(formatUnits(price[0].value, usrAsset.decimals));
                            });
                        }
                    }
                }
            }

            console.log("assetsActiveOracles -------------->", assetsActiveOracles.value)

            for (let coll of modalData.value.loan_vanilla_data.acceptedAssets) {
                if (coll.asset.symbol == 'BTC') {
                    coll['asset_img'] = wBTC_32;
                    coll['name'] = 'Bitcoin';
                    coll['ticker'] = 'BTC';
                    coll['price'] = bitcoinPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'BTC').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
                if (coll.asset.symbol == 'ETH') {
                    coll['asset_img'] = wETH_32;
                    coll['name'] = 'Ethereum';
                    coll['ticker'] = 'ETH';
                    coll['price'] = ethereumPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'ETH').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
                if (coll.asset.symbol == 'USDC') {
                    coll['asset_img'] = USDC_32;
                    coll['name'] = 'USD Coin';
                    coll['ticker'] = 'USDC';
                    coll['price'] = usdcPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'USDC').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
                if (coll.asset.symbol == 'USDT') {
                    coll['asset_img'] = tether_32;
                    coll['name'] = 'Tether';
                    coll['ticker'] = 'USDT';
                    coll['price'] = usdtPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'USDT').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
                if (coll.asset.symbol == 'MOCK') {
                    coll['asset_img'] = luminIconPng;
                    coll['name'] = 'Mock';
                    coll['ticker'] = 'MOCK';
                    coll['price'] = mockPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'MOCK').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
                if (coll.asset.symbol == 'SMOCK') {
                    coll['asset_img'] = luminIconPng;
                    coll['name'] = 'SMock';
                    coll['ticker'] = 'SMOCK';
                    coll['price'] = smockPrice.value;
                    coll['amount_to_use'] = 0;
                    coll['amount_avaliable'] = walletStats.find((asset) => asset.id == 'SMOCK').deposit;
                    // FIND ASSET INSIDE assetsActiveOracles THAT IS EQUAL TO coll.asset.symbol
                    const assetFeeds = assetsActiveOracles.value.find((asset) => asset.symbol == coll.ticker);
                    console.log("assetFeeds -------------->", assetFeeds)
                    coll.acceptedPriceFeeds = assetFeeds.priceFeeds;

                    for (let feed of coll.acceptedPriceFeeds) {
                        feed['description'] = feed['proxy']['description'];
                        feed['use_oracle'] = false;
                    }
                }
            }

            principalAssetFeeds.value = modalData.value.loan_vanilla_data.acceptedPriceFeeds;
            for (let principalFeed of principalAssetFeeds.value) {
                principalFeed['enabled'] = false;
            }

            // LOAN CONFIG ID
            loanAcceptObject.value.configId = ethers.parseUnits((modalData.value.loan_vanilla_data.id).toString(), 'wei');
            // LOAN BORROWING AMOUNT
            loanAcceptObject.value.principalAmount = modalData.value.borrowing_amount;
            // LOAN COLLATERAL & INTEREST AMOUNTS AND ORACLES INITIAL SET
            // modalData.value.loan_vanilla_data.acceptedAssets.amount_to_use are going as array into loanAcceptObject.value.collateralAmount
            // modalData.value.loan_vanilla_data.acceptedAssets.acceptedPriceFeeds when enabled their IDs are going as array into loanAcceptObject.value.collateralAssetPriceFeedIndex
            // Initialy set all oracles data inside array to null
            for (let asset of modalData.value.loan_vanilla_data.acceptedAssets) {
                loanAcceptObject.value.collateralAmount.push(asset.amount_to_use);
                loanAcceptObject.value.collateralAssetPriceFeedIndex.push(null);
            }
            console.log("INITIAL LOAN BLUEPRINT DATA", loanAcceptObject.value);

            loadingOracleFeeds.value = false;
        };

        const principalAssetOracleChange = (event, oracle) => {
            console.log(event)
            console.log(oracle)

            for (let feed of principalAssetFeeds.value) {
                feed.use_oracle = false;
            }
            oracle.use_oracle = event.target.checked;
            loanAcceptObject.value.takingAssetPriceFeedIndex = parseInt(oracle.id);
        };

        const collateralAssetOracleChange = (event, oracleList, index, parent_index) => {
            console.log(event)
            console.log(oracleList)
            console.log(index)
            console.log(parent_index)

            for (let feed of oracleList.acceptedPriceFeeds) {
                feed.use_oracle = false;
            }
            console.log(loanAcceptObject.value)
            oracleList.acceptedPriceFeeds[index].use_oracle = event.target.checked;
            // loanAcceptObject.value.collateralAssetPriceFeedIndex = oracle.id at right index
            loanAcceptObject.value.collateralAssetPriceFeedIndex[parent_index] = ethers.parseUnits((oracleList.acceptedPriceFeeds[index].priceFeedIndex).toString(), 'wei');
            console.log(loanAcceptObject.value)
        };

        const interestAssetOracleChange = (event, oracleList, oracle) => {
            console.log(event)
            console.log(oracleList)
            console.log(oracle)

            for (let feed of oracleList) {
                feed.use_oracle = false;
            }
            oracle.use_oracle = event.target.checked;
            loanAcceptObject.value.takingAssetPriceFeedIndex = oracle.id;
        };

        // MODAL ALLOWANCE LOGIC
        const setAllowance = async () => {
            modalData.value.processing_tx = true;

            let temp_provider = new ethers.BrowserProvider(window['ethereum']);
            const signer = await temp_provider.getSigner();

            let erc20Contract = new ethers.Contract(
                modalData.value.contract,
                ERC20,
                signer
            );

            const luminContracts = await luminInstance.backend.contracts();
            // AssetManger contract is the second contract in the array 
            const luminManagerAddress = luminContracts.find((contract) => contract.name == "AssetManager").address;
            console.log(luminManagerAddress);

            try {
                const tx = await erc20Contract.approve(
                    luminManagerAddress,
                    ethers.parseUnits(modalData.value.deposit_amount.toString(), modalData.value.decimals)
                );

                // waitForTransaction FROM wagmi library
                const waitTransactionEnd = waitForTransaction({
                    chainId: 97,
                    hash: tx.hash
                });

                // IF WE GET HASH SHOW MESSAGE THAT APPROVAL IS PROCESSING
                if (tx.hash) {
                    openToast("Approval is being processed");
                }

                // AFTER TRANSACTION IS MINED SHOW MESSAGE THAT APPROVAL IS FINISHED
                waitTransactionEnd.then(res => {
                    openToast("Approval is finished");
                    modalData.value.processing_tx = false;
                    modalData.value.allowed = modalData.value.deposit_amount;
                })
            } catch (error) {
                console.log(error);
                modalData.value.processing_tx = false;
            }
        };

        const assetDeposit = async () => {
            console.log("Deposit Asset")
            console.log(modalData.value.deposit)
            console.log(modalData)
            modalData.value.processing_tx = true;

            let temp_provider = new ethers.BrowserProvider(window['ethereum']);
            const signer = await temp_provider.getSigner();

            const assets = await luminInstance.backend.assets();
            const depositAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
            const depositAmount = ethers.parseUnits(modalData.value.deposit_amount.toString(), modalData.value.decimals);

            const depositAsset0Transaction = await luminInstance.assetManager?.deposit(depositAsset, depositAmount);
            console.log(depositAsset0Transaction);
            depositAsset0Transaction.validate(walletString.value)
                .then(() => {
                    console.log("Transaction is valid");
                    try {
                        depositAsset0Transaction.transaction
                            .then(async tx => {
                                console.log(tx);

                                try {
                                    const ongoingTx = await signer.sendTransaction(tx);
                                    console.log(ongoingTx);
                                    
                                    const waitTransactionEnd = waitForTransaction({
                                        chainId: 97,
                                        hash: ongoingTx?.hash
                                    });

                                    if (ongoingTx?.hash) {
                                        openToast("Your deposit is being processed");
                                        isModalOpen.value = false;
                                        modalData.value.processing_tx = false;
                                    }

                                    waitTransactionEnd.then(res => {
                                        openToast("Deposit arrived on the protocol");
                                        loadingUserAsset.value = true;
                                        setTimeout(() => {
                                            let walletValue = localStorage.getItem('connected_address');
                                            loadingUserAsset.value = false;
                                        }, 3000);
                                    })
                                } catch (error) {
                                    console.log(error);
                                    modalData.value.processing_tx = false;
                                }
                            })
                    } catch (error) {
                        console.log(error);
                        modalData.value.processing_tx = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    modalData.value.processing_tx = false;
                })
        };

        const assetWithdraw = async () => {
            console.log("Withdraw Asset")
            console.log(modalData.value.deposit)
            console.log(modalData)
            modalData.value.processing_tx = true;

            let temp_provider = new ethers.BrowserProvider(window['ethereum']);
            const signer = await temp_provider.getSigner();

            const assets = await luminInstance.backend.assets();
            const withdrawAsset = assets.find((asset) => asset.symbol == modalData.value.ticker);
            const withdrawAmount = ethers.parseUnits(modalData.value.withdraw_amount.toString(), modalData.value.decimals);

            const withdrawAsset0Transaction = await luminInstance.assetManager?.withdraw(withdrawAsset, withdrawAmount);
            console.log(withdrawAsset0Transaction);
            withdrawAsset0Transaction.validate(walletString.value)
                .then(() => {
                    console.log("Transaction is valid");
                    try {
                        withdrawAsset0Transaction.transaction
                            .then(async tx => {
                                console.log(tx);

                                try {
                                    const ongoingTx = await signer.sendTransaction(tx);
                                    console.log(ongoingTx);

                                    const waitTransactionEnd = waitForTransaction({
                                        chainId: 97,
                                        hash: ongoingTx?.hash
                                    });

                                    if (ongoingTx?.hash) {
                                        openToast("Your withdraw is being processed");
                                        isModalOpen.value = false;
                                        modalData.value.processing_tx = false;
                                    }

                                    waitTransactionEnd.then(res => {
                                        openToast("Withdrawal is completed");
                                        loadingUserAsset.value = true;
                                        setTimeout(() => {
                                            let walletValue = localStorage.getItem('connected_address');
                                            loadingUserAsset.value = false;
                                        }, 3000);
                                    })
                                } catch (error) {
                                    console.log(error);
                                    modalData.value.processing_tx = false;
                                }
                            })
                    } catch (error) {
                        console.log(error);
                        modalData.value.processing_tx = false;
                    }
                })
        };

        const validateDepositInputs = () => {
            if (
                modalData.value.deposit_amount > 0.001 ) {
                return false;
            } else {
                return true;
            }
        };

        const validateWithdrawInputs = () => {
            if (
                modalData.value.withdraw_amount > 0 ) {
                return false;
            } else {
                return true;
            }
        };

        const nextStep = () => {
            currentStep.value++;

            if (currentStep.value > numSteps.value) {
                currentStep.value = 1;
            }
        };

        const stepClasses = (index) => {
            const stepNum = index + 1;

            return {
                editing: stepNum === currentStep.value,
                done: stepNum < currentStep.value,
            };
        };

        const navigateLoanDetailTabs = (direction) => {
            if (direction == 'next') {
                if (loanDetailDialog.value == 'summary') loanDetailDialog.value = 'manage-principal';
                if (loanDetailDialog.value == 'manage-principal') loanDetailDialog.value = 'manage-interest';
                if (loanDetailDialog.value == 'manage-interest') loanDetailDialog.value = 'summary';
            }
            if (direction == 'prev') {
                if (loanDetailDialog.value == 'summary') loanDetailDialog.value = 'manage-interest';
                if (loanDetailDialog.value == 'manage-principal') loanDetailDialog.value = 'summary';
                if (loanDetailDialog.value == 'manage-interest') loanDetailDialog.value = 'manage-principal';
            }
        };

        // LOAN TAKING VALIDATION LOGIC
        const validateFirstStep = () => {
            if (modalData.value.borrowing_amount > 0 && loanAcceptObject.value.takingAssetPriceFeedIndex != null) {
                return true;
            } else {
                return false;
            }
        };

        const validateSecondStep = () => {
            if (
                modalData.value.loan_vanilla_data.acceptedAssets.some((asset) => asset.amount_to_use > 0) && 
                modalData.value.loan_vanilla_data.acceptedAssets.some((asset) => asset.acceptedPriceFeeds.some((feed) => feed.use_oracle == true)) &&
                healthFactorCalc.value > 130
            ) {
                return true;
            } else {
                return false;
            }
        };

        const validateThirdStep = () => {
            /* if (loanAcceptObject.value.interestPercentage > 0 
                && loanAcceptObject.value.terms > 0
                && repaymentOptions.value.some((option) => option.checked == true)) { */
                return true;
            /* } else {
                return false;
            } */
        };

        const loanTaking = async () => {
            console.log(loanAcceptObject.value);

            let temp_provider = new ethers.BrowserProvider(window['ethereum']);
            const signer = await temp_provider.getSigner();
            modalData.value.processing_tx = true;
            
            // TAKE CONFIG ID OUT OF THE LOAN ACCEPT OBJECT
            const configId = loanAcceptObject.value.configId;
            const takingAssetPriceFeedIndex = loanAcceptObject.value.takingAssetPriceFeedIndex;

            console.log("PARAM 1 ----------->", configId);
            console.log("PARAM 2 ----------->", loanAcceptObject.value.principalAmount);
            console.log("PARAM 3 ----------->", takingAssetPriceFeedIndex);
            console.log("PARAM 4 ----------->", loanAcceptObject.value.collateralAmount);
            console.log("PARAM 5 ----------->", loanAcceptObject.value.collateralAssetPriceFeedIndex);

            const takeLoanTransaction = luminInstance.loanManagerDelegator?.createLoan(
                configId, 
                loanAcceptObject.value.principalAmount, 
                takingAssetPriceFeedIndex, 
                loanAcceptObject.value.collateralAmount,
                loanAcceptObject.value.collateralAssetPriceFeedIndex
            );
            console.log("takeLoanTransaction ------------>", takeLoanTransaction);
            if (takeLoanTransaction) {
                console.log('About to validate');
                takeLoanTransaction.validate(walletString.value)
                    .then(() => {
                        console.log("Transaction is valid");
                        try {
                            takeLoanTransaction.transaction
                                .then(async tx => {
                                    console.log(tx);

                                    // ESTIMATE GAS TX COST
                                    const gasEstimate = await temp_provider.getFeeData();
                                    console.log("ESTIMATE GAS TX COST ------------>", gasEstimate);
                                    /* tx['gasLimit'] = 10000000; */

                                    try {
                                        const ongoingTx = await signer.sendTransaction(tx);
                                        console.log(ongoingTx);
                                        
                                        const waitTransactionEnd = waitForTransaction({
                                            chainId: 97,
                                            hash: ongoingTx?.hash
                                        });

                                        if (ongoingTx?.hash) {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "Transaction Pending", 
                                                content: "Offer is being processed",
                                                icon: "fa fa-info", 
                                                color: "grey"
                                            });
                                            isModalOpen.value = false;
                                            modalData.value.processing_tx = false;
                                        }

                                        waitTransactionEnd.then(res => {
                                            newToastMessage({
                                                isToastOpen: true,
                                                title: "New Loan", 
                                                content: "Offer is taken successfully", 
                                                icon: "fa fa-check", 
                                                color: "green"
                                            });
                                            loadingOffers.value = true;
                                            setTimeout(() => {
                                                let walletValue = localStorage.getItem('connected_address');
                                                // REFETCH MARKET OFFERS
                                                marketOffersData.refetch();
                                                loadingOffers.value = false;
                                            }, 4000);
                                        })
                                    } catch (error) {
                                        const errorDescription = takeLoanTransaction.errorDescription(error.data);
                                        console.log(errorDescription);
                                        newToastMessage({
                                            isToastOpen: true,
                                            title: "Validation Error", 
                                            content: errorDescription?.name,
                                            icon: "fa fa-close", 
                                            color: "red"
                                        });
                                        console.log(tx);
                                        console.log(error);
                                        modalData.value.processing_tx = false;
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    console.log(error?.message);
                                    modalData.value.processing_tx = false;
                                })
                        } catch (error) {
                            console.log(error);
                            newToastMessage({
                                isToastOpen: true,
                                title: "Validation Error", 
                                content: error?.message,
                                icon: "fa fa-close", 
                                color: "red"
                            });
                            modalData.value.processing_tx = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error?.message);
                        modalData.value.processing_tx = false;

                        newToastMessage({
                            isToastOpen: true,
                            title: "Error",
                            content: error?.message, 
                            icon: "fa fa-close", 
                            color: "red"
                        });
                    })
            }
        };

        // Toast logic
        const openToast = async (title, paragraph, data) => {
            isToastOpen.value = true;
            toastTitle.value = title;

            console.log(toastTitle.value);
            console.log(toastContent.value);
            console.log(toastData.value);

            setTimeout(() => {
                toastTitle.value = "Deposit is on the way....";
                isToastOpen.value = null;
                isToastOpen.value = false;
            }, 4000);
        };

        const newToastMessage = ({title: title, content: content, icon: icon, color: color}) => {
            // NEW TOAST ARRAY LOGIC
            const newToast = {
                isToastOpen: true,
                toastTitle: title,
                toastContent: content,
                icon: icon,
                color: color
                /* icon: "fa fa-check",
                color: "green" */
            };

            toasts.value.push(newToast);
            const toastIndex = toasts.value.length - 1;
            setTimeout(() => {
                toasts.value[toastIndex].isToastOpen = false;
            }, 5000);
        };

        const fetchCoinGeckoData = async () => {
            loadingAssetDollarValues.value = true;
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd');
                const data = await response.json();

                bitcoinPrice.value = data.bitcoin.usd;
                ethereumPrice.value = data.ethereum.usd;
                usdcPrice.value = data['usd-coin'].usd;
                usdtPrice.value = data.tether.usd;

                /* console.log('Bitcoin Price:', bitcoinPrice);
                console.log('Ethereum Price:', ethereumPrice);
                console.log('USDC Price:', usdcPrice);
                console.log('USDT Price:', usdtPrice); */

                all_assets_deposits_usd.value = 0;
                all_assets_borrows_usd.value = 0;

                /* for (let asset of marketsDetails.value) {
                    console.log(asset)
                    if (asset.asset == 'Bitcoin') {
                        all_assets_deposits_usd.value += parseFloat(asset.deposits * bitcoinPrice.value);
                        all_assets_borrows_usd.value += parseFloat(asset.borrows * bitcoinPrice.value);
                    }
                    if (asset.asset == 'Ethereum') {
                        all_assets_deposits_usd.value += parseFloat(asset.deposits * ethereumPrice.value);
                        all_assets_borrows_usd.value += parseFloat(asset.borrows * ethereumPrice.value);
                    }
                    if (asset.asset == 'USDC') {
                        all_assets_deposits_usd.value += parseFloat(asset.deposits * usdcPrice.value);
                        all_assets_borrows_usd.value += parseFloat(asset.borrows * usdcPrice.value);
                    }
                    if (asset.asset == 'USDT') {
                        all_assets_deposits_usd.value += parseFloat(asset.deposits * usdtPrice.value);
                        all_assets_borrows_usd.value += parseFloat(asset.borrows * usdtPrice.value);
                    }
                } */

                loadingAssetDollarValues.value = false;
            } catch (error) {
                console.log(error);
            }
        };

        const fetchDiaData = async () => {
            loadingAssetDollarValues.value = true;
            try {
                const responseEth = await fetch('https://api.diadata.org/v1/quotation/ETH');
                const responseBtc = await fetch('https://api.diadata.org/v1/quotation/BTC');
                const responseUSDC = await fetch('https://api.diadata.org/v1/quotation/USDC');
                const responseUSDT = await fetch('https://api.diadata.org/v1/quotation/USDT');
                
                Promise.all([responseEth, responseBtc, responseUSDC, responseUSDT])
                .then(async (responses) => {
                    const dataEth = await responses[0].json();
                    const dataBtc = await responses[1].json();
                    const dataUSDC = await responses[2].json();
                    const dataUSDT = await responses[3].json();

                    ethereumPrice.value = dataEth.Price;
                    bitcoinPrice.value = dataBtc.Price;
                    usdcPrice.value = dataUSDC.Price;
                    usdtPrice.value = dataUSDT.Price;

                    /* console.log('Bitcoin Price:', bitcoinPrice.value);
                    console.log('Ethereum Price:', ethereumPrice.value);
                    console.log('USDC Price:', usdcPrice.value);
                    console.log('USDT Price:', usdtPrice.value); */

                    all_assets_deposits_usd.value = 0;
                    all_assets_borrows_usd.value = 0;

                    /* for (let asset of marketsDetails.value) {
                        console.log(asset)
                        if (asset.asset == 'Bitcoin') {
                            all_assets_deposits_usd.value += parseFloat(asset.deposits * bitcoinPrice.value);
                            all_assets_borrows_usd.value += parseFloat(asset.borrows * bitcoinPrice.value);
                        }
                        if (asset.asset == 'Ethereum') {
                            all_assets_deposits_usd.value += parseFloat(asset.deposits * ethereumPrice.value);
                            all_assets_borrows_usd.value += parseFloat(asset.borrows * ethereumPrice.value);
                        }
                        if (asset.asset == 'USDC') {
                            all_assets_deposits_usd.value += parseFloat(asset.deposits * usdcPrice.value);
                            all_assets_borrows_usd.value += parseFloat(asset.borrows * usdcPrice.value);
                        }
                        if (asset.asset == 'USDT') {
                            all_assets_deposits_usd.value += parseFloat(asset.deposits * usdtPrice.value);
                            all_assets_borrows_usd.value += parseFloat(asset.borrows * usdtPrice.value);
                        }
                    } */

                    loadingAssetDollarValues.value = false;
                })
            } catch (error) {
                console.log(error);
            }
        };

        const formatPrice = (asset, value, decimals = 0) => {
            if (asset == 'ETH') {
                let val = (value * ethereumPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                return val
            }
            if (asset == 'BTC') {
                let val = (value * bitcoinPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDC') {
                let val = (value * usdcPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'USDT') {
                let val = (value * usdtPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'MOCK') {
                let val = (value * mockPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == 'SMOCK') {
                let val = (value * smockPrice.value).toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
            if (asset == '') {
                let val = value.toFixed(decimals);
                val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: decimals}).format(val).replace(/^(\D+)/, '$ ')
                /* , minimumFractionDigits: 0  */
                return val
            }
        };

        const cumulativeCollateralAssetsAmount = () => {
            if (modalData.value.loan_vanilla_data) {
                console.log("modalData.value: ", modalData.value);
                let cumulativeDollarValueCollateralAmount = 0;

                // INTEREST AMOUNT TO PLAY SHOULD BE IN NATIVE ASSET
                // FIRST DISCOVER DOLLAR AMOUNTS OF modalData.value.vanilla_config_data.acceptedAssets THAT ARE USED FOR INTEREST PAYMENT
                // AND THEN RECALCULATE THAT DOLLAR AMOUNT TO NATIVE ASSET
                for (let asset of modalData.value.loan_vanilla_data.acceptedAssets) {
                    if (asset.useCollateral == true) {
                        if (asset.ticker == 'USDC') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'USDT') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'BTC') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'ETH') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'MOCK') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                        if (asset.ticker == 'SMOCK') {
                            cumulativeDollarValueCollateralAmount += parseInt(asset.amount_to_use) * asset.price;
                        }
                    }
                }
                // RECALCULATION INTO NATIVE ASSET AMOUNT
                console.log("Cumulative Dollar Value Collateral Amount:", cumulativeDollarValueCollateralAmount);
                return formatPrice('', cumulativeDollarValueCollateralAmount);
            }
        };

        const fetchLuminPriceData = async () => {
            loadingAssetDollarValues.value = true;
            const assets = await luminInstance?.backend.assets();
            /* console.log(assets); */

            for (let libAsset of assets) {
                luminInstance.assetManager.getPrice(libAsset).then((price) => {
                    /* console.log(price); */

                    /* ethereumPrice.value = dataEth.Price;
                    bitcoinPrice.value = dataBtc.Price;
                    usdcPrice.value = dataUSDC.Price;
                    usdtPrice.value = dataUSDT.Price; */

                    if (libAsset.symbol == 'ETH') {
                        ethereumPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'BTC') {
                        bitcoinPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'USDC') {
                        usdcPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'USDT') {
                        usdtPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'MOCK') {
                        mockPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }
                    if (libAsset.symbol == 'SMOCK') {
                        smockPrice.value = parseInt(formatUnits(price[0].value, price.decimals));
                    }

                    /* console.log('Bitcoin Price:', bitcoinPrice.value);
                    console.log('Ethereum Price:', ethereumPrice.value);
                    console.log('USDC Price:', usdcPrice.value);
                    console.log('USDT Price:', usdtPrice.value);
                    console.log('MOCK Price:', mockPrice.value);
                    console.log('SMOCK Price:', smockPrice.value); */
                });
                loadingAssetDollarValues.value = false;
            }
        };

        const changePriceSource = (source) => {
            if (source == 'dia') fetchDiaData();
            if (source == 'coingecko') fetchCoinGeckoData();
        }

        const rangeSliderPrincipal = (value, max) => {
            const start = 0;
            const end = max;
            const percentage = (value - start) / (end - start) * 100;
            // console.log(percentage)

            const slider = document.getElementById('rangeSliderPrincipal');
            if (slider) {
                // console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 ${percentage}%, #E8E600 ${percentage}%, #808080 0%, #808080 100%)`;
            }
            // SETTING LOAN BORROWING AMOUNT
            const valueString = value.toString();
            loanAcceptObject.value.principalAmount = ethers.parseUnits(valueString, modalData.value.decimals);
        }

        const rangeSliderCollateral = (value, max, index) => {
            const start = 0;
            const end = max;
            const percentage = (value - start) / (end - start) * 100;
            // console.log(percentage)

            const slider = document.getElementById('rangeSliderCollateral-' + index);
            if (slider) {
                // console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 ${percentage}%, #E8E600 ${percentage}%, #808080 0%, #808080 100%)`;
            }
            // SETTING COLLATERAL AMOUNT AT RIGHT INDEX INSIDE loanAcceptObject.value.collateralAmount
            const valueString = value.toString();
            loanAcceptObject.value.collateralAmount[index] = ethers.parseUnits(valueString, modalData.value.loan_vanilla_data.acceptedAssets[index].asset.decimals);
            /* modalData.value.loan_vanilla_data.acceptedAssets[index].amount_to_use = value; */
        }

        const rangeSlide = (value, max) => {
            const start = 0;
            const end = max;
            const percentage = (value - start) / (end - start) * 100;
            // console.log(percentage)

            const slider = document.getElementById('rangeSlider');
            if (slider) {
                // console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 ${percentage}%, #E8E600 ${percentage}%, #808080 0%, #808080 100%)`;
            }
        }

        const setMaxAmount = () => {
            if (modalTitle.value == 'DEPOSIT') modalData.value.deposit_amount = modalData.value.available;
            if (modalTitle.value == 'WITHDRAW') modalData.value.withdraw_amount = modalData.value.deposit;
            if (modalTitle.value == 'OFFER PREVIEW') modalData.value.borrowing_amount = modalData.value.available_for_borrow;
            
            const slider = document.getElementById('rangeSlider');
            if (slider) {
                console.log(slider)
                slider.style.background = `linear-gradient(to right, #E8E600 100%, #E8E600 100%, #808080 0%, #808080 100%)`;
            }
        }

        const rangeSlideInterest = (value) => {
            console.log(value)
            // Calculate future payoff percentage
            const start = 0;
            const end = 122.52;
            const percentage = (value - start) / (end - start) * 100;
            interestFuturePayoffBar.value = percentage;
        }

        const rangeOfferAmounts = () => {
            const lowerSlider = document.getElementById('lower');
            const upperSlider = document.getElementById('upper');

            if (lowerSlider.value > upperSlider.value) {
                upperSlider.value += 500;
            }

            if (upperSlider.value < lowerSlider.value) {
                lowerSlider.value -= 500;
            }

            /* console.log(lowerSlider.value)
            console.log(upperSlider.value) */

            /* const start = 0;
            const end = max;

            const percentage = (value - start) / (end - start) * 100;
            console.log(percentage) */
        }

        const rangeOfferAmountFinal = (pointer) => {
            if (pointer == 'amount') {
                const lowerSlider = document.getElementById('lowerAmount');
                const upperSlider = document.getElementById('upperAmount');

                if (lowerSlider.value > upperSlider.value - 500) {
                    offerRangeMin.value = upperSlider.value + 500;
                }

                if (upperSlider.value < lowerSlider.value + 500) {
                    offerRangeMax.value = lowerSlider.value - 500;
                }

                console.log(lowerSlider.value)
                console.log(upperSlider.value)
            }

            if (pointer == 'nativeAmount') {
                const lowerSlider = document.getElementById('lowerNativeAmount');
                const upperSlider = document.getElementById('upperNativeAmount');

                if (lowerSlider.value > upperSlider.value - 500) {
                    upperSlider.value = upperSlider.value + 500;
                }

                if (upperSlider.value < lowerSlider.value + 500) {
                    lowerSlider.value = lowerSlider.value - 500;
                }

                console.log(lowerSlider.value)
                console.log(upperSlider.value)
            }

            /* const start = 0;
            const end = max;

            const percentage = (value - start) / (end - start) * 100;
            console.log(percentage) */
        }

        const switchLoanSizeUnit = () => {
            const switchIcon = document.getElementsByClassName('native-currency-switch');
            console.log(switchIcon[0])
            switchIcon[0].style.transform = `rotate(180deg)`;

            if (loanSizeUnit.value != 'USD') {
                loanSizeUnit.value = 'USD';
            } else {
                loanSizeUnit.value = 'NATIVE';
            }
        }

        // MODAL VALIDATIONS


        // ANIMATION LOGIC
        const animateProgress = () => {
            setTimeout(() => {
                const bar = document.getElementById('loanBar');
                const perc = parseInt(modalData.value.progress, 10);

                let p = 0;
                const animationId = setInterval(() => {
                    p++;
                    if (p > perc) {
                        clearInterval(animationId);
                    }
                    bar.style.transform = `rotate(${45 + p * 1.8}deg)`;
                }, 10);

                // Principal Bar Animation
                const barPrincipal = document.getElementById('loanBarPrincipal');
                const percPrincipal = parseInt(15, 10);

                let pPrincipal = 0;
                const animationIdPrincipal = setInterval(() => {
                    pPrincipal++;
                    if (pPrincipal > percPrincipal) {
                        clearInterval(animationIdPrincipal);
                    }
                    barPrincipal.style.transform = `rotate(${45 + pPrincipal * 1.8}deg)`;
                }, 10);
            }, 500);
        };

        const fetchMarketData = () => {
            // marketDataQuery.refetch();
        }

        onMounted(() => {
            if (isDesktop.value) {
                // console.log("Desktop preview");
                // your code
            }

            if (isMobileView.value) {
                isMobileView.value = true;
            }

            console.log("isMobileView", isMobileView)
            console.log("isDesktop", isDesktop)
            // can use only in setup()
            startLumin();
            const checkMarket = useRoute().query.market;

            /* console.log("Market:", useRoute().query.market);
            console.log("checkMarket:", checkMarket); */

            if (availableMarkets.value.includes(checkMarket)) {
                console.log("Market is available");
            } else {
                console.log("Market is not available");
                router.push({ path: '/' });
            }

            setTimeout(() => {
                checkWalletConnection();
                fetchLuminPriceData();
            }, 2500);

            setTimeout(() => {
                console.log("ALL MARKET OFFERS: ", marketOffers.value)
                console.log("ALL PROTOCOL OFFERS: ", allProtocolOffers.value)

                if (checkMarket == 'USD Coin') {
                    marketTicker.value = 'USDC';
                    for (let loan of marketOffers.value) {
                        loan.loan_size_min = loan.loan_size_native_min * usdcPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * usdcPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * usdcPrice.value;
                    }

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "USDC") {
                            marketPendingPrincipals.value += parseInt(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseInt(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "USDC");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */
                    
                    marketOffers.value.filter((loan) => loan.ticker == 'USDC');
                }
                if (checkMarket == 'Tether') {
                    marketTicker.value = 'USDT';
                    for (let loan of marketOffers.value) {
                        loan.loan_size_min = loan.loan_size_native_min * usdtPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * usdtPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * usdtPrice.value;
                    }

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "USDT") {
                            marketPendingPrincipals.value += parseInt(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseInt(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "USDT");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */

                    marketOffers.value = marketOffers.value.filter((loan) => loan.ticker == 'USDT');
                }
                if (checkMarket == 'Bitcoin') {
                    marketTicker.value = 'BTC';
                    for (let loan of marketOffers.value) {
                        loan.loan_size_min = loan.loan_size_native_min * bitcoinPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * bitcoinPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * bitcoinPrice.value;
                    }

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "USDT") {
                            marketPendingPrincipals.value += parseInt(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseInt(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "BTC");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */

                    marketOffers.value = marketOffers.value.filter((loan) => loan.ticker == 'BTC');
                }
                if (checkMarket == 'Ethereum') {
                    marketTicker.value = 'ETH';
                    for (let loan of marketOffers.value) {
                        loan.loan_size_min = loan.loan_size_native_min * ethereumPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * ethereumPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * ethereumPrice.value;
                    }
                    

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "ETH") {
                            marketPendingPrincipals.value += parseInt(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseInt(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "ETH");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */

                    marketOffers.value = marketOffers.value.filter((loan) => loan.ticker == 'ETH');
                }
                if (checkMarket == 'Mock') {
                    marketTicker.value = 'MOCK';
                    for (let loan of marketOffers.value) {
                        console.log("marketOffer -------------->", loan);
                        loan.loan_size_min = loan.loan_size_native_min * mockPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * mockPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * mockPrice.value;
                    }

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "MOCK") {
                            /* console.log("OFFER market.pendingPrincipalAmount ----------------->", formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            console.log("OFFER market.pendingInterestAmount ----------------->", formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals)); */

                            marketPendingPrincipals.value += parseFloat(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseFloat(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "MOCK");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */

                    marketOffers.value = marketOffers.value.filter(loan => loan.ticker == 'MOCK');
                }
                if (checkMarket == 'SMock') {
                    marketTicker.value = 'SMOCK';
                    for (let loan of marketOffers.value) {
                        loan.loan_size_min = loan.loan_size_native_min * smockPrice.value;
                        loan.loan_size_max = loan.loan_size_native_max * smockPrice.value;
                        loan.available_for_borrow = loan.available_for_borrow_native * smockPrice.value;
                    }
                    

                    // CALCULATE MARKET PENDING PRINCIPALS AND INTERESTS
                    for (let market of allProtocolOffers.value) {
                        if (market.status == "Open" && market.config.principalAsset.symbol == "SMOCK") {
                            marketPendingPrincipals.value += parseInt(formatUnits(market.pendingPrincipalAmount.toString(), market.config.principalAsset.decimals));
                            marketPendingInterests.value += parseInt(formatUnits(market.pendingInterestAmount.toString(), market.config.principalAsset.decimals));
                        }
                    }
                    
                    // FILTER PRINCIPAL ASSET ONLY FOR MARKET DATA FROM ROUTER FROM latestActionsProtocol
                    latestActionsProtocol.value = latestActionsProtocol.value.filter(data => data.symbol == "SMOCK");

                    /* console.log("marketPendingPrincipals ----------------->", marketPendingPrincipals.value);
                    console.log("marketPendingInterests ----------------->", marketPendingInterests.value); */

                    marketOffers.value = marketOffers.value.filter((loan) => loan.ticker == 'SMOCK');
                }
                if (walletString.value != '0x') {
                    marketOffers.value = marketOffers.value.filter(
                        offer => offer.loan_vanilla_data.lender != walletString.value
                    );
                }
                loadingOffers.value = false;
                loadingActionsProtocol.value = false;
                showMarket(checkMarket);
            }, 4000);
        });
    
        watch(
            [isModalOpen, modalData], 
            ([newIsModalOpen, newModalDataChange], 
            [oldIsModalOpen, prevModalDataChange]) => {
                // Reacting to isModalOpen changes
                /* console.log("isModalOpen:", newIsModalOpen); */
                if (!newIsModalOpen) {
                    console.log("Modal is closed");
                    currentStep.value = 1;
                    loanAcceptObject.value = {
                        configId: null,
                        principalAmount: null,
                        takingAssetPriceFeedIndex: null,
                        collateralAmount: [],
                        collateralAssetPriceFeedIndex: []
                    };
                }
                
                // Reacting to Principal and Collateral changes
                console.log("Borrow Amount:", modalData.value.borrowing_amount);
                console.log("New Borrow Amount:", newModalDataChange.borrowing_amount);

                let cumulativeCollateralAmount = 0;
                for (let collAmount of modalData?.value.loan_vanilla_data.acceptedAssets) {
                    /* console.log(collAmount.ticker + " Collateral Amount Change:", collAmount.amount_to_use); */
                    // CHECK ASSET VALUES AND ADD THEM TO CUMULATIVE COLLATERAL AMOUNT
                    if (collAmount.ticker == 'USDC') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * usdcPrice.value;
                    if (collAmount.ticker == 'USDT') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * usdtPrice.value;
                    if (collAmount.ticker == 'BTC') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * bitcoinPrice.value;
                    if (collAmount.ticker == 'ETH') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * ethereumPrice.value;
                    if (collAmount.ticker == 'MOCK') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * mockPrice.value
                    if (collAmount.ticker == 'SMOCK') cumulativeCollateralAmount += parseInt(collAmount.amount_to_use) * smockPrice.value
                }
                /* console.log("Cumulative Collateral Amount:", cumulativeCollateralAmount); */
                // CALCULATE HEALTH FACTOR WITH BORROWING AMOUNT AND CUMULATIVE COLLATERAL AMOUNT AND MAKE IT IN PERCENTAGES
                healthFactorCalc.value = (cumulativeCollateralAmount / parseInt(modalData.value.borrowing_amount)) * 100;
                healthFactorIndicator.value = ((cumulativeCollateralAmount / parseInt(modalData.value.borrowing_amount)) * 100);
                /* console.log("Borrowing Paylod Object: ", loanAcceptObject.value); */

                // MINIMUM modalData.value.borrowing_amount IS ALWAYS modalData.value.loan_size_native_min
                if (modalData?.value.borrowing_amount < modalData?.value.loan_size_native_min) {
                    modalData.value.borrowing_amount = modalData.value.loan_size_native_min;
                }
                rangeSliderPrincipal(modalData?.value.borrowing_amount, modalData?.value.loan_size_native_max);
            },
            { deep: true }
        );
  
        return {
            luminInstance,
            chainAssets,
            assetManager,
            availableMarkets,
            ethers,
            ERC20,
            formatUnits,
            createConfig,
            waitForTransaction,
            // market overall data
            marketTicker,
            marketSize,
            marketPendingPrincipals,
            marketPendingInterests,
            marketAvailable,
            marketBorrows,
            marketUtilisationRate,
            // data and methods
            screenWidth,
            isMobileView,
            isDesktop,
            unsupportedNetwork,
            ethereum,
            libIsInstantiated,
            walletConnected,
            walletStringShort,
            UIPreview,
            latestActionsProtocol,
            loadingActionsProtocol,
            mainUIheader,
            all_assets_deposits_usd,
            all_assets_borrows_usd,
            all_assets_paid_fees,
            loadingUserAsset,
            offersList,
            userWalletData,
            userOffersData,
            userBorrowsData,
            tabsManager,
            switchNetwork,
            connectWallet,
            checkWalletConnection,
            startChainDetection,
            showProfile,
            disconnectAddress,
            showMarket,
            filterMarketOffers,
            backToAllMarkets,
            startLumin,
            manageSidebar,
            tabsManagerOpen,
            validateDepositInputs,
            validateWithdrawInputs,
            navigateLoanDetailTabs,
            // images
            logoSrc,
            walletConnectionIcon,
            bscLogo,
            wBTC_32,
            wETH_32,
            USDC_32,
            tether_32,
            // Grey Asset Icons
            LUMIN_grey,
            wBTC_grey,
            wETH_grey,
            USDC_grey,
            USDT_grey,
            coins_illustration,
            twitter_white,
            telegram_white,
            medium_white,
            web_white,
            arbLogoSrc,
            // modal
            isModalOpen,
            modalTitle,
            modalContent,
            modalData,
            loanDetailDialog,
            payingInterestTerm,
            openModal,
            showInterestOptions,
            rangeSlide,
            rangeSlideInterest,
            rangeSliderPrincipal,
            rangeSliderCollateral,
            rangeOfferAmounts,
            rangeOfferAmountFinal,
            switchLoanSizeUnit,
            loanSizeUnit,
            available_loan_asset_indicator,
            minimum_loan_indicator,
            maximum_loan_indicator,
            allocated_deposit_indicator,
            collateral_adjustment_option,
            offerRangeMin,
            offerRangeMax,
            offerRangeNativeMin,
            offerRangeNativeMax,
            termsRangeMin,
            termsRangeMax,
            interestRangeMin,
            interestRangeMax,
            interestAssetAmount,
            principalAssetAmount,
            interestFuturePayoffBar,
            healthFactorCalc,
            healthFactorIndicator,
            principalFuturePayoffBar,
            setMaxAmount,
            // toast,
            isToastOpen,
            toastTitle,
            toastContent,
            toastData,
            openToast,
            newToastMessage,
            notifications,
            toasts,
            // Offer creation
            currentStep,
            numSteps,
            nextStep,
            stepClasses,
            setAllowance,
            assetDeposit,
            assetWithdraw,
            loanTaking,
            principalAssetFeeds,
            principalAssetOracleChange,
            collateralAssetOracleChange,
            interestAssetOracleChange,
            validateFirstStep,
            validateSecondStep,
            validateThirdStep,
            loadingOracleFeeds,
            // animation variable
            isWalletOffersExpanded,
            isWalletBorrowsExpanded,
            animateProgress,
            // Unnecessary logic
            formatPrice,
            changePriceSource,
            fetchCoinGeckoData,
            loadingAssetDollarValues,
            bitcoinPrice,
            ethereumPrice,
            usdcPrice,
            usdtPrice,
            mockPrice,
            smockPrice,
            cumulativeCollateralAssetsAmount,
            // DUMMY DATA
            offersListDummy,
            interestTokensList,
            loadingOffers,
            // FILTERS
            allMarketFilters,
            startMarketOffersFilter,
            showMarketFilter,
            marketFilterLoanSize,
            marketFilterCollateral,
            marketFilterTerms,
            marketFilterInterest,
            marketFilterInterestPerTerm,
            marketFilterRepaymentType,
            resetMarketOffersFilter,
            resetMarketOffersFilterBy,
            setFilterLoanSize,
            setFilterTerms,
            setFilterInterest,
            setFilterInterestPerTerm,
            filterActive,
            // Dialogs Validations
            loanConfigurationValidation,
            loanAcceptValidation,
            loanAcceptObject,
            // APOLLO DATA
            marketOffers,
            marketOffersData,
            latestActionsQuery,
            allProtocolOffers,
            latestActionsProfileQuery,
            fetchMarketData
        };
      },
      /* components: {
          Modal
      } */
};
</script>
    
<style lang="scss">
    @import "../styles/luminApp.scss";
</style>