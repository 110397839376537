<template>
  <div class="main-container" style="background-color: unset;border: none;padding-bottom: 150px;">
    <div class="lumin-info" style="text-align: center;padding-top: 120px;">
        <h1>Lumin Finance
            <img :src="logoSrc" alt="">
        </h1>
        <p style="opacity: 1;"> Effortless protocol deposits, all in one place </p>
    </div>
    
    <div class="deposits-header" >
        <div class="header-wrapper">
            <p> 1. Connect Wallet </p>
            <button v-if="!walletConnected" @click="connectWallet()" style="cursor: pointer;">
                <img :src=walletConnectionIcon alt="">
                <span> Connect Wallet </span>
            </button>

            <button v-if="walletConnected" disabled>
                <img :src=walletConnectionIn alt="">
                <span> {{ walletStringShort }} </span>
            </button>

            <p> 2. Select Asset </p>
            <div class="asset-selection">

                <div v-for="asset in depositAssets" :key="asset.contract"
                    :class="[asset.picked_asset ? 'picked-asset' : '', !walletConnected ? 'disabled-asset' : '']"
                    @click="pickDepositAsset(asset)">
                    <img :src="asset.img_src" :alt="asset.name">
                    <span> {{ asset.name }} </span>
                    <span v-if="asset.picked_asset" class="fa fa-check" style="font-family: 'FontAwesome';"></span>
                </div>
            </div>

            <p> 3. Enter the amount </p>
            <div class="asset-amount" style="">
                <input type="number"
                    step="0.1"
                    min="0"
                    :disabled="!walletConnected"
                    v-model="selectedAssetAmount"
                    :style="{'opacity': !walletConnected ? '0.25' : '1'}">
            </div>
            <div class="asset-amount-details" :style="{'opacity': !walletConnected ? '0.25' : '1'}">
              <span> ${{ formatPrice() }} </span>
              <span style="margin-left: auto; margin-right: 5px;"> Balance: {{ selectedAssetWalletAmount }} </span>
              <button @click="maxDeposit()">
                MAX
              </button>
            </div>

            <Transition name="button">
              <button class="deposit-button"
                :disabled="txSignPending"
                @click="setAllowance()" 
                v-if="enableAllowanceAction()"
                style="cursor: pointer;margin-top: 5px;margin-bottom: 25px;">
                  <span v-if="!txSignPending"> Approve </span>
                  <div v-if="txSignPending" class="lumin-sign-wait"><div></div><div></div><div></div><div></div></div>
              </button>
            </Transition>

            <button class="deposit-button" 
              :disabled="!walletConnected || invalidAmount() || enableAllowanceAction() || txSignPending"
              @click="assetDeposit()"
              v-if="true"
              style="cursor: pointer;margin-top: 5px;margin-bottom: 25px;">
                <span v-if="!txSignPending"> Deposit → </span>
                <div v-if="txSignPending" class="lumin-sign-wait"><div></div><div></div><div></div><div></div></div>
            </button>
        </div>
    </div>
  </div>

  <Teleport to="#toast">
      <Transition name="toast">
          <div class="toast" v-if="isToastOpen" style=""
              @click="isToastOpen = false">
              <!-- :style="{'top': 60 * (index) + 'px'}"  -->
              <p> {{ toastTitle }} </p>
          </div>
      </Transition>
  </Teleport>
</template>

<script>
import { ref, onMounted } from 'vue';
import { ethers, Contract, formatUnits } from 'ethers';

/* import { Lumin } from 'lumin-lib/src/Lumin/Lumin';
import { Database, RemoteType } from 'lumin-lib/src/Database/Database';
import { ChainType } from 'lumin-lib/src/Chain/ChainType';
import { ProviderType } from 'lumin-lib/src/Provider/ProviderType';
import { DAO } from 'lumin-lib/src/Database/DAO/DAO'; */

import { ROARR, Roarr as log } from 'roarr';
import { createLogWriter } from '@roarr/browser-log-writer';

import ERC20 from '@/components/ERC20.json';

ROARR.write = (message) => {
    // console.log(JSON.parse(message));
};

import { createConfig, configureChains, waitForTransaction } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { bscTestnet } from '@wagmi/core/chains'

log.write = createLogWriter();

import logoSrc from '@/assets/Lumin_Logo_Keylines.png';
import twitterWhite from '@/assets/twitter-white.png';
import telegramWhite from '@/assets/telegram-white.png';
import webWhite from '@/assets/web-white.png';
import gitbookLogo from '@/assets/gitbook.svg';
import walletConnectionIcon from '@/assets/wallet-connection-icon.png';
import walletConnectionIn from '@/assets/wallet-connection-in.png';

import wBTC_32 from '@/assets/wBTC_32.png';
import wETH_32 from '@/assets/wETH_32.png';
import USDC_32 from '@/assets/USDC_32.png';
import tether_32 from '@/assets/tether_32.png';


export default {
  name: 'LuminFaucet',
  setup() {
    // LUMIN VARs
    let luminInstance;
    let chainAssets;
    let dao;

    // WAGMI SETUP
    const { chains, publicClient, webSocketPublicClient } = configureChains(
        [bscTestnet],
        [publicProvider()],
    )

    const config = createConfig({
        bscTestnet,
        publicClient,
        webSocketPublicClient,
    })

    const notifications = ref([]);
    const ethereum = ref(window['ethereum']);
    const walletString = ref("");
    const walletStringShort = ref("");
    const walletConnected = ref(false);
    const unsupportedNetwork = ref(false);

    // Assets data prices
    let bitcoinPrice = ref(0);
    let ethereumPrice = ref(0);
    let usdcPrice = ref(0);
    let usdtPrice = ref(0);

    let selectedAssetAmount = ref(0);
    let selectedAssetAllowanceAmount = ref(0);
    let selectedAssetWalletAmount = ref(0);
    let selectedAssetTicker = ref('');
    let selectedAssetContract = ref('');
    
    let depositAssets = ref([
      {
          name: " ₿ BITCOIN ",
          ticker: "BTC",
          contract: "0x8D88e5E43b396F4bf84E1AEB80A9567dB2785Ea9",  
          shorthand_contract: "0x8D....5Ea9",
          avaliable: true,
          processing_tx: false,
          picked_asset: false,
          img_src: wBTC_32,
          decimals: 18,
          price: 30839.92,
          amount: 0,
          allowed_amount: 0
      },
      {
          name: " Ξ ETHEREUM ",
          ticker: "ETH",
          contract: "0x6E1949ECA01582ef688B677A9dE8D7fA7465323E",     
          shorthand_contract: "0x6E....323E",
          avaliable: true,
          processing_tx: false,
          picked_asset: false,
          img_src: wETH_32,
          decimals: 18,
          price: 1835.91,
          amount: 0,
          allowed_amount: 0
      },
      {
          name: " $ USDC ",
          ticker: "USDC",
          contract: "0x22299281a0fFF5c1687A380aB1dbCc14A426B428",      
          shorthand_contract: "0x22....B428",
          avaliable: true,
          processing_tx: false,
          picked_asset: false,
          img_src: USDC_32,
          decimals: 18,
          price: 1,
          amount: 0,
          allowed_amount: 0
      },
      {
          name: " ₮ USDT ",
          ticker: "USDT",
          contract: "0xUSDT",
          shorthand_contract: "0x....USDT",
          avaliable: false,
          processing_tx: false,
          picked_asset: false,
          img_src: tether_32,
          decimals: 18,
          price: 1,
          amount: 0,
          allowed_amount: 0
      }
    ]);

    let isToastOpen = ref(false);
    let toastTitle = ref('');
    let toastContent = ref('');
    let toastData = ref(null);

    let txSignPending = ref(false);

    const startLumin = async () => {
      // OLD LUMIN LIB LOGIC
        /* // INIT COUCHDB
        const db = new Database({ debug: true });
        await db.initialize();

        // INIT CONNECTION WITH REMOTE LUMIN COUCHDB
        db.setRemote({
            remoteType: RemoteType.Push,
            url: 'https://db.lumin.finance/db-testnet',
            username: 'client',
            password: 'client'
        });

        // INIT DAO AND CHECK CHAINS
        dao = new DAO(db, log);

        luminInstance = new Lumin({ chainId: 97 }, dao);

        await luminInstance.initialize();
        await luminInstance.start();

        const chain = luminInstance.chains.entries().next().value[1];

        chainAssets  = await dao.asset.getAssets(chain.config); */
    };

    const connectWallet = async () => {
      if (ethereum.value) {
        try {
          const accounts = await ethereum.value.request({ method: 'eth_requestAccounts' });
          walletString.value = accounts[0];
          walletStringShort.value = `${accounts[0].substring(0, 6)}...${accounts[0].substring(38)}`;
          walletConnected.value = true;

          // FETCH USER DATA ON PROTOCOL LEVEL
          const chain = luminInstance.chains.entries().next().value[1];
          const walletBalances = await chain.assetManager.balancesOf(walletString.value);
          const luminManagerAddress = chain.config.contracts.assetManager.address;

          let temp_provider = new ethers.BrowserProvider(window['ethereum']);
          const signer = await temp_provider.getSigner();

          console.log("USER BALANCES", walletBalances.assetIds);
          console.log("USER BALANCES ON CHAIN", chain);

          for (let index = 0; index < walletBalances.assetIds.length; ++index) {
              const asset = chainAssets.find((asset) => asset.assetId == walletBalances.assetIds[index]);
              if (asset) {
                  console.log(`Asset ${asset._id}`);

                  /* console.log(`${Object.keys(asset)}`);
                  console.log(`${Object.values(asset)}`); */

                  /* const depositUser = walletBalances.depositsUser[index];
                  if (depositUser) {
                      console.log(`  user deposit`);
                      console.log(`    - total: ${ethers.formatEther(depositUser.total)}`);
                      console.log(`    - locked: ${ethers.formatEther(depositUser.locked)}`);
                  } */

                  const balanceUser = walletBalances.balances[index];
                  /* if (balanceUser !== undefined) {
                      console.log(`  wallet balance`);
                      console.log(`    - total: ${ethers.formatEther(balanceUser)}`);
                  } */

                  for (let chainContract of asset.chains) {
                      console.log(`Asset Chain ID ${chainContract.chainId}`);
                      console.log(`Asset Chain Contract ${chainContract.contract}`);

                      if (chainContract.chainId == 97) {
                        let temp_erc20Contract = new Contract(
                            chainContract.contract,
                            ERC20,
                            signer
                        );

                        let allowanceCheck = await temp_erc20Contract['allowance'](walletString.value, luminManagerAddress);
                        console.log(typeof allowanceCheck)
                        console.log(Number(allowanceCheck))

                        let allowanceAmount = allowanceCheck.toString();
                        let assetAllowed = parseFloat(formatUnits(allowanceAmount, 18));

                        console.log(`Allowed ${asset.symbol} on protocol level`, assetAllowed); // Output: 123456789

                        depositAssets.value.find(user_asset => {
                          if (user_asset.ticker == asset.symbol) {
                            user_asset.allowed_amount = assetAllowed;
                          }
                        });
                      }
                  }

                  depositAssets.value.find(user_asset => {
                    if (user_asset.ticker == asset.symbol) {
                      user_asset.amount = parseFloat(ethers.formatEther(balanceUser));
                    }
                  });
                  console.log(depositAssets);
              } else {
                  console.error(`Unknown asset at index ${index}: ${walletBalances.assetIds[index]}`);
              }
          }

          startChainDetection();


        } catch (err) {
          console.error(err);
        }
      } else {
        console.log('Please install MetaMask!');
      }
    };

    const startChainDetection = async () => {
      window.ethereum
          .on("chainChanged", (chainId) => {
              // console.log("Chain Detection Trigger ---------> ", chainId)

              if (chainId != '0x61') {
                  unsupportedNetwork.value = true;
              } else {
                  unsupportedNetwork.value = false;
              }

          })
          .on("accountsChanged", () => {
              // console.log("User Wallet Changed!")
              disconnectAddress();
          });

      window.ethereum.request({ method: 'eth_chainId' })
          .then((chainId) => {
              // console.log("Chain Detection Trigger ---------> ", chainId)

              if (chainId != '0x61') {
                  unsupportedNetwork.value = true;
              } else {
                  unsupportedNetwork.value = false;
              }

          })
          .catch((err) => {
              console.error(err);
          });
    };

    const pickDepositAsset = (providedAsset) => {
      selectedAssetAmount.value = 0;

      for (let asset of depositAssets.value) {
        asset.picked_asset = false;
      }

      providedAsset.picked_asset = true;
      selectedAssetTicker.value = providedAsset.ticker;
      selectedAssetWalletAmount.value = providedAsset.amount;
      selectedAssetContract.value = providedAsset.contract;
      selectedAssetAllowanceAmount.value = providedAsset.allowed_amount;
    };

    const maxDeposit = () => {
      selectedAssetAmount.value = selectedAssetWalletAmount.value;
    };

    const invalidAmount = () => {
      if (selectedAssetAmount.value > selectedAssetWalletAmount.value) {
        return true;
      } else {
        return false;
      }
    };

    const enableAllowanceAction = () => {
      if (selectedAssetAmount.value > selectedAssetAllowanceAmount.value) {
        return true;
      } else {
        return false;
      }
    }

    const setAllowance = async () => {
        txSignPending.value = true;
        
        let temp_provider = new ethers.BrowserProvider(window['ethereum']);
        const signer = await temp_provider.getSigner();

        let erc20Contract = new ethers.Contract(
            selectedAssetContract.value,
            ERC20,
            signer
        );

        const chain = luminInstance.chains.entries().next().value[1];
        const luminManagerAddress = chain.config.contracts.assetManager.address;

        try {
            const tx = await erc20Contract.approve(
                luminManagerAddress,
                ethers.parseUnits(selectedAssetAmount.value.toString(), 18)
            );

            const waitTransactionEnd = waitForTransaction({
                chainId: 97,
                hash: tx.hash
            });

            if (tx.hash) {
                openToast("Approval is being processed");
            }

            waitTransactionEnd.then(res => {
                openToast("Approval is finished");
                selectedAssetAllowanceAmount.value = selectedAssetAmount.value;
            })

            txSignPending.value = false;
        } catch (error) {
            txSignPending.value = false;
            console.log(error);
        }
    };

    const assetDeposit = async () => {
        console.log("Deposit Asset")
        txSignPending.value = true;

        let temp_provider = new ethers.BrowserProvider(window['ethereum']);
        const signer = await temp_provider.getSigner();

        const amount = ethers.parseEther(selectedAssetAmount.value.toString());
        console.log(amount)

        /* const chain = luminInstance.chains.entries().next().value[1];
        let chainAsset = null;

        chainAssets  = await dao.asset.getAssets(chain.config);
        console.log(chainAssets)

        const asset = chainAssets.find((asset) => asset.symbol == selectedAssetTicker.value);
        if (asset) {
          console.log(asset)
          chainAsset = asset;
        }

        try {
            const txDeposit = await chain.createTxDeposit(chainAsset, amount);
            const tx = await signer.sendTransaction(txDeposit);

            const waitTransactionEnd = waitForTransaction({
                chainId: 97,
                hash: tx.hash
            });

            if (tx.hash) {
                openToast("Deposit is being processed...");
            }

            waitTransactionEnd.then(res => {
                openToast("Deposit successful!");
                setTimeout(() => {
                  connectWallet();
                }, 3000);
            })

            txSignPending.value = false;
        } catch (error) {
            txSignPending.value = false;
            console.log(error);
        } */
    };

    const disconnectAddress = () => {
        // Remove the key 'connected_address' from local storage
        localStorage.removeItem('connected_address');
        walletConnected.value = false;
        walletString.value = "";
        walletStringShort.value = "";
    };

    const addNetwork = async () => {
      const testnetNetwork = {
        chainId: "0x61",
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: {
          name: "tBNB",
          symbol: "tBNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-testnet.publicnode.com"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
      }

      try {
        const wasAdded = await ethereum.value.request({
          method: 'wallet_addEthereumChain',
          params: [testnetNetwork],
        });

        if (wasAdded) {
          console.log('Network Added to MM!');
        } else {
          console.log('User Denied Network Addition!');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const switchNetwork = async () => {
      const provider = window['ethereum'];

      if (!provider) {
        console.log("Metamask is not installed, please install!");
      } else {
        
        const chainId = await provider.request({ method: 'eth_chainId' });
        const goerliTestChainId = '0x61'

        if (chainId === goerliTestChainId) {

          console.log("Bravo!, you are on the correct network");

        } else {
          console.log("oulalal, switch to the correct network")
          try {

            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x61'}],
            });
            /* console.log("You have succefully switched to Arbitrum Test network") */
            this.unsupportedNetwork = false;

          } catch (switchError) {

            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === goerliTestChainId) {
              console.log("This network is not available in your metamask, please add it")
            }
            console.log("Failed to switch to the network")

          }
        }
      }
    };

    const addToMetamask = async (asset) => {
        console.log(asset);
        // Add to Metamask logic here
        const tokenAddress = asset.contract;
        const tokenSymbol = asset.ticker;
        const tokenDecimals = asset.decimals;
        
        try {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          const wasAdded = await ethereum.value.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
              },
            },
          });
        
          if (wasAdded) {
            console.log('Token Added to MM!');
          } else {
            console.log('User Denied Token!');
          }
        } catch (error) {
          console.log(error);
        }
    };

    const validatePrice = (asset) => {
      console.log(asset.value)
      if (asset.price < asset.min || asset.price > asset.max) {
        asset.price = asset.previous_price;
        console.log('Invalid price!');
      } else {
        console.log('Valid price!');
      }
    };

    const fetchCoinGeckoData = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd');
        const data = await response.json();

        bitcoinPrice.value = data.bitcoin.usd;
        ethereumPrice.value = data.ethereum.usd;
        usdcPrice.value = data['usd-coin'].usd;
        usdtPrice.value = data.tether.usd;

        depositAssets.value.forEach((asset) => {
          if (asset.ticker === 'BTC') {
            asset.price = bitcoinPrice;            
            asset.previous_price = bitcoinPrice;
          } else if (asset.ticker === 'ETH') {
            asset.price = ethereumPrice;            
            asset.previous_price = ethereumPrice;
          } else if (asset.ticker === 'USDC') {
            asset.price = usdcPrice;            
            asset.previous_price = usdcPrice;
          } else if (asset.ticker === 'USDT') {
            asset.price = usdtPrice;            
            asset.previous_price = usdtPrice;
          }
          asset.min = asset.price * 0.8;
          asset.max = asset.price * 1.2;
        });

        /* console.log('Bitcoin Price:', bitcoinPrice);
        console.log('Ethereum Price:', ethereumPrice);
        console.log('USDC Price:', usdcPrice);
        console.log('USDT Price:', usdtPrice); */

        /* console.log(depositAssets.value) */
      } catch (error) {
        console.log(error);
      }
    };

    const formatPrice = () => {
      if (selectedAssetTicker.value == 'ETH') {
          let val = selectedAssetAmount.value * ethereumPrice.value;
          val = (val/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      if (selectedAssetTicker.value == 'BTC') {
          let val = selectedAssetAmount.value * bitcoinPrice.value;
          val = (val/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      if (selectedAssetTicker.value == 'USDC') {
          let val = selectedAssetAmount.value * usdcPrice.value;
          val = (val/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      if (selectedAssetTicker.value == 'USDT') {
          let val = selectedAssetAmount.value * usdtPrice.value;
          val = (val/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      if (selectedAssetTicker.value == '') {
          let val = selectedAssetAmount.value;
          val = (val/1).toFixed(2).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
    }

    // Toast logic
    const openToast = async (title, paragraph, data) => {
        isToastOpen.value = true;

        toastTitle.value = title;

        console.log(toastTitle.value);
        console.log(toastContent.value);
        console.log(toastData.value);

        setTimeout(() => {
            toastTitle.value = "Deposit is on the way....";
            isToastOpen.value = null;
            isToastOpen.value = false;
        }, 4000);
    };

    onMounted(async () => {
      // On mounted logic here
      startLumin()
      fetchCoinGeckoData()
    });

    return {
      // Lumin
      startLumin,
      luminInstance,
      chainAssets,
      ERC20,
      notifications,
      ethereum,
      ethers,
      walletString,
      walletStringShort,
      walletConnected,
      unsupportedNetwork,
      validatePrice,
      depositAssets,
      pickDepositAsset,
      maxDeposit,
      invalidAmount,
      setAllowance,
      assetDeposit,
      enableAllowanceAction,
      // network logic
      connectWallet,
      startChainDetection,
      switchNetwork,
      addNetwork,
      addToMetamask,
      fetchCoinGeckoData,
      formatPrice,
      selectedAssetAmount,
      selectedAssetAllowanceAmount,
      selectedAssetWalletAmount,
      selectedAssetTicker,
      selectedAssetContract,
      bitcoinPrice,
      ethereumPrice,
      usdcPrice,
      usdtPrice,
      // images
      logoSrc,
      twitterWhite,
      telegramWhite,
      webWhite,
      gitbookLogo,
      walletConnectionIcon,
      walletConnectionIn,
      // toast,
      isToastOpen,
      toastTitle,
      toastContent,
      toastData,
      openToast,
      txSignPending,
    };
    }
}
</script>

<style scoped lang="scss">
  @import "../styles/luminOnePageDeposit.scss";
</style>